import { CardActions, CardContent, CardHeader, CardMedia, Typography, Button, TextField, FormControlLabel, FormControl, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { USCard } from "../../../../../USCommonComponents/USCardComponent";
import USIOSSwitch from "../../../../../USCommonComponents/USIOSSwitch";
import { getRequest } from "../../../../../../Api/ApiService/Service";
import { addNotification } from "../../../../../../reduxSettings/reduxSlices/NotificationSlice";

export default function TemplateSettings({selectedTemplateId, backStep, createSite}) {
    const [siteDetails, setSiteDetails] = useState({
        "Name" :"",
        "Description" :"",
        "Country" :"",
        "DefaultCurrency" :"",
        "DefaultLanguage" :"",
        "OrderActivationStatus" :"",
        "OrderLifeCycleType" :"",
        "PricingStrategy" :"",
        "ResultsPerPage" :"",
        "SiteDomen" : "",
        "isActive" : false,
        "SelfDomain" : false
    });

    const [currentTemplate, setCurrentTemplate] = useState(null);

    const handleFieldValue = e => {
        console.log('new value :: ',e);
        const fieldName = e.target.name;
        const fieldValue = e.target?.hasOwnProperty('checked') ? e.target?.checked : e.target?.value;
        //const value = e.target.value != null && e.target.value.hasOwnProperty('value') ? e.target.value.value : e.target.value ;
        setSiteDetails(existingValues => ({
            ...existingValues,
            [fieldName]: fieldValue,
        }))
    }

    useEffect(() => {
        fetchData();
    },[selectedTemplateId])

    const fetchData = async () => {
        try {
            const response = await getRequest('/user/record/storetemplate/' + selectedTemplateId);
            console.log("response.data.data", response);
            setCurrentTemplate(response.data.data.recordData);
        } catch (error) {
            dispatch(addNotification({"type" : "error", "message" : error, "title" : "load error"}));
        }
    }

    const [selfDomen, setSelfDomen] = useState();
    const handleChangeSelfDomen = () => {
        setSelfDomen(!selfDomen);
    }

    const [selfHosting, setSelfHosting] = useState();
    const handleChangeSelfHosting = () => {
        setSelfHosting(!selfHosting);
    }
    return (
        <div className="row mt-10">
            <div className="col-sm-12 col-md-6 col-lg-6">
                {currentTemplate && <USCard>
                    <CardHeader
                        title={currentTemplate.Name}
                        subheader="September 14, 2016"
                    />
                    <CardMedia
                        component="img"
                        width="100"
                        image={currentTemplate.Image}
                        alt="Paella dish"
                    />
                    <CardContent>
                        <Typography variant="body2" color="text.secondary">
                            {currentTemplate.Description}
                        </Typography>
                    </CardContent>
                </USCard>}
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
                <USCard>
                    <CardContent>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <Typography variant="body1"> Запустите свой сайт, используя поддомен *.bp.by</Typography>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10">
                                <TextField name="Name" value={siteDetails["Name"]} size="small" placeholder="Site name" onChange={(e) => handleFieldValue(e)}/>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10">
                                <TextField name="SiteDomen" value={siteDetails["SiteDomen"]} size="small" placeholder="Site domen" onChange={(e) => handleFieldValue(e)}/>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10">
                                <Stack direction="row" spacing={2}>
                                    <Typography variant="body1">Self Domen</Typography>
                                    <USIOSSwitch name="SelfDomain"  onChange={(e) => handleFieldValue(e)} checked={selfDomen} />
                                </Stack>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10">
                                <Stack direction="row" spacing={2}>
                                    <Typography variant="body1">Self Domain</Typography>
                                    <USIOSSwitch name="SelfHosting"  onChange={(e) => handleFieldValue(e)} checked={selfHosting} />
                                </Stack>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10">
                                <Typography variant="body1" > Domen settings(можно заполнить позже)</Typography>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10">
                                <TextField name="Description" value={siteDetails["Description"]} size="small" placeholder="Description" onChange={(e) => handleFieldValue(e)}/>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10">
                                <TextField name="DefaultCurrency" value={siteDetails["DefaultCurrency"]} size="small" placeholder="Default Currency" onChange={(e) => handleFieldValue(e)}/>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10">
                                <TextField name="Country" value={siteDetails["Country"]} size="small" placeholder="Country" onChange={(e) => handleFieldValue(e)}/>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10">
                                <TextField name="DefaultLanguage" value={siteDetails["DefaultLanguage"]} size="small" placeholder="Default Language" onChange={(e) => handleFieldValue(e)}/>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10">
                                <TextField name="OrderActivationStatus" value={siteDetails["OrderActivationStatus"]} size="small" placeholder="Order Activation Status" onChange={(e) => handleFieldValue(e)}/>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10">
                                <TextField name="OrderLifeCycleType" value={siteDetails["OrderLifeCycleType"]} size="small" placeholder="Order Life Cycle Type" onChange={(e) => handleFieldValue(e)}/>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10">
                                <TextField name="PricingStrategy" value={siteDetails["PricingStrategy"]} size="small" placeholder="Pricing Strategy" onChange={(e) => handleFieldValue(e)}/>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10">
                                <TextField name="ResultsPerPage"value={siteDetails["ResultsPerPage"]} size="small" placeholder="Results Per Page" onChange={(e) => handleFieldValue(e)}/>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10">
                                <Stack direction="row" spacing={2}>
                                    <Typography variant="body1">isActive</Typography>
                                    <USIOSSwitch name="isActive"  onChange={(e) => handleFieldValue(e)} checked={selfHosting} />
                                </Stack>
                            </div>
                        </div>
                    </CardContent>

                    <CardActions>
                        <Button size="small" className="ml-10" fullWidth variant="outlined" onClick={backStep}>Back</Button>
                        <Button size="small" variant="outlined" fullWidth onClick={() => createSite(siteDetails)}>Создать</Button>
                    </CardActions>
                </USCard>
            </div>
        </div>
    )
}
