import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

export default function USAlertDialog({isOpen, dialogTitle, dialogDesctiption, handleAction}) {

    return (
        <Dialog
            open={isOpen}
            onClose={() => handleAction(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {dialogTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogDesctiption}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleAction(false)}>Disagree</Button>
                <Button onClick={() => handleAction(true)} autoFocus>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    )
}
