/**
 * Sign Up page (Register)
 */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';


// app config
import AppConfig from 'Constants/AppConfig';
import { Button, Fab, FormGroup, Input } from '@mui/material';
import { useDispatch } from 'react-redux';
import { registerUser } from '../../../reduxSettings/reduxSlices/userAuthSlice';
import { useHistory } from 'react-router-dom';
import { USCard, USCardContent } from '../USCardComponent';

//   redux action
//   import {
//      signupUserInFirebase,
//      signinUserWithFacebook,
//      signinUserWithGoogle,
//      signinUserWithGithub,
//      signinUserWithTwitter
//   } from 'Actions';

 function USRegister({onChangeToRegister}) {

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);
    const [history, setHistory] = useState();
    const dispatch = useDispatch();
    const navigate = useHistory();

    const onUserRegister = () => {
        if (email !== '' && password !== '') {
            dispatch(registerUser({
                email: email,
                password: password,
                password_confirmation : password
            })).then(() => {
                navigate.push('/user');
            }).catch((error) => {
                dispatch(addNotification({"type" : "success","title":"Product Added", "message":  error}));
            });
        }
    }

    /**
     * On User Signup
     */
    const onUserSignUp = () => {
       if (email !== '' && password !== '') {
          //signupUserInFirebase({ email, password }, history);
       }
    }

    return (

        <USCard>
            <USCardContent>
                <div className="row row-eq-height">
                    <div className="col-12">
                    <div className="session-body text-center">
                        <div className="session-head mb-15 mt-15">
                            <h2 className="font-weight-bold">Зарегистрироваться</h2>
                        </div>
                        <FormGroup className="has-wrapper">
                            <Input type="mail" value={email} name="user-mail" id="user-mail" className="has-input input-lg" placeholder="Enter Email Address" onChange={(e) => setEmail(e.target.value)} />
                            <span className="has-icon"><i className="ti-email"></i></span>
                        </FormGroup>
                        <FormGroup className="has-wrapper">
                            <Input value={password} type="Password" name="user-pwd" id="pwd" className="has-input input-lg" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                            <span className="has-icon"><i className="ti-lock"></i></span>
                        </FormGroup>
                        <FormGroup className="mb-15 mt-15">
                            <Button
                                className="btn-info text-white btn-block w-100"
                                variant="contained"
                                size="large"
                                onClick={() => onUserRegister()}>
                                Sign Up
                            </Button>
                        </FormGroup>

                        <FormGroup className="mb-15 mt-15">
                            <Button
                                color="primary"
                                className="btn-block text-white w-100"
                                variant="contained"
                                size="large"
                                onClick={() => onChangeToRegister()}>
                                Sign In
                            </Button>
                        </FormGroup>

                        <p className="mb-20">or sign in with</p>
                        <Fab variant="round" size="small"
                            className="btn-facebook mr-15 mb-20 text-white"
                            onClick={() => signinUserWithFacebook(history)}
                        >
                            <i className="zmdi zmdi-facebook"></i>
                        </Fab>
                        <Fab variant="round" size="small"
                            className="btn-google mr-15 mb-20 text-white"
                            onClick={() => signinUserWithGoogle(history)}
                        >
                            <i className="zmdi zmdi-google"></i>
                        </Fab>
                        <Fab variant="round" size="small"
                            className="btn-twitter mr-15 mb-20 text-white"
                            onClick={() => signinUserWithTwitter(history)}
                        >
                            <i className="zmdi zmdi-twitter"></i>
                        </Fab>
                        <Fab variant="round" size="small"
                            className="btn-instagram mr-15 mb-20 text-white"
                            onClick={() => signinUserWithGithub(history)}
                        >
                            <i className="zmdi zmdi-github-alt"></i>
                        </Fab>
                        <p className="text-muted">By signing up you agree to {AppConfig.brandName}</p>
                        <p><Link to="/terms-condition" className="text-muted">Terms of Service</Link></p>
                    </div>
                    </div>
                </div>
            </USCardContent>
        </USCard>
    );
 }

 // map state to props
//  const mapStateToProps = ({ authUser }) => {
//     const { loading } = authUser;
//     return { loading };
//  };

export default USRegister;
//  connect(mapStateToProps, {
//     signupUserInFirebase,
//     signinUserWithFacebook,
//     signinUserWithGoogle,
//     signinUserWithGithub,
//     signinUserWithTwitter
//  })(SignupFirebase);
