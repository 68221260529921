import React, { useState } from 'react'
import USContactStep from './USContactStep'
import USCheckContract from './USCheckContract'
import { Avatar, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, Modal } from '@mui/material'
import { red } from '@mui/material/colors'
import { useDispatch, useSelector } from 'react-redux'
import { closeContractDialog, updateContractOptions } from '../../../../reduxSettings/reduxSlices/systemSlice'
import USProductStep from './USProductStep'
import { addNotification } from '../../../../reduxSettings/reduxSlices/NotificationSlice'
import { updateSalesContact } from '../../../../Api/SalesConsoleRequests/USSalesRequestHandler'

export default function USNewContractDialog() {
    const {opportunityId} = useSelector((state) => state.system.contractOptions);
    const isOpen = useSelector((state) => state.system.newContractDialog);
    const [step, setStep] = useState(0);

    const [contact, setContact] = useState();

    const handleUpdateRecord = (formData) => {
        setContact(formData);
    }

    const handleNextStep = () => {
        if(step < 2) {
            if(step == 0) {
                setStep(step + 1);
            } else {
                if(step == 1 && contact) {
                    updateContractDetails(contact);
                    setStep(step + 1);
                } else {
                    console.log('contactPasport', contact);
                    setStep(step + 1);
                }
            }

        }
    }

    const handlePrevStep = () => {
        if(step > 0) {
            if(step == 1 && contact) {
                updateContractDetails(contact);
            }
            setStep(step - 1);
        }
    }

    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(closeContractDialog());
    }

    const updateContractDetails = async(currentData) => {
        try {
            console.log('update currentCont', currentData);

            const response = await updateSalesContact(currentData);
            console.log('update record', response);
            if(response?.id) {
                dispatch(addNotification({"type" : "success", "message" : 'updated'}));
            } else {
                dispatch(addNotification({"type" : "error", "message" : "Error record creation"}));
            }
        } catch(error) {
            console.error(error);
            dispatch(addNotification({"type" : "info", "message" : error}));
        }
    }

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="m-10"
            style={{ height: "90%", overflowY: 'scroll' }}
        >
            <Card>
                <CardHeader
                    avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        T
                    </Avatar>
                    }
                    action={
                        <>
                            <ButtonGroup>
                                <Button onClick={handleClose}>Close</Button>
                            </ButtonGroup>
                        </>
                    }
                    title="Новый контракт"
                />
                <CardContent style={{ minHeight: "400px" }}>
                    {step == 0 && <USProductStep opportunityId={opportunityId}/>}
                    {step == 1 && <USContactStep id={opportunityId} objectName="contact" handleUpdate={handleUpdateRecord}/>}
                    {step == 2 && <USCheckContract id={opportunityId} contactPasport={contact['contactPasport']}
                                                    issuedDate={contact['issuedDate']}/>}
                </CardContent>
                <CardActions>
                    {step != 2 &&<Button onClick={handlePrevStep}>Previous</Button>}
                    {step != 2 &&<Button onClick={handleNextStep}>Next</Button>}
                    {step == 2 &&<Button onClick={handleClose}>Finish</Button>}
                </CardActions>
            </Card>
        </Modal>
    )
}
