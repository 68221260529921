import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import IntlMessages from 'Util/IntlMessages';
import { useHistory } from "react-router-dom";
import { addNotification } from "../../../../reduxSettings/reduxSlices/NotificationSlice";
import { deleteRecord, getRecordById, saveRecord } from "../../../../Api/SalesConsoleRequests/USSalesRequestHandler";
import { useParams } from 'react-router-dom';
import { USCard, USCardContent } from "../../../USCommonComponents/USCardComponent";
import USTable from "../../../USCommonComponents/USTable";
import { Element } from "../../../../helpers/RouteHelper";

export default function NewEditRecord() {
    const { id } = useParams();
    const objectName = 'productscompany';
    const navigate = useHistory();

    const [readRecord, setReadRecord] = useState(true);
    const [sections,setSections] = useState(null);
    const [objectFields, setObjectFields] = useState(null);
    const [relatedList, setRelatedList] = useState(null);

    const dispatch = useDispatch();
    const handleFieldValue = e => {
        console.log('e.target', e.target.value);
        console.log('e.target', e.target.name);
        const fieldName = e.target.name;
        //const value = e.target.value != null && e.target.value.hasOwnProperty('value') ? e.target.value.value : e.target.value ;
        setObjectFields(existingValues => ({
            ...existingValues,
            [fieldName]: e.target.value,
        }))
    }

    useEffect(() => {
        console.log('useEffect(() =>', id);
        if(id)
            initData(id);
    }, [id, objectName]);

    const initData = async (recId) => {
        try {
            const currentRecord = await getRecordById(objectName,recId);
            console.log('response', currentRecord);
            let hasSections = currentRecord?.layout?.sections ? true : false;
            let hasData = currentRecord?.recordData ? true : false;
            let hasRelatedLists = currentRecord?.layout?.relatedList ? true : false;

            if(hasData) {
                setObjectFields(currentRecord.recordData);
            }
            if(hasSections) {
                setSections(currentRecord.layout.sections);
            }
            if(hasRelatedLists) {
                console.log('currentRecord.data.data.layout.relatedList',currentRecord.layout.relatedList);
                setRelatedList(currentRecord.layout.relatedList);
            }
        } catch (error) {
            console.error(error);
            dispatch(addNotification({"type" : "info", "message" : error}));
        }
    }

    const chekIfDependedLookup = (currentField) => {
        const propExist = currentField.hasOwnProperty('depended_picklist');
        if(propExist) {
            const hasField = objectFields.hasOwnProperty(currentField.depended_picklist);
            if(hasField) {
                return objectFields[currentField.depended_picklist];
            }
        }
        return null;
    }

    const [isNewOpen, setIsNewOpen] = useState(false);
    const handleSaveNew = async (newRecord) => {
        try {
            const response = await postRequest('/company/edit/'+objectName, newRecord);
            if(response.status == 200) {
                setIsNewOpen(false);
                dispatch(addNotification({"type" : "info", "message" : response.data.data.message}));
            } else {
                dispatch(addNotification({"type" : "error", "message" : "New Record Crea"}));
            }

        } catch(error) {
            console.error(error);
            dispatch(addNotification({"type" : "info", "message" : error}));
        }
    }

    const [newObjectForDialog, setNewObjectForDialog] = useState(null);
    const handleNewRecord = (newObjectName) => {
        setNewObjectForDialog(newObjectName);
        setIsNewOpen(true);
    }

    const handleSaveRecord = async () => {
        try {
            const response = await saveRecord(objectName, id, objectFields);
            if(response.status == 200) {
                setReadRecord(true);
                dispatch(addNotification({"type" : "info", "message" : response.data.data.message}));
            } else {
                dispatch(addNotification({"type" : "error", "message" : "New Record Crea"}));
            }
        } catch(error) {
            console.error(error);
            dispatch(addNotification({"type" : "info", "message" : error}));
        }
    }

    const handleDelete = async () => {
        const response = await deleteRecord(objectName, id);
        if(response.status == 200) {
            navigate.push('/sales');
        } else {
            dispatch(addNotification({"type" : "error", "message" : "New Record Crea"}));
        }
    }

    const handleManageParts = () => {
        navigate.push(`/sales/manageProductParts/${id}`);
    }

    return (
        <>
            <USCard>
                <USCardContent>
                    {readRecord && <Button size="small" variant="outlined" className="ml-5" onClick={() =>  navigate.push('/company/edit/'+objectName)}>New</Button>}
                    {readRecord && <Button size="small" variant="outlined" className="ml-5" onClick={() => setReadRecord(false)}>Edit</Button>}
                    {!readRecord && <Button size="small" variant="outlined" className="ml-5" onClick={() => handleSaveRecord(true)}>Save</Button>}
                    {!readRecord && <Button size="small" variant="outlined" className="ml-5" onClick={() => setReadRecord(true)}>Cancel</Button>}
                    {readRecord && <Button size="small" variant="outlined" className="ml-5" onClick={handleDelete}>Delete</Button>}
                    <Button size="small" variant="outlined" className="ml-5" onClick={handleManageParts}>Manage Parts</Button>
                </USCardContent>
            </USCard>

            <USCard>
                <USCardContent>
                    {sections && objectFields ? sections.map((section, i) => (
                        <div className="row" key={i}>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <USCard heading={<IntlMessages id={section.sectionName}/>} >
                                    <USCardContent>
                                        {(section.style && section.style === 'columns') ?
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6 col-lg-6" style={{ display:'block' }}>
                                                    {section.leftSideFields && section.leftSideFields.map((lField, j) => {
                                                        if(lField.show) {
                                                            let depLookUp = chekIfDependedLookup(lField);
                                                            return <Element key={j} fieldDetails={lField} handleEdit={handleFieldValue} value={objectFields[lField.field_api_name]} dependedObjectValue={depLookUp} variant="standard"  readOnly={readRecord}/>
                                                        }
                                                        return null;
                                                    })}
                                                </div>
                                                <div className="col-sm-12 col-md-6 col-lg-6" style={{ display:'block' }}>
                                                    {section.rightSideFields && section.rightSideFields.map((rField, k) => {
                                                        if(rField.show) {
                                                            let depLookUp = chekIfDependedLookup(rField);
                                                            return <Element key={k} fieldDetails={rField} handleEdit={handleFieldValue} value={objectFields[rField.field_api_name]} dependedObjectValue={depLookUp}  variant="standard"  readOnly={readRecord}/>
                                                        }
                                                        return null;
                                                    })}
                                                </div>
                                            </div>
                                                :
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12 col-lg-12" style={{ display:'block' }}>
                                                    {section.leftSideFields && section.leftSideFields.map((lField, j) => {
                                                        if(lField.show) {
                                                            let depLookUp = chekIfDependedLookup(lField)
                                                            return <Element key={j} fieldDetails={lField} handleEdit={handleFieldValue} value={objectFields[lField.field_api_name]} dependedObjectValue={depLookUp} variant="standard" readOnly={readRecord}/>
                                                        }
                                                        return null;
                                                    })}
                                                </div>
                                            </div>
                                        }
                                    </USCardContent>
                                </USCard>
                            </div>
                        </div>
                        )) : null
                    }
                </USCardContent>
            </USCard>
            {(relatedList && readRecord) &&
                relatedList.map((listItem) => {
                    return <USTable key={listItem.Title} tableTitle={<IntlMessages id={listItem.Title}/>} parentObjectName={objectName} parentObjectId={id} parentFieldName={listItem.ParentFieldName} objectName={listItem.ObjectName} requestUrl={'company/child'} expandableRows={false} handleNew={true}/>
            })}

        </>
    )

}
