import USDetailsPage from "../../../USCommonComponents/USDetailsPage";
import {useParams} from "react-router-dom";

export default function USDocumentDetails() {
    const {id} = useParams();

    return (
        <USDetailsPage objectName="document" id={id}/>
    )
}
