import { Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../reduxSettings/reduxSlices/systemSlice";
import USDetailsPage from "../USDetailsPage";

export default function USNewRecordDialog() {

    const dispatch = useDispatch();
    const {objectName, dialogTitle, parentFieldName, parentRecordId, detailsParams, showStepper, stepperFieldName} = useSelector((state) => state.system.options);
    const isOpen = useSelector((state) => state.system.openNewDialog);

    const handleClose = () => {
        dispatch(closeDialog());
    }

    return (
        <>  {objectName && <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="m-10"
                style={{ height: "90%", overflowY: 'scroll' }}
            >
                <USDetailsPage parentFieldName={parentFieldName} parentRecordId={parentRecordId} objectName={objectName} showRelatedList={false} showDelete={false} showNew={false} viewRecord={false} detailsParams={detailsParams} moduleUrl="sales" showStepper={showStepper} stepperFieldName={stepperFieldName}/>
            </Modal>}
        </>
    )
}
