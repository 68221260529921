import React from 'react';
import {LoggedForCompany} from '../../../helpers/RouteHelper';
import { useHistory } from 'react-router-dom';
import Pages from '../pages';
import USLayoutContainer from './USLayoutContainer';

function USMainLayout(props) {
   const navigate = useHistory();
   console.log(navigate);
   return (
       <USLayoutContainer>
           <LoggedForCompany path="/sales" component={Pages} token={localStorage.getItem('token')} location={navigate.location}/>
       </USLayoutContainer>
   );
}

export default USMainLayout;
