



function USGeneralInformationSettings() {


    return (
        <>USGeneralInformationSettings</>
    )
}

export default USGeneralInformationSettings;
