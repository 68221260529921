import React from 'react';
import IntlMessages from 'Util/IntlMessages';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
export default function USDate ({fieldDetails, handleChange, value=null, variant="standard", readOnly }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                id={fieldDetails.field_api_name}
                readOnly={(fieldDetails.field_access == 'edit' && !readOnly) ? false : true}
                name={fieldDetails.field_api_name}
                label={<IntlMessages id={fieldDetails.field_label} />}
                onChange={(e) => !readOnly ? handleChange({target : {
                                                name: fieldDetails.field_api_name,
                                                value: e}}) : null }
                value={value}
                renderInput={(params) =>
                    <TextField variant={variant} {...params} fullWidth/>
                }
            />
        </LocalizationProvider>
    );
}

