import { Button} from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { postRequest } from "../../../../../Api/ApiService/Service";
import { USCard } from "../../../../USCommonComponents/USCardComponent";
import USTable from "../../../../USCommonComponents/USTable";
import USNewConnectionModal from "./USPriceSettingsHelper/USNewConnectModal";
import USNewIntegrationModal from "./USPriceSettingsHelper/USNewFilterSettingModal";
import USNewMappingModal from "./USPriceSettingsHelper/USNewMappingModal";

function USPriceSettings() {

    const navigate = useHistory();

    const [refresh, setRefresh] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isGoogle, setisGoogle] = useState(false);
    const [isYandex, setisYandex] = useState(false);

    const handleOpenModal = (modalType) => {

        if(modalType === "google") {
            setisYandex(false);
            setisGoogle(true)
        } else if(modalType === "yandex") {
            setisYandex(true);
            setisGoogle(false)
        } else {
            setisYandex(false);
            setisGoogle(false)
        }
        console.log(modalType, isGoogle);
        setIsModalOpen(true);
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
    }

    const handleSaveModal = async (newData) => {
        try {
            console.log(newData);
            const response = await postRequest('/company/emailconnectsettings/new/'+localStorage.getItem('currentAccount'),newData);
            console.log(response);
            var currentdate = new Date();
            setRefresh(currentdate.getSeconds());
            setIsModalOpen(false);
        } catch(e) {

        }
    }

    const [isMappingModalOpen, setMappingIsModalOpen] = useState(false);
    const [mappingRefresh, setMappingRefresh] = useState('');
    const handleMappingCloseModal = () => {
        setMappingIsModalOpen(false);
    }

    const handleMappingSaveModal = async (newData) => {
        try {
            console.log(newData);
            const response = await postRequest('/company/filemapping/new',newData);
            console.log(response);
            var currentdate = new Date();
            setMappingRefresh(currentdate.getSeconds());
            setMappingIsModalOpen(false);
        } catch(e) {

        }
    }

    const [isNewIntegrationOpen, setNewIntegrationOpen] = useState(false);
    const handleIntegrationCloseModal = () => {
        setNewIntegrationOpen(false);
    }

    const handleIntegrationSaveModal = async (newData) => {
        try {
            navigate.push({
                pathname: '/company/integration',
                state: {
                    selectedValue: newData,
                },
            });
            setNewIntegrationOpen(false);
        } catch(e) {

        }
    }

    return (
        <>
            <USCard heading="API Settings">
                <div className="row pl-10 pr-10 pt-10" >
                    <div className="col-sm-12 col-md-4 col-lg-4 ">
                        <Button size="small" variant="outlined" onClick={() => setNewIntegrationOpen(true)}>New Connecion</Button>
                    </div>
                </div>
                <div className="row" >
                    <div className="col-sm-12 col-md-12 col-lg-12 p-20">
                        <USTable tableTitle="Integration settings" refres={refresh} objectName={'integration'} requestUrl={'company/getTableList'} expandableRows={false} hideToolbar={true} hidePagination={true}/>
                    </div>
                </div>
            </USCard>
            {isNewIntegrationOpen && <USNewIntegrationModal isModalOpen={isNewIntegrationOpen} saveModal={handleIntegrationSaveModal} closeModal={handleIntegrationCloseModal}/>}

            <USCard heading="Mail settings">
                <div className="row pl-10 pr-10 pt-10" >
                    <div className="col-sm-12 col-md-4 col-lg-4 ">
                        <Button size="small" variant="outlined" onClick={() => handleOpenModal('google')}>New Google Connecion</Button>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 ">
                        <Button size="small" variant="outlined" onClick={() => handleOpenModal('yandex')}>New Yandex Connecion</Button>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 ">
                        <Button size="small" variant="outlined" onClick={() => handleOpenModal('other')}>New Other Connecion</Button>
                    </div>
                </div>
                <div className="row" >
                    <div className="col-sm-12 col-md-12 col-lg-12 p-20">
                        <USTable tableTitle="Google connection settings" refres={refresh} objectName={'emailconnectsetting'} requestUrl={'company/getTableList'} expandableRows={false} hideToolbar={true} hidePagination={true}/>
                    </div>
                </div>
            </USCard>
            {isModalOpen && <USNewConnectionModal isGoogle={isGoogle} isYandex={isYandex} isModalOpen={isModalOpen} saveModal={handleSaveModal} closeModal={handleCloseModal} />}

            <USCard heading="Mapping Files">
                <div className="row pl-10 pr-10 pt-10 " >
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <Button size="small" variant="outlined" onClick={() => setMappingIsModalOpen(true)}>New Mapping</Button>
                    </div>
                </div>
                <div className="row" >
                    <div className="col-sm-12 col-md-12 col-lg-12 p-20">
                        <USTable tableTitle="File Mapping" refres={mappingRefresh} objectName={'filemapping'} requestUrl={'company/getTableList'} expandableRows={false} hideToolbar={true} hidePagination={true}/>
                    </div>
                </div>
            </USCard>
            {isMappingModalOpen && <USNewMappingModal isModalOpen={isMappingModalOpen} saveModal={handleMappingSaveModal} closeModal={handleMappingCloseModal} />}
        </>

    )
}

export default USPriceSettings;
