import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getRequest, postRequest } from "../../../../Api/ApiService/Service";
import { useParams } from 'react-router-dom';
import { Button, Card, CardContent, TextField } from "@mui/material";
import USDndRow from "./USLayoutUtils/USDndRow";
import USDndNotSelected from "./USLayoutUtils/USDndNotSelected";
import { CardFooter } from "reactstrap";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../../reduxSettings/reduxSlices/NotificationSlice";

   
export default function USObjectLayoutEdit() {
    const { layoutName, objectName } = useParams();
    const [selectedLayout, setSelectedLayout] = useState();
    const [sections, setSections] = useState();
    const [notSelected, setNotSelected] = useState();
    const dispatch = useDispatch();
    const onDragEnd = (result) => {
        if (!result.destination) return;
        const { source, destination } = result;
        const copySections = [...sections];
        
        if(destination.droppableId == 'notSelected' || source.droppableId == 'notSelected') {
            let notSelectedCopy = [];
            if(notSelected) {
                notSelectedCopy = [...notSelected];
            }

            if(destination.droppableId == 'notSelected') {
                const fieldsSectionArea = source.droppableId.split('_');
                let editSections = copySections[fieldsSectionArea[1]];
                let fieldsList = editSections[fieldsSectionArea[0]];
                const draggedField = fieldsList[source.index];
                fieldsList.splice(source.index, 1);
                notSelectedCopy.push(draggedField);
                setNotSelected(notSelectedCopy);
            } else {
                const fieldsSectionArea = destination.droppableId.split('_');
                let editSections = copySections[fieldsSectionArea[1]];
                let fieldsList = editSections[fieldsSectionArea[0]];
                const draggedField = notSelectedCopy[source.index];
                notSelectedCopy.splice(source.index, 1);
                setNotSelected(notSelectedCopy);
                const copyFieldsList = [
                    ...fieldsList.slice(0, destination.index),
                    draggedField,
                    ...fieldsList.slice(destination.index)
                ];
                copySections[fieldsSectionArea[1]][fieldsSectionArea[0]] = copyFieldsList;
                setSections(copySections);
            }
        } else {
            if(source.droppableId == 'sections' && destination.droppableId == 'sections') {
                const draggedSection = copySections[source.index];
                copySections.splice(source.index, 1);
                const resultList = [
                    ...copySections.slice(0, destination.index),
                    draggedSection,
                    ...copySections.slice(destination.index)
                ];
                setSections(resultList);
            } else {
                if(source.droppableId == destination.droppableId) {
                    const fieldsSectionArea = source.droppableId.split('_');
                    let editSections = copySections[fieldsSectionArea[1]];
                    let fieldsList = editSections[fieldsSectionArea[0]];
                    const draggedField = fieldsList[source.index];
                    fieldsList.splice(source.index, 1);
                    const copyFieldsList = [
                        ...fieldsList.slice(0, destination.index),
                        draggedField,
                        ...fieldsList.slice(destination.index)
                    ];
                    fieldsList = [...copyFieldsList];
                    copySections[fieldsSectionArea[1]][fieldsSectionArea[0]] = copyFieldsList;
                    setSections(copySections);
                } else {
                    const sectionAndFieldsFrom = source.droppableId.split('_');
                    const sectionAndFieldsTo = destination.droppableId.split('_');
                    let editSectionsFrom = copySections[sectionAndFieldsFrom[1]];
                    let editSectionsTo = copySections[sectionAndFieldsTo[1]];
                    let setFieldsFrom = editSectionsFrom[sectionAndFieldsFrom[0]];
                    let setFiedlsTo = editSectionsTo[sectionAndFieldsTo[0]];
                    const draggedField = setFieldsFrom[source.index];
                    setFieldsFrom.splice(source.index, 1);
                    const copySetFiedlsTo = [
                        ...setFiedlsTo.slice(0, destination.index),
                        draggedField,
                        ...setFiedlsTo.slice(destination.index)
                    ];
                    copySections[sectionAndFieldsFrom[1]][sectionAndFieldsFrom[0]] = setFieldsFrom;
                    copySections[sectionAndFieldsTo[1]][sectionAndFieldsTo[0]] = copySetFiedlsTo;
                    setSections(copySections);
                }
            }
        }
    };

    const fetchLayoutDetails = async() => {
        try {
            const response = await getRequest('company/layout/' + objectName +'/' + layoutName);
            setSelectedLayout(response.data.data.layout);
            setSections(response.data.data.layout?.sections);
            setNotSelected(response.data.data.layout?.notSelected);
        } catch (error) {
            console.log('fetch layout details error' ,error)
        }
    }

    useEffect(() => {
        fetchLayoutDetails();
    },[layoutName, objectName]);

    const [newSectioName, setNewSectionName] = useState('');
    const newSection = () => {
        const section = {"sectionName": newSectioName,"style": "columns","leftSideFields": [],"rightSideFields": []};
        const copySections = [...sections];
        copySections.push(section);
        setSections(copySections);
    }
    
    const saveLayout = async() => {
        try {
            let postLayout = JSON.parse(JSON.stringify(selectedLayout));
            postLayout['sections'] = sections;
            postLayout['notSelected'] = notSelected;
            const response = await postRequest('company/layout/' + objectName +'/' + layoutName , postLayout);
            console.log('saveLayout response', response);
            dispatch(addNotification({type: "info", message: "Layout Updated"}));
        } catch(error) {
            console.log('saveLayout', error);
        }
    }
    return (
        <div>
            {sections && <div
                style={{ display: "flex", justifyContent: "center", height: "100%" }}
            >
                <DragDropContext
                    onDragEnd={(result) => onDragEnd(result)}
                >
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 mb-5">
                            <Card>
                                <CardContent >
                                    <USDndNotSelected notSelectedItems={notSelected}/>
                                </CardContent>
                                <CardFooter>
                                    <TextField size="small" value={newSectioName} onChange={(e) => setNewSectionName(e.target.value)} />
                                    <Button variant="outlined" size="small" onClick={newSection}>Add Section</Button>
                                    <Button variant="outlined" size="small" onClick={saveLayout}>Save</Button>
                                </CardFooter>
                            </Card>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <Card>
                                <CardContent >
                                    <USDndRow sections={sections} />
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                    
                
                {/* {layout?.sections.map((sectionItem, index) => {
                    return (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}
                            key={index}
                        >
                            <h2>{sectionItem.sectionName}</h2>
                            <div style={{ margin: 8 }}>
                                <Droppable droppableId={index} key={index}>
                                    {(provided, snapshot) => {
                                        return (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={{
                                                    background: snapshot.isDraggingOver
                                                    ? "lightblue"
                                                    : "lightgrey",
                                                    padding: 4
                                                }}
                                            >
                                                {sectionItem.leftSideFields.map((fieldItem, fieldIndex) => {
                                                    return (
                                                        <Draggable
                                                            key={fieldItem.field_api_name}
                                                            draggableId={fieldItem.field_api_name}
                                                            index={fieldIndex}
                                                        >
                                                            {(provided, snapshot) => {
                                                                return (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            userSelect: "none",
                                                                            padding: 16,
                                                                            margin: "0 0 8px 0",
                                                                            minHeight: "50px",
                                                                            backgroundColor: snapshot.isDragging
                                                                            ? "#263B4A"
                                                                            : "#456C86",
                                                                            color: "white",
                                                                            ...provided.draggableProps.style
                                                                        }}
                                                                    >
                                                                        {fieldItem.field_label}
                                                                    </div>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        );
                                    }}
                                </Droppable>
                            </div>
                        </div>
                    );
                })} */}
                </DragDropContext>
            </div>}
        </div>
    );
}
    