import { createSlice } from '@reduxjs/toolkit';

// nav links
import navLinks from 'Components/USCompanyComponents/CompanySidebar/NavLinks';
import userNavLinks from 'Components/USUserComponents/UserSidebar/NavLinks';

const initialState = {
    userSidebarMenu: userNavLinks,
    sidebarMenus: navLinks
}

export const sidebarSlice = createSlice({
    name : "sidebar",
    initialState,
    reducers: {
        onToggleMenu: (state, action) => {
            let index = state.sidebarMenus[action.payload.stateCategory].links.indexOf(action.payload.menu);
            console.log('state.sidebarMenus == ', state.sidebarMenus[action.payload.stateCategory]);
            console.log('action.payload.stateCategory == ', action.payload.stateCategory);
            console.log('action.payload.menu.open == ', action.payload.menu.open);
            console.log('action.payload.menu == ', action.payload.menu);
            console.log('userMenuIndex == ', index);
            for (var key in state.sidebarMenus) {
                var obj = state.sidebarMenus[key].links;
                for (let i = 0; i < obj?.length; i++) {
                    const element = obj[i];
                    console.log('obj',obj);
                    console.log('element',element);
                    console.log('element',element.open);
                    if (element.open) {
                        console.log('element',element);
                        if (key === action.payload.stateCategory) {
                            state.sidebarMenus[key][i].open = false;
                            state.sidebarMenus[key][index].open = !action.payload.menu.open;


                        } else {

                            state.sidebarMenus[key][i].open = false;
                        }
                    }
                }
            }
            sidebarMenus[action.payload.stateCategory][index].open = !action.payload.menu.open;
        }
    }

        // onToggleUserMenu : (state, action) => {
        //     let userMenuIndex = action.payload.menu.id;//state.userSidebarMenu[action.payload.stateCategory].links.indexOf(action.payload.menu);
        //      console.log('userSidebarMenu == ', state.userSidebarMenu);
        //      console.log('state.userSidebarMenu == ', state.userSidebarMenu[action.payload.stateCategory]);
        //      console.log('action.payload.stateCategory == ', action.payload.stateCategory);
        //      console.log('action.payload.menu == ', action.payload.menu);
        //      console.log('userMenuIndex == ', userMenuIndex);
        //      for (var key in state.userSidebarMenu) {
        //          var obj = state.userSidebarMenu[key].links;
        //          console.log('obj == ', obj);
        //          for (let i = 0; i < obj.length; i++) {
        //              const element = obj[i];
        //              if (element.open) {
        //                  if (key === action.payload.stateCategory) {
        //                      return update(state, {
        //                          userSidebarMenu: {
        //                              [key]: {
        //                                  messageId: { $set: state.userSidebarMenu[key].messageId },
        //                                  links: {
        //                                          [i]: {
        //                                          open: { $set: false }
        //                                      }
        //                                  },
        //                                  messageId: { $set: state.userSidebarMenu[key].messageId },
        //                                  links: {
        //                                      [userMenuIndex]: {
        //                                          open: { $set: !action.payload.menu.open }
        //                                      }
        //                                  }
        //                              }
        //                          }
        //                      });
        //                  } else {
        //                      return update(state, {
        //                          userSidebarMenu: {
        //                              [key]: {
        //                                  [i]: {
        //                                      open: { $set: false }
        //                                  }
        //                              },
        //                              [action.payload.stateCategory]: {
        //                                  [userMenuIndex]: {
        //                                      open: { $set: !action.payload.menu.open }
        //                                  }
        //                              }
        //                          }
        //                      });
        //                  }
        //              }
        //          }
        //      }
        //      return update(state, {
        //          userSidebarMenu: {
        //              [action.payload.stateCategory]: {
        //                  messageId: { $set: state.userSidebarMenu[action.payload.stateCategory].messageId },
        //                  links: {
        //                      [userMenuIndex]: {
        //                          open: { $set: !action.payload.menu.open }
        //                      }
        //                  }
        //              }
        //          }
        //      });
        // }
})

export const {onToggleMenu, onToggleUserMenu} = sidebarSlice.actions;
export default sidebarSlice.reducer;


/**
 * Sidebar Reducers
 */
//  import update from 'react-addons-update';
//  import { TOGGLE_MENU, AGENCY_TOGGLE_MENU, USER_TOGGLE_MENU } from 'Actions/types';

//  // nav links
//  import navLinks from 'Components/Sidebar/NavLinks';
//  import userNavLinks from 'Components/Users/UserSidebar/NavLinks';
//  import agencyNavLinks from 'Components/AgencyMenu/NavLinks';

//  const INIT_STATE = {
//      userSidebarMenu: userNavLinks,
//      sidebarMenus: navLinks,
//      agencySidebarMenu: agencyNavLinks,
//  };

//  export default (state = INIT_STATE, action) => {

//      switch (action.type) {
//          case TOGGLE_MENU:
//              let index = state.sidebarMenus[action.payload.stateCategory].links.indexOf(action.payload.menu);
//              console.log('state.sidebarMenus == ', state.sidebarMenus[action.payload.stateCategory]);
//              console.log('action.payload.stateCategory == ', action.payload.stateCategory);
//              console.log('action.payload.menu.open == ', action.payload.menu.open);
//              console.log('action.payload.menu == ', action.payload.menu);
//              console.log('userMenuIndex == ', index);
//              for (var key in state.sidebarMenus) {
//                  var obj = state.sidebarMenus[key].links;
//                  for (let i = 0; i < obj.length; i++) {
//                      const element = obj[i];
//                      console.log('obj',obj);
//                      console.log('element',element);
//                      console.log('element',element.open);
//                      if (element.open) {
//                          console.log('element',element);
//                          if (key === action.payload.stateCategory) {
//                              return update(state, {
//                                  sidebarMenus: {
//                                      [key]: {
//                                          [i]: {
//                                              open: { $set: false }
//                                          },
//                                          [index]: {
//                                              open: { $set: !action.payload.menu.open }
//                                          }
//                                      }
//                                  }
//                              });
//                          } else {
//                              return update(state, {
//                                  sidebarMenus: {
//                                      [key]: {
//                                          [i]: {
//                                              open: { $set: false }
//                                          }
//                                      },
//                                      [action.payload.stateCategory]: {
//                                          [index]: {
//                                              open: { $set: !action.payload.menu.open }
//                                          }
//                                      }
//                                  }
//                              });
//                          }
//                      }
//                  }
//              }
//              return update(state, {
//                  sidebarMenus: {
//                      [action.payload.stateCategory]: {
//                          [index]: {
//                              open: { $set: !action.payload.menu.open }
//                          }
//                      }
//                  }
//              });
//          case USER_TOGGLE_MENU:
//              let userMenuIndex = action.payload.menu.id;//state.userSidebarMenu[action.payload.stateCategory].links.indexOf(action.payload.menu);
//              console.log('userSidebarMenu == ', state.userSidebarMenu);
//              console.log('state.userSidebarMenu == ', state.userSidebarMenu[action.payload.stateCategory]);
//              console.log('action.payload.stateCategory == ', action.payload.stateCategory);
//              console.log('action.payload.menu == ', action.payload.menu);
//              console.log('userMenuIndex == ', userMenuIndex);
//              for (var key in state.userSidebarMenu) {
//                  var obj = state.userSidebarMenu[key].links;
//                  console.log('obj == ', obj);
//                  for (let i = 0; i < obj.length; i++) {
//                      const element = obj[i];
//                      if (element.open) {
//                          if (key === action.payload.stateCategory) {
//                              return update(state, {
//                                  userSidebarMenu: {
//                                      [key]: {
//                                          messageId: { $set: state.userSidebarMenu[key].messageId },
//                                          links: {
//                                                  [i]: {
//                                                  open: { $set: false }
//                                              }
//                                          },
//                                          messageId: { $set: state.userSidebarMenu[key].messageId },
//                                          links: {
//                                              [userMenuIndex]: {
//                                                  open: { $set: !action.payload.menu.open }
//                                              }
//                                          }
//                                      }
//                                  }
//                              });
//                          } else {
//                              return update(state, {
//                                  userSidebarMenu: {
//                                      [key]: {
//                                          [i]: {
//                                              open: { $set: false }
//                                          }
//                                      },
//                                      [action.payload.stateCategory]: {
//                                          [userMenuIndex]: {
//                                              open: { $set: !action.payload.menu.open }
//                                          }
//                                      }
//                                  }
//                              });
//                          }
//                      }
//                  }
//              }
//              return update(state, {
//                  userSidebarMenu: {
//                      [action.payload.stateCategory]: {
//                          messageId: { $set: state.userSidebarMenu[action.payload.stateCategory].messageId },
//                          links: {
//                              [userMenuIndex]: {
//                                  open: { $set: !action.payload.menu.open }
//                              }
//                          }
//                      }
//                  }
//              });
//          case AGENCY_TOGGLE_MENU:
//              let agencyMenuIndex = state.agencySidebarMenu[action.payload.stateCategory].indexOf(action.payload.menu);
//              for (var id in state.agencySidebarMenu) {
//                  var object = state.agencySidebarMenu[id];
//                  for (let i = 0; i < object.length; i++) {
//                      const element = object[i];
//                      console.log('element.open =',element.open);
//                      if (element.open) {
//                          if (id === action.payload.stateCategory) {
//                              return update(state, {
//                                  agencySidebarMenu: {
//                                      [id]: {
//                                          [i]: {
//                                              open: { $set: false }
//                                          },
//                                          [agencyMenuIndex]: {
//                                              open: { $set: !action.payload.menu.open }
//                                          }
//                                      }
//                                  }
//                              });
//                          } else {
//                              return update(state, {
//                                  agencySidebarMenu: {
//                                      [id]: {
//                                          [i]: {
//                                              open: { $set: false }
//                                          }
//                                      },
//                                      [action.payload.stateCategory]: {
//                                          [agencyMenuIndex]: {
//                                              open: { $set: !action.payload.menu.open }
//                                          }
//                                      }
//                                  }
//                              });
//                          }
//                      }
//                  }
//              }
//              return update(state, {
//                  agencySidebarMenu: {
//                      [action.payload.stateCategory]: {
//                          [agencyMenuIndex]: {
//                              open: { $set: !action.payload.menu.open }
//                          }
//                      }
//                  }
//              });
//          default:
//              return { ...state };
//      }
//  }
