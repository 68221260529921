import { Button, CardHeader, TextField } from "@mui/material";
import { USCard, USCardContent } from "../../../USCommonComponents/USCardComponent";
import {useHistory} from 'react-router-dom';

import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import Cloud from '@mui/icons-material/Cloud';


export default function USObjectManagerLayout({children, objectName}) {

    const navigate = useHistory();


    const handleRedirect = (url) => {
        navigate.push('/company/ObjectManager/'+ objectName + url);
    }

    return (
        <>
            <USCard>
                <USCardContent>
                    Object Details
                </USCardContent>
            </USCard>

            <div className="row">
                <div className="col-sm-3 col-md-3 col-lg-3">
                    <USCard>
                        <USCardContent>
                        <Paper sx={{ width: 320, maxWidth: '100%' }}>
                            <MenuList>
                                <MenuItem onClick={() => handleRedirect('')}>
                                    <ListItemIcon>
                                        <ContentCut fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Details</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => handleRedirect('/Fields')}>
                                    <ListItemIcon>
                                        <ContentCopy fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Fields</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => handleRedirect('/Layouts')}>
                                    <ListItemIcon>
                                        <ContentPaste fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Layouts</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => handleRedirect('/Limits')}>
                                    <ListItemIcon>
                                        <Cloud fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Limits</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => handleRedirect('/RecordTypes')}>
                                    <ListItemIcon>
                                        <ContentPaste fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Record Type</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => handleRedirect('/RestrictionRules')}>
                                    <ListItemIcon>
                                        <ContentPaste fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Restriction Rule</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => handleRedirect('/Triggers')}>
                                    <ListItemIcon>
                                        <ContentPaste fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Triggers</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => handleRedirect('/ValidationRules')}>
                                    <ListItemIcon>
                                        <ContentPaste fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Validation Rules</ListItemText>
                                </MenuItem>
                            </MenuList>
                            </Paper>
                        </USCardContent>
                    </USCard>
                </div>
                <div className="col-sm-9 col-md-9 col-lg-9">
                    {children}
                </div>
            </div>
        </>
    )
}
