import { Button, CardActions, CardHeader, Modal, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { USCard, USCardContent } from "../../../../../USCommonComponents/USCardComponent";
import USIOSSwitch from "../../../../../USCommonComponents/USIOSSwitch";

function USNewMappingModal({isModalOpen, saveModal, closeModal}) {

    const [objectFields, setObjectFields] = useState({
        'Name' : '',
        'isActive' : true,
        'EmailConnectSettingsId' : '',
        'EmailFilterSettingsId ' : ''
    });

    const handleFieldValue = e => {
        const fieldName = e.target.name;
        let fieldValue = e.target.value;
        console.log(e);
        console.log(e.target.name, e.target.checked);

        if(fieldName === "isActive") {
            if(objectFields[fieldName]) {
                fieldValue = false;
            } else {
                fieldValue = true;
            }
        }

        //const value = e.target.value != null && e.target.value.hasOwnProperty('value') ? e.target.value.value : e.target.value ;
        setObjectFields(existingValues => ({
            // Retain the existing values
            ...existingValues,
            // update the current field
            [fieldName]: fieldValue,
        }))
    }

    return (
        <Modal
            open={isModalOpen}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="m-10 w-30"
            style={{ left: '40%'}}
            >
            <USCard>
                <CardHeader
                    title="Mew Mapping"
                    className="ml-10 mr-10"
                />
                <USCardContent>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="row" >
                        <div className="col-sm-12 col-md-12 col-lg-12 mt-10" >
                                <Stack direction="row" spacing={2} alignItems="left" className="mt-25">
                                    <Typography>Active</Typography>
                                    <USIOSSwitch name="is Active" onChange={(e) => handleFieldValue(e) } checked={objectFields['isActive']} />
                                </Stack>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12" >
                                <TextField id="standard-basic" label="Name" name="Name" onChange={(e) => handleFieldValue(e)} value={objectFields['Name']} variant="standard" fullWidth/>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10" >
                                <TextField id="standard-basic" label="Email Connect Settings" name="EmailConnectSettingsId" onChange={(e) => handleFieldValue(e)} value={objectFields['EmailConnectSettingsId']} variant="standard" fullWidth/>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10" >
                                <TextField id="standard-basic" label="Email Filter Settings" name="EmailFilterSettingsId " onChange={(e) => handleFieldValue(e)} value={objectFields['EmailFilterSettingsId ']} variant="standard" fullWidth/>
                            </div>
                        </div>
                    </div>
                </USCardContent>
                <CardActions className='pb-20 ml-10' sx={{ justifyContent: "center" }}>
                    <Button variant='outlined' size="small" color="error" onClick={closeModal}>Cancel</Button>
                    <Button variant='outlined' size="small" color="success" onClick={() => saveModal(objectFields)}>Save</Button>
                </CardActions>
            </USCard>
        </Modal>
    )
}
export default USNewMappingModal;
