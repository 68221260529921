import React, { useState } from 'react'
import { postFileRequest } from '../../../Api/ApiService/Service';
import { Button, CardActions, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { USCard, USCardContent } from '../USCardComponent';

export default function USUploadFiles({objectName, handleCloseModal}) {
    const styles = {
        main: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: "linear-gradient('to right', '#8E24AA', '#b06ab3')",
            color: '#D7D7EF',
            fontFamily: 'Lato, sans-serif'
        },
        fileDropArea: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            width: '450px',
            maxWidth: '100%',
            padding: '25px',
            border: '1px dashed rgba(255, 255, 255, 0.4)',
            borderRadius: '3px',
            transition: '0.2s'
          },

        chooseFileButton: {
            flexShrink: '0',
            backgroundColor: 'rgba(255, 255, 255, 0.04)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            borderRadius: '3px',
            padding: '8px 15px',
            marginRight: '10px',
            fontSize: '12px',
            textTransform: 'uppercase'
          },

          fileMessage: {
            fontSize: 'small',
            fontWeight: '300',
            lineHeight: '1.4',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          },

          fileInput: {
            position: 'absolute',
            left: '0',
            top: '0',
            height: '100%',
            width: '100%',
            cursor: 'pointer',
            opacity: '0'
          },

          mt100 : {
              marginTop:'100px'
        }
    };
    const [previewUploadedFile, setPreviewUploadedFile] = useState(null);
    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0])
        loadFile(event.target.files[0]);
    }

    const [selectedFile, setSelectedFile] = useState(null);
    const loadFile = async (selectedFile) => {
        try {
            const response = await postFileRequest("/company/uploadedfile/previewFile", selectedFile);
            console.log(response);
            setPreviewUploadedFile(response.data.data);
        } catch (error) {
            console.log(error)
        }
    }

    const handleSaveModal = async (objName) => {
        try {
            console.log(objName);
            const response = await postFileRequest("/company/uploadedfile/objectUpload/" + objName, selectedFile);
            console.log(response);
            setPreviewUploadedFile(null);
            handleCloseModal();
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <USCard>
            <USCardContent>
            <div className="container d-flex justify-content-center mt-100" style={styles.main}>
                <div className="row">
                    <div className="col-md-12">
                        <h2>Bulk Upload {objectName}</h2>
                        <div style={styles.fileDropArea}>
                            <span style={styles.chooseFileButton}>Choose files</span>
                            <span style={styles.fileMessage}>or drag and drop files here</span>
                            <input style={styles.fileInput} type="file" multiple onChange={handleFileSelect}/>
                        </div>
                    </div>
                </div>
            </div>
                {previewUploadedFile && <TableContainer component={Paper} sx={{ maxHeight: '500px' }}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {previewUploadedFile[0].map((headerCell, indexHeader) => {
                                    return <TableCell key={indexHeader}>{headerCell}</TableCell>
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {previewUploadedFile.map((row, indexRow) => {
                            if(indexRow != 0) {
                                return <TableRow key={indexRow}>
                                    {row.map((cell, index) => (
                                        <TableCell component="th" scope="row">
                                            {cell}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            }
                        })}
                        </TableBody>
                    </Table>
                </TableContainer>}
            </USCardContent>
            <CardActions className='pb-20 ml-10 justify-content-center'>
                <Button variant='outlined' size="small" color="error" onClick={handleCloseModal}>Cancel</Button>
                <Button variant='outlined' size="small" color="success" onClick={() => handleSaveModal(objectName)}>Save</Button>
            </CardActions>
        </USCard>
  )
}
