import USDetailsPage from "../../../../USCommonComponents/USDetailsPage";
import {useParams} from "react-router-dom";

export default function USSurveyElementValueDetails() {
    const {id} = useParams();

    return (
        <USDetailsPage objectName="surveyelementvalue" id={id}/>
    )
}
