import { postRequest } from "../ApiService/Service";

export async function selectObjectRecords(requestUrl, objectName, currentPage, sortOrderField, sortOrderDirection, rowsPerPage, parentFieldName, parentObjectId, searchCondition, listViewApiName) {
    try{
        let searchParams = {};
        objectName = objectName ? objectName : null;
        parentFieldName = parentFieldName ? parentFieldName : null;
        parentObjectId = parentObjectId ? parentObjectId : null;
        searchParams['objectName'] =  objectName;
        searchParams['currentPage'] =  currentPage;
        searchParams['sortOrderField'] =  sortOrderField;
        searchParams['sortOrderDirection'] =  sortOrderDirection;
        searchParams['rowsPerPage'] =  rowsPerPage;
        searchParams['parentFieldName'] =  parentFieldName;
        searchParams['parentObjectId'] =  parentObjectId;
        searchParams['searchCondition'] = searchCondition;
        searchParams['listViewApiName'] = listViewApiName;
        const response = await postRequest(requestUrl, searchParams);
        console.log('response', response);
        return response?.data?.data;
    } catch(error) {
        return error;
    }
}

export async function saveRecord(objectName, id, objectFields) {
    try{
        const response = await postRequest('/company/edit/'+objectName +  '/' + id, objectFields);
        return response;
    } catch(error) {
        return error;
    }
}
