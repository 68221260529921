
import { Avatar, Button, ButtonGroup, Card, CardContent, CardHeader, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import IntlMessages from 'Util/IntlMessages';
import { USCard, USCardContent, USCardFooter } from '../../../../USCommonComponents/USCardComponent';
import { getCompanyProductById, getCompanyProductsByFieldNameAndValue, getContactOrLeadByContract, getContactOrLeadById, getContactOrLeadByPhone, getRecordById } from '../../../../../Api/SalesConsoleRequests/USSalesRequestHandler';
import { Table } from 'reactstrap';
import DeleteIcon from '@mui/icons-material/Delete';
import { Element } from '../../../../../helpers/RouteHelper';
import USSearchProduct from '../USSalesConsoleProducts/USSearchProduct';
import USTable from '../../../../USCommonComponents/USTable';
import { red } from '@mui/material/colors';
function USNewOrder({callbackRecordId, handleAddObjectToSave, handleSaveNewCall, handleSaveNewLead}) {
    const initClientData = {"Name": "", "Phone":"", "Source":"","Type":"", "Description":"", "Status":"", "VisitDate" : "", "ContractDate": ""};
    const initAddComment = {"Loan": "", "Credit":"", "Delivery":"","AdditionalQuestion":"", "Price":"", "AdditionalThing":"", "Description" : ""};
    const [objectFields, setObjectFields] = useState(initClientData);
    const [addComments, setAddComments] = useState(initAddComment);

    const [selectedContact, setSelectedContact] = useState(null);
    const [clientSelectedProduct, setClientSelectedProduct] = useState();
    const [selectedCallbackRecord, setSelectedCallbackRecord] = useState();

    const [productCode, setProductCode] = useState('');
    const [productName, setProductName] = useState('');
    const [productList, setProductList] = useState([]);

    useEffect(() => {
        if(callbackRecordId) {
            selectCallbackRecord(callbackRecordId);
        }
    },[callbackRecordId]);

    const selectCallbackRecord = (callbackRecordId) => {
        try {
            if(!callbackRecordId.contactId) {
                if(callbackRecordId.phone) {
                    searchLeadOrContactByPhone(callbackRecordId.phone);
                }
            } else {
                selectExistingContact(callbackRecordId.contactId);
            }
        } catch(error) {

        }
    }

    const searchProduct = async (fieldName, inputValue) => {
        try {
            if(fieldName == 'ProductsCompanyCode') {
                setProductCode(inputValue);
                setProductName('');
            }
            if(fieldName == 'Name') {
                setProductCode('');
                setProductName(inputValue);
            }
            const response = await getCompanyProductsByFieldNameAndValue(fieldName, inputValue);
            setClientSelectedProduct(null);
            setProductList(response);
        } catch (error) {
            console.log('error', error);
        }
    }

    const handleCommentsUpdate = e => {
        const fieldName = e.target.name
        setAddComments(existingValues => ({
            ...existingValues,
            [fieldName]: e.target.value,
        }));
    }

    const handleFieldValue = e => {
        const fieldName = e.target.name
        setObjectFields(existingValues => ({
            ...existingValues,
            [fieldName]: e.target.value,
        }));
    }

    const updateProductDetails = async (selectedValue) => {
        const response = await getCompanyProductById(selectedValue);
        updatedDescription(response.Name);
        setClientSelectedProduct(response);
    }

    const updatedDescription = (textDescription) => {
        let updateDescription = addComments['Description'];
        updateDescription += '\n';
        updateDescription += "Обсуждалась: " + textDescription;
        setAddComments(existingValues => ({
            ...existingValues,
            ["Description"]: updateDescription,
        }));
    }

    const [newPhoneNumber, setNewPhoneNumber] = useState('');
    const [contractNumber, setContractNumber] = useState('');
    const [existingContactList, setExistingContactList] = useState();
    const clearSearchContact = () => {
        setExistingContactList([]);
        setContractNumber('');
        setNewPhoneNumber('');
        setObjectFields(initClientData);
        handleFieldValue({target: {value: newPhoneNumber, name:'Phone'}});
    }

    const searchLeadOrContactByPhone = async(phoneNumber) => {
        try {
            setContractNumber('');
            setNewPhoneNumber(phoneNumber);
            setObjectFields(existingValues => ({
                ...existingValues,
                "Phone" : phoneNumber,
            }));

            if(phoneNumber?.length > 5) {
                const contactList = await getContactOrLeadByPhone(phoneNumber);
                if(contactList && contactList.length > 0) {
                    setExistingContactList(contactList);
                } else {
                    setExistingContactList([]);
                }
            } else {
                setExistingContactList([]);
            }
        } catch(error) {

        }
    }

    const selectExistingContact = async (contactId) => {
        try {
            const selectExistingContact = await getContactOrLeadById(contactId);
            console.log(selectExistingContact);
            setSelectedContact(selectExistingContact);
            setNewPhoneNumber(selectExistingContact.Phone);
            setObjectFields(existingValues => ({
                ...existingValues,
                "Name": selectExistingContact?.FirstName ? selectExistingContact?.FirstName : '' + ' '+ selectExistingContact?.LastName ? selectExistingContact?.LastName : ''  +' '+ selectExistingContact?.MiddleName ? selectExistingContact?.MiddleName : '' ,
                "Phone": selectExistingContact.Phone
            }));
            setExistingContactList([]);
        }catch(error) {

        }
    }

    const searchByContract = async(contractNumber) => {
        try {
            setNewPhoneNumber('');
            setContractNumber(contractNumber);
            if(contractNumber && contractNumber.length > 4) {
                const selectExistingContact = await getContactOrLeadByContract(contractNumber);
                if(selectExistingContact && selectExistingContact.length > 0) {
                    let contacts = [];
                    selectExistingContact.map((selectedOrder) => {
                        contacts.push(selectedOrder.contact)
                    });
                    setExistingContactList(contacts);
                    setObjectFields(selectExistingContact);
                } else {
                    setExistingContactList([]);
                }
            } else {
                setExistingContactList([]);
            }
        }catch(error) {

        }
    }

    const dataToSaveFunc = (productDetails) => {
        const data = {
            'form' : objectFields,
            'contactId' : selectedContact?.id,
            'callbackId' : selectedCallbackRecord?.id,
            'productDetails' : productDetails,
            'callDescription' : addComments
        };
        handleAddObjectToSave(data);
    }

    const saveNewLead = () => {
        const data = {
            'form' : objectFields,
            'callDescription' : addComments
        };
        handleSaveNewLead(data);
    }

    const saveCall = () => {
        const data = {
            'form' : objectFields,
            'callDescription' : addComments
        };
        handleSaveNewCall(data);
    }

    return (
        <div className="row">
            <div className='col-sm-12 col-md-12 col-lg-12'>
                {(existingContactList && existingContactList.length > 0) &&
                    <div className="table-responsive">
                        <div className="unseen">
                            <Table bordered striped>
                                <thead>
                                    <tr className="bg-primary text-white">
                                        <th>Имя</th>
                                        <th>Телефон</th>
                                        <th>Телефон</th>
                                        <th>Телефон</th>
                                        <th>Телефон</th>
                                        <th>Город</th>
                                        <th>Дата первого обращения</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {existingContactList.map((contactItem, index) => {
                                        return <tr key={index} onClick={() => selectExistingContact(contactItem.id)}>
                                            <td>{contactItem.FirstName}</td>
                                            <td>{contactItem.Phone} </td>
                                            <td>{contactItem.Mobile} </td>
                                            <td>{contactItem.OtherPhone} </td>
                                            <td>{contactItem.HomePhone} </td>
                                            <td>{contactItem.City}</td>
                                            <td>{contactItem.created_at}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>}
            </div>
            <>
                <div className='col-sm-12 col-md-7 col-lg-7'>
                    <div className="row">
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                            <USCard heading={<IntlMessages id="client.label"/>}>
                                <USCardContent >
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-2 col-lg-2'>
                                            <TextField
                                                size="small"
                                                name="Phone"
                                                fullWidth
                                                margin="dense"
                                                label="Телефон"
                                                value={newPhoneNumber}
                                                onChange={(e) => searchLeadOrContactByPhone(e.target.value)}
                                            />
                                        </div>
                                        <div className='col-sm-12 col-md-2 col-lg-2'>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                margin="dense"
                                                label="Номер договора"
                                                value={contractNumber}
                                                onChange={(e) => searchByContract(e.target.value)}
                                            />
                                        </div>
                                        <div className='col-sm-12 col-md-4 col-lg-4'>
                                            <TextField
                                                label={<IntlMessages id="common.first-name" />}
                                                id="nameId"
                                                name="Name"
                                                size="small"
                                                fullWidth
                                                margin="dense"
                                                value={objectFields["Name"] ? objectFields["Name"] : ''}
                                                onChange={(e) => handleFieldValue(e)}
                                            />
                                        </div>
                                        <div className='col-sm-12 col-md-2 col-lg-2'>
                                            <TextField
                                                label={<IntlMessages id="common.City" />}
                                                id="city"
                                                name="City"
                                                size="small"
                                                fullWidth
                                                margin="dense"
                                                value={objectFields["City"] ? objectFields["City"] : ''}
                                                onChange={(e) => handleFieldValue(e)}
                                            />
                                        </div>
                                        <div className='col-sm-12 col-md-2 col-lg-2'>
                                            <FormControl size="small" fullWidth style={{ marginTop:'8px' }}>
                                                <InputLabel id="statusField">Источник</InputLabel>
                                                <Select
                                                    id="SourceId"
                                                    name="Source"
                                                    value={objectFields["Source"] ? objectFields["Source"] : ''}
                                                    onChange={(e) => handleFieldValue(e)}
                                                    label="Источник"
                                                    size="small"
                                                >
                                                    <MenuItem value="">
                                                        <em>Выбрать</em>
                                                    </MenuItem>
                                                    <MenuItem value="site">Сайт</MenuItem>
                                                    <MenuItem value="google">Гугл</MenuItem>
                                                    <MenuItem value="yandex">Яндекс</MenuItem>
                                                    <MenuItem value="instagram">Инстаграм</MenuItem>
                                                    <MenuItem value="tiktok">Тикток</MenuItem>
                                                    <MenuItem value="youtube">Ютуб</MenuItem>
                                                    <MenuItem value="vkontakte">Вконтакте</MenuItem>
                                                    <MenuItem value="odnoklassniki">Однокласники</MenuItem>
                                                    <MenuItem value="other">Другое</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className='row'>
                                    <div className='col-sm-12 col-md-2 col-lg-2'>
                                            <FormControl size="small" fullWidth style={{ marginTop:'8px' }}>
                                                <InputLabel id="statusField">Статус</InputLabel>
                                                <Select
                                                    id="StatusId"
                                                    name="Status"
                                                    value={objectFields["Status"] ? objectFields["Status"] : ''}
                                                    onChange={(e) => handleFieldValue(e)}
                                                    label="Статус"
                                                    size="small"
                                                >
                                                    <MenuItem value="">
                                                        <em>Выбрать</em>
                                                    </MenuItem>
                                                    <MenuItem value="inCall">Входящий звонок</MenuItem>
                                                    <MenuItem value="recall">Перезвонить</MenuItem>
                                                    <MenuItem value="waitingForVisit">Ждем на площадке</MenuItem>
                                                    <MenuItem value="SendExamples">Отправить примеры</MenuItem>
                                                    <MenuItem value="complaint">Рекламация</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className='col-sm-12 col-md-2 col-lg-2'>
                                            <FormControl size="small" fullWidth style={{ marginTop:'8px' }}>
                                                <InputLabel id="statusField">Тип клиента</InputLabel>
                                                <Select
                                                    id="TypeId"
                                                    name="Type"
                                                    value={objectFields["Type"] ? objectFields["Type"] : ''}
                                                    onChange={(e) => handleFieldValue(e)}
                                                    label="Тип клиента"
                                                    size="small"
                                                >
                                                    <MenuItem value="">
                                                        <em>Выбрать</em>
                                                    </MenuItem>
                                                    <MenuItem value="fiz">Физ. лицо</MenuItem>
                                                    <MenuItem value="ur">Юр. лицо</MenuItem>
                                                    <MenuItem value="supp">Оптовик</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className='col-sm-12 col-md-2 col-lg-2'>
                                            <Element fieldDetails={{
                                                "field_api_name": "VisitDate",
                                                "field_label": "contactrequest.VisitDate",
                                                "field_mandatory": "false",
                                                "field_placeholder": "",
                                                "field_type": "date",
                                                "helperText": "",
                                                "field_access": "edit"
                                            }} handleEdit={handleFieldValue} value={objectFields["VisitDate"] ? objectFields["VisitDate"] : null} variant="standard"/>
                                        </div>
                                        <div className='col-sm-12 col-md-2 col-lg-2'>
                                            <Element fieldDetails={{
                                                "field_api_name": "ContractDate",
                                                "field_label": "contactrequest.ContactDate",
                                                "field_mandatory": "false",
                                                "field_placeholder": "",
                                                "field_type": "date",
                                                "helperText": "",
                                                "field_access": "edit"
                                            }} handleEdit={handleFieldValue} value={objectFields["ContractDate"] ? objectFields["ContractDate"] : null} variant="standard"/>
                                        </div>
                                        <div className='col-sm-12 col-md-2 col-lg-2'>
                                            <Button variant="contained" size="small" style={{marginTop: '15px', height: '30px'}} onClick={clearSearchContact} endIcon={<DeleteIcon />}>Очистить</Button>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-12 col-lg-12'>
                                            <div className="form-group">
                                                <label for="clientDescription">{<IntlMessages id="common.description" />}</label>
                                                <textarea className="form-control" id="clientDescription" name="Description" rows="5" value={addComments["Description"] ? addComments["Description"] : ''} onChange={(e) => handleCommentsUpdate(e)}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-4 col-lg-4'>
                                            <TextField
                                                label="Рассрочка"
                                                id="Loan"
                                                name="Loan"
                                                alt="Рассрочка"
                                                size="small"
                                                fullWidth
                                                margin="dense"
                                                value={addComments["Loan"] ? addComments["Loan"] : ''}
                                                onChange={(e) => handleCommentsUpdate(e)}
                                            />
                                        </div>
                                        <div className='col-sm-12 col-md-4 col-lg-4'>
                                            <TextField
                                                label="Кредит"
                                                alt="Кредит"
                                                id="Credit"
                                                name="Credit"
                                                size="small"
                                                fullWidth
                                                margin="dense"
                                                value={addComments["Credit"] ? addComments["Credit"] : ''}
                                                onChange={(e) => handleCommentsUpdate(e)}
                                            />
                                        </div>
                                        <div className='col-sm-12 col-md-4 col-lg-4'>
                                            <TextField
                                                label="Доставка"
                                                alt="Доставка"
                                                id="Delivery"
                                                name="Delivery"
                                                size="small"
                                                fullWidth
                                                margin="dense"
                                                value={addComments["Delivery"] ? addComments["Delivery"] : ''}
                                                onChange={(e) => handleCommentsUpdate(e)}
                                            />
                                        </div>
                                        <div className='col-sm-12 col-md-4 col-lg-4'>
                                            <TextField
                                                label="Вопросы по допам"
                                                alt="Вопросы по допам"
                                                id="AdditionalQuestion"
                                                name="AdditionalQuestion"
                                                size="small"
                                                fullWidth
                                                margin="dense"
                                                value={addComments["AdditionalQuestion"] ? addComments["AdditionalQuestion"] : ''}
                                                onChange={(e) => handleCommentsUpdate(e)}
                                            />
                                        </div>
                                        <div className='col-sm-12 col-md-4 col-lg-4'>
                                            <TextField
                                                label="Цена"
                                                alt="Цена"
                                                id="Price"
                                                name="Price"
                                                size="small"
                                                fullWidth
                                                margin="dense"
                                                value={addComments["Price"] ? addComments["Price"] : ''}
                                                onChange={(e) => handleCommentsUpdate(e)}
                                            />
                                        </div>
                                        <div className='col-sm-12 col-md-4 col-lg-4'>
                                            <TextField
                                                label="Замечания"
                                                alt="Замечания"
                                                id="AdditionalThing"
                                                name="AdditionalThing"
                                                size="small"
                                                fullWidth
                                                margin="dense"
                                                value={addComments["AdditionalThing"] ? addComments["AdditionalThing"] : ''}
                                                onChange={(e) => handleCommentsUpdate(e)}
                                            />
                                        </div>
                                    </div>
                                </USCardContent>
                                <USCardFooter>
                                    <Button variant="outlined" size="small" onClick={saveCall}>Сохранить Звонок</Button>
                                    <Button style={{ marginLeft: '10px' }} variant="outlined" size="small" onClick={saveNewLead}>Сохранить и конвертировать в Лида</Button>
                                </USCardFooter>
                            </USCard>
                            <div className="row">
                                <div className='col-sm-12 col-md-12 col-lg-12'>
                                    {selectedContact && <USTable tableTitle="Previous Requests" objectName="contactrequest" parentFieldName="ContactId" parentObjectId={selectedContact.id} requestUrl={'company/child'} expandableRows={false} showDelete={false} packageUrl="sales"/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-5 col-lg-5'>
                    <div className="row">
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                            <Card heading={<IntlMessages id="client.product-label"/>}>
                                <CardHeader
                                    avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                        R
                                    </Avatar>
                                    }
                                    action={
                                        <ButtonGroup size="small" aria-label="Small button group">
                                            <Button key="one">Продукты</Button>
                                            <Button key="two">Закупка</Button>
                                        </ButtonGroup>
                                    }
                                    title="Call Type"
                                />
                                <CardContent>
                                    <div className="row">
                                        <div className='col-sm-12 col-md-12 col-lg-6'>
                                            <TextField
                                                label="Артикул"
                                                id="productCodeId"
                                                size="small"
                                                fullWidth
                                                margin="dense"
                                                name="ProductsCompanyCode"
                                                value={productCode}
                                                onChange={(e) => searchProduct('ProductsCompanyCode', e.target.value)}
                                            />
                                        </div>
                                        <div className='col-sm-12 col-md-12 col-lg-6'>
                                            <TextField
                                                label="Название"
                                                id="productName"
                                                size="small"
                                                fullWidth
                                                margin="dense"
                                                name="Name"
                                                value={productName}
                                                onChange={(e) => searchProduct('Name', e.target.value)}
                                            />
                                        </div>
                                        <div className='col-sm-12 col-md-12 col-lg-12'>
                                            <USSearchProduct detail={clientSelectedProduct} handleUpdateProduct={dataToSaveFunc}/>
                                        </div>
                                    </div>
                                    {productList && <div className='row mt-2'>
                                        <div className='col-sm-12 col-md-12 col-lg-12'>
                                            <div className="table-responsive">
                                                <div className="unseen">
                                                    <Table bordered striped>
                                                        <thead>
                                                            <tr className="bg-primary text-white">
                                                                <th>Имя</th>
                                                                <th>Код Продукта</th>
                                                                <th>Цена</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {productList.map((productItem, indexp) => {
                                                                return <tr key={indexp} onClick={() => updateProductDetails(productItem.id)}>
                                                                    <td>{productItem.Name}</td>
                                                                    <td>{productItem.ProductsCompanyCode} </td>
                                                                    <td>{productItem.Price} </td>
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div>
            </>

        </div>
    )
}
export default USNewOrder;
