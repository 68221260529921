import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//import { useSelector } from "react-redux";
import Sidebar from "./Components/Sidebar";
import TopNav from "./Components/TopNav";
import geditorConfig from "./BuilderConfig/Config";
import PageSection from "./Components/PageSection";
import "grapesjs/dist/css/grapes.min.css"
import  "../../../../css/app.css";
import { Col, Row } from "reactstrap";


const Builder = () => {
    const [editor, setEditor] = useState(null);
    const [assets, setAssets] = useState([]);
    const { id } = useParams();

    const  pageStore  = {
        listPageLoading: false,
        listPageError: "",
        pages: [],
        createPageError: "",
        createPageLoading: false,
    };
    //useSelector((state) => state);
    const { pages } = pageStore;

    useEffect(() => {
        async function getAllAssets() {
            try {
                //const response = await axios.get(`${API_HOST}assets/`);
                //setAssets(response.data);
            } catch (error) {
                setAssets(error.message);
            }
        }

        getAllAssets();
    }, []);

    useEffect(() => {
        console.log(id);
        //const editor = geditorConfig(assets, pageId);
        const editor = geditorConfig('assets', id);
        setEditor(editor);
    }, [id, assets]);

    return (
        <Row>
            <Col sm={12} md={2} xl={2}>
                <div id="navbar" className="sidenav d-flex flex-column overflow-scroll">
                    <nav className="navbar navbar-light">
                        <div className="container-fluid">
                            <span className="navbar-brand mb-0 h3 logo">Code Dexterous</span>
                        </div>
                    </nav>
                    <PageSection pages={pages} />
                    <Sidebar />
                </div>
            </Col>
            <Col sm={12} md={10} xl={10}>
                <div className="main-content position-relative w-85 start-15" id="main-content">
                    <TopNav />
                    <div id="editor"></div>
                </div>
            </Col>
        </Row>
    );
};

export default Builder;

/*
import React,{useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import grapesJs from 'grapesjs';
import gjsPresentWebpage from "grapesjs-preset-webpage";
import "grapesjs/dist/css/grapes.min.css"
import "../../../../css/app.css";
import {loadPage, storePage, postRequest} from '../ApiService/Service'

function Builder() {
    const [editor, setEditor] = useState(null);
    const {id} = useParams();
    console.log('grapesJs::page id:: ',id);
    useEffect(() => {
        const editor = grapesJs.init({
            container: "#editor",
            plugins: [gjsPresentWebpage],
            pluginsOpts: {
                gjsPresentWebpage: {},
            },
            storageManager: {
                type: "remote",
                autosave: true, // Store data automatically
                autoload: true,
                stepsBeforeSave: 1,
                contentTypeJson: true,
                storeComponents: true,
                storeStyles: true,
                storeHtml: true,
                storeCss: true,
                id: "acc-",
            }
        });
        editor.Storage.add('remote', {
            async load() {
                console.log('load page from database');
                const response = await loadPage(`page/${id}`);
                console.log(JSON.parse(response.data.data));
                return JSON.parse(response.data.data);
            },

            async store(data) {
                console.log(data);
                const response =  await storePage(`store-page/${id}`,  JSON.stringify(data));
                console.log(response);
                return response;
            },
        });
        setEditor(editor);
    }, [])

    return (
        <div id="editor"></div>
    );
}

export default Builder;*/
