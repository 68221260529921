import { Button, CardHeader, TextField } from "@mui/material";
import { USCard, USCardContent } from "../../../USCommonComponents/USCardComponent";
import { useParams } from 'react-router-dom';
import USObjectManagerLayout from "./USObjectManagerLayout";

export default function USObjectTrigger() {
    const {objectName} = useParams();

    return (
        <USObjectManagerLayout objectName={objectName}>
            <USCard>
                <CardHeader
                    action={
                        <div>
                            <Button variant="outlined" size="small">New</Button>
                        </div>
                    }
                    title="Trigger"
                    subheader=""
                />
                <USCardContent>
                    Under Construction
                </USCardContent>
            </USCard>
        </USObjectManagerLayout>
    )
}
