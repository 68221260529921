import { Link } from '@mui/material';
import React, { useState, useEffect } from 'react';


import {getRequest, postRequest, deleteRequest} from '../ApiService/Service'

function PageList() {
    const [pageName, setPageName] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [pages, setPages] = useState([]);

    //const dispatch = useDispatch();

    //const { pageStore } = useSelector((state) => state);
    //const { pages } = pageStore;

    const handleSubmit = (e) => {
        e.preventDefault();
        createNewPage(pageName);
    };

    const createNewPage = async (name) => {
        if (name) {
            const slugName = name.toLowerCase().split(" ").join("-");
            const response = await postRequest('createPage',{Name: name, Slug: slugName});
            getPages();
          }
    }

    const deletePage = async (e, pageId) => {
        e.preventDefault();
        const response = await deleteRequest(`deletePage/${pageId}`);
        console.log(response);
        getPages();
    }

    const getPages = async () => {
        const response = await getRequest('getPages?AccountId=1');
        setPages(response.data.data);
    }

    useEffect(() => {
        getPages();
    }, []);

    return (

      <div className="row">
        <div className="col-12 mt-5">
          <form id="create-page">
            <div className="modal-header">
              <h5 className="modal-title" id="addPageModalLabel">
                Create Page
              </h5>
            </div>
            <div className="modal-body">
              <div className="col-auto">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className={`form-control form-control-sm ${
                    isValid ? "" : "is-invalid"
                  }`}
                  id="name"
                  name="name"
                  placeholder="Name of Page"
                  value={pageName}
                  onChange={(e) => setPageName(e.target.value)}
                />
                {!isValid && (
                  <div className="invalid-feedback">
                    Please provide a valid name.
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-bs-dismiss="modal"
              >
                Clear
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={(e) => handleSubmit(e)}
              >
                Save
              </button>
            </div>
          </form>
        </div>
        <div className="col-12 my-2">
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <td>ID</td>
                <td>Name</td>
                <td>Slug</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {pages
                ? pages.map((page) => (
                    <tr key={page.id}>
                      <td>{page.id}</td>
                      <td>{page.Name}</td>
                      <td>{page.Slug}</td>
                      <td>
                        <Link href={`/site-builder/builder/${page.id}`}>Edit</Link> | <Link component="button" variant="body2" onClick={(e) => deletePage(e, page.id)}>Delete</Link>
                      </td>
                    </tr>
                  ))
                : "No Page"}
            </tbody>
          </table>
        </div>
      </div>
    );
}

export default PageList;
