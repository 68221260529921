module.exports = {
    "sidebar.help" : "Help",
    "sidebar.housemates" : "Housemates",
    "sidebar.announcement" : "Announcement",
    "sidebar.publicevent" : "Public Events",
    "sidebar.survey" : "Survey",
    "sidebar.console" : "Console",
    "carparts.suppliers" : "Suppliers",
    "carparts.orders" : "Orders",
    "carparts.warehouse" : "warehouse",
    "carparts.delivery" : "delivery",
    "carparts.settings" : "Settings",

    "sidebar.partcategory" : "Part category",
    "partcategory.Name" : "",
    "partcategory.isActive" : "",
    "partcategory.id" : "",
    "partcategory.created_at" : "",
    "partcategory.CategoryType" : "",
    "partcategory.AccountId" : "",
    "partcategory.updated_at" : "",

    "carparts.customers": "Customers",

    "quicklinks.settings" : "Admin Settings" ,
    "quicklinks.portal" : "Company Portal" ,
    "quicklinks.service" : "Service Console" ,
    "quicklinks.sales" : "Sales Console" ,
    "quicklinks.profile" : "User Profile" ,
    "quicklinks.site" : "Public Site" ,

    "common.City" : "City",
    "common.edit" : "Edit",
    "common.save" : "Save",
    "common.cancel" : "Cancel",
    "common.assignToUser" : "Assign To User",
    "account.table-name" : "Account",
    "account.Name" : "Account",
    "Account" : "Account",
    "relatedList.Productcompanyparts" : "Product Parts",
    "productcompanyparts.AccountId" : "Account" ,
    "productcompanyparts.Count" : "Count" ,
    "productcompanyparts.id" : "Action" ,
    "productcompanyparts.isActive" : "is Active" ,
    "productcompanyparts.Name" : "Name" ,
    "productcompanyparts.ProductCompanyId" : "Product Company" ,
    "productcompanyparts.ProductPartsId" : "Product Parts" ,
    "productcompanyparts.Slug" : "Slug" ,
    "productcompanyparts.Type" : "Type" ,
    "productcompanyparts.isDefault" : "isDefault" ,

    "productparts.AccountId" : "Account",
    "productparts.AssetId" : "Asset",
    "productparts.Count" : "Count",
    "productparts.created_at" : "Created Date",
    "productparts.Default" : "Default",
    "productparts.id" : "Action",
    "productparts.isActive" : "is Active",
    "productparts.MaterialCount" : "Material Count",
    "productparts.Name" : "Name",
    "productparts.Price" : "Price",
    "productparts.Series" : "Series",
    "productparts.Type" : "Type",
    "productparts.updated_at" : "Updated Date",
    "productparts.Fields" : "Fields",

    "productparts" : "Productparts",
    "relatedList.Productpartsasset" : "Product Part Assets",
    "productpartsasset.AccountId" : "Account",
    "productpartsasset.AssetId" : "Asset",
    "productpartsasset.Comment" : "Comment",
    "productpartsasset.created_at" : "Created Date",
    "productpartsasset.updated_at" : "Updated Date" ,
    "productpartsasset.TotalAmount" : "Total Amount" ,
    "productpartsasset.Quantity" : "Quantity" ,
    "productpartsasset.ProductpartsId" : "Productpart" ,
    "productpartsasset.Name" : "Name" ,
    "productpartsasset.isActive" : "is Active" ,
    "productpartsasset.id" : "Action" ,

    "event.id" : "id",
    "event.Name" : "Name",
    "event.Subject" : "Subject",
    "event.Type" : "Type",
    "event.SubType" : "SubType",
    "event.Description" : "Description",
    "event.Status" : "Status",
    "event.Location" : "Location",
    "event.StartDate" : "Start Date",
    "event.EndDate" : "End Date",
    "event.Duration" : "Duration",
    "event.Reminder" : "Reminder",
    "event.isPublic" : "is Public",
    "event.isRepeatable" : "is Repeatable",
    "event.AssignedToId" : "Assigned To",
    "event.CreatedById" : "Created By",
    "event.LastModifiedById" : "Last Modified By",
    "event.AccountId" : "Account",
    "event.created_at" : "Crated Date",
    "event.updated_at" : "Updated Date",

    "document.id" : "id",
    "document.EmployeeId" : "Employee",
    "document.AccountId" : "Account",
    "document.Name" : "Name",
    "document.SerialNumber" : "Serial Number",
    "document.IssueDate" : "Issue Date",
    "document.ExpirationDate" : "Expiration Date",
    "document.created_at" : "Created Date",
    "document.updated_at" : "Updated Date",
    "document.ParentUserId" : "Parent User",
    "document.URL" : "URL",
    "Call" : "Call",
    "task" : "Task",
    "task.id" : "id",
    "task.Name" : "Name",
    "task.Phone" : "Phone",
    "task.Source" : "Source",
    "task.Subject" : "Subject",
    "task.Type" : "Type",
    "task.SubType" : "Sub-Type",
    "task.Description" : "Description",
    "task.Priority" : "Priority",
    "task.Status" : "Status",
    "task.CompletedDate" : "Completed Date",
    "task.DueDate" : "Due Date",
    "task.Reminder" : "Reminder",
    "task.AssignedToId" : "Assigned To",
    "task.CreatedById" : "Created By",
    "task.LastModifiedById" : "Last Modified By",
    "task.AccountId" : "Account",
    "task.ContactId" : "Contact",
    "task.LeadId" : "Lead",
    "task.created_at" : "Created Date",
    "task.updated_at" : "Updated Date",
    "profile": "Profile",
    "profile.id" : "Action",
    "profile.Fields" : "Profile",
    "profile.isActive" : "is Active",
    "profile.License" : "License",
    "profile.Name" : "Name",
    "profile.updated_at" : "Modified Date",
    "profile.created_at" : "Created Date",

    "roletoprofile.AccountId" : "Account",
    "roletoprofile.created_at" : "Created Date",
    "roletoprofile.id" : "Action",
    "roletoprofile.ProfileId" : "Profile",
    "roletoprofile.RoleId" : "Role",
    "roletoprofile.updated_at" : "Updated Date",


    "productcategory.id" : "Id",
    "productcategory.Name" : "Name",
    "productcategory.ShowInMenu" : "Show In Menu",
    "productcategory.SortOrder" : "Order",
    "productcategory.Description" : "Description",
    "productcategory.AccountId" : "Account",
    "productcategory.ParentCategoryId" : "Parent Category",
    "productcategory.ProductCatalogId" : "Catalog",
    "productcategory.Type" : "Type",
    "productcategory.Items" : "Items",
    "productcategory.ProductCatalogId_depricated" : "Удалить",

    "productcatalog.Fields" : "Catalog",
    "productcatalog.id" : "Id",
    "productcatalog.Name" : "Name",
    "productcatalog.AccountId" : "Account",
    "productcatalog.Type" : "Type",
    "productcatalog.Slug" : "Slug",
    "productcatalog.Order" : "Order",
    "productcatalog.StoreId" : "Store",
    "productcatalog.Items" : "Items",
    "productcatalog.Image" : "Image",
    "address.Type":"Type",
    "address.Fields" : "Address",
    "address.id" : "Address",
    "address.AccountId" : "Account",
    "address.City" : "City",
    "address.Country" : "Country",
    "address.OrderId" : "Order",
    "address.Street" : "Street",
    "address.ZipCode" : "Zipcode",
    "address.AssetId" : "Asset",
    "address.ContactId" : "Contact",
    "address.StateProvince" : "State/Province",
    "address.UserId" : "User",

    "relatedList.Workschedule" : "Workschedules",
    "workschedule.start":"Start",
    "workschedule.lunch":"Lunch",
    "workschedule.finish":"Finish",
    "workschedule.Fields" : "Workschedule",
    "workschedule.id" : "Workschedule",
    "workschedule.EmployeeId" : "Employee",
    "workschedule.AccountId" : "Account",
    "workschedule.SundayStart" : "Sun. Start",
    "workschedule.MondayStart" : "Mon. Start",
    "workschedule.TuesdayStart" : "Tue. Statr",
    "workschedule.WednesdayStart" : "Wed. Start",
    "workschedule.ThursdayStart" : "Thu. Start",
    "workschedule.FridayStart" : "Fri. Start",
    "workschedule.SaturdayStart" : "Sat. Start",
    "workschedule.SundayFinish" : "Sun. Finish",
    "workschedule.MondayFinish" : "Mon. Finish",
    "workschedule.TuesdayFinish" : "Tue. Finish",
    "workschedule.WednesdayFinish" : "Wed. Finish",
    "workschedule.ThursdayFinish" : "Thu. Finish",
    "workschedule.FridayFinish" : "Fri. Finish",
    "workschedule.SaturdayFinish" : "Sat. Finish",
    "workschedule.SundayLunch" : "Sun. Lunch",
    "workschedule.MondayLunch" : "Mon. Lunch",
    "workschedule.TuesdayLunch" : "Tue. Lunch",
    "workschedule.WednesdayLunch" : "Wed. Lunch",
    "workschedule.ThursdayLunch" : "Thu. Lunch",
    "workschedule.FridayLunch" : "Fri. Lunch",
    "workschedule.SaturdayLunch" : "Sat. Lunch",
    "workschedule.created_at" : "Created Date",
    "workschedule.updated_at" : "Updated Date",
    "workschedule.AddressId" : "Address",

    "store.id" : "Id",
    "store.Name" : "Name",
    "store.AccountId" : "Account",
    "store.Description" : "Description",
    "store.Country" : "Country",
    "store.DefaultCurrency" : "Default Currency",
    "store.DefaultLanguage" : "Default Language",
    "store.OrderActivationStatus" : "Order Activation Status",
    "store.OrderLifeCycleType" : "Life Cycle Type",
    "store.PricingStrategy" : "Pricing Strategy",
    "store.ResultsPerPage" : "Results Pre Page",
    "store.isActive" : "Is Active",
    "asset" : "Asset",
    "asset.Fields" : "Action",
    "asset.id" : "Action",
    "asset.Name" : "Name",
    "asset.CreatedById" : "Created By",
    "asset.LastModifiedById" : "Last Modified By",
    "asset.Address" : "Address",
    "asset.AssetLevel" : "Asset Level",
    "asset.CurrentAmount" : "Current Amount",
    "asset.CurrentQuantity" : "Current Quantity",
    "asset.Description" : "Description",
    "asset.ExternalId" : "External",
    "asset.HasLifecycleManagment" : "Has Lifecycle Managment",
    "asset.InternalAsset" : "Internal Asset",
    "asset.InstallDate" : "Install Date",
    "asset.LifecycleStartDate" : "Lifecycle Start Date",
    "asset.LifecycleEndDate" : "Lifecycle End Date",
    "asset.ManufactureDate" : "Manufacture Date",
    "asset.ProductCode" : "Product Code",
    "asset.ProductDescription" : "Product Description",
    "asset.PurchaseDate" : "Purchase Date",
    "asset.Price" : "Price",
    "asset.SerialNumber" : "Serial Number",
    "asset.UsageEndDate" : "Usage End Date",
    "asset.ProductFamily" : "Product Family",
    "asset.Status" : "Status",
    "asset.StatusReason" : "Status Reason",
    "asset.LastModifiedDate" : "Last  Modified Date",
    "asset.CreatedDate" : "Created Date",
    "asset.created_at" : "created at",
    "asset.updated_at" : "updated at",
    "asset.AccountId" : "Account",
    "asset.ProvidedById" : "Provided By",
    "asset.ServicedById" : "Serviced By",
    "asset.ProductId" : "Product",
    "asset.EmployeeId" : "Employee",
    "asset.InventoryNumber" : "Inventory Number",
    "asset.AssignmentDate" : "Assignment Date",
    "asset.Type" : "Type",
    "asset.EnsuranceStartDate" : "Ensurance Start Date",
    "asset.EnsuranceEndDate" : "Ensurance End Date",

    "emailconnectsettings.Name" : "Name",
    "emailconnectsettings.isActive" : "is Active",
    "emailconnectsettings.Host" : "Host",
    "emailconnectsettings.Port" : "Port",
    "emailconnectsettings.Encryption" : "Encryption",
    "emailconnectsettings.ValidateCerteficate" : "Validate Certeficate",
    "emailconnectsettings.Username" : "Username",
    "emailconnectsettings.Password" : "Password",
    "emailconnectsettings.AccountId" : "Account",
    "emailconnectsettings.created_at" : "Created Date",
    "emailconnectsettings.updated_at" : "Last Update Date",

    "emailfiltersettings.Name" : "Name",
    "emailfiltersettings.isActive" : "is Active",
    "emailfiltersettings.EmailFrom" : "Email From",
    "emailfiltersettings.FolderName" : "Folder Name",
    "emailfiltersettings.SubjectHas" : "Subject Has",
    "emailfiltersettings.TextHas" : "Text Has",
    "emailfiltersettings.DateFrom" : "Date From",
    "emailfiltersettings.EmailConnectSettingsId " : "Email Connect Settings",
    "emailfiltersettings.AccountId" : "Account",
    "emailfiltersettings.created_at" : "Created Date",
    "emailfiltersettings.updated_at" : "Last Update Date",

    "filemapping.Name" : "Name",
    "filemapping.isActive" : "is Active",
    "filemapping.EmailConnectSettingsId" : "Email Connect Settings",
    "filemapping.EmailFilterSettingsId" : "Email Filter Settings",
    "filemapping.AccountId" : "Account",
    "filemapping.created_at" : "Created Date",
    "filemapping.updated_at" : "Last Update Date",

    "filemappingitem.Name" : "Name",
    "filemappingitem.isActive" : "is Active",
    "filemappingitem.ColumnFrom" : "Email Connect Settings",
    "filemappingitem.ColumnTo" : "Email Filter Settings",
    "filemappingitem.SkipIfEmpty" : "Skip If Empty",
    "filemappingitem.FileMappingId " : "File Mapping",
    "filemappingitem.AccountId" : "Account",
    "filemappingitem.created_at" : "Created Date",
    "filemappingitem.updated_at" : "Last Update Date",

    "bankdetails.id" : "Bank Details",
    "bankdetails.Name" : "Name",
    "bankdetails.AccountId" : "Account",
    "bankdetails.AccountNumber" : "Account Number",
    "bankdetails.BankCorrespondNumber" : "Bank Correspond Number",
    "bankdetails.BIC" : "BIC",
    "bankdetails.City" : "City",
    "bankdetails.Country" : "Country",
    "bankdetails.CodeOfBankOffice": "Code Of Bank Office",
    "bankdetails.CreatedById" : "Created By",
    "bankdetails.CreatedDate" : "CreatedDate",
    "bankdetails.Description" : "Description",
    "bankdetails.EmployeeId" : "Employee",
    "bankdetails.isMain" : "is Main",
    "bankdetails.LastModifiedById" : "Last Modified By",
    "bankdetails.LastModifiedDate" : "Last Modified Date",
    "bankdetails.StateProvince" : "State/Province",
    "bankdetails.Street" : "Street",
    "bankdetails.UNP" : "UNP",
    "bankdetails.UserId" : "User",
    "bankdetails.ZipCode" : "Zipcode",
    "bankdetails.AccountId" : "Account",

    "cartaddressdetail.id" : "id",
    "cartaddressdetail.AccountId" :"Account",
    "cartaddressdetail.ContactId" : "Contact",
    "cartaddressdetail.DeliveryAddress" : "Delivery Address",
    "cartaddressdetail.FirstName" : "First Name",
    "cartaddressdetail.Email" : "Email",
    "cartaddressdetail.DeliveryType" : "Delivery Type",
    "cartaddressdetail.DeliveryTime" : "Delivery Time",
    "cartaddressdetail.VinCode" : "Vincode",
    "cartaddressdetail.UserId" : "User id",
    "cartaddressdetail.UserCarId" : "User Car Details",
    "cartaddressdetail.updated_at" : "Updated Date",
    "cartaddressdetail.ShippingZipCode" : "Shipping Zipcode",
    "cartaddressdetail.ShippingStreet": "Shipping Street",
    "cartaddressdetail.ShippingStateProvince" : "Shipping State",
    "cartaddressdetail.ShippingCountry" : "Shipping Country",
    "cartaddressdetail.ShippingCity" : "Shipping City",
    "cartaddressdetail.Phone" : "Phone",
    "cartaddressdetail.OrderId" : "Order",
    "cartaddressdetail.MakeCall" : "Make Call",
    "cartaddressdetail.created_at" : "Created Date",
    "cartaddressdetail.CheckForVin" : "Check Vin",

    "order.Fields" : "Order",
    "order.id" : "ID",
    "order.Name" : "Name",
    "order.OrderNumber" : "Order Number",
    "order.CreatedById" : "Created By",
    "order.LastModifiedById" : "Last Modified By",
    "order.BillingAddress" : "Billing Address",
    "order.ShippingAddress" : "Shipping Address",
    "order.Description" : "Description",
    "order.Amount" : "Amount",
    "order.StartDate" : "Start Date",
    "order.EndDate" : "End Date",
    "order.OrderType" : "Order Type",
    "order.ReductionOrder" : "Reduction Order",
    "order.Status" : "Status",
    "order.LastModifiedDate" : "Last Modified Date",
    "order.CreatedDate" : "Created Date",
    "order.created_at" : "Created Date",
    "order.updated_at" : "Update Date",
    "order.AccountId" : "Account",
    "order.ContactId" : "Contact",
    "order.ContractId" : "Contract",
    "order.EmployeeId" : "Employee",
    "order.Quantity" : "Quantity",
    "order.Prepayment" : "Prepayment",
    "order.StoreId" : "Store",
    "order.UserId" : "User",
    "order.PaymentMethod" : "Payment Method",
    "order.SalesChannel" : "Sales Channel",
    "order.OrderSource" : "Order Source",

    "contact.id" : "ID",
    "contact.CreatedById" : "Created By",
    "contact.LastModifiedById"  : "Last Modified By",
    "contact.ParentUserId" : "Parent User",
    "contact.Birthdate" : "Birthdate",
    "contact.Status" : "Status",
    "contact.Description" : "Description",
    "contact.DoNotCall" : "DoNotCall",
    "contact.Email" : "Email",
    "contact.Fax" : "Fax",
    "contact.HomePhone" : "HomePhone",
    "contact.Mobile" : "Mobile",
    "contact.Phone" : "Phone",
    "contact.OtherPhone" : "OtherPhone",
    "contact.FirstName" : "FirstName",
    "contact.LastName" : "LastName",
    "contact.MiddleName" : "MiddleName",
    "contact.Title" : "Title",
    "contact.Name" : "Name",
    "contact.LastModifiedDate" : "Last Modified Date",
    "contact.CreatedDate" : "Created Date",
    "contact.created_at" : "Created Date",
    "contact.updated_at" : "Modified Date",
    "contact.AccountId" : "Account",
    "contact.Type" : "Type",
    "contact.EmployeeId" : "Employee",
    "contact.isSupplier" : "isSupplier",
    "contact.Сurrency" : "Сurrency",
    "contact.DeliveryTime" : "Delivery Time",
    "contact.Color" : "Color",
    "contact.OnMyStorehouse" : "On My Storehouse",
    "contact.Sort" : "Sort",
    "contact.OnOrder" : "On Order",
    "contact.InInstalments" : "In Instalments",
    "contact.forRegisteredUsers" : "for Registered Users",
    "contact.PriceUpdated" : "Price Updated",
    "contact.ExternalName" : "External Name",

    "relatedList.Asset" : "Assets",
    "relatedList.Cartitem" : "Cart Items",
    "cartitem.AccountId" : "Account",
    "cartitem.Active" : "Active",
    "cartitem.CartId" : "Cart",
    "cartitem.created_at" : "Created Date",
    "cartitem.Discount" : "Discount",
    "cartitem.id" : "Cart Item",
    "cartitem.Image" : "Image",
    "cartitem.Name" : "Name",
    "cartitem.OrderId" : "Order",
    "cartitem.Price" : "Price",
    "cartitem.ProductId" : "Product",
    "cartitem.Quantity" : "Quantity",
    "cartitem.Total" : "Total",
    "cartitem.updated_at" : "Updated Date",
    "cartitem.UserCarId" : "User Car",

    "relatedList.Productcategoryproduct" : "Product Category Product",
    "productcategoryproduct.AccountId" : "Account",
    "productcategoryproduct.id" : "Product Category",
    "productcategoryproduct.isPrimaryCategory" : "is Primary Category",
    "productcategoryproduct.Name" : "Name",
    "productcategoryproduct.ProductId" : "Product",

    "relatedList.Orderproduct" : "Product's order",
    "orderproduct.AccountId" : "Account",
    "orderproduct.AvailableQuantity" : "Available Quantity",
    "orderproduct.created_at" : "Created Date",
    "orderproduct.CreatedById" : "Created By",
    "orderproduct.CreatedDate" : "Created Date",
    "orderproduct.EndDate" : "End Date",
    "orderproduct.id" : "Order Product",
    "orderproduct.LastModifiedById" : "Last Modified By",
    "orderproduct.LastModifiedDate" : "last Modified Date",
    "orderproduct.OrderId" : "Order",
    "orderproduct.Price" : "Price",
    "orderproduct.ProductCode" : "Product Code",
    "orderproduct.ProductId" : "Product",
    "orderproduct.Quantity" : "Quantity",
    "orderproduct.UnitPrice" : "Unit Price",
    "orderproduct.updated_at" : "Updated Date",

    "relatedList.Productcharacteristics" : "Product Characteristics",
    "productcharacteristics.AccountId" : "Account",
    "productcharacteristics.created_at" : "Created Date",
    "productcharacteristics.Description" : "Description",
    "productcharacteristics.id" : "Product Characteristics",
    "productcharacteristics.isActive" : "isActive",
    "productcharacteristics.Name" : "Name",
    "productcharacteristics.ProductChildName" : "Product Child Name",
    "productcharacteristics.ProductId" : "Product",
    "productcharacteristics.updated_at" : "Created Date",

    "relatedList.Productitem" : "Product Items",
    "productitem.AccountId" : "Account",
    "productitem.ChildProductId" : "Child Product",
    "productitem.Count" : "Count",
    "productitem.created_at" : "Created Date",
    "productitem.Default" : "Default",
    "productitem.id" : "Product Item",
    "productitem.isActive" : "is Active",
    "productitem.ParentProductId" : "Parent Product",
    "productitem.Price" : "Price",
    "productitem.updated_at" : "Created Date",

    "relatedList.Productlike" : "Product Likes",
    "productlikes.id" : "Product Like",
    "productlikes.Product" : "Product",
    "productlikes.User" : "User",



    "common.label": "Label",
    "common.apiname": "Api Name",
    "common.type": "Type",
    "common.description": "Description",
    "common.last-modified": "Last-Modified",
    "common.deployed": "Deployed",
    "common.indexed" : "Indexed",


    "common.objectmanager" : "Object Manager",
    "common.import-type":"Import Type",
    "common.import-type-unite":"Unite Prices",
    "common.import-type-replace":"Replace Prices",
    "common.homepage" : "Home",
    "common.hottubs" : "Hot Tubs",
    "common.brazier" : "Braziers",
    "common.terrace" : "Terrace",
    "common.contacts" : "Contacts",
    "common.aboutUs": "About Us",
    "common.service": "Service",
    "common.number": "Number",
    "common.byitnow":"Buy it now",
    "components.buyit": "Buy it",
    "common.addtocart":"Add to cart",
    "common.addtowishlist":"Add to wishlist",
    "common.birthdate" :"Birthdate",
    "common.moredetails": "More details...",
    "common.partners": "For Partners",
    "common.active":"Active",
    "common.default":"is Default",
    "common.sendEmail": "Send Email",
    "common.name":"Name",
    "common.settings":"Settings",
    "common.profile":"My Profile",
    "common.profileSettings":"Profile Settings",
    "common.permissions" : "Permissions",
    "common.roleSettings":"Role Settings",
    "common.rolePermissions":"Role Permissions",
    "common.objectName" : "Object Name",
    "common.allpermissions" : "All",
    "common.create" : "Create",
    "common.read" : "Read",
    "common.update" : "Update",
    "common.delete" : "Delete",
    "common.product":"Product",
    "common.load-product":"Load Product",
    "common.productcode" : "Product Code",
    "common.material" : "Material",
    "common.height" : "Height",
    "common.width" : "Width",
    "common.lenght" : "Lenght",
    "common.radius" : "Radius",
    "common.productType":"Product Type",
    "common.available" : "Available",
    "common.contact":"Contact",
    "common.manager" : "Manager",
    "common.status":"Status",
    "common.vendor-code": "Vendor Code",
    "common.price":"Price",
    "common.price-settings":"Price Settings",
    "common.checkforvin" : "Check for VIN",
    "common.deliveryaddress" : "Delivery Address",
    "common.checkallprices":"Check All Prices",
    "common.quantity":"Quantity",
    "common.purchase-date":"Purchase Date",
    "common.install-date":"Install Date",
    "common.usage-end-date":"Usage End Date",
    "common.serial-number":"Serial Number",
    "common.type":"Type",
    "common.industry":"Industry",
    "common.subIndustry":"Sub-Industry",
    "common.description":"Description",
    "common.ensurance-start-date":"Ensurance Start Date",
    "common.ensurance-end-date":"Ensurance End Date",
    "common.guarantee-end-date":"Guarantee End Date",
    "common.first-name":"First Name",
    "common.last-name":"Last Name",
    "common.middle-name":"Middle Name",
    "common.middle-name-end-date":"Middle Name",
    "common.phone":"Phone number",
    "common.home-phone": "Home Phone",
    "common.fax":"Fax",
    "common.education": "Education",
    "common.additionalDetails":"Additional Details",
    "common.createdDate":"Created Date",
    "common.lastModifiedDate":"Last Modified Date",
    "common.anualRevenue":"Anual Revenue",
    "common.numberOfEmployees": "Number Of Employees",
    "common.numberOfOfices":"Number Of Ofices",
    "common.rating": "Rating",
    "common.telegram":"Telegram",
    "common.viber":"Viber",
    "common.whatsapp":"WhatsApp",
    "common.slack":"Slack",
    "common.wechat":"WeChat",
    "common.vkontakte" : "Vkontakte",
    "common.instagram" : "Instagram",
    "common.odnoklassniki" : "Odnoklassniki",
    "common.facebook" : "Facebook",
    "common.twitter" : "Twitter",
    "common.youtube" : "Youtube",
    "common.linkedIn" : "LinkedIn",
    "common.registered":"Registered",
    "common.upload":"Upload",
    "common.order-positions":"All Position",
    "common.order-list":"Client's Orders",
    "common.new-order":"New Order",
    "common.request-to-supplier":"Orders To Supplier",
    "common.articles" : "Articles" ,
    "common.reviews" : "Reviews" ,
    "car.brandid": "Brand Id",
    "car.ispopular": "is Popular",
    "car.logo": "Logo",
    "car.logo": "Logo",
    "car.showonmain": "Show on main",
    "car.isactive": "is Active",
    "car.place-to-display": "Place to Display",
    "car.car": "Car",
    "car.motobike": "Motobike",
    "car.truck": "Truck",
    "car.van": "Van",
    "car.place-to-display": "Place to display",
    "car.show-on-top": "Show on top",
    "car.show-on-bottom": "Show on bottom",
    "address.type":"Type",
    "sidebar.marketing" : "Marketing",
    "sidebar.aboutUs" : "About Us",
    "sidebar.instruction" : "Instructions",
   "sidebar.us_company-label" : "Company Details",
   "sidebar.us_companyDescription" : "Company Details",
   "sidebar.us_company-general" : "General",
   "sidebar.us_company-info" : "Company Information",
   "sidebar.us_company-employees" : "Employees",
   "sidebar.us_company-dashboard" : "Dasboard",
   "sidebar.us_company-assets" : "Assets",
   "sidebar.us_stores" : "Stores",
   "sidebar.site-builder": "Site Builder",
   "sidebar.assets" : "Assets",
   "sidebar.assetsDetails" : "Assets Details",
   "sidebar.us_company-sales-label": "Sales",
   "sidebar.us_clients-management": "Clients Managment",
   "sidebar.company_products": "Company Products",
   "sidebar.complects": "Complects",
   "sidebar.all_products": "All Products",
   "sidebar.clientsManagement": "Clients Managment",
   "sidebar.us_orders": "Orders",
   "sidebar.orders": "Orders",
   "sidebar.us_clients" : "Clients",
   "sidebar.leads" : "Leads",
   "sidebar.opportunity" : "Opportunity",
   "sidebar.us_suppliers": "Suppliers",
   "sidebar.markup": "Markup",
   "sidebar.us_consumers": "Consumers",
   "sidebar.us_products": "Products",
   "sidebar.products": "Products",
   "sidebar.us_company-activity-label": "Activities",
   "sidebar.us_company-requests" : "Requests",
   "sidebar.us_company-events" : "Events",
   "sidebar.us_tasks": "Tasks",
   "sidebar.us_company-vacation" : "Vacation",
   "sidebar.us_company-career-label" : "Career",
   "sidebar.us_learning": "Learning",
   "sidebar.us_achievements": "Achievements",
   "sidebar.us_progress": "Progress",
   "sidebar.us_company-discounts" : "Discounts",
   "sidebar.us_company-desktop" : "Desktop",
   "sidebar.us_company-hr" : "HR Department",
   "sidebar.us_company-vacancies" : "Vacancies",
   "sidebar.us_company-positions" : "Positions",
   "sidebar.us_company-accounting" : "Accounting",
   "sidebar.us_company-accounting-calculate_salary" : "Calculate Salary",
   "sidebar.us_company-accounting-calculate_main" : "Main calculating",
   "sidebar.us_company-accounting-reports" : "Reports",
   "sidebar.company": "Company",
   "sidebar.mypurchases": "My Purchases",
   "sidebar.myfavoriteshops": "My Shops",
   "sidebar.shopshares":"My Shop Shares",
   "sidebar.myorders": "My Orders",
   "sidebar.producttrack": "Track Products",
   "sidebar.mycareer": "My Career",
   "sidebar.myjobs": "My Jobs",
   "sidebar.mycources": "My Cources",
   "sidebar.mycv": "My CV",
   "sidebar.trackvacancy": "Track Vacancies",
   "sidebar.myfinance": "My Finance",
   "sidebar.mycompany": "My Companies",
   "sidebar.realestate": "My Real Estate",
   "sidebar.myhome": "My Home",
   "sidebar.myhometasks": "My Home Tasks",
   "sidebar.wishlist": "Wish List",
   "content.company-details.main" : "General Information",
   "content.company-details.brand" : "Brand",
   "content.company-details.password" : "Password",
   "company-details.comp_name" : "Company Name",
   "company-details.full_comp_name" : "Full Company Name",
   "company-details.registrationDate" : "Registration Date",
   "company-details.registrationAddress" : "Registration Address",
   "company-details.addresses" : "Company Addresses",
   "company-details.bank-details" : "Company Bank Details",
   "company-details.issued-by" : "Issued By",
    "company-details.regDetails":"Registration Details",
   "address.billing-address" : "Billing Address",
   "address.shipping-address" : "Shipping Address",
   "address.modal-header" : "Add New Address",
   "address.country" : "Country",
   "address.street" : "Street",
   "address.city" : "City",
   "address.state" : "State/Province",
   "address.zipcode" : "Zip/Postal Code",
   "address.isRegistration" : "Registered Address",

   "address.inn" : "INN/UNP",
   "address.kpp" : "KPP",
   "address.ogrn" : "OGRN",
   "address.okpo" : "OKPO",
   "address.okato" : "OKATO",
   "address.okogy" : "OKOGY",
   "address.okfs" : "OKFS",
   "address.okopf" : "OKOPF",
   "address.okved" : "OKVED",

   "relatedList.Feedback" : "Feedbacks",
    "feedback.UserReply" : "User Reply",
    "feedback.UserPost" : "User Post",
    "feedback.updated_at" : "Created Date",
    "feedback.Status" : "Status",
    "feedback.Product" : "Product",
    "feedback.ParentFeedback" : "Parent Feedback",
    "feedback.id" : "Feedback",
    "feedback.Likes" : "Likes",
    "feedback.Description" : "Description",
    "feedback.created_at" : "Created Date",

    "relatedList.Image" : "Image",
    "image.Title" : "Title",
    "image.alt" : "Alt",
    "image.URL" : "URL",
    "image.Active" : "Active",

   "accountDetails.modal-header" : "Add New Account Details",
   "accountDetails.bank-name" : "Bank Name",
   "accountDetails.bank-address" : "Bank Address",
   "accountDetails.bank-account-number" : "Account Number",
   "accountDetails.bank-correspond-number" : "Bank Correcpond Number",
   "accountDetails.bank-bic" : "BIC",
   "accountDetails.bank-unp" : "UNP",
   "accountDetails.bank-bankcode" : "Code of bank office",
   "accountDetails.bank-ismain" : "Is Main",
   "opportunityproductpart" : "Opportunity Product Part",
   "opportunityproductpart.AccountId" : "Account",
   "opportunityproductpart.Description" : "Description",
   "opportunityproductpart.Discount" : "Discount",
   "opportunityproductpart.id" : "Action",
   "opportunityproductpart.isActive" : "isActive",
   "opportunityproductpart.ListPrice" : "List Price",
   "opportunityproductpart.Name" : "Name",
   "opportunityproductpart.OpportunityProductId" : "Opportunity Product",
   "opportunityproductpart.ProductPartId" : "Product Part",
   "opportunityproductpart.Quantity" : "Quantity",
   "opportunityproductpart.SalesPrice" : "Sales Price",
   "opportunityproductpart.SubTotal" : "Sub Total",
   "opportunityproductpart.TotalPrice" : "Total Price",
   "contract" : "contract",
   "contract.Price" : "Price",
   "contract.PrePayment" : "Pre Payment",
   "contract.TotalPrice" : "Total Price",
   "contract.Discount" : "Discount",
   "contract.AccountId" : "Account",
   "contract.ContractEndDate" : "Contract End Date",
   "contract.ContractNumber" : "Contract Number",
   "contract.ContractStartDate" : "Contract Start Date",
   "contract.created_at" : "Created Date",
   "contract.CreatedById" : "Created By",
   "contract.CreatedDate" : "Created Date",
   "contract.id" : "Action",
   "contract.LastModifiedById" : "Last Modified By",
   "contract.LastModifiedDate" : "Updated Date",
   "contract.Name" : "Name",
   "contract.updated_at" : "Updated Date",
   "contract.OpportunityId" : "Opportunity",
   "contract.ContactId" : "Contact",
   "sidebar.laed" : "Lead",
   "To_Lead" : "To Lead",
   "Lead" : "Lead",
   "lead.id" : "Action",
   "lead.Fields" : "Lead",
    "lead.Name" : "Name",
    "lead.FirstName" : "First Name",
    "lead.LastName" : "Last Name",
    "lead.MiddleName" : "Middle Name",
    "lead.Priority" : "Priority",
    "lead.Status" : "Status",
    "lead.SubType" : "Sub Type",
    "lead.Title" : "Title",
    "lead.Type" : "Type",
    "lead.WhatsAppOptIn" : "WhatsApp",
    "lead.ViberOptIn" : "Viber",
    "lead.TelegramOptIn" : "Telegram",
    "lead.LeadSource" : "Source",
    "lead.Company" : "Company",
    "lead.Industry" : "Industry",
    "lead.LeadCurrency" : "Currency",
    "lead.Phone" : "Phone",
    "lead.Fax" : "Fax",
    "lead.Email" : "Email",
    "lead.Website" : "Website",
    "lead.LeadStatus" : "Status",
    "lead.Rating" : "Rating",
    "lead.Description" : "Description",
    "lead.AccountId" : "Account",
    "lead.StoreId" : "Store",
    "lead.ManagerId" : "Manager",
    "lead.ContactId" : "Contact",
    "lead.PotentialVisitDate" : "Potential Visit Date",
    "lead.created_at" : "Created Date",
    "lead.updated_at" : "Updated Date",
    "lead.CreatedById" : "Created By",
    "lead.DoNotCall" : "Do Not Call",
    "lead.LastModifiedById" : "Last Modified By",
    "lead.CompletedDate" : "Completed Date",
    "lead.SMSOptIn" : "SMS",
    "lead.Price": "Price",
    "lead.convertToOpportunity" : "Convert",
    "To_Opportunity" : "To Opportunity",
    "opportunity" : "Opportunity",
    "opportunity.id" : "Action",
    "opportunity.Name" : "Name",
    "opportunity.AccountId" : "Account",
    "opportunity.ContactId" : "Contact",
    "opportunity.ContractId" : "Contract",
    "opportunity.CreatedById" : "Created By",
    "opportunity.LastModifiedById" : "Last Modified By",
    "opportunity.Description" : "Description",
    "opportunity.Amount" : "Amount",
    "opportunity.DiscountPercent" : "Discount Percent",
    "opportunity.ExpectedRevenue" : "Expected Revenue",
    "opportunity.LeadSource" : "Lead Source",
    "opportunity.Currency" : "Currency",
    "opportunity.Score" : "Score",
    "opportunity.OrderNumber" : "Order Number",
    "opportunity.Private" : "Private",
    "opportunity.Probability" : "Probability",
    "opportunity.Quantity" : "Quantity",
    "opportunity.Stage" : "Stage",
    "opportunity.Type" : "Type",
    "opportunity.TrackNumber" : "Track Number",
    "opportunity.created_at" : "Created Date",
    "opportunity.updated_at" : "Modified Date",
    "opportunityproduct.Type" : "Type",
    "opportunityproduct.id" : "Action",
    "opportunityproduct.isActive" : "Active",
    "opportunityproduct.Name" : "Name",
    "opportunityproduct.ProductCode" : "Product Code",
    "opportunityproduct.Date" : "Date",
    "opportunityproduct.Discount" : "Discount",
    "opportunityproduct.ListPrice" : "List Price",
    "opportunityproduct.SalesPrice" : "Sales Price",
    "opportunityproduct.SubTotal" : "Sub Total",
    "opportunityproduct.TotalPrice" : "Total Price",
    "opportunityproduct.Quantity" : "Quantity",
    "opportunityproduct.Description" : "Description",
    "opportunityproduct.AccountId" : "Account",
    "opportunityproduct.OpportunityId" : "Opportunity",
    "opportunityproduct.ProductCompanyId" : "Product Company",

   "employee.personal-information" :"Personal Information",
   "employee.first-name" :"First Name",
   "employee.last-name" :"Last Name",
   "employee.middle-name" :"Middle Name",
   "employee.work-phone" :"Work Phone",
   "employee.work-email" :"Work Email",
   "employee.work-telegram" :"Work telegram",
   "employee.birthdate" :"Birthdate",

   "employee.employee-information" :"Name",
   "employee.office" :"Office",
   "employee.department" :"Employee Department",
   "employee.title" :"Employee Title",
   "employee.role" :"Employee Role",
   "employee.hire-date" :"Hired Date",

   "employee.contact-information" :"Contact Information",
   "employee.phone" :"Phone",
   "employee.second-phone" :"Second Phone",
   "employee.emergency-phone" :"Emergency Phone",
   "employee.altPhone": "Alt Phone",
   "employee.email" :"Email",
   "employee.viber" :"Viber",
   "employee.telegram" :"Telegram",
   "employee.whatsapp" :"WhatsApp",

   "employee.workschedule" :"Work Schedule",
   "employee.skills" :"Skills",
   "employee.certificates" :"Certificates",
   "employee.documents" :"Documents",
   "employee.professional-assesments" :"Professional Assesments",
   "employee.professionalSkills" :"Professional Skills",
   "employee.career-path" :"Career Path",
   "employee.requests" :"Requests",
   "employee.assets" :"Assets",
   "employee.children" :"Children",
   "employee.salary" :"Salary",
   "employee.bonus" :"Bonuses",
   "employee.deduction" :"Deductions",

   "asset.label": "Add New Compnay Asset",
    "asset.name": "Asset",
    "asset.inventory-number": "Inventory Number",
    "asset.serial-number": "Serial Number",
    "asset.assignment-date": "Assignment Date",
    "asset.status": "Status",
    "asset.provided-by": "Provided By",
    "asset.serviced-by": "Serviced By",

    "children.name": "Name",
    "children.first-name": "First Name",
    "children.last-name": "Last Name",
    "children.middle-name": "Middle Name",
    "children.birthdate": "Birthdate",
    "children.gender": "Gender",
    "children.age": "Age",

    "careeerpath.category": "Category",
    "careeerpath.update": "Update",
    "careeerpath.Date": "Date",

    "request.start-date" : "Start Date",
    "request.end-date" : "End Date",
    "request.duration" : "Duration",
    "request.type" : "Type",
    "request.status" : "Status",

    "profassesment.date": "Date",
    "profassesment.requester": "Requester",
    "profassesment.status": "Status",

    "certificate.name": "Name",
    "certificate.serialNumber": "Serial Number",
    "certificate.issueDate": "Issue Date",
    "certificate.expirationDate": "Expiration Date",

    "skill.name":"Name",
    "skill.type":"Type",
    "skill.experience":"Experience",

    "document.name" : "Name",
    "document.serialNumber": "Serial Number",
    "document.issueDate": "Issue Date",
    "document.expirationDate": "Expiration Date",

    "salary.period":"Period",
    "salary.rate":"Rate",
    "salary.amount":"Amount",

    "contactrequest.id" : "Callback Request",
    "contactrequest.Name" : "Name",
    "contactrequest.Type" : "Type",
    "contactrequest.Description" : "Description",
    "contactrequest.Priority" : "Priority",
    "contactrequest.AccountId" : "Account",
    "contactrequest.ContactId" : "Contact",
    "contactrequest.created_at" : "Created Date",
    "contactrequest.updated_at" : "Updated Date",
    "contactrequest.Phone" : "Phone",
    "contactrequest.StoreId" : "Store",
    "contactrequest.Status" : "Status",
    "contactrequest.City" : "City",
    "contactrequest.VisitDate" : "Visit Date",
    "contactrequest.ContactDate" : "Contact Date",

    "contract.contracts": "Contracts",
    "contract.status": "Contract Status",

    "order.label" : "Add New Order",
    "order.client-phone-number":"Client Phone Number",
    "order.client":"Client",
    "order.add-new-client":"Add New Client",
    "order.client-type":"Client Type",
    "order.contract-number": "Order Number",
    "order.supplier": "Supplier",

    "order.status.New": "New",
    "order.status.awaiting-processing": "Awaiting processing",
    "order.status.awaiting-payment": "Awaiting payment",
    "order.status.in-the-pipeline": "In the pipeline",
    "order.status.order-at-a-supplier": "Order at a supplier",
    "order.status.validated-by-supplier": "Validated by supplier",
    "order.status.rejected-by-supplier": "Rejected by supplier",
    "order.status.sent-to-central-warehouse": "Sent to central warehouse",
    "order.status.failed-to-reserve-position": "Failed to reserve position",
    "order.status.partial-reservation": "Partial reservation",
    "order.status.ready-for-shipment": "Ready for shipment",
    "order.status.Minimum-delivery-amount": "Minimum delivery amount",
    "order.status.assembled": "Assembled",
    "order.status.on-the-way": "On the way",
    "order.status.delivered": "Delivered",
    "order.status.issued": "Issued",
    "order.status.deleted": "Deleted",
    "order.status.shipped": "Shipped",
    "order.status.error": "Error",
    "order.status.success": "Success",
    "order.status.awaiting-payment": "Awaiting payment",
    "order.status.payment-confirmed": "Payment confirmed",
    "order.status.partially-confirmed": "Partially confirmed",
    "order.status.payment-not-confirmed": "Payment not confirmed",
    "order.status.on-the-move": "On the move",
    "order.status.canceled": "Canceled",
    "order.status.waiting-completion-transaction": "The order is waiting for the completion of the transaction",

    "call.table-label": "Client Activities",
    "call.contact-type": "Client Contact Type",

    "client.label":"Client Information",
    "client.product-label":"Product Information",
    "client.current-activities":"Current Client Activities",
    "production.name" : "Production" ,
    "product.editProduct":"Edit Product",
    "product.code":"Product Code",
    "product.family":"Product Family",
    "product.ProductCode" : "Product Code",
    "product.ProductFamily" : "Product Family",
    "product.QuantityUnitOfMeasure" : "Quantity Unit Of Measure",
    "product.DisplayURL" : "DisplayURL",
    "product.Likes" : "Likes",
    "product.Rate" : "Rate",
    "product.Price" : "Price",
    "product.Discount" : "Discount",
    "product.AvalableForCredit" : "AvalableForCredit",
    "product.Description" : "Description",
    "product.siberianLarch" : "Siberian Larch",
    "product.deliveryDays" : "Estimated Delivery In Days",
    "product.deliveryTime" : "Delivery Time",
    "product.productionDays" :"Production in days",
    "product.wallThickness" : "Wall thickness",
    "product.series": "Series",
    "product.material":"Material",
    "product.radius":"Radius",
    "product.vendorcode":"Vendor Code",
    "product.oemnumber":"OEM Number",
    "product.details":"List of parts",
    "product.applicable":"Aplicable",
    "product.infoanalog":"Info / Analogues",
    "product.Fields" : "Prodcut",
    "product.AccountId" : "Account",
    "product.Active" : "Is Active",
    "product.Article" : "Article",
    "product.AvalableForCredit" : "AvalableForCredit",
    "product.Count" : "Count",
    "product.created_at" : "Created Date",
    "product.CreatedById" : "Created By",
    "product.CreatedDate" : "Created Date",
    "product.Default" : "Default",
    "product.Description	" : "Description",
    "product.Discount" : "Discount",
    "product.DisplayURL" : "Display Url",
    "product.EstimatedDeliveryInDays" : "Delivery Days",
    "product.ExternalDataSource" : "External Data",
    "product.ExternalId" : "External Id",
    "product.Height" : "Height",
    "product.id" : "Product",
    "product.IsHot" : "Is Hot",
    "product.IsNew" : "Is New",
    "product.IsSale" : "Is Sale",
    "product.LastModifiedById" : "Last Modified By",
    "product.LastModifiedDate" : "Last Modified Date",
    "product.Likes" : "Likes",
    "product.Manufacturer" : "Manufactorer",
    "product.Material" : "Material",
    "product.MaxPrice" : "Max Price",
    "product.Name" : "Name",
    "product.NormalProductCode" : "Normal Product Code",
    "product.PreviousPrice" : "Pervious Price",
    "product.Price" : "Price",
    "product.ProductCode" : "Product Code",
    "product.ProductFamily" : "Product Family",
    "product.ProductionInDays" : "Days of Production",
    "product.QuantityUnitOfMeasure" : "Quantity Unit Of Measure",
    "product.Radius" : "Radius",
    "product.Rate" : "Rate",
    "product.Reviews" : "Reviews",
    "product.Series" : "Series",
    "product.ShortDescription" : "Short Description",
    "product.SKU" : "SKU",
    "product.Slug" : "Slug",
    "product.Stock" : "Stock",
    "product.SupplierPrice" : "Supplier Price",
    "product.SupplierProductCode" : "Supplier Product Code",
    "product.Thickness" : "Thickness",
    "product.updated_at" : "Last Modified Date",
    "product.Width" : "Width",
    "product.Length" : "Length",

    "marketing.gift" : "Gift Cards",
    "marketing.posts" : "Posts",
    "marketing.discounts" : "Discounts",
    "marketing.vouchers" : "Vouchers",

    "LOAN_6M" : "Рассрочка на 6 месяцев",
    "LOAN_9M" : "Кредит на 9 месяцев",
    "LOAN_12M" : "Кредит на 12 месяцев",
    "LOAN_24M" : "Кредит на 24 месяцев",
    "LOAN_60M" : "Кредит на 60 месяцев",

    "user.whatlanguagesdoyouspeak" : "What languages have you Speak",
    "user.academicdegree" : "Academic degree, academic title",
    "user.ScientificWorks": "What scientific works or inventions do you have",
    "user.workHistory" : "Work performed since the beginning of employment (including studies at higher and secondary educational institutions, military service, work, study, internship abroad)",
    "user.workNote" : "When filling out this paragraph, institutions, organizations and enterprises must be called as they were called at one time, military service should be recorded with an indication of the position.",
    "user.participationInElections" : "Participation in elections to legislative and representative bodies",
    "user.tradeunion" : "Participation in a trade union",
    "user.tradeunionnote" : "What union are you a member of and since when?",
    "user.govermentawards":"What government awards and honorary titles do you have",
    "user.armyRank":"Attitude to military duty and military rank",
    "user.militaryspecialty" : "Military specialty",
    "user.familyStatus": "Family status",
    "user.familyStatusNote": "List family members by age",
    "passport" : "Passport",
    "IssuedDate" : "Issued Date",
    "user.passportDetails": "Passport Details",
    "user.passportSeries": "Passport Number",
    "user.passportMinistryOf": "Ministry Of",
    "user.passportIdentificationNum": "Identification Number",
    "user.fillDate": "Fill Date",
    "user.dateOfIssue" : "Date of issue",
    "user.govermentawardsnote":"When and by whom awarded, appropriated",
    "user.placeOfBirth": "Place Of Birth",
    "user.citizenship": "Citizenship",
    "user.accountsettings": "Account Settings",
    "user.settings": "User Settings",
    "user.mycompany": "My Company",
    "user.careerpath": "Career Path",
    "user.alias" : "Alias",
    "user.username" : "Username",
    "user.emailSignature" : "Email Signature",
    "user.senderName" : "Sender Name",
    "user.senderAddress" : "Sender Address",
    "user.localeSettings" : "Locale Settings",
    "user.contacts" : "Contact Details",
    "user.timeZone" : "Time Zone",
    "user.locale" : "Locale",
    "user.language" : "Language",
    "user.aboutme" : "About Me",
    "user.sex" : "Sex",
    "user.localSettings":"Locale Settings",

    "survey.id" : "id",
    "survey.Name" : "Name",
    "survey.Subject" : "Subject",
    "survey.Type" : "Type",
    "survey.Description" : "Description",
    "survey.Status" : "Status",
    "survey.StartDate" : "Start Date",
    "survey.EndDate" : "End Date",
    "survey.Duration" : "Duration",
    "survey.Reminder" : "Reminder",
    "survey.isPublic" : "is Public",
    "survey.isActive" : "is Active",
    "survey.CreatedById" : "Created By",
    "survey.LastModifiedById" : "Last Modified By",
    "survey.AccountId" : "Account",
    "survey.created_at" : "Created Date",
    "survey.updated_at" : "Updated Date",
    "survey.Fields" : "Survey",

    "surveypage.id" : "id",
    "surveypage.Name" : "Name",
    "surveypage.Subject" : "Subject",
    "surveypage.Description" : "Description",
    "surveypage.isActive" : "isActive",
    "surveypage.CreatedById" : "Created By",
    "surveypage.LastModifiedById" : "Last Modified By",
    "surveypage.AccountId" : "Account",
    "surveypage.SurveyId" : "Survey",
    "surveypage.created_at" : "Created Date",
    "surveypage.updated_at" : "Updated Date",
    "surveypage.Fields" : "Survey Page",

    "relatedList.surveypage" : "Survey Pages",
    "relatedList.surveyelement" : "Survey Page Element",
    "relatedList.surveyelementvalue" : "Survey Element Value",

    "Surveyelement.Fields" : "Survey Page Element",
    "surveyelement.id" : "id",
    "surveyelement.Name" : "Name",
    "surveyelement.Subject" : "Subject",
    "surveyelement.Type" : "Type",
    "surveyelement.Description" : "Description",
    "surveyelement.isActive" : "isActive",
    "surveyelement.ColCount" : "ColCount",
    "surveyelement.CreatedById" : "Created By",
    "surveyelement.LastModifiedById" : "Last Modified By",
    "surveyelement.AccountId" : "Account",
    "surveyelement.SurveyPageId" : "Survey Page",
    "surveyelement.created_at" : "Created Date",
    "surveyelement.updated_at" : "Updated Date",

    "surveyelementvalue.Fields" : "Survey Element Value",
    "surveyelementvalue.id" : "id",
    "surveyelementvalue.Name" : "Name",
    "surveyelementvalue.Value" : "Value",
    "surveyelementvalue.RowName" : "Row Name",
    "surveyelementvalue.isActive" : "is Active",
    "surveyelementvalue.CreatedById" : "Created By",
    "surveyelementvalue.LastModifiedById" : "Last Modified By",
    "surveyelementvalue.AccountId" : "Account",
    "surveyelementvalue.SurveyElementId" : "Survey Element",
    "surveyelementvalue.created_at" : "Created Date",
    "surveyelementvalue.updated_at" : "Updated Date",

    "productscompany.Fields" : "Productscompany",
    "productscompany.AccountId" : "Account",
    "productscompany.Article" : "Article",
    "productscompany.AvalableForCredit" : "Avalable For Credit",
    "productscompany.Count" : "Count",
    "productscompany.created_at" : "created_at",
    "productscompany.CreatedById" : "Created By",
    "productscompany.CreatedDate" : "Created Date",
    "productscompany.Description" : "Description",
    "productscompany.Discount" : "Discount",
    "productscompany.DisplayURL" : "Display URL",
    "productscompany.EstimatedDeliveryInDays" : "Estimated Delivery In Days",
    "productscompany.ExternalDataSource" : "External Data Source",
    "productscompany.ExternalId" : "ExternalId",
    "productscompany.Height" : "Height",
    "productscompany.id" : "Product Company",
    "productscompany.isActive" : "isActive",
    "productscompany.LastModifiedById" : "LastModifiedById",
    "productscompany.LastModifiedDate" : "LastModifiedDate",
    "productscompany.Length" : "Length",
    "productscompany.Manufacturer" : "Manufacturer",
    "productscompany.Material" : "Material",
    "productscompany.MaxPrice" : "MaxPrice",
    "productscompany.Name" : "Name",
    "productscompany.NormalProductsCompanyCode" : "Normal Products Company Code",
    "productscompany.PreviousPrice" : "Previous Price",
    "productscompany.Price" : "Price",
    "productscompany.ProductsCompanyCode" : "Products Company Code",
    "productscompany.ProductsCompanyFamily" : "Products Company Family",
    "productscompany.ProductsCompanyionInDays" : "Products Companyi on In Days",
    "productscompany.QuantityUnitOfMeasure" : "Quantity Unit Of Measure",
    "productscompany.Radius" : "Radius",
    "productscompany.Series" : "Series",
    "productscompany.ShortDescription" : "Short Description",
    "productscompany.SKU" : "SKU",
    "productscompany.Slug" : "Slug",
    "productscompany.Stock" : "Stock",
    "productscompany.SupplierPrice" : "Supplier Price",
    "productscompany.SupplierProductsCompanyCode" : "Supplier Products Company Code",
    "productscompany.Thickness" : "Thickness",
    "productscompany.updated_at" : "updated_at",
    "productscompany.Width" : "Width",

    "sidebar.userdetails": "User Details",
    "sidebar.handbook": "Handbook",
    "sidebar.cities": "Cities",
    "sidebar.currencies": "Currencies",
    "sidebar.carsbrands": "List of Car's brands",
    "sidebar.parts-brands": "Parts Brands",
    "sidebar.exceptions-to-the-brands": "Exceptions to the brands",
   "sidebar.app": "App",
   "sidebar.news": "News",
   "sidebar.horizontal": "Horizontal",
   "sidebar.horizontalMenu": "Horizontal Menu",
   "sidebar.general": "General",
   "sidebar.component": "Component",
   "sidebar.features": "Features",
   "sidebar.applications": "Applications",
   "sidebar.dashboard": "Dashboard",
   "sidebar.dashboard1": "Dashboard 1",
   "sidebar.dashboard2": "Dashboard 2",
   "sidebar.dashboard3": "Dashboard 3",
   "sidebar.modules": "Modules",
   "sidebar.agency": "Agency",
   "sidebar.pages": "Pages",
   "sidebar.gallery": "Gallery",
   "sidebar.pricing": "Pricing",
   "sidebar.terms&Conditions": "Terms & Conditions",
   "sidebar.feedback": "Feedback",
   "sidebar.report": "Report",
   "sidebar.faq(s)": "Faq(s)",
   "sidebar.advancedComponent": "Advance Component",
   "sidebar.blank": "Blank",
   "sidebar.session": "Session",
   "sidebar.login": "Login",
   "sidebar.register": "Register",
   "sidebar.lockScreen": "Lock Screen",
   "sidebar.forgotPassword": "Forgot Password",
   "sidebar.404": "404",
   "sidebar.500": "500",
   "sidebar.uiComponents": "UI Components",
   "sidebar.alerts": "Alerts",
   "sidebar.appBar": "App Bar",
   "sidebar.avatars": "Avatars",
   "sidebar.buttons": "Buttons",
   "sidebar.bottomNavigations": "Bottom Navigations",
   "sidebar.badges": "Badges",
   "sidebar.cards": "Cards",
   "sidebar.cardsMasonry": "Cards Masonry",
   "sidebar.chip": "Chip",
   "sidebar.dialog": "Dialog",
   "sidebar.dividers": "Dividers",
   "sidebar.drawers": "Drawers",
   "sidebar.popover": "Popover",
   "sidebar.expansionPanel": "Expansion Panel",
   "sidebar.gridList": "Grid List",
   "sidebar.list": "List",
   "sidebar.menu": "Menu",
   "sidebar.popoverAndToolTip": "Pop Over & ToolTip",
   "sidebar.progress": "Progress",
   "sidebar.snackbar": "Snackbar",
   "sidebar.selectionControls": "Selection Controls",
   "sidebar.advanceUiComponents": "Advance UI Components",
   "sidebar.dateAndTimePicker": "Date & Time Picker",
   "sidebar.tabs": "Tabs",
   "sidebar.stepper": "Stepper",
   "sidebar.notification": "Notification",
   "sidebar.sweetAlert": "Sweet Alert",
   "sidebar.autoComplete": "Auto Complete",
   "sidebar.widgets": "Widgets",
   "sidebar.forms": "Forms",
   "sidebar.formElements": "Form Elements",
   "sidebar.textField": "Text Field",
   "sidebar.selectList": "Select List",
   "sidebar.charts": "Charts",
   "sidebar.reCharts": "Re Charts",
   "sidebar.reactChartjs2": "React Chartjs 2",
   "sidebar.icons": "Icons",
   "sidebar.themifyIcons": "Themify Icons",
   "sidebar.simpleLineIcons": "Simple Line Icons",
   "sidebar.materialIcons": "Material Icons",
   "sidebar.fontAwesome": "Font Awesome",
   "sidebar.tables": "Tables",
   "sidebar.basic": "Basic",
   "sidebar.dataTable": "Data Table",
   "sidebar.responsive": "Responsive",
   "sidebar.reactTable": "React Table",
   "sidebar.maps": "Maps",
   "sidebar.googleMaps": "Google Maps",
   "sidebar.leafletMaps": "Leaflet Maps",
   "sidebar.inbox": "Inbox",
   "sidebar.users": "Users",
   "sidebar.userProfile1": "User Profile 1",
   "sidebar.userProfile2": "User Profile 2",
   "sidebar.userManagement": "User Management",
   "sidebar.userProfile": "User Profile",
   "sidebar.userList": "User List",
   "sidebar.calendar": "Calendar",
   "sidebar.cultures": "Cultures",
   "sidebar.dnd": "Dnd",
   "sidebar.selectable": "Selectable",
   "sidebar.customRendering": "Custom Rendering",
   "sidebar.chat": "Chat",
   "sidebar.toDo": "ToDo",
   "sidebar.editor": "Editor",
   "sidebar.wysiwygEditor": "WYSIWYG Editor",
   "sidebar.quillEditor": "Quill Editor",
   "sidebar.reactAce": "React Ace",
   "sidebar.dragAndDrop": "Drag And Drop",
   "sidebar.reactDragula": "React Dragula",
   "sidebar.reactDnd": "React Dnd",
   "sidebar.blogManagement": "Blog Management",
   "sidebar.ecommerce": "Ecommerce",
   "sidebar.shopList": "Shop List",
   "sidebar.shopGrid": "Shop Grid",
   "sidebar.invoice": "Invoice",
   "sidebar.multilevel": "Multilevel",
   "sidebar.sublevel": "Sublevel",
   "sidebar.crypto": "Crypto",
   "sidebar.marketCap": "Market cap",
   "sidebar.wallet": "wallet",
   "sidebar.trade": "trade",
   "sidebar.crm": "CRM",
   "sidebar.dashboard": "dashboard",
   "widgets.totalEarns": "Total Earns",
   "widgets.emailsStatistics": "Emails Statistics",
   "widgets.totalRevenue": "Total Revenue",
   "widgets.onlineVistors": "Online Vistors",
   "widgets.trafficSources": "Traffic Sources",
   "widgets.RecentOrders": "Recent Orders",
   "widgets.topSellings": "Top Sellings",
   "widgets.productReports": "Product Reports",
   "widgets.productStats": "Product Stats",
   "widgets.ComposeEmail": "Compose Email",
   "widgets.employeePayroll": "Employee Payroll",
   "widgets.visitors": "Visitors",
   "widgets.orders": "Orders",
   "widgets.orderStatus": "Order Status",
   "widgets.totalSales": "Total Sales",
   "widgets.netProfit": "Net Profit",
   "widgets.overallTrafficStatus": "Overall Traffic Status",
   "widgets.tax": "Tax",
   "widgets.expenses": "Expenses",
   "widgets.currentTime": "Current Time",
   "widgets.currentDate": "Current Date",
   "widgets.todayOrders": "Today Orders",
   "widgets.toDoList": "To Do Lists",
   "widgets.discoverPeople": "Discover People",
   "widgets.commments": "Comments",
   "widgets.newCustomers": "New Customers",
   "widgets.recentNotifications": "Recent Notifications",
   "widgets.appNotifications": "App Notifications",
   "widgets.ratings": "Ratings",
   "widgets.newEmails": "New Emails",
   "widgets.siteVisitors": "Site Visitors",
   "widgets.socialCompanines": "Social Companines",
   "widgets.recentActivities": "Recent Activities",
   "widgets.recentOrders": "Recent Orders",
   "widgets.gallery": "Gallery",
   "widgets.pricing": "Pricing",
   "widgets.enterpriseEdition": "Enterprise Edition",
   "widgets.personalEdition": "Personal Edition",
   "widgets.teamEdition": "Team Edition",
   "widgets.standard": "Standard",
   "widgets.advanced": "Advanced",
   "widgets.master": "Master",
   "widgets.Mega": "Mega",
   "widgets.logIn": "Log In",
   "widgets.signUp": "Sign Up",
   "widgets.lockScreen": "Lock Screen",
   "widgets.alertsWithLink": "Alerts With Link",
   "widgets.additionalContent": "Additional Content",
   "widgets.alertDismiss": "Alert Dismiss",
   "widgets.uncontrolledDisableAlerts": "Uncontrolled Disable Alerts",
   "widgets.contexualAlerts": "Contexual Alerts",
   "widgets.alertsWithIcons": "Alerts With Icons",
   "widgets.Simple App Bars": "Simple App Bars",
   "widgets.appBarsWithButtons": "App Bars With Buttons",
   "widgets.imageAvatars": "Image Avatars",
   "widgets.lettersAvatars": "Letters Avatars",
   "widgets.iconsAvatars": "Icons Avatars",
   "widgets.flatButtons": "Flat Buttons",
   "widgets.raisedButton": "Raised Button",
   "widgets.buttonWithIconAndLabel": "Button With Icon And Label",
   "widgets.floatingActionButtons": "Floating Action Buttons",
   "widgets.iconButton": "Icon Button",
   "widgets.socialMediaButton": "Social Media Button",
   "widgets.reactButton": "React Button",
   "widgets.buttonOutline": "Button Outline",
   "widgets.buttonSize": "Button Size",
   "widgets.buttonState": "Button State",
   "widgets.buttonNavigationWithNoLabel": "button Navigation With No Label",
   "widgets.buttonNavigation": "Button Navigation",
   "widgets.iconNavigation": "Icon Navigation",
   "widgets.badgeWithHeadings": "Badge With Headings",
   "widgets.contexualVariations": "Contexual Variations",
   "widgets.badgeLinks": "Badge Links",
   "widgets.materialBadge": "Material Badge",
   "widgets.simpleCards": "Simple Cards",
   "widgets.backgroundVarient": "Background Varient",
   "widgets.cardOutline": "Card Outline",
   "widgets.overlayCard": "Overlay Card",
   "widgets.cardGroup": "Card Group",
   "widgets.cardTitle": "Card Title",
   "widgets.speacialTitleTreatment": "Speacial Title Treatment",
   "widgets.chipWithClickEvent": "Chip With Click Event",
   "widgets.chipArray": "Chip Array",
   "widgets.dialogs": "Dialogs",
   "widgets.listDividers": "List Dividers",
   "widgets.insetDividers": "Inset Dividers",
   "widgets.temporaryDrawers": "Temporary Drawers",
   "widgets.permanentDrawers": "Permanent Drawers",
   "widgets.simpleExpansionPanel": "Simple Expansion Panel",
   "widgets.controlledAccordion": "Controlled Accordion",
   "widgets.secondaryHeadingAndColumns": "Secondary Heading And Columns",
   "widgets.imageOnlyGridLists": "Image Only Grid Lists",
   "widgets.advancedGridLists": "Advanced Grid Lists",
   "widgets.singleLineGridLists": "Single Line Grid Lists",
   "widgets.simpleLists": "Simple Lists",
   "widgets.folderLists": "Folder Lists",
   "widgets.listItemWithImage": "List Item With Image",
   "widgets.switchLists": "Switch Lists",
   "widgets.insetLists": "Inset Lists",
   "widgets.nestedLists": "Nested Lists",
   "widgets.checkboxListControl": "Checkbox List Control",
   "widgets.pinedSubHeader": "Pined Sub Header",
   "widgets.InteractiveLists": "Interactive Lists",
   "widgets.simpleMenus": "Simple Menus",
   "widgets.selectedMenu": "Selected Menu",
   "widgets.maxHeightMenu": "Max Height Menu",
   "widgets.changeTransition": "Change Transition",
   "widgets.paper": "Paper",
   "widgets.anchorPlayGround": "Anchor Play Ground",
   "widgets.tooltip": "ToolTip",
   "widgets.positionedToolTips": "Positioned Snackbar",
   "widgets.circularProgressBottomStart": "Circular Progress Bottom Start",
   "widgets.interactiveIntegration": "Interactive Integration",
   "widgets.determinate": "Determinate",
   "widgets.linearProgressLineBar": "Linear Progress Line Bar",
   "widgets.indeterminate": "Indeterminate",
   "widgets.buffer": "Buffer",
   "widgets.query": "Query",
   "widgets.transitionControlDirection": "Transition Control Direction",
   "widgets.simpleSnackbar": "Simple Snackbar",
   "widgets.positionedSnackbar": "Positioned Snackbar",
   "widgets.contexualColoredSnackbars": "Contexual Colored Snackbars",
   "widgets.simpleCheckbox": "Simple Checkbox",
   "widgets.interminateSelection": "Interminate Selection",
   "widgets.disabledCheckbox": "Disabled Checkbox",
   "widgets.customColorCheckbox": "Custom Color Checkbox",
   "widgets.VerticalStyleCheckbox": "Vertical Style Checkbox",
   "widgets.horizontalStyleCheckbox": "Horizontal Style Checkbox",
   "widgets.radioButtons": "Radio Buttons",
   "widgets.disabledRadio": "Disabled Radio",
   "widgets.withError": "With Error",
   "widgets.switches": "Swiches",
   "widgets.dateAndTimePicker": "Date And Time Picker",
   "widgets.defaultPicker": "Default Picker",
   "widgets.timePicker": "Time Picker",
   "widgets.weekPicker": "Week Picker",
   "widgets.defaultDatePicker": "Default Date Picker",
   "widgets.customPicker": "Custom Picker",
   "widgets.tabs": "Tabs",
   "widgets.fixedTabs": "Fixed Tabs",
   "widgets.basicTab": "Basic Tab",
   "widgets.wrappedLabels": "Wrapped Labels",
   "widgets.centeredLabels": "Centered Labels",
   "widgets.forcedScrolledButtons": "Forced Scrolled Buttons",
   "widgets.iconsTabs": "Icons Tabs",
   "widgets.withDisableTabs": "With Disable Tabs",
   "widgets.iconWithLabel": "Icon With Label",
   "widgets.stepper": "Stepper",
   "widgets.horizontalLinear": "Horizontal Linear",
   "widgets.horizontalNonLinear": "Horizontal Non Linear",
   "widgets.horizontalLinerAlternativeLabel": "Horizontal Liner Alternative Label",
   "widgets.horizontalNonLinerAlternativeLabel": "Horizontal Non Liner Alternative Label",
   "widgets.verticalStepper": "Vertical Stepper",
   "widgets.descriptionAlert": "Description Alert",
   "widgets.customIconAlert": "Custom Icon Alert",
   "widgets.withHtmlAlert": "With Html Alert",
   "widgets.promptAlert": "Prompt Alert",
   "widgets.passwordPromptAlert": "Password Prompt Alert",
   "widgets.customStyleAlert": "Custom Style Alert",
   "widgets.autoComplete": "Auto Complete",
   "widgets.reactSelect": "React Select",
   "widgets.downshiftAutoComplete": "Downshift Auto Complete",
   "widgets.reactAutoSuggests": "React Auto Suggests",
   "widgets.aboutUs": "About Us",
   "widgets.ourVission": "Our Vission",
   "widgets.ourMissions": "Our Missions",
   "widgets.ourMotivation": "Our Motivation",
   "widgets.defualtReactForm": "Defualt React Form",
   "widgets.url": "Url",
   "widgets.textArea": "Text Area",
   "widgets.file": "File",
   "widgets.formGrid": "Form Grid",
   "widgets.inlineForm": "Inline Form",
   "widgets.inputSizing": "Input Sizing",
   "widgets.inputGridSizing": "Input Grid Sizing",
   "widgets.hiddenLabels": "Hidden Labels",
   "widgets.formValidation": "Form Validation",
   "widgets.number": "Number",
   "widgets.date": "Date",
   "widgets.time": "Time",
   "widgets.color": "Color",
   "widgets.search": "Search",
   "widgets.selectMultiple": "Select Multiple",
   "widgets.inputWithSuccess": "Input With Success",
   "widgets.inputWithDanger": "Input With Danger",
   "widgets.simpleTextField": "Simple Text Field",
   "widgets.componet": "Components",
   "widgets.layouts": "Layouts",
   "widgets.inputAdorements": "Input Adorements",
   "widgets.formattedInputs": "Formatted Inputs",
   "widgets.simpleSelect": "Simple Select",
   "widgets.nativeSelect": "Native Select",
   "widgets.MutltiSelectList": "Mutlti Select List",
   "widgets.lineChart": "Line Chart",
   "widgets.barChart": "Bar Chart",
   "widgets.stackedBarChart": "Stacked Bar Chart",
   "widgets.lineBarAreaChart": "Line Bar Area Chart",
   "widgets.areaChart": "Area Chart",
   "widgets.stackedAreaChart": "Stacked Area Chart",
   "widgets.verticalChart": "Vertical Chart",
   "widgets.radarChart": "Radar Chart",
   "widgets.doughnut": "Doughnut",
   "widgets.polarChart": "Polar Chart",
   "widgets.pieChart": "Pie Chart",
   "widgets.bubbleChart": "Bubble Chart",
   "widgets.horizontalBar": "Horizontal Bar",
   "widgets.basicTable": "Basic Table",
   "widgets.contexualColoredTable": "Contexual Colored Table",
   "widgets.dataTable": "Data Table",
   "widgets.employeeList": "Employee List",
   "widgets.responsiveTable": "Responsive Table",
   "widgets.responsiveFlipTable": "Responsive Flip Table",
   "widgets.reactGridControlledStateMode": "React Grid Controlled State Mode",
   "widgets.productsReports": "Products Reports",
   "widgets.taskList": "Task List",
   "widgets.basicCalender": "Basic Calender",
   "widgets.culturesCalender": "Cultures Calender",
   "widgets.dragAndDropCalender": "Drag And Drop Calender",
   "widgets.selectableCalender": "Selectable Calender",
   "widgets.customRendering": "Custom Rendering",
   "widgets.customCalender": "Custom Calender",
   "widgets.searchMailList": "Search Mail List",
   "components.buyNow": "Buy Now",
   "compenets.choose": "Choose",
   "compenets.username": "Username",
   "compenets.passwords": "Passwords",
   "widgets.forgetPassword": "Forget Password",
   "compenets.signIn": "Sign In",
   "compenets.dontHaveAccountSignUp": "Dont Have Account SignUp",
   "compenets.enterUserName": "Enter User Name",
   "compenets.enterEmailAddress": "Enter Email Address",
   "compenets.confirmPasswords": "Confirm Passwords",
   "components.alreadyHavingAccountSignIn": "Already Having Account Sign In",
   "components.enterYourPassword": "Enter Your Password",
   "components.unlock": "Unlock",
   "components.enterPasswords": "Enter Passwords",
   "components.resestPassword": "Resest Password",
   "components.pageNotfound": "Page not Found",
   "components.goToHomePage": "Go To Home Page",
   "components.sorryServerGoesWrong": "Sorry Server Goes Wrong",
   "components.persistentDrawer": "Persistent Drawer",
   "components.withHtml": "With Html",
   "components.prompt": "Prompt",
   "components.withDescription": "With Description",
   "components.success": "Success",
   "components.passwordPrompt": "Password Prompt",
   "components.warning": "Warning",
   "components.customIcon": "Custom Icon",
   "components.customStyle": "Custom Style",
   "components.basic": "Basic",
   "components.submit": "Submit",
   "components.compose": "Compose",
   "components.sendMessage": "Send Message",
   "components.addNewTasks": "Add New Tasks",
   "components.addToCart": "Add To Cart",
   "components.payNow": "Pay Now",
   "components.print": "Print",
   "components.cart": "Cart",
   "components.viewCart": "View Cart",
   "components.checkout": "Checkout",
   "widgets.QuickLinks": "Quick Links",
   "widgets.upgrade": "upgrade",
   "widgets.app": "App",
   "widgets.addNew": "Add New",
   "widgets.orderDate": "Order Date",
   "widgets.status": "Status",
   "widgets.trackingNumber": "Tracking Number",
   "widgets.action": "Action",
   "widgets.designation": "Designation",
   "widgets.subject": "subject",
   "widgets.send": "Send",
   "widgets.saveAsDrafts": "Save As Drafts",
   "widgets.onlineSources": "Online Sources",
   "widgets.lastMonth": "Last Month",
   "widgets.widgets": "Widgets",
   "widgets.listing": "Listing",
   "widgets.paid": "Paid",
   "widgets.refunded": "Refunded",
   "widgets.done": "Done",
   "widgets.pending": "Pending",
   "widgets.cancelled": "Cancelled",
   "widgets.approve": "Approve",
   "widgets.following": "Following",
   "widgets.follow": "follow",
   "widgets.graphs&Charts": "Graphs & Charts",
   "widgets.open": "Open",
   "widgets.bounced": "Bounced",
   "widgets.spam": "Spam",
   "widgets.unset": "Unset",
   "widgets.bandwidthUse": "Bandwidth Use",
   "widgets.dataUse": "Data Use",
   "widgets.unsubscribe": "Unsubscribe",
   "widgets.profile": "Profile",
   "widgets.messages": "Messages",
   "widgets.support": "Support",
   "widgets.faq(s)": "Faq(s)",
   "widgets.upgradePlains": "Upgrade Plains",
   "widgets.logOut": "Log Out",
   "widgets.mail": "Mail",
   "widgets.adminTheme": "Admin Theme",
   "widgets.wordpressTheme": "Wordpress Theme",
   "widgets.addToCart": "Add To Cart",
   "widgets.plan": "Plan",
   "widgets.basic": "Basic",
   "widgets.pro": "Pro",
   "widgets.startToBasic": "Start To Basic",
   "widgets.upgradeToPro": "Upgrade To Pro",
   "widgets.upgradeToAdvance": "Upgrade To Advance",
   "widgets.comparePlans": "Compare Plans",
   "widgets.free": "Free",
   "widgets.frequentlyAskedQuestions": "Frequently Asked Questions",
   "widgets.searchIdeas": "Search Ideas",
   "widgets.startDate": "Start Date",
   "widgets.endDate": "End Date",
   "widgets.category": "Category",
   "widgets.apply": "Apply",
   "widgets.yesterday": "Yesterday",
   "widgets.totalOrders": "Total Orders",
   "widgets.totalVisitors": "Total Visitors",
   "widgets.typeYourQuestions": "Type Your Questions",
   "widgets.username": "Username",
   "widgets.password": "Password",
   "widgets.signIn": "Sign In",
   "widgets.enterYourPassword": "Enter Your Password",
   "widgets.alreadyHavingAccountLogin": "Already Having Account Login",
   "widgets.composeMail": "Compose Mail",
   "widgets.issue": "Issue",
   "widgets.recentChat": "Recent Chat",
   "widgets.previousChat": "Previous Chat",
   "widgets.all": "All",
   "widgets.filters": "Filters",
   "widgets.deleted": "Deleted",
   "widgets.starred": "Starred",
   "widgets.frontend": "Frontend",
   "widgets.backend": "Backend",
   "widgets.api": "Api",
   "widgets.simpleAppBar": "Simple App Bar",
   "widgets.recents": "Recents",
   "widgets.cardLink": "Card Link",
   "widgets.anotherLink": "Another Link",
   "widgets.cardSubtitle": "card Subtitle",
   "widgets.confirmationDialogs": "Confirmation Dialogs",
   "widgets.deletableChip": "Deletable Chip",
   "widgets.customDeleteIconChip": "Custom Delete Icon Chip",
   "widgets.openAlertDialog": "Open Alert Dialog",
   "widgets.openResponsiveDialog": "Open Responsive Dialog",
   "widgets.openSimpleDialog": "Open Simple Dialog",
   "widgets.openFormDialog": "Open Form Dialog",
   "widgets.follower": "Follower",
   "widgets.important": "Important",
   "widgets.private": "Private",
   "widgets.openLeft": "Open Left",
   "widgets.openRight": "Open Right",
   "widgets.openTop": "Open Top",
   "widgets.openBottom": "Open Bottom",
   "widgets.selectTripDestination": "Select Trip Destination",
   "widgets.pinnedSubheaderList": "Pinned Subheader List",
   "widgets.singleLineItem": "Single Line Item",
   "widgets.optionA": "Option A",
   "widgets.optionB": "Option B",
   "widgets.optionC": "Option C",
   "widgets.optionM": "Option M",
   "widgets.optionN": "Option N",
   "widgets.optionO": "Option O",
   "widgets.customColor": "Custom Color",
   "widgets.centeredTabs": "Centered Tabs",
   "widgets.multipleTabs": "Multiple Tabs",
   "widgets.preventScrolledButtons": "Prevent Scrolled Buttons",
   "widgets.browse": "Browse",
   "widgets.formValidate": "Form Validate",
   "widgets.code": "Code",
   "widgets.company": "Company",
   "widgets.price": "Price",
   "widgets.change": "Change",
   "widgets.high": "High",
   "widgets.low": "Low",
   "widgets.volume": "Volume",
   "widgets.personalDetails": "Personal Details",
   "widgets.occupation": "Occupation",
   "widgets.companyName": "Company Name",
   "widgets.phoneNo": "Phone No",
   "widgets.updateProfile": "Update Profile",
   "widgets.reject": "Reject",
   "widgets.exportToExcel": "Export To Excel",
   "widgets.workWeek": "Work Week",
   "widgets.agenda": "Agenda",
   "widgets.conference": "Conference",
   "widgets.multilevel": "Multilevel",
   "widgets.dailySales": "Daily Sales",
   "widgets.today": "Today",
   "widgets.campaignPerformance": "Campaign Performance",
   "widgets.supportRequest": "Support Request",
   "widgets.usersList": "Users List ",
   "widgets.lastWeek": "Last Week",
   "themeOptions.sidebarOverlay": "Sidebar Overlay",
   "themeOptions.sidebarBackgroundImages": "Sidebar Background Images",
   "themeOptions.appSettings": "App Settings",
   "themeOptions.sidebarImage": "Sidebar Image",
   "themeOptions.miniSidebar": "Mini Sidebar",
   "themeOptions.boxLayout": "Box Layout",
   "themeOptions.rtlLayout": "Rtl Layout",
   "themeOptions.darkMode": "Dark Mode",
   "themeOptions.sidebarLight": "Light",
   "themeOptions.sidebarDark": "Dark",
   "button.cancel": "Cancel",
   "button.add": "Add",
   "button.update": "Update",
   "button.reply": "Reply",
   "button.delete": "Delete",
   "button.yes": "Yes",
   "button.viewAll": "View All",
   "button.like": "Like",
   "button.assignNow": "Assign Now",
   "button.seeInsights": "See Insights",
   "sidebar.dateTimePicker": "Date & Time Picker",
   "components.summary": "Summary",
   "hint.whatAreYouLookingFor": "What are You Looking For",
   "components.yesterday": "Yesterday",
   "components.last7Days": "Last 7 Days",
   "components.last1Month": "Last 1 Month",
   "components.last6Month": "Last 6 Month",
   "components.spaceUsed": "Space Used",
   "components.followers": "Follower",
   "components.trending": "Trending",
   "components.paid": "Paid",
   "components.refunded": "Refunded",
   "components.done": "Done",
   "components.pending": "Pending",
   "components.cancelled": "Cancelled",
   "components.approve": "Approve",
   "components.week": "Week",
   "components.month": "Month",
   "components.year": "Year",
   "components.today": "Today",
   "components.popularity": "Popularity",
   "components.email": "Email",
   "components.drafts": "Drafts",
   "components.sent": "Sent",
   "components.trash": "Trash",
   "components.all": "All",
   "components.do": "Do",
   "components.title": "Title",
   "components.projectName": "Project Name",
   "components.companyName": "Company Name",
   "components.openAlert": "Open Alert",
   "components.slideInAlertDialog": "Slide In Alert Dialog",
   "components.openFullScreenDialog": "Open Full Screen Dialogs",
   "components.basicChip": "Basic Chip",
   "components.clickableChip": "Clickable Chip",
   "components.left": "Left",
   "components.right": "Right",
   "components.expansionPanel1": "Expansion Panel 1",
   "components.expansionPanel2": "Expansion Panel 2",
   "components.generalSetting": "General Setting",
   "components.advancedSettings": "Advanced Settings",
   "components.firstName": "First Name",
   "components.lastName": "Last Name",
   "components.occupation": "Occupation",
   "components.phoneNo": "Phone No",
   "components.address": "Address",
   "components.city": "City",
   "components.state": "State",
   "components.zipCode": "Zip Code",
   "components.socialConnection": "Social Connection",
   "widgets.buyMore": "Buy More",
   "widgets.trafficChannel": "Traffic Channel",
   "widgets.stockExchange": "Stock Exchange",
   "widgets.tweets": "Tweets",
   "widgets.ourLocations": "Our Locations",
   "widgets.sales": "Sales",
   "widgets.to": "To",
   "widgets.shipTo": "Ship To",
   "widgets.description": "Description",
   "widgets.unitPrice": "Unit Price",
   "widgets.total": "Total",
   "widgets.note": "Note",
   "widgets.chipWithAvatar": "Chip With Avatar",
   "widgets.chipWithTextAvatar": "Chip With Text Avatar",
   "widgets.chipWithIconAvatar": "Chip With Icon Avatar",
   "widgets.customClickableChip": "Custom Clickable Chip",
   "widgets.outlineChip": "Outline Chip",
   "widgets.disableChip": "Disable Chip",
   "widgets.alertDialog": "Alert Dialog",
   "widgets.animatedSlideDialogs": "Animated Slide Dialogs",
   "widgets.fullScreenDialogs": "Full Screen Dialogs",
   "widgets.formDialogs": "Form Dialogs",
   "widgets.simpleDialogs": "Simple Dialogs",
   "widgets.responsiveFullScreen": "Responsive Full Screen",
   "widgets.primary": "Primary",
   "widgets.social": "Social",
   "widgets.user": "User",
   "widgets.admin": "Admin",
   "widgets.permanentdrawer": "Permanent Drawer",
   "widgets.persistentdrawer": "Persistent Drawer",
   "widgets.swiches": "Swiches",
   "widgets.horizontalLinearAlternativeLabel": "Horizontal Linear Alternative Label",
   "widgets.horizontalNonLinearAlternativeLabel": "Horizontal Non Linear Alternative Label",
   "widgets.notifications": "Notifications",
   "widgets.basicAlert": "Basic Alert",
   "widgets.successAlert": "Success Alert",
   "widgets.warningAlert": "Warning Alert",
   "widgets.reactAutoSuggest": "React Auto Suggest",
   "widgets.components": "Components",
   "widgets.inputAdornments": "Input Adorements",
   "widgets.multiSelectList": "Multi Select List",
   "widgets.contextualColoredTable": "Contexual Colored Table",
   "widgets.updateYourEmailAddress": "Update Your Email Address",
   "widgets.selectADefaultAddress": "Select A Default Address",
   "widgets.activity": "Activity",
   "widgets.basicCalendar": "Basic Calendar",
   "widgets.culturesCalendar": "Cultures Calendar",
   "widgets.dragAndDropCalendar": "Drag And Drop Calendar",
   "widgets.quillEditor": "Quill Editor",
   "widgets.reactDND": "React DND",
   "widgets.dragula": "Dragula",
   "button.acceptTerms": "Accept Terms",
   "button.reject": "Reject",
   "button.addNew": "Add New",
   "button.goToCampaign": "Go To Campaign",
   "button.viewProfile": "View Profile",
   "button.sendMessage": "Send Message",
   "button.saveNow": "Save Now",
   "button.makeOrder": "Make Order",
   "button.makepayment": "Make Payment",
   "button.pen": "Pen",
   "button.search": "Search",
   "button.downloadPdfReport": "Download Pdf Report",
   "button.primary": "Primary",
   "button.secondary": "Secondary",
   "button.danger": "Danger",
   "button.info": "Info",
   "button.success": "Success",
   "button.warning": "Warning",
   "button.link": "Link",
   "button.smallButton": "Small Button",
   "button.largeButton": "Large Button",
   "button.blockLevelButton": "Block Level Button",
   "button.primaryButton": "Primary Button",
   "button.button": "Button",
   "button.save": "Save",
   "button.openMenu": "Open Menu",
   "button.openWithFadeTransition": "Open With Fade Transition",
   "button.openPopover": "Open Popover",
   "button.accept": "Accept",
   "button.click": "Click",
   "button.complete": "Complete",
   "button.back": "Back",
   "button.next": "Next",
   "button.completeStep": "Complete Step",
   "button.error": "Error",
   "button.writeNewMessage": "Write New Message",
   "button.saveChanges": "Save Changes",
   "button.addNewUser": "Add New User",
   "button.more": "More",
   "hint.searchMailList": "Search Mail List",
   "widgets.AcceptorrRejectWithin": "Accept or reject within",
   "widgets.quoteOfTheDay": "Quote Of The Day",
   "widgets.updated10Minago": "Updated 10 min ago",
   "widgets.personalSchedule": "Personal Schedule",
   "widgets.activeUsers": "Active Users",
   "widgets.totalRequest": "Total Request",
   "widgets.new": "New",
   "widgets.ShareWithFriends": "Share with friends!",
   "widgets.helpToShareText": "Help us spread the world by sharing our website with your friends and followers on social media!",
   "widgets.thisWeek": "This Week",
   "widgets.howWouldYouRateUs": "How would you rate us?",
   "widgets.booking": "Booking",
   "widgets.confirmed": "Confirmed",
   "widgets.monthly": "Monthly",
   "widgets.weekly": "Weekly",
   "widgets.target": "Target",
   "widgets.totalActiveUsers": "Total Active Users",
   "sidebar.user": "User",
   "sidebar.miscellaneous": "Miscellaneous",
   "sidebar.promo": "Promo",
   "themeOptions.themeColor": "Theme Color",
   "module.inbox": "Inbox",
   "module.drafts": "Drafts",
   "module.sent": "Sent",
   "module.trash": "Trash",
   "module.spam": "Spam",
   "module.frontend": "Frontend",
   "module.backend": "Backend",
   "module.api": "Api",
   "module.issue": "Issue",
   "components.emailPrefrences": "Email Prefrences",
   "components.myProfile": "My Profile",
   "sidebar.gettingStarted": "Getting Started",
   "widgets.deadline": "Deadline",
   "widgets.team": "Team",
   "widgets.projectManagement": "Project Management",
   "widgets.latestPost": "Latest Post",
   "widgets.projectTaskManagement": "Project Task Management",
   "widgets.selectProject": "Select Project",
   "widgets.activityBoard": "Activity Board",
   "widgets.checklist": "Checklist",
   "sidebar.shop": "Shop",
   "sidebar.cart": "Cart",
   "sidebar.checkout": "Checkout",
   "components.product": "Product",
   "components.quantity": "Quantity",
   "components.totalPrice": "Total Price",
   "components.removeProduct": "Remove Product",
   "components.mobileNumber": "Mobile Number",
   "components.address2Optional": "Address 2 (Optional)",
   "components.country": "Country",
   "components.zip": "Zip",
   "components.saveContinue": "Save & Continue",
   "components.placeOrder": "Place Order",
   "components.payment": "Payment",
   "components.billingAddress": "Billing Address",
   "components.ShippingAddressText": "Shipping address is the same as billing address.",
   "components.CartEmptyText": "Your Shopping Cart Is Empty!",
   "components.NoItemFound": "No Item Found",
   "components.goToShop": "Go To Shop",
   "components.cardNumber": "Card Number",
   "components.expiryDate": "Expiry Date",
   "components.cvv": "CVV",
   "components.nameOnCard": "Name On Card",
   "components.confirmPayment": "Confirm Payment",
   "components.completeTheOrder": "Complete The Order",
   "sidebar.saas": "SAAS",
   "sidebar.multiLevel": "MultiLevel",
   "sidebar.level1": "Level 1",
   "sidebar.level2": "Level 2",
   "sidebar.boxed": "Boxed",
   "sidebar.extensions": "Extensions",
   "sidebar.imageCropper": "Image Cropper",
   "sidebar.videoPlayer": "Video Player",
   "sidebar.dropzone": "Dropzone",
   "widgets.baseConfig": "Base Config",
   "widgets.customControlBar": "Custom Control Bar",
   "widgets.withDownloadButton": "With Download Button",
   "widgets.httpLiveStreaming": "HTTP Live Streaming",
   "widgets.keyboardShortcuts": "Keyboard Shortcuts",
   "button.useDefaultImage": "Use Default Image",
   "button.cropImage": "Crop Image",
   "widgets.preview": "Preview",
   "widgets.croppedImage": "Cropped Image",
   "widgets.transactionList": "Transaction List",
   "widgets.transferReport": " Transfer Report",
   "widgets.expenseCategory": "Expense Category",
   "widgets.upcomingEvents": "Upcoming events",
   "widgets.OngoingProjects": "Ongoing Projects",
   "widgets.ProjectStatus": "Project Status",
   "widgets.LiveChatSupport": "Live Chat Support",
   "sidebar.projects": "projects",
   "sidebar.projectDetail": "project Detail",
   "sidebar.clients": "clients",
   "sidebar.reports": "reports"
}
