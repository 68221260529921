import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import { agencyLayoutBgHandler } from "../../../../reduxSettings/reduxSlices/basicSlice";

function AgencyLayoutBgProvider(props) {

    const dispatch = useDispatch();
	const changeLayoutBg = (color) => {
		dispatch(agencyLayoutBgHandler(color));
	}

    const { agencyLayoutBgColors } = useSelector((state) => state.basic);

    return (
        <div>
            <ul className="list-unstyled agency-bg mb-0 p-10 text-center">
                <li className="header-title mb-10">
                    <span>Background Color </span>
                </li>
                {agencyLayoutBgColors.map((color, key) => (
                    <li
                        className={classnames("list-inline-item", {
                            'active': color.active
                        })}
                        key={key}
                        onClick={() => changeLayoutBg(color)}
                    >
                        <span className={classnames("badge", color.class)}></span>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default AgencyLayoutBgProvider
