/**
 * Company Routes
 */
 import React from 'react';
 import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
 import { Helmet } from "react-helmet";

 import {
    AsyncUSUserMainPage,
    AsyncUSUserDetailsPage
 } from '../USUserAsyncPageLoader/USUserPageLoader';
import USUserCompany from './USUserCompany';
import USUserShops from './USUserShops';
import USUserShopShares from './USUserShops/USUserShopShares';


const Pages = (props) => {
    console.log('user pages' , props);

    return  (
        <div className="content-wrapper">
            <Helmet>
                <title>Ecommerce | Shop</title>
                <meta name="description" content="Reactify Ecommerce Shop" />
            </Helmet>
            <Switch>
            <Route path="/user/about-us" component={AsyncUSUserDetailsPage}/>
                <Route path="/user/calendar" component={AsyncUSUserDetailsPage}/>
                <Route path="/user/home" component={AsyncUSUserDetailsPage}/>
                <Route path="/user/wishlist" component={AsyncUSUserDetailsPage}/>
                <Route path="/user/hometasks" component={AsyncUSUserDetailsPage}/>
                <Route path="/user/requests" component={AsyncUSUserDetailsPage}/>
                <Route path="/user/finance" component={AsyncUSUserDetailsPage}/>
                <Route path="/user/vacancies" component={AsyncUSUserDetailsPage}/>
                <Route path="/user/cv" component={AsyncUSUserDetailsPage}/>
                <Route path="/user/cources" component={AsyncUSUserDetailsPage}/>
                <Route path="/user/jobs" component={AsyncUSUserDetailsPage}/>
                <Route path="/user/track-product" component={AsyncUSUserDetailsPage}/>
                <Route path="/user/orders" component={AsyncUSUserDetailsPage}/>
                <Route path="/user/shops-shares" component={USUserShopShares}/>
                <Route path="/user/shops" component={USUserShops}/>
                <Route path="/user/mycompany/create" component={USUserCompany}/>
                <Route path="/user/mycompany" component={USUserCompany}/>
                <Route path="/user/settings" component={AsyncUSUserDetailsPage}/>
                <Route path="/user" component={AsyncUSUserMainPage}/>
            </Switch>
        </div>
    );
 }
 export default Pages;
