/**
 * Helpers Functions
 */
import moment from 'moment';

/**
 * Function to convert hex to rgba
 */
export function hexToRgbA(hex, alpha) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c?.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
    }
    throw new Error('Bad Hex');
}

/**
 * Text Truncate
 */
export function textTruncate(str, length, ending) {
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = '...';
    }
    if (str?.length > length) {
        return str.substring(0, length - ending?.length) + ending;
    } else {
        return str;
    }
}

/**
 * Get Date
 */
export function getTheDate(timestamp, format) {
    let time = timestamp * 1000;
    let formatDate = format ? format : 'MM-DD-YYYY';
    return moment(time).format(formatDate);
}

/**
 * Convert Date To Timestamp
*/
export function convertDateToTimeStamp(date, format) {
    let formatDate = format ? format : 'YYYY-MM-DD';
    return moment(date, formatDate).unix();
}

export function formatDateTimeFromString(currentDate) {
    const date1 = currentDate.getDay();
    const date2 = currentDate.getMonth();
    const date3 = currentDate.getFullYear();
    const date4 = currentDate.getHours();
    const date5 = currentDate.getMinutes();
    console.log(date1 + '/' + date2 + '/' + date3 + '/' + date4 + '/' + date5);
    return (date1 < 10 ? '0' + date1 : date1) + '/' + (date2 < 10 ? '0' + date2: date2) + '/' + (date3 < 10 ? '0' + date3: date3) + '-' + (date4 < 10 ? '0'+ date4 : date4) + ':' + (date5 < 10 ? '0' + date5: date5);
}

export function checkExperationDate(dateTimeString, currentDate) {
    try {
        if((currentDate - dateTimeString) >= 86400000) {
            return 'error';
        } else {
            if((currentDate - dateTimeString) >= 180000) {
                return 'warning';
            } else {
                return 'info';
            }
        }
    } catch(error) {
        return 'info';
    }
}

/**
 * Function to return current app layout
 */
export function getAppLayout(url) {
    let location = url?.pathname ? url.pathname : '';
    let path = location?.split('/');
    return path?.lenght ? path[1] : '';
}
