import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import SettingsIcon from '@mui/icons-material/Settings';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <div className="tab-content">
                {children}
            </div>
        </div>
    );
  }

function Sidebar() {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} variant="fullWidth">
                    <Tab icon={<ViewInArIcon />} value={0} style={{minWidth: '10px'}} />
                    <Tab icon={<ListAltIcon />}  value={1} style={{minWidth: '10px'}}/>
                    <Tab icon={<FormatColorFillIcon value={2} style={{minWidth: '10px'}}/>}/>
                    <Tab icon={<SettingsIcon />} value={3} style={{minWidth: '10px'}}/>
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <div
                    className="tab-pane fade show active"
                    id="block"
                    role="tabpanel"
                    aria-labelledby="block-tab"
                >
                    <div id="blocks"></div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div
                    className="tab-pane fade show active"
                    id="layer"
                    role="tabpanel"
                    aria-labelledby="layer-tab"
                >
                    <div id="layers-container"></div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <div
                    className="tab-pane fade show active"
                    id="style"
                    role="tabpanel"
                    aria-labelledby="style-tab"
                >
                    <div id="styles-container"></div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <div
                    className="tab-pane fade show active"
                    id="trait"
                    role="tabpanel"
                    aria-labelledby="trait-tab"
                >
                    <div id="trait-container"></div>
                </div>
            </TabPanel>
        </Box>
    );
  }

  export default Sidebar;


//   <>
//         <ul className="nav nav-tabs" id="myTab" role="tablist">
//           <li className="nav-item" role="presentation">
//             <button
//               className="nav-link active"
//               id="block-tab"
//               data-bs-toggle="tab"
//               data-bs-target="#block"
//               type="button"
//               role="tab"
//               aria-controls="block"
//               aria-selected="true"
//             >
//               <i className="fa fa-cubes"></i>
//             </button>
//           </li>
//           <li className="nav-item" role="presentation">
//             <button
//               className="nav-link"
//               id="layer-tab"
//               data-bs-toggle="tab"
//               data-bs-target="#layer"
//               type="button"
//               role="tab"
//               aria-controls="layer"
//               aria-selected="false"
//             >
//               <i className="fa fa-tasks"></i>
//             </button>
//           </li>
//           <li className="nav-item" role="presentation">
//             <button
//               className="nav-link"
//               id="style-tab"
//               data-bs-toggle="tab"
//               data-bs-target="#style"
//               type="button"
//               role="tab"
//               aria-controls="style"
//               aria-selected="false"
//             >
//               <i className="fa fa-paint-brush"></i>
//             </button>
//           </li>
//           <li className="nav-item" role="presentation">
//             <button
//               className="nav-link"
//               id="trait-tab"
//               data-bs-toggle="tab"
//               data-bs-target="#trait"
//               type="button"
//               role="tab"
//               aria-controls="trait"
//               aria-selected="false"
//             >
//               <i className="fa fa-cog"></i>
//             </button>
//           </li>
//         </ul>
//         <div className="tab-content">




//         </div>
