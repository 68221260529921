import React from 'react'
import USDetailsPage from '../../../USCommonComponents/USDetailsPage'
import { useParams } from 'react-router-dom'

export default function USCompanyAddress() {
    const {id} = useParams();
    return (
        <USDetailsPage id={id} objectName={'address'}/>
    )
}
