/**
 * AsyncComponent
 * Code Splitting Component / Server Side Rendering
 */
 import React from 'react';
 import Loadable from 'react-loadable';

 // rct page loader
 import RctPageLoader from 'Components/USCommonComponents/USPageLoader/USPageLoader';

 // ecommerce dashboard
 const AsyncUSUserMainPage = Loadable({
    loader: () => import("../Pages/USUserMain"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSUserDetailsPage = Loadable({
    loader: () => import("../Pages/USUserDetails"),
    loading: () => <RctPageLoader />,
 });

 export {
    AsyncUSUserMainPage, AsyncUSUserDetailsPage
 };
