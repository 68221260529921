/**
 * Company Routes
 */
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Customers from './Customers';
import Suppliers from './Suppliers';
import USClientsManagement from '../../USCompanyComponents/Pages/USClientsManagement';
import SalesConsole from './SalesConsole';
import { AsyncUSCompanyProducts } from '../../USCompanyComponents/USCompanyAsyncPageLoader/USCompnayPageLoader';
import SalesCompanyProducts from './SalesCompanyProducts';
import NewEditRecord from './ManageRecords/NewEditRecord';
import USRecordList from '../../USCommonComponents/USRecordDetails/USRecordList';
import USViewRecord from '../../USCommonComponents/USRecordDetails/USViewRecord';
import USEditRecord from '../../USCommonComponents/USRecordDetails/USEditRecord';
import EditProductParts from './PartCategory/EditProductParts';
import USManageProductPartsAssets from '../../USCompanyComponents/Pages/USManageProductPartsAssets/USManageProductPartsAssets';
import EditAssetRecord from './ManageRecords/EditAssetRecord';
import USBulkAddRelatedRecords from '../../USCommonComponents/USDetailsPage/USBulkAddRelatedRecords';
import USNewContractDialog from './USNewContractDialog/USNewContractDialog';

const Pages = (props) => {
    return  (
        <div className="content-wrapper">
            <Helmet>
                <title>Sales Console</title>
                <meta name="description" content="Sales console" />
            </Helmet>
            <Switch>
                <Route path={`/sales/products`} component={AsyncUSCompanyProducts} />
                <Route path={`/sales/clients`} component={USClientsManagement} />
                <Route path={`/sales/customers`} component={Customers} />
                <Route path={`/sales/suppliers`} component={Suppliers} />
                <Route path={`/sales/addproductparts/:id`} component={EditProductParts} />
                <Route exact from="/sales" component={SalesConsole} />

                <Route path="/sales/view/:parentObject/:relatedObject/:id" component={USBulkAddRelatedRecords}/>

                <Route path={`/sales/view/productscompany/:id`} component={NewEditRecord} />
                <Route path={`/sales/manageProductParts/:id`} component={EditProductParts} />
                <Route path={`/sales/view/productparts/:id`} component={EditAssetRecord} />
                <Route path={`/sales/manageproductpartsassets/:id`} component={USManageProductPartsAssets} />
                <Route path={`/sales/productscompany`} component={SalesCompanyProducts} />

                <Route path="/sales/view/:objectName?/:id" component={USViewRecord}/>
                <Route path="/sales/edit/:objectName?/:id?" component={USEditRecord}/>
                <Route path="/sales/:objectName" component={USRecordList}/>
            </Switch>
            <USNewContractDialog />
        </div>
    );
}
export default Pages;
