/**
 * Quill Editor
 */
import React, { useState } from 'react';
import ReactQuill from 'react-quill';

const modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'font': [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image'],
    ['clean'],
    [{ 'align': [] }],
    ['code-block']
  ],
};

const formats = [
  'header',
  'font',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'align',
  'code-block'
];

function QuillEditor({handleOnchangeValue, value, fieldDetails}) {

    const [quillValue, setQuillValue] = useState(value);

    const handleChange = currentValue => {
        setQuillValue(currentValue);
        handleOnchangeValue({'target' : { 'value' : currentValue, 'name' : fieldDetails.field_api_name}});
    }

    return (
            <ReactQuill modules={modules} formats={formats} value={quillValue ?? ""} defaultValue="" onChange={handleChange} placeholder="Enter Your Message.." />
        );
    }

export default QuillEditor;
