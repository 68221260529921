import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest, postRequest } from '../../Api/ApiService/Service';

const initialState = {
    carts: "[]",//JSON.parse(localStorage.getItem("cartItems") != undefined ? localStorage.getItem("cartItems") : "[]"),
    error: false,
    loading: false,
    newCartItem: {
        "ProductId":"",
        "Name": "",
        "Image": "",
        "Price": null,
        "Description": '',
        "Brand": '',
        "Quantity": null,
        "Total": null,
        "Discount": 0
    },
}

export const fetchCartData = createAsyncThunk(
    'cart/fetchCartData',
    async () => {
        try {
            console.log('fetchCartData');
            const response = await getRequest('/carparts/getProductCart');
            const result = response.data.data;
            if(result) {
                return response.data.data;
            } else {
                return '[]';
            }
        } catch(e) {
            return e;
        }
    }
);

export const saveCartData = createAsyncThunk(
    'cart/saveCartData',
    async (cartData) => {
        try {
            const response = await postRequest('/company/setProductCart', cartData);
            return response.data.data;
        } catch(e) {
            return e;
        }
    }
);

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setProductCartItems : (state, action) => {
            state.carts = action.payload.data;
            localStorage.setItem('cartItems', action.payload.data);
            state.loading = false;
        },
        addItemToCart: (state, action) => {
            let recordExist = state.carts.filter((item) =>
            {
               if(item.ProductId === action.payload.ProductId) {
                    item.Quantity = parseInt(item.Quantity) + parseInt(action.payload.Quantity);
                    item.Total = item.Quantity * item.Price;
                    return item;
               }
            });
            if(recordExist?.length == 0) {
                state.carts.push(action.payload);
                localStorage.setItem('cartItems',JSON.stringify(state.carts));
            }
            console.log('additemToCard123', JSON.stringify(state.carts));
            const response = postRequest('/carparts/setProductCart', state.carts);
        },
        deleteItemFromCart: (state, action) => {
            const productList = state.carts.filter((item) => item.ProductId != action.payload.ProductId);
            state.carts = productList;
            localStorage.setItem('cartItems',JSON.stringify(state.carts));
            console.log('additemToCard123', JSON.stringify(action.payload));
            const response = postRequest('/carparts/removeProductCart', action.payload);
        },
        changeProductQuantity: (state, action) => {

            let recordExist = state.carts.filter((item) =>
            {
               if(item.ProductId === action.payload.ProductId) {
                    item.Quantity = parseInt(action.payload.Quantity);
                    item.Total = item.Quantity * item.Price;
                    return item;
               }
            });
            if(recordExist?.length == 0) {
                state.carts.push(action.payload);
            }
            localStorage.setItem('cartItems',JSON.stringify(state.carts));
            const response = postRequest('/carparts/setProductCart', state.carts);
        },
        applyDiscount: (state, action) => {

        }
    },
    extraReducers : {
        [fetchCartData.pending]: (state,action) => {
            state.loading = true;
            state.error = null;
        },
        [fetchCartData.fulfilled]: (state, action) => {
            console.log('action.payload', action.payload);
            state.carts = action.payload != undefined ? action.payload : "[]";
            localStorage.setItem('cartItems', JSON.stringify(action.payload));
            state.loading = false;
        },
        [fetchCartData.rejected]: (state, action) => {
            state.error = action.error.message;
            state.loading = false;
        },

        [saveCartData.pending]: (state,action) => {
            state.loading = true;
            state.error = null;
        },
        [saveCartData.fulfilled]: (state, action) => {
            state.carts = action.payload.data;
            localStorage.setItem('cartItems', JSON.stringify(action.payload));
            state.loading = false;
        },
        [saveCartData.rejected]: (state, action) => {
            state.error = action.error.message;
            state.loading = false;
        }
    }
})

export const { addItemToCart, deleteItemFromCart, changeProductQuantity, setProductCartItems, applyDiscount } = cartSlice.actions;
export default cartSlice.reducer;
