import React from 'react'
import { Draggable, Droppable } from "react-beautiful-dnd";
import USDndFieldList from './USDndFieldList';
import { Card, CardContent } from '@mui/material';

export default function USDndRow({sections}) {
    return (
        <Droppable 
            droppableId="sections"
            type="SECTION"
            direction="vertical"
        >
            {(provided, snapshot) => {
                return <Card style={{ backgroundColor: 'grey' }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >   
                    <CardContent>
                        {sections.map((sectionItem, index) => {
                            return (
                                <Draggable
                                    key={sectionItem.sectionName}
                                    draggableId={sectionItem.sectionName}
                                    index={index}
                                >
                                    {(provided, snapshot) => {
                                        return (
                                            <div 
                                                className="row"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <div className="col-lg-12 col-md-12 col-sm-12 bg-primary text-white" key={index}>{sectionItem.sectionName}</div>
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <USDndFieldList leftColumnFields={sectionItem.leftSideFields} rightColumnFields={sectionItem.rightSideFields} sectionId={index}/>
                                                </div>
                                            </div>
                                        );
                                    }}
                                </Draggable> 
                            )
                        })}
                        {provided.placeholder}
                    </CardContent>  
                </Card>
            }}
        </Droppable>
    );
}
