/**
 * Basic Table
 */
 import React, { Component, Fragment, useState } from 'react';
 import Table from '@mui/material/Table';
 import TableBody from '@mui/material/TableBody';
 import TableCell from '@mui/material/TableCell';
 import TableHead from '@mui/material/TableHead';
 import TableRow from '@mui/material/TableRow';

 // intl messages
 import IntlMessages from 'Util/IntlMessages';

 // For Basic Table
 let id = 0;

 function createData(name, inventoryNumber, serialNumber, assignmentDate, status) {
    id += 1;
    return { id, name, inventoryNumber, serialNumber, assignmentDate, status };
 }

 const data = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
 ];

 function ClientProductHistory(props) {

    const [employeeId, SetEmployeeId] = useState(props.employeeID);

    return (
        <div className="table-wrapper">
            <div className="table-responsive">
                <Table>
                    <TableHead>
                        <TableRow hover>
                            <TableCell>Asset</TableCell>
                            <TableCell align="right"><IntlMessages id="asset.inventory-number" /></TableCell>
                            <TableCell align="right"><IntlMessages id="asset.serial-number" /></TableCell>
                            <TableCell align="right"><IntlMessages id="asset.assignment-date" /></TableCell>
                            <TableCell align="right"><IntlMessages id="asset.status" /></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <Fragment>
                        {data.map(n => {
                            return (
                                <TableRow hover key={n.id}>
                                    <TableCell>{n.name}</TableCell>
                                    <TableCell align="right">{n.inventoryNumber}</TableCell>
                                    <TableCell align="right">{n.serialNumber}</TableCell>
                                    <TableCell align="right">{n.assignmentDate}</TableCell>
                                    <TableCell align="right">{n.status}</TableCell>
                                </TableRow>
                            );
                        })}
                        </Fragment>
                    </TableBody>
                </Table>
            </div>
        </div>
    );
 }

 export default ClientProductHistory;
