import React from 'react'
import { useParams } from 'react-router-dom'
import USRecordDetails from '.';

export default function USEditRecord() {
    const { id, objectName } = useParams();
    return (
        <USRecordDetails objectName={objectName} recordId={id}/>
    )
}
