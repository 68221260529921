/**
 * Quick Links
 */
import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import Tooltip from '@mui/material/Tooltip';
import IntlMessages from 'Util/IntlMessages';
import { useSelector } from 'react-redux';

const QuickLinks = ({ location }) => {
    const user = useSelector((state) => state?.auth?.user);
    return (
        <UncontrolledDropdown nav className="list-inline-item quciklink-dropdown tour-step-1">
            <DropdownToggle nav className="header-icon p-0">
                <Tooltip title="Quick Links" placement="bottom">
                    <i className="zmdi zmdi-apps"></i>
                </Tooltip>
            </DropdownToggle>
            <DropdownMenu>
                <Scrollbars className="rct-scroll" autoHeight autoHeightMin={100} autoHeightMax={350}>
                    <div className="dropdown-content">
                        <div className="dropdown-top d-flex justify-content-between rounded-top bg-primary">
                            <span className="text-white font-weight-bold">Quick Links</span>
                            <Badge color="warning">1 NEW</Badge>
                        </div>
                        <ul className="list-unstyled mb-0 dropdown-list">
                            {user?.application && user?.application?.map((appItem, appKey) => (
                                <React.Fragment key={appKey}>
                                    {appItem.isVisible && <li key={appKey}>
                                        <Link to={appItem.Link}>
                                            <i className={`${appItem.Icon}  mr-10`}></i>
                                            <IntlMessages id={appItem.Label} />
                                        </Link>
                                    </li>}
                                </React.Fragment>

                            ))}
                            {/* <li>
                                <Link to={`/portal`}>
                                    <i className="ti-notepad text-primary mr-10"></i>
                                    <IntlMessages id="quicklinks.portal" />
                                </Link>
                            </li>
                            <li>
                                <Link to={`/service`}>
                                    <i className="ti-user text-success mr-10"></i>
                                    <IntlMessages id="quicklinks.service" />
                                </Link>
                            </li>
                            <li>
                                <Link to={`/sales`}>
                                    <i className="ti-layout text-danger mr-10"></i>
                                    <IntlMessages id="quicklinks.sales" />
                                </Link>
                            </li>
                            <li>
                                <Link to={`/user`}>
                                    <i className="ti-layout text-danger mr-10"></i>
                                    <IntlMessages id="quicklinks.profile" />
                                </Link>
                            </li>
                            <li>
                                <Link to={`/`}>
                                    <i className="ti-layout text-danger mr-10"></i>
                                    <IntlMessages id="quicklinks.site" />
                                </Link>
                            </li> */}
                        </ul>
                    </div>
                </Scrollbars>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
}

export default QuickLinks;
