//Top Authors widget

import React, {useEffect, useState} from 'react'
import USCollapsibleCard from 'Components/USCommonComponents/USCollapsibleCard/USCollapsibleCard';
import Avatar from 'react-avatar-edit';
import {
    Form,
    FormGroup,
    Label,
    Input,
    Modal, ModalHeader, ModalBody, ModalFooter, Row, Col
} from 'reactstrap';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import IntlMessages from 'Util/IntlMessages';

import Assets from './EmployeeComponents/Assets';
import Bonuses from './EmployeeComponents/Bonuses';
import CareerPath from './EmployeeComponents/CareerPath';
import Certificates from './EmployeeComponents/Certificates';
import Children from './EmployeeComponents/Children';
import Deductions from './EmployeeComponents/Deductions';
import Documents from './EmployeeComponents/Documents';
import ProfessionalAssesments from './EmployeeComponents/ProfessionalAssesments';
import Requests from './EmployeeComponents/Requests';
import Salary from './EmployeeComponents/Salary';
import Skills from './EmployeeComponents/Skills';
import WorkSchedule from './EmployeeComponents/WorkSchedule';

import { getRequest, postRequest} from '../../../../Api/ApiService/Service';
import { Avatar as MuiAvatar, Badge } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Element } from '../../../../helpers/RouteHelper';

const author =
	{
		id: 1,
		name: "Natasha Knight",
		avatarSrc: require("Assets/img/user-37.jpg").default,
		phone: "+01 2345 67890",
		email: "natasha@example.com",
		address: "E-51 Phase-1 Mohali",
		articles: 200,
		followers: 1400,
		likes: 580
	}

function EmployeeDetails(props) {
    const {id} = useParams();
    const [preview,setPreview] = useState(null);
    const [src, setSrc] = useState(author.avatarSrc);
    const [isSelectingAvatar, setIsSelectingAvatar] = useState(false);

    const [employee, setEmployee] = useState(null);
    const [user, setUser] = useState(null);

    const editAvatar = () => {
        setIsSelectingAvatar(true);
    }

    const onCloseAvatart = () => {
        setIsSelectingAvatar(false);
    }

    const onClose = () => {
        setPreview(null);
    }

    const onCrop = (previewValue) => {
        setPreview(previewValue);
    }

    const uploadFile = () => {
        var formData = new FormData();
        var imagefile = document.querySelector('#file');
        formData.append("image", imagefile.files[0]);
        axios.post('upload_file', formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        })
    }

    const record = {
        country: '',
        city: '',
        street: '',
        state: '',
        zipcode: ''
    }

    const [employeeId, setEmployeeId] = useState();
    const [country, setCountry] = useState(record.country);
    const [city, setCity] = useState(record.city);
    const [street, setStreet] = useState(record.street);
    const [state, setState] = useState(record.state);
    const [zipcode, setZipcode] = useState(record.zipcode);

    useEffect(() => {
        load(id);
    }, [id]);

    const load = async(currentEmplId) => {
        console.log('response.data.data' );
        const response = await getRequest('company/view/contact/' + currentEmplId);
        console.log('response.data.data', response.data.data);
        const responseObj = response.data.data.recordData;
        setObjectFields(responseObj);
        setSections(response.data.data.layout?.sections);
        //setUser(responseObj?.User);
        setEmployeeId(id);
    }
    ///////////////////////////////////////////////////
    const [sections,setSections] = useState(null);
    const [objectFields, setObjectFields] = useState(null);

    const handleFieldValue = e => {
        const fieldName = e.target.name;
        console.log('fieldNamefieldNamefieldName',fieldName);
        console.log('fieldNamefieldNamefieldName',e.target.value);
        //const value = e.target.value != null && e.target.value.hasOwnProperty('value') ? e.target.value.value : e.target.value ;
        setObjectFields(existingValues => ({
            ...existingValues,
            [fieldName]: e.target.value,
        }))
    }

    const [isEdit, setIsEdit] = useState(false);
    const saveRecord = () => {
        save(objectFields);
    }

    const chekIfDependedLookup = (currentField) => {
        const propExist = currentField.hasOwnProperty('depended_picklist');
        if(propExist) {
            const hasField = objectFields.hasOwnProperty(currentField.depended_picklist);
            if(hasField) {
                return objectFields[currentField.depended_picklist];
            }
        }
        return null;
    }

    return (
        <div>
            {sections && <div className="row">
                <div className="col-sm-12 col-md-3 col-xl-3" style={{ display: 'block'}}>
                    <div className="top-author-wrap rct-block">
                        <div className="bg-primary text-white pt-4 rounded-top">
                            <h4 className="mb-0 text-center">{employee?.JobTitle}</h4>
                        </div>
                        <div key={user?.id} className="author-detail-wrap d-flex justify-content-between flex-column">
                            <div className="author-avatar bg-primary overlay-wrap mb-5">
                                <div className="avatar-img">
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        badgeContent={
                                            <AddAPhotoOutlinedIcon onClick={editAvatar}/>
                                        }
                                    >
                                        <MuiAvatar sx={{ width: 80, height: 80 }} alt="Travis Howard" src={user?.LogoUrl} />
                                    </Badge>
                                </div>
                            </div>
                            <div className="p-3 authors-info">
                                <h5>{user?.name}</h5>
                                <ul className="list-unstyled author-contact-info mb-2">
                                    <li>
                                        <span className="mr-3"><i className="zmdi zmdi-phone-msg"></i></span>
                                        <a href="tel:123456" className="font-xs text-muted">{author.phone}</a>
                                    </li>
                                    <li>
                                        <span className="mr-3"><i className="zmdi zmdi-email"></i></span>
                                        <a href="mailto:joan_parisian@gmail.com" className="font-xs text-muted">{user?.email}</a>
                                    </li>
                                    <li>
                                        <span className="mr-3"><i className="zmdi zmdi-pin"></i></span>
                                        <span className="font-xs text-muted">{employee?.Office}</span>
                                    </li>
                                </ul>
                                <ul className="d-flex social-info list-unstyled">
                                    <li><a className="facebook" href={user?.Facebook} onClick={e => e.preventDefault()}><i className="zmdi zmdi-facebook-box"></i></a></li>
                                    <li><a className="twitter" href={user?.Twitter} onClick={e => e.preventDefault()}><i className="zmdi zmdi-twitter-box"></i></a></li>
                                    <li><a className="linkedin" href={user?.LinkedIn} onClick={e => e.preventDefault()}><i className="zmdi zmdi-linkedin-box"></i></a></li>
                                    <li><a className="instagram" href={user?.Instagram} onClick={e => e.preventDefault()}><i className="zmdi zmdi-instagram"></i></a></li>
                                </ul>
                            </div>
                            <ul className="d-flex list-unstyled footer-content text-center w-100 border-top mb-0">
                                <li>
                                    <h5 className="mb-0">{author.articles}</h5>
                                    <span className="font-xs text-muted">Articles</span>
                                </li>
                                <li>
                                    <h5 className="mb-0">{author.followers}</h5>
                                    <span className="font-xs text-muted">Followers</span>
                                </li>
                                <li>
                                    <h5 className="mb-0">{author.likes}</h5>
                                    <span className="font-xs text-muted">Likes</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-9 col-xl-9">
                    {(sections && objectFields) ? sections.map((section, i) => (
                        <Row key={i}>
                            <Col sm={12} md={12} xl={12}>
                                <USCollapsibleCard heading={<IntlMessages id={section.sectionName}/>} >
                                    {(section.style && section.style === 'columns') ?
                                        <Row>
                                            <Col sm={12} md={12} xl={6} style={{ display:'block' }}>
                                                {section.leftSideFields && section.leftSideFields.map((lField, j) => {
                                                    if(lField.show) {
                                                        let depLookUp = chekIfDependedLookup(lField);
                                                        return <Element key={j} fieldDetails={lField} handleEdit={handleFieldValue} value={objectFields[lField.field_api_name]} dependedObjectValue={depLookUp} variant="standard"/>
                                                    }
                                                    return null;
                                                })}
                                            </Col>
                                            <Col sm={12} md={12} xl={6} style={{ display:'block' }}>
                                                {section.rightSideFields && section.rightSideFields.map((rField, k) => {
                                                    if(rField.show  == 'true') {
                                                        let depLookUp = chekIfDependedLookup(rField);
                                                        return <Element key={k} fieldDetails={rField} handleEdit={handleFieldValue} value={objectFields[rField.field_api_name]} dependedObjectValue={depLookUp}  variant="standard"/>
                                                    }
                                                    return null;
                                                })}
                                            </Col>
                                        </Row>
                                            :
                                        <Row>
                                            <Col sm={12} md={12} xl={12} style={{ display:'block' }}>
                                                {section.leftSideFields && section.leftSideFields.map((lField, j) => {
                                                    if(lField.show == 'true') {
                                                        let depLookUp = chekIfDependedLookup(lField)
                                                        return <Element key={j} fieldDetails={lField} handleEdit={handleFieldValue} value={objectFields[lField.field_api_name]} dependedObjectValue={depLookUp} variant="standard"/>
                                                    }
                                                    return null;
                                                })}
                                            </Col>
                                        </Row>
                                    }
                                </USCollapsibleCard>
                            </Col>
                        </Row>
                        )) : null
                    }
                </div>
            </div>}
            {sections &&
            <div className='row'>
                <div className="col-sm-12 col-md-12 col-xl-12">
                    <USCollapsibleCard heading={<IntlMessages id="employee.assets"/>} collapsible={true} collapse={false}>
                        <Assets employeeID={employeeId}/>
                    </USCollapsibleCard>
                    <USCollapsibleCard heading={<IntlMessages id="employee.skills"/>} collapsible={true} collapse={false}>
                        <Skills employeeID={employeeId}/>
                    </USCollapsibleCard>
                    <USCollapsibleCard heading={<IntlMessages id="employee.workschedule"/>} collapsible={true} collapse={false}>
                        <WorkSchedule employeeID={employeeId}/>
                    </USCollapsibleCard>
                    <USCollapsibleCard heading={<IntlMessages id="employee.documents"/>} collapsible={true} collapse={false}>
                        <Documents employeeID={employeeId}/>
                    </USCollapsibleCard>
                    <USCollapsibleCard heading={<IntlMessages id="employee.certificates"/>} collapsible={true} collapse={false}>
                        <Certificates employeeID={employeeId}/>
                    </USCollapsibleCard>
                    <USCollapsibleCard heading={<IntlMessages id="employee.professional-assesments"/>} collapsible={true} collapse={false}>
                        <ProfessionalAssesments employeeID={employeeId}/>
                    </USCollapsibleCard>
                    <USCollapsibleCard heading={<IntlMessages id="employee.career-path"/>} collapsible={true} collapse={false}>
                        <CareerPath employeeID={employeeId}/>
                    </USCollapsibleCard>
                    <USCollapsibleCard heading={<IntlMessages id="employee.requests"/>} collapsible={true} collapse={false}>
                        <Requests employeeID={employeeId}/>
                    </USCollapsibleCard>
                    <USCollapsibleCard heading={<IntlMessages id="employee.children"/>} collapsible={true} collapse={false}>
                        <Children employeeID={employeeId}/>
                    </USCollapsibleCard>
                    <USCollapsibleCard heading={<IntlMessages id="employee.salary"/>} collapsible={true} collapse={false}>
                        <Salary employeeID={employeeId}/>
                    </USCollapsibleCard>
                    <USCollapsibleCard heading={<IntlMessages id="employee.bonus"/>} collapsible={true} collapse={false}>
                        <Bonuses employeeID={employeeId}/>
                    </USCollapsibleCard>
                    <USCollapsibleCard heading={<IntlMessages id="employee.deduction"/>} collapsible={true} collapse={false}>
                        <Deductions employeeID={employeeId}/>
                    </USCollapsibleCard>
                </div>
            </div>
            }
            <Modal isOpen={isSelectingAvatar} toggle={onCloseAvatart} size="lg" style={{maxWidth: '700px', width: '100%'}}>
                <ModalHeader toggle={onCloseAvatart}>Edit avatar</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12} md={12} xl={6}>
                            <Avatar
                                width={390}
                                height={295}
                                onCrop={onCrop}
                                onClose={onClose}
                                src={user?.LogoUrl}
                        />
                        </Col>
                        <Col sm={12} md={12} xl={6}>
                            <img src={preview} />
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default EmployeeDetails;
