/**
 * Pricing Component
 */
import { CardActionArea, CardActions, Collapse, styled } from '@mui/material';
import React from 'react';
import { Button } from 'reactstrap';

// intl messages
import IntlMessages from 'Util/IntlMessages'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { USCard, USCardContent } from '../../../../USCommonComponents/USCardComponent';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const PricingBlockV1 = ({ planType, type, description, price, users, features, color, buttonText, handleOnSelect }) => {

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <USCard customClasses="text-center" colClasses="col-md-4">
            <USCardContent>
                <div className="pricing-icon mb-40">
                    <img src={require('Assets/img/pricing-icon.png').default} alt="pricing icon" className="img-fluid" width="" height="" />
                </div>
                <h2 className={`text-${color} pricing-title`}><IntlMessages id={type} /></h2>
                <p>{description}</p>
                {planType !== 'free' && <span className="text-muted mb-5 d-block small">Starting at just</span>}
                <div className="mb-25">
                    {planType === 'free' ?
                        <h2 className="amount-title"><IntlMessages id={price} /></h2>
                        : <h2 className="amount-title">${price}<sub>/mo</sub></h2>
                    }
                    <span className="text-muted small">For {users} user</span>
                </div>

            </USCardContent>



            <CardActionArea>
                <CardActions disableSpacing>
                    <Button color={color} className='btn-block btn-lg' onClick={() => handleOnSelect('selecedREcord')}>
                        <IntlMessages id={buttonText} />
                    </Button>
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                </CardActions>
            </CardActionArea>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <USCardContent>
                    <ul className="price-detail list-unstyled">
                        {features.map((feature, key) => (
                            <li key={key}>{feature}</li>
                        ))}
                    </ul>
                </USCardContent>
            </Collapse>
        </USCard>
    )

}


export default PricingBlockV1;
