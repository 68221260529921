import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import USIOSSwitch from "../../../USCommonComponents/USIOSSwitch";
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import IntlMessages from 'Util/IntlMessages';

const USCarListModal = ({ isOpen, onCloseSupportPage, onSubmit, record, header, layout }) => {

    const [currentRecord, setCurrentRecord] = useState(null);

    useEffect(() => {
        console.log('layout', record);
        setCurrentRecord(record);
    }, [record]);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(currentRecord);
    }

    const handleFieldValue = (fieldName, value) => {
        setCurrentRecord(existingValues => ({
            // Retain the existing values
            ...existingValues,
            // update the current field
            [fieldName]: value,
        }))
    }

    const handleChange = (e) => {
        console.log(e);
        handleFieldValue(e.target.name, e.target.value)
    }

    return (
        <>
        {currentRecord &&
            <Modal isOpen={isOpen} toggle={onCloseSupportPage} size="lg" style={{maxWidth: '700px', width: '100%'}}>
                <ModalHeader toggle={onCloseSupportPage}>{header}</ModalHeader>
                <ModalBody>
                    <Stack spacing={2} >
                        <TextField label={<IntlMessages id="car.brandid" />} disabled color="info" focused fullWidth value={currentRecord.ExternalBrandId}/>
                        <TextField label={<IntlMessages id="common.name" />} color="info" focused fullWidth name="Name" onChange={(e) => handleFieldValue('Name',e.target.value) } value={currentRecord.Name}/>

                        <FormControl fullWidth>
                            <InputLabel id="brandTypelabel"><IntlMessages id="common.type" /></InputLabel>
                            <Select
                                labelId="brandTypelabel"
                                id="brandTypelabel"
                                name="Type"
                                value={currentRecord.Type}
                                label={<IntlMessages id="common.type" />}
                                onChange={handleChange}
                            >
                                <MenuItem value="Car"><IntlMessages id="car.car" /></MenuItem>
                                <MenuItem value="Motobike"><IntlMessages id="car.motobike" /></MenuItem>
                                <MenuItem value="Truck"><IntlMessages id="car.truck" /></MenuItem>
                                <MenuItem value="Van"><IntlMessages id="car.van" /></MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel id="placeToDisplay"><IntlMessages id="car.place-to-display" /></InputLabel>
                            <Select
                                labelId="placeToDisplay"
                                id="placeToDisplay"
                                name="PlaceToDisplay"
                                value={currentRecord.PlaceToDisplay ? currentRecord.PlaceToDisplay : "null"}
                                label={<IntlMessages id="car.place-to-display" />}
                                onChange={handleChange}
                            >
                                <MenuItem value="null" >None</MenuItem>
                                <MenuItem value="ShowOnTop"><IntlMessages id="car.show-on-top" /></MenuItem>
                                <MenuItem value="ShowOnBottom"><IntlMessages id="car.show-on-bottom" /></MenuItem>
                            </Select>
                        </FormControl>

                        <FormControlLabel
                            control={<USIOSSwitch name="isPopular" onChange={(e) => handleFieldValue('isPopular',!currentRecord.isPopular) } checked={currentRecord.isPopular} />}
                            label={<Typography className="ml-10"><IntlMessages id="car.ispopular" /></Typography>}
                        />

                        <FormControlLabel
                            control={<USIOSSwitch name="ShowOnMainPage" onChange={(e) => handleFieldValue('ShowOnMainPage',!currentRecord.ShowOnMainPage) } checked={currentRecord.ShowOnMainPage} />}
                            label={<Typography className="ml-10"><IntlMessages id="car.showonmain" /></Typography>}
                        />

                        <FormControlLabel
                            control={<USIOSSwitch name="isActive" onChange={(e) => handleFieldValue('isActive', !currentRecord.isActive) } checked={currentRecord.isActive} />}
                            label={<Typography className="ml-10"><IntlMessages id="car.isactive" /></Typography>}
                        />

                        <Button variant="outlined" startIcon={<PhotoCamera />}>
                            <IntlMessages id="common.upload" />
                        </Button>
                    </Stack>

                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" color="primary" className="text-white bg-primary" onClick={(e) => handleSubmit(e)}>Submit</Button>{' '}
                    <Button variant="contained" className="btn-danger text-white" onClick={onCloseSupportPage}>Cancel</Button>
                </ModalFooter>
            </Modal>
        }
        </>
    )
}

export default USCarListModal;
