
import { Button, Stack } from "@mui/material";
import { USCard  } from "../../../USCommonComponents/USCardComponent";
import USTable from "../../../USCommonComponents/USTable";


const USCompanyCurrencies = () => {

    return (
        <>
            <USCard>
                <Stack direction="row" spacing={2}>
                    <Button variant="outlined">New</Button>
                </Stack>
            </USCard>

            <USTable tableTitle="Compay's Currency" objectName={'currency'} requestUrl={'company/list/'} expandableRows={false} />
        </>

    )
}

export default USCompanyCurrencies;
