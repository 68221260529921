import React from "react";
import { Chip, createTheme, LinearProgress, Link, Modal, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Tooltip, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import IntlMessages from 'Util/IntlMessages';
import { getRequest, postRequest } from "../../../Api/ApiService/Service";
import USIOSSwitch from "../USIOSSwitch";
import { updateUserDetails } from '../../../reduxSettings/reduxSlices/userAuthSlice';
import { useDispatch } from "react-redux";
import { openDialog } from "../../../reduxSettings/reduxSlices/systemSlice";
import { addNotification } from "../../../reduxSettings/reduxSlices/NotificationSlice";
import { Button } from "@mui/material";
import USUploadFiles from "../USUploadFiles/USUploadFiles";

 function USTable({tableTitle, objectName, parentObjectName, parentFieldName, parentObjectId, requestUrl, expandableRows, hideToolbar, hidePagination, refres, appUrl, handleNew, showDelete=true, columnModificationFunction  = null, searchCondition = null, packageUrl="company" }) {

    const getMuiTheme = () =>
        createTheme({
            components: {
                MUIDataTable: {
                    styleOverrides:{ responsiveBase: {
                        paddingLeft: '10px',
                        paddingRight: '10px',
                    }},
                },
                MuiTableCell: {
                    styleOverrides:{ root: {
                        padding: '0px',
                        paddingLeft: '3px',
                        fontSize: '0.75rem',
                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                        borderLeft: '1px solid rgba(224, 224, 224, 1)'
                    }},

                },
                MUIDataTableHeadCell: {
                styleOverrides:{ root: {
                    color:'white',
                    backgroundColor: '#5D92F4'
                }}
                },
                MUIDataTableSelectCell : {
                    styleOverrides:{ headerCell: {
                        backgroundColor: '#5D92F4'
                    }}
                },
                MUIDataTableToolbar: {
                    styleOverrides:{ root: {
                        display: hideToolbar ? 'none' : 'flex'
                    }}
                },
                MUIDataTablePagination: {
                    styleOverrides:{ root: {
                        display: hidePagination ? 'none' : 'flex'
                    }}
                }
            }
        }
    );

    const dispatch = useDispatch();

    const navigate = useHistory();
    let relatedObjects = null;
    let tableData = null;
    const [orderDataTable, setOrderDataTable] = useState(null);
    const [conlumnsData, setColumnsData] = useState(null);

    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [sortOrder, setSortOrder] = useState({});
    const [sortOrderField, setSortOrderField] = useState('created_at');
    const [sortOrderDirection, setSortOrderDirection] = useState('desc');
    const [options, setOptions] = useState({
                                            filter: true,
                                            filterType: 'dropdown',
                                            responsive: 'vertical',
                                            serverSide: true,
                                            rowsPerPage: rowsPerPage,
                                            rowsPerPageOptions: [],
                                            sortOrder: sortOrder,

                                            onChangeRowsPerPage (numberOfRows) {
                                                console.log({numberOfRows});
                                            },
                                            onTableChange: (action, tableState) => {
                                                switch (action) {
                                                    case 'changePage':
                                                        changePage(tableState.page, tableState.sortOrder);
                                                    break;
                                                    case 'sort':
                                                        sort(tableState.sortOrder);
                                                    break;
                                                    case 'propsUpdate':
                                                        console.log('propsUpdate', tableState);

                                                    default:
                                                        console.log('action not handled.', action);
                                                }
                                            },
                                        });

    const handleShowEditDialog = () => {
        dispatch(openDialog({objectName : objectName, dialogTitle : 'New/Edit ' + objectName, parentFieldName : parentFieldName, parentRecordId: parentObjectId}));
    }

    const fetchData = async (currentPage) => {
        try {
            let fetchRequestUrl = requestUrl;
            let searchParams = {};
            objectName = objectName ? objectName : null;
            parentFieldName = parentFieldName ? parentFieldName : null;
            parentObjectId = parentObjectId ? parentObjectId : null;

            searchParams['objectName'] =  objectName;
            searchParams['currentPage'] =  currentPage;
            searchParams['sortOrderField'] =  sortOrderField;
            searchParams['sortOrderDirection'] =  sortOrderDirection;
            searchParams['rowsPerPage'] =  rowsPerPage;
            searchParams['parentFieldName'] =  parentFieldName;
            searchParams['parentObjectId'] =  parentObjectId;
            searchParams['searchCondition'] = searchCondition;
            console.log('response', searchParams);
            const response = await postRequest(fetchRequestUrl, searchParams);
            console.log('response', response);
            const responseObj = {...response?.data?.data};
            tableData = responseObj;
            generateTable(responseObj);

        } catch (error) {
            console.error(error)
        }
    }

    const generateTable = (selectedTableData) => {
        setCount(selectedTableData?.count);
        setOrderDataTable(selectedTableData?.tableData);
        setColumnsData(getColumnsData(selectedTableData?.tableHeader));
        relatedObjects = selectedTableData?.relatedObjects;
        console.log('selectedTableData', selectedTableData);
        console.log('parentObjectName', parentObjectName);
        console.log('objectName', objectName);
        //tableTitle = objectName;
        setOptions({
            download: false,
            filter: false,
            print: false,
            viewColumns: false,
            selectableRowsHideCheckboxes: true,
            count: selectedTableData?.count,
            filterType: 'dropdown',
            expandableRows: (expandableRows ? expandableRows : false),
            customToolbar: () => {
                // if(!handleNew) {
                //     return;
                // }
                return (
                    <span>
                        {handleNew && <Tooltip title={`New ${objectName}`}>
                            <>
                                <Button className="ms-1" variant="outlined" onClick={handleShowEditDialog}>New</Button>
                                <Button className="ms-1" variant="outlined" onClick={() => navigate.push(`/${packageUrl}/view/${parentObjectName}/${objectName}/${parentObjectId}`)}>Bulk</Button>
                                <Button className="ms-1" variant="outlined" onClick={handleUploadModal}>Import Excel</Button>
                            </>
                        </Tooltip>}
                    </span>
                );
            },
            renderExpandableRow: expandableRows ? (rowData, rowMeta) => {
            return (
                <React.Fragment>
                    <tr>
                        <td colSpan={12} className="p-10">
                            <TableContainer component={Paper}>
                                <Table style={{ minWidth: "650" }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell align="right" className="pr-5">Quantity</TableCell>
                                            <TableCell align="right" className="pr-5">Discount</TableCell>
                                            <TableCell align="right" className="pr-5">Price</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedTableData.orderData && selectedTableData.orderData[rowMeta.rowIndex].cartitems.map((row, indexRow) => {
                                            return <TableRow key={indexRow}>
                                                <TableCell >{row.Name}</TableCell>
                                                <TableCell align="right" className="pr-5">{row.Quantity}</TableCell>
                                                <TableCell align="right" className="pr-5">{row.Discount}</TableCell>
                                                <TableCell align="right" className="pr-5">{row.Price}</TableCell>
                                            </TableRow>
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </td>
                    </tr>
                </React.Fragment>
        )} : null
        });
    }

    const changePage = (pageNumber, sortOrder) => {
        setPage(pageNumber);
        fetchData(pageNumber);
    }

    const sort = (fieldName) => {
        console.log(fieldName);
        setSortOrderField(fieldName.name);
        setSortOrderDirection(fieldName.direction);
        fetchData(page);
    }

    const [refreshAfterUpdate, setRefreshAfterUpdate] = useState();

    useEffect(() => {
        setOrderDataTable(null);
        fetchData(page);
    }, [objectName, refres, refreshAfterUpdate, searchCondition, parentObjectName]);

    function getColumnsData(responseObj) {

        let preparedColumns = [];
        for (let step = 0; step < responseObj?.length; step++) {
            if(responseObj[step]?.isAvailable) {
                let colElement = getTableRow(responseObj[step]);
                preparedColumns.push(colElement);
            }
        }
        return preparedColumns;
    }

    const updateTableRow = (recId, fieldName, fieldValue) => {

    }

    const deleteRecord = async (recordId, rowIndex) => {
        try {
            const response = await getRequest('company/delete/' + objectName + '/' + recordId);
            var currentdate = new Date();
            setRefreshAfterUpdate(currentdate.getSeconds());
            dispatch(addNotification({"type" : "success","title":"Record deleted successfuly", "message":  "Done"}));
        } catch(error) {
            dispatch(addNotification({"type" : "error","title":"Something went wrong", "message":  error.message}));
        }

    }

    const updateSelectedCompany = (value) => {
        console.log("selected Accoount id", value);
        dispatch(updateUserDetails({"CompanyId": value}));
        navigate.push("/company/account");
    }

    const getRelatedObjectName = (objectName, objectId) => {
        if(relatedObjects?.hasOwnProperty(objectName)) {
            if(relatedObjects[objectName]?.hasOwnProperty(objectId)) {
                return relatedObjects[objectName][objectId];
            } else {
                return  '';
            }
        }
        return '';
    }

    const columnModification = (columnName, cellValue, objectName, rowRecordId) => {
        if(!columnModificationFunction) {
            return null;
        } else {
            return columnModificationFunction(columnName, cellValue, objectName, rowRecordId);
        }
    }

    const [isEditRows, setIsEditRows] = useState(false);
    const editTableRows = (value) => {
        console.log('editTableRows');
        setIsEditRows(true);
        generateTable(tableData);
    }

    function getTableRow(responseObj) {
        console.log('responseObj.fieldType', responseObj.fieldType);
        console.log('responseObj.fieldName', responseObj.fieldName);
        console.log('responseObj.referenceObject', responseObj.referenceObject);
        if(responseObj.isAvailable) {
            const fieldType = responseObj.fieldType;
            const fieldLabel = <IntlMessages id={responseObj.fieldLabel}/>;
            const fieldName = responseObj.fieldName;
            const referenceObject = responseObj.referenceObject;

            if(fieldType) {
                switch (fieldType) {
                    case 'bigint':
                        return { name: fieldName, label: fieldLabel, options: {referenceObject: referenceObject, customBodyRender: (value, tableMeta) => {
                                    let reference = objectName
                                    if(tableMeta?.columnData?.referenceObject) {
                                        reference = tableMeta?.columnData?.referenceObject;
                                    }
                                    let currentAppUrl = appUrl ? appUrl : `/${packageUrl}/view` ;
                                    return (<>
                                        {value ? <Stack direction="row" spacing={2}>
                                            {objectName != 'account' ? <Link
                                                component="button"
                                                variant="body2"
                                                onClick={() => {
                                                    navigate.push(`${currentAppUrl}/${reference}/${value}`);
                                            }}>
                                                {tableMeta.columnData.name != 'id' ? getRelatedObjectName(reference,value) : 'view'}
                                            </Link>
                                            :
                                            <Link
                                                component="button"
                                                variant="body2"
                                                onClick={() => {
                                                    updateSelectedCompany(value);
                                            }}>
                                                View
                                            </Link>}

                                            {(tableMeta.columnData.name == 'id' && showDelete) &&
                                                <Link
                                                    component="button"
                                                    variant="body2"
                                                    onClick={() => {
                                                        deleteRecord(value, tableMeta.rowIndex);
                                                }}>
                                                    Delete
                                                </Link>
                                            }
                                            </Stack>
                                        : null}
                                        </>
                                    );}
                                }
                            }
                    case 'float':
                        return { name: fieldName, label: fieldLabel , options: {setIsEditRows: editTableRows, isEditRows: isEditRows, customBodyRender: (value, tableMeta) => {
                            return <div onClick={() => {console.log('qwerty'), editTableRows(true)}}>
                                    <div style={{ display: isEditRows ? 'none' : 'block' }}>{value}</div>
                                    <input style={{ display: isEditRows ? 'block' : 'none' }} value={value} />
                                </div>
                        }}}
                    case 'date':
                        return { name: fieldName, label: fieldLabel , options: {customBodyRender: (value, tableMeta) => {
                            if(value != null) {
                                let colData = new Date(value);
                                let day = colData.getDate();
                                let month = colData.getMonth();
                                let year = colData.getFullYear();
                                return month+1 + "/" + day + "/" + year;
                            }
                            return '';
                        }}}
                    case 'datetime':
                        return { name: fieldName, label: fieldLabel , options: {customBodyRender: (value, tableMeta) => {
                            if(value != null) {
                                const cellValue = columnModification(tableMeta.columnData.name, value, objectName,tableMeta.rowData[0]);
                                if(cellValue) {
                                    return cellValue
                                }
                                const recordDate = new Date(cellValue);
                                return recordDate.toLocaleString("ru-RU");
                            }
                            return '';
                        }}}
                    case 'string':
                    return { name: fieldName, label: fieldLabel , options: {customBodyRender: (value, tableMeta) => {
                                if(tableMeta.columnData.name === 'Status') {
                                    switch (value) {
                                        case 'New':
                                            return <Chip label={<IntlMessages id="order.status.New" />} color="success" variant="outlined"/>
                                            break;
                                        case 'Awaiting processing':
                                            return <Chip label={<IntlMessages id="order.status.awaiting-processing" />} color="success" />
                                            break;
                                        case 'Awaiting payment':
                                            return <Chip label={<IntlMessages id="order.status.awaiting-payment" />} color="success" />
                                            break;
                                        case 'In the pipeline':
                                            return <Chip label={<IntlMessages id="order.status.in-the-pipeline" />} color="success" />
                                            break;
                                        case 'Order at a supplier':
                                            return <Chip label={<IntlMessages id="order.status.order-at-a-supplier" />} color="success" />
                                            break;
                                        case 'Validated by supplier':
                                            return <Chip label={<IntlMessages id="order.status.validated-by-supplier" />} color="success" />
                                            break;
                                        case 'Rejected by supplier':
                                            return <Chip label={<IntlMessages id="order.status.rejected-by-supplier" />} color="success" />
                                            break;
                                        case 'Sent to central warehouse':
                                            return <Chip label={<IntlMessages id="order.status.sent-to-central-warehouse" />} color="success" />
                                            break;
                                        case 'Failed to reserve position':
                                            return <Chip label={<IntlMessages id="order.status.failed-to-reserve-position" />} color="success" />
                                            break;
                                        case 'Partial reservation':
                                            return <Chip label={<IntlMessages id="order.status.partial-reservation" />} color="success" />
                                            break;
                                        case 'Ready for shipment':
                                            return <Chip label={<IntlMessages id="order.status.ready-for-shipment" />} color="success" />
                                            break;
                                        case 'Minimum delivery amount':
                                            return <Chip label={<IntlMessages id="order.status.Minimum-delivery-amount" />} color="success" />
                                            break;
                                        case 'Assembled':
                                            return <Chip label={<IntlMessages id="order.status.assembled" />} color="success" />
                                            break;
                                        case 'On the way':
                                            return <Chip label={<IntlMessages id="order.status.on-the-way" />} color="success" />
                                            break;
                                        case 'Delivered':
                                            return <Chip label={<IntlMessages id="order.status.delivered" />} color="success" />
                                            break;
                                        case 'Issued':
                                            return <Chip label={<IntlMessages id="order.status.issued" />} color="success" />
                                            break;
                                        case 'Deleted':
                                            return <Chip label={<IntlMessages id="order.status.deleted" />} color="success" />
                                            break;
                                        case 'Shipped':
                                            return <Chip label={<IntlMessages id="order.status.shipped" />} color="success" />
                                            break;
                                        case 'Success':
                                            return <Chip label={<IntlMessages id="order.status.success" />} color="success" />
                                            break;
                                        case 'Error':
                                            return <Chip label={<IntlMessages id="order.status.error" />} color="success" />
                                            break;
                                        case 'Awaiting payment':
                                            return <Chip label={<IntlMessages id="order.status.awaiting-payment" />} color="success" />
                                            break;
                                        case 'Payment confirmed':
                                            return <Chip label={<IntlMessages id="order.status.payment-confirmed" />} color="success" />
                                            break;
                                        case 'Partially confirmed':
                                            return <Chip label={<IntlMessages id="order.status.partially-confirmed" />} color="success" />
                                            break;
                                        case 'Payment not confirmed':
                                            return <Chip label={<IntlMessages id="order.status.payment-not-confirmed" />} color="success" />
                                            break;
                                        case 'On the move':
                                            return <Chip label={<IntlMessages id="order.status.on-the-move" />} color="success" />
                                            break;
                                        case 'Canceled':
                                            return <Chip label={<IntlMessages id="order.status.canceled" />} color="success" />
                                            break;
                                        case 'Waiting completion transaction':
                                            return <Chip label={<IntlMessages id="order.status.waiting-completion-transaction" />} color="success" />
                                            break;
                                        default:
                                            return <Chip label={<IntlMessages id="order.status.error" />} color="error" />
                                    }
                                }
                                return value;
                                }}
                            }
                    break;
                    case 'integer':
                        return { name: fieldName, label: fieldLabel , options: {customBodyRender: (value, tableMeta) => {
                            return <TextField onClick={() => console.log('qwer')}>value</TextField>
                        }}}
                        break;
                    case 'boolean':
                    return { name: fieldName, label: fieldLabel , options: {customBodyRender: (value, tableMeta) => {
                            return <USIOSSwitch name="isPopular" onChange={(e) => updateTableRow(value,'isPopular',!value) } checked={value} />
                        }}}
                    break;
                    default:
                        return { name: fieldName, label: fieldLabel };
                        break;
                }
            }
            return { name: fieldName, label: fieldLabel };
        }
    }

    const [isUploadModalOpen, setIsUploadModalOpen] = useState();
    const handleUploadModal = () => {
        setIsUploadModalOpen(true);
    }
    const handleCloseUploadModal = () => {
        setIsUploadModalOpen(false);
        fetchData(0);
    }
     return (
        <>
            {orderDataTable && conlumnsData ?
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={objectName}
                        data={orderDataTable}
                        columns={conlumnsData}
                        options={options}
                    />
                </ThemeProvider>
            :
                <LinearProgress />
            }

            {(objectName && isUploadModalOpen) && <Modal
                open={isUploadModalOpen}
                onClose={handleCloseUploadModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="m-10"
            >
                <USUploadFiles objectName={objectName} handleCloseModal={handleCloseUploadModal}/>
            </Modal>}
        </>
     )
 }
 export default USTable;
