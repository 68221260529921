import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest, postRequest } from "../../Api/ApiService/Service";
import { addNotification } from "./NotificationSlice";

const initialState = {
    user: (localStorage.getItem("user") != "null" && localStorage.getItem("user") != "undefined") ? JSON.parse(localStorage.getItem("user")): null,
    isLogged: (localStorage.getItem("token") && localStorage.getItem("token") != "null" && localStorage.getItem("token") != "undefined")  ? true : false,
    application:null,
    error: null,
    loading: false,
    application: null
};

export const loginUser = createAsyncThunk('auth/loginUser', async (credentials) => {
    try {
        console.log('datadatadata',credentials);
        const response = await postRequest('user/login',{...credentials, "remember_me":false});
        console.log(response.data);
        return response.data;
    } catch(e) {
        return e;
    }
});

export const registerUser = createAsyncThunk('auth/registerUser', async (credentials) => {
    try {
        console.log(credentials);
        const response = await postRequest('/user/register', credentials);
        console.log(response);
        return response.data;
    } catch(e) {
        dispatch(addNotification({"type" : "success","title":"User Created", "message":  e}));
    }
});

export const getUserDetails = createAsyncThunk('auth/getUserDetails', async () => {
    try {
        console.log('createAsyncThunk');
        const response = await getRequest('user/details');
        console.log(response.data.data);
        return response?.data?.data;
    } catch(e) {
        return e;
    }
});

export const updateUserDetails = createAsyncThunk('auth/getUserDetails', async (details) => {
    try {
        const response = await postRequest('/user/updateDetails', details);
        console.log(response.data);
        return response.data.data;
    } catch(e) {
        return e;
    }
});

export const userAuthSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: (state, action) => {
            state.user = null;
            localStorage.setItem('user', null);
            state.token = null;
            state.isLogged = false;
            localStorage.setItem('token', null);
        },

        changeAvatar: (state, action) => {
            state.user.LogoUrl = action.payload;
        },
        cahngeBackground: (state, action) => {
            state.user.BackgroundURL = action.payload;
        }
    },
    extraReducers : {
        [loginUser.pending]: (state) => {
            state.loading = true;
            state.error = null;

        },
        [loginUser.fulfilled]: (state, action) => {
            state.user = action.payload.data;
            localStorage.setItem('user', JSON.stringify(state.user));
            state.token = action.payload.token;
            localStorage.setItem('token', state.token);
            state.token_type = action.payload.token_type;
            localStorage.setItem('token_type', state.token_type);
            state.isLogged = true;
            state.loading = false;

        },
        [loginUser.rejected]: (state, action) => {
            state.error = action.error.message;
            state.loading = false;

        },
        [registerUser.pending]: (state,action) => {
            state.loading = true;
            state.error = null;

        },
        [registerUser.fulfilled]: (state, action) => {
            state.user = action.payload.data;
            localStorage.setItem('user', JSON.stringify(state.user));
            state.token = action.payload.token;
            localStorage.setItem('token', state.token);
            state.token_type = action.payload.token_type;
            localStorage.setItem('token_type', state.token_type);
            state.isLogged = true;
            state.loading = false;
        },
        [registerUser.rejected]: (state, action) => {
            state.error = action.error.message;
            state.loading = false;

        },
        [getUserDetails.pending]: (state) => {
            console.log('pending');
            state.loading = true;
            state.isLogged = false;
            state.error = null;

        },
        [getUserDetails.fulfilled]: (state, action) => {
            console.log('action.payload', action.payload);
            state.user = action.payload;
            state.application = action.payload.application;
            localStorage.setItem('user', JSON.stringify(state.user));
            localStorage.setItem('application', JSON.stringify(state.user.application));
            state.isLogged = true;
            state.loading = false;
        },
        [getUserDetails.rejected]: (state, action) => {
            console.log('rejected');
            state.error = action.error.message;
            state.loading = false;
            state.isLogged = false;

        },
        [updateUserDetails.pending]: (state,action) => {
            state.loading = true;
            state.error = null;

        },
        [updateUserDetails.fulfilled]: (state, action) => {
            state.user = action.payload;
            localStorage.setItem('user', JSON.stringify(state.user));
            state.isLogged = true;
            state.loading = false;
        },
        [updateUserDetails.rejected]: (state, action) => {
            state.error = action.error.message;
            state.loading = false;
        },
    }
});

export const {logout, changeAvatar, cahngeBackground} = userAuthSlice.actions;
export default userAuthSlice.reducer;
