import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { useParams, useHistory } from "react-router-dom"
import USRecordDetails from "../../../USCommonComponents/USRecordDetails";
import USTable from "../../../USCommonComponents/USTable";
import IntlMessages from 'Util/IntlMessages';
import { getRequest } from "../../../../Api/ApiService/Service";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../../reduxSettings/reduxSlices/NotificationSlice";

export default function USCompanyEditProfile() {
    const {id} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const [refreshUserItems, setRefreshUserItems] = useState(null);
    const [refreshRoleItems, setRefreshRoleItems] = useState(null);

    const [openAssignProfile, setOpenAssignProfile] = useState(false);
    const [userForProfileList, setuserForProfileList] = useState(null);
    const openAssignProfileDialog = async() => {
        const response = await getRequest('permissions/companyusers');
        setuserForProfileList(response.data.data);
        setOpenAssignProfile(true);

    }
    const assignProfile = async () => {
        //const response = await getRequest('permissions/companyusers/' + id + '/' + selectedRole);
        setOpenAssignProfile(false);
        var currentdate = new Date();
        setRefreshUserItems(currentdate.getSeconds());
        setuserForProfileList(null);
        dispatch(addNotification({"type" : "success","title":"Role Added", "message":  'response.data.message'}));
    }

    const [openAddRole, setOpenAddRole] = useState(false);
    const [roles, setRoles] = useState(null);
    const [selectedRole, setSelectedRole] = useState("");
    const addRoleToProfile = async() => {
        const response = await getRequest('permissions/role/list/' + id);
        console.log(response.data.data);
        setRoles(response.data.data)
        setOpenAddRole(true);
    }

    const saveRole = async () => {
        const response = await getRequest('permissions/assignRoleToProfile/' + id + '/' + selectedRole);
        setOpenAddRole(false);
        var currentdate = new Date();
        setRefreshRoleItems(currentdate.getSeconds());
        dispatch(addNotification({"type" : "success","title":"Role Added", "message":  response.data.message}));
    }

    return (
        <>
            <USRecordDetails objectName={'profile'} recordId={id} actions={
                <>
                    <Button variant="outlined" onClick={addRoleToProfile} size="small">Add Role To Profile</Button>
                    <Button variant="outlined" onClick={openAssignProfileDialog} size="small">Assign Profile To Users</Button>
                    <Button variant="outlined" onClick={() => history.push(`/company/profile/manage/${id}`)} size="small">Manage Profile</Button>
                </>
            }>
                <USTable refres={refreshRoleItems} objectName={'roletoprofile'} requestUrl={'company/getTableList'} expandableRows={false} hideToolbar={false} hidePagination={false}/>
                <USTable refres={refreshUserItems} objectName={'user'} requestUrl={'company/getTableList'} expandableRows={false} hideToolbar={false} hidePagination={false}/>
            </USRecordDetails>

            <Dialog
                open={openAddRole}
                onClose={()=> setOpenAddRole(false)}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm">
                <DialogTitle id="form-dialog-title">
                    <IntlMessages id="components.addNewTasks" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Manage Roles for Profile
                    </DialogContentText>

                    {roles && <Select
                        labelId="labelRoleId"
                        id="roleId"
                        value={selectedRole}
                        label="Role"
                        onChange={(e) => setSelectedRole(e.target.value)}
                        fullWidth
                    >
                        <MenuItem value="">None</MenuItem>
                        {roles.map((role, index) => {
                            return <MenuItem key={index} value={role.id}>{role.name}</MenuItem>
                        })}
                    </Select>}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={()=> setOpenAddRole(false)} className="btn-danger text-white">
                        <span><IntlMessages id="button.cancel" /></span>
                    </Button>
                    <Button variant="contained" onClick={() => saveRole()} color="primary" className="text-white">
                        <span><IntlMessages id="button.add" /></span>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openAssignProfile}
                onClose={()=> setOpenAssignProfile(false)}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="sm">
                <DialogTitle id="form-dialog-title">
                    <IntlMessages id="components.addNewTasks" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Assign Profile to User
                    </DialogContentText>

                    {userForProfileList &&
                        <USTable refres={refreshRoleItems} objectName={'roletoprofile'} requestUrl={'company/getTableList'} expandableRows={false} hideToolbar={false} hidePagination={false}/>
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={()=> setOpenAssignProfile(false)} className="btn-danger text-white">
                        <span><IntlMessages id="button.cancel" /></span>
                    </Button>
                    <Button variant="contained" onClick={() => assignProfile()} color="primary" className="text-white">
                        <span><IntlMessages id="button.add" /></span>
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    )
}
