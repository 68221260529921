import React, { useEffect, useState } from 'react';
// intl messages
import IntlMessages from 'Util/IntlMessages';
import { useHistory } from 'react-router-dom';
import { USCard } from '../../../USCommonComponents/USCardComponent';
import { Button, Stack } from '@mui/material';
import USTable from '../../../USCommonComponents/USTable';

 function USGifts() {

    const navigate = useHistory();
    return (
        <USCard>
            <CardHeader
                action={
                    <Button variant="outlined" onClick={() => navigate.push('edit/gift')}>New</Button>
                }
                title="Gifts need to create data table"
                className="ml-10 mr-10"
            />
            <USCardContent>
                <USTable tableTitle="Gift" objectName={'markup'} requestUrl={'company/list/'} expandableRows={false} hideToolbar={false} hidePagination={false}/>
            </USCardContent>
        </USCard>
    );
}

 export default USGifts;
