import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import USDndFieldItem from './USDndFieldItem';
import { Card } from '@mui/material';

export default function USDndFieldList({leftColumnFields, rightColumnFields,sectionId}) {
  return (
        <div className='row'>
            <div className='col-sm-6 col-md-6 col-lg-6'>
                <Droppable droppableId={`leftSideFields_${sectionId}`}>
                    {(provided, snapshot) => {
                        return (<div className='row'
                                style={{ backgroundColor: 'blue', minHeight: '250px'}}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >      
                            {leftColumnFields?.map((fieldItem, index) => {
                                return (
                                    <Draggable
                                        key={fieldItem.field_api_name}
                                        draggableId={fieldItem.field_api_name}
                                        index={index}
                                    >
                                        {(provided, snapshot) => {
                                            return (
                                                <div className='col-sm-12 col-md-12 col-lg-12'
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div>{fieldItem.field_api_name}</div>
                                                </div>
                                            );
                                        }}
                                    </Draggable> 
                                )
                            })}
                            {provided.placeholder}
                        </div>);
                    }}
                </Droppable>
            </div>
            <div className='col-sm-6 col-md-6 col-lg-6'>
                <Droppable droppableId={`rightSideFields_${sectionId}`}>
                    {(provided, snapshot) => {
                        return (<div className='row'
                        style={{ backgroundColor: '#ea41d5' }}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >      
                            {rightColumnFields?.map((fieldItem, index) => {
                                return (
                                    <Draggable
                                        key={fieldItem.field_api_name}
                                        draggableId={fieldItem.field_api_name}
                                        index={index}
                                    >
                                        {(provided, snapshot) => {
                                            return (
                                                <div className='col-sm-12 col-md-12 col-lg-12'
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div>{fieldItem.field_api_name}</div>
                                                </div>
                                            );
                                        }}
                                    </Draggable> 
                                )
                            })}
                            {provided.placeholder}
                        </div>);
                    }}
                </Droppable>
            </div>
        </div>
    );
}
