import React, { useEffect } from 'react';
import { Box, Card, CardContent, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import IntlMessages from 'Util/IntlMessages';
import Feedback from 'Components/USCommonComponents/USPageComponents/feedback';
import { getRequest } from '../../../../../Api/ApiService/Service';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function ProductTabComponent({detail}) {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Card>
             {detail && <CardContent>
                <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={value} onChange={ handleChange} aria-label="basic tabs example">
                        <Tab label={<IntlMessages id="common.description" />} {...a11yProps(0)} />
                        <Tab label={<IntlMessages id="sidebar.feedback" />} {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    {detail.Description && <div className="product-description">
                        <div className="mt-2">
                            {parse(detail.Description)}
                        </div>
                    </div>}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Feedback productId={detail.id}/>
                </TabPanel>
            </CardContent>}
        </Card>
    );
}

export default ProductTabComponent;
