import { Autocomplete, Link, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { getRequest } from '../../../../Api/ApiService/Service';
import IntlMessages from 'Util/IntlMessages';

function USLookup({ fieldDetails, handleChange, value, handleSearchByInputValue, isDisabled, dependedObjectValue, readOnly }) {

    const [selectedItems, setSelectedItems] = useState([]);
    const [inputSearchValue, setInputSearchValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);

    const searchByInputValue = async (inputValue) => {
        setInputSearchValue(inputValue);
        if(inputValue?.length > 0) {
            try {
                const apiFieldName = 'Name';
                let response = null;
                if(dependedObjectValue) {
                    response = await getRequest('utils/getDependedLookup/' + fieldDetails.related_object + '/'+apiFieldName + '/'+ dependedObjectValue + '/' + fieldDetails.depended_field + '/' + inputValue);
                } else {
                    response = await getRequest('utils/getLookupValies/' + fieldDetails.referenceObject + '/' + apiFieldName + '/' +inputValue);
                }
                selectOptions(response);
            } catch (error) {
                console.log('error', error);
            }
        } else {
            setSelectedItems([]);
        }
    }

    const selectOptions = (resp) => {
        if(resp) {
            const responseObj = resp.data.data;
            let selectedItemsArray = [];
            if(responseObj) {
                responseObj?.map((resultItem, key)=>{
                    selectedItemsArray.push({"title" : resultItem.Name, "value" : resultItem.id});
                });
            }
            setSelectedItems(selectedItemsArray);
        } else {
            return null;
        }

    }

    useEffect(() => {
        const updateLookUp = async () => {
            if(dependedObjectValue) {
                const resp = await getRequest('utils/getDependedLookup/' + fieldDetails.related_object + '/Name/' + value + '/' +fieldDetails.depended_field + '/' +dependedObjectValue);
                selectOptions(resp);
            }
            if(value) {
                const response = await getRequest('utils/getLookupValue/' + fieldDetails.referenceObject + '/' + value);
                const responseObj = response.data.data;
                if(responseObj && responseObj?.length > 0) {
                    setSelectedValue({"title" : responseObj[0].Name, "value" : responseObj[0].id});
                }
            } else {
                setSelectedValue({"title" :'', "value" : ''});
            }

        }
        updateLookUp();
    },[value, dependedObjectValue]);

    const checkIfNull = (val) => {
        return val != null ? val : '';
    }

    const handleChangeValue = (value) => {
        handleChange({
            "target": {
                "name" : fieldDetails.field_api_name,
                "value": value ? value.value : null,
                "data": value
            }
        });
    }

    const fieldValue = (fieldDetails, params, editRecord) => {
        if(editRecord) {
            return <TextField
                        {...params}
                        label={<IntlMessages id={fieldDetails.field_label} />}
                        InputProps={{
                            readOnly: (fieldDetails.field_access == 'edit' || !readOnly ? false : true),
                            ...params.InputProps,
                            type: 'search',
                        }}
                        variant="standard"
                    />
        } else {
            return <a href=""></a>;
        }

    }

    return (
        <>
            {(fieldDetails && !readOnly) && <Autocomplete
                id={`'id'+${fieldDetails.field_api_name}`}
                options={selectedItems}
                value={checkIfNull(selectedValue)}
                onChange={(event, newValue) => {
                    //event.preventDefault();
                    // if(readOnly) {
                    //     return;
                    // }
                    handleChangeValue(newValue);
                }}
                inputValue={inputSearchValue}
                onInputChange={(event, newInputValue) => {
                    //event.preventDefault();
                    // if(readOnly) {
                    //     return;
                    // }
                    searchByInputValue(newInputValue);
                }}
                getOptionLabel={(option) => option.title ? option.title : ''}
                disabled={readOnly}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={<IntlMessages id={fieldDetails.field_label} />}
                        InputProps={{
                            readOnly: (fieldDetails.field_access == 'edit' || !readOnly ? false : true),
                            ...params.InputProps,
                            type: 'search',
                        }}
                        variant="standard"
                    />
                )}
            />}

            {(fieldDetails && selectedValue && readOnly) && <TextField
                fullWidth
                label={<IntlMessages id={fieldDetails.field_label} />}
                value={selectedValue.title}
                variant="standard"
                InputProps={{
                    endAdornment: (
                        <Link
                            href={`${(selectedValue.value != '' && selectedValue.value) ? `/sales/view/${fieldDetails.referenceObject}/${selectedValue.value}` : ''}`}
                        >
                            {`${(selectedValue.value != '' && selectedValue.value) ? 'view': ''}`}
                        </Link>
                    ),
                }}
            />}
        </>
    )
}

export default USLookup;
