import { getRequest } from "../ApiService/Service";

export async function getObjectTemplate(objectName) {
    try{
        const response = await getRequest(`/company/objectFieldTemplate/${objectName}`);
        console.log('Admin handler getObjectTemplate', response);
        return response.data.data;
    } catch(error) {
        return error;
    }
}
