/**
 * Rct Section Loader
 */
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const USSectionLoader = () => (
    <div className="d-flex justify-content-center loader-overlay">
        <CircularProgress />
    </div>
);

export default USSectionLoader;
