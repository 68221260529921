/**
 * Sidebar Content
 */
 import React from 'react';
 import List from '@mui/material/List';
 import ListSubheader from '@mui/material/ListSubheader';
 import { useSelector, useDispatch } from 'react-redux';

 import IntlMessages from 'Util/IntlMessages';

 import NavMenuItem from './NavMenuItem';
 import NavLinks from './NavLinks';
  // redux actions
 // import { onToggleMenu } from 'Actions';
 import { onToggleMenu } from '../../../reduxSettings/reduxSlices/sidebarSlice';

 function UserSidebarContent(props) {

     const dispatch = useDispatch();

     const toggleMenu = (menu, stateCategory) => {
         console.log('toogleMenu');
         let data = {
             menu,
             stateCategory
         }
         console.log('stateCategory', stateCategory);
         dispatch(onToggleMenu(data));
     }

     return (
         <div className="rct-sidebar-nav">
             <nav className="navigation">
                 { Object.keys(NavLinks).map((category, i) => (
                     <List key={category}
                         className="rct-mainMenu p-0 m-0 list-unstyled"
                         subheader={
                             <ListSubheader className="side-title" component="li">
                                 <IntlMessages id={NavLinks[category].messageId} />
                             </ListSubheader>
                         }
                     >
                         {(NavLinks[category].links).map((menu, key) => (
                             <NavMenuItem
                                 menu={menu}
                                 key={key}
                                 onToggleSidebarMenu={() => toggleMenu(menu, category)}
                             />
                         ))}
                     </List>
                 ))}
             </nav>
         </div>
     );
  }

  export default UserSidebarContent;
