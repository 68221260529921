import React, { useState } from "react";
// rct card box
import USCollapsibleCard from 'Components/USCommonComponents/USCollapsibleCard/USCollapsibleCard';
import { Button, Card, Stack, TextField  } from "@mui/material";
import { Col, Row } from "reactstrap";

// page title bar
import USPageTitleBar from 'Components/USCommonComponents/USPageTitleBar/USPageTitleBar';
// intl messages
import IntlMessages from 'Util/IntlMessages';
import ClientProductHistory from "./ClientComponents/ClientProductHistory";
import { USCard, USCardContent } from 'Components/USCommonComponents/USCardComponent';

function USClientDetails(props) {

    const [isEdit, setIsEdit] = useState(false);

    const [employeeId, setEmployeeId] = useState();
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [street,setStreet] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');

    const author =
	{
		id: 1,
		name: "Natasha Knight",
		avatarSrc: require("Assets/img/user-37.jpg").default,
		phone: "+01 2345 67890",
		email: "natasha@example.com",
		address: "E-51 Phase-1 Mohali",
		articles: 200,
		followers: 1400,
		likes: 580
	}

    const saveRecord = (e) => {
        e.preventDefault();

    }

    return (
        <div>
            <USPageTitleBar title={<IntlMessages id="Номер заказа: 123456, статус: Акстивный" />} match={props.match} />
            <div className="search-bar-wrap">
                <Card>
                    <Row>
                        <Col sm={12} md={12} xl={4}>
                            <Stack direction="row" spacing={2}>
                                {!isEdit &&<Button variant="outlined" color="primary" onClick={() => setIsEdit(true)}>Edit</Button>}
                                {isEdit &&
                                <Button variant="outlined" color="primary" onClick={(e) => saveRecord(e)}>Save</Button>}
                                {isEdit && <Button variant="outlined" color="primary" onClick={() => setIsEdit(false)}>Cancel</Button> }
                            </Stack>
                        </Col>
                    </Row>
                </Card>
            </div>
            <Row>
                <Col sm={12} md={12} xl={4} style={{ display:'block' }}>
                    <Row>
                        <Col sm={12} md={12} xl={12}>
                            <USCollapsibleCard collapse={true}>
                                <div className="top-author-wrap rct-block">
                                    <div className="bg-primary text-white pt-4 rounded-top">
                                        <h4 className="mb-0 text-center">Top Authors</h4>
                                    </div>
                                    <div key={author.id} className="author-detail-wrap d-flex justify-content-between flex-column">
                                        <div className="author-avatar bg-primary overlay-wrap mb-5">
                                            <div className="avatar-img">
                                                <img src={author.avatarSrc} width="80" height="80" className="img-fluid mx-auto rounded-circle" alt="avtar" />
                                            </div>
                                        </div>
                                        <div className="p-3 authors-info">
                                            <h5>{author.name}</h5>
                                            <ul className="list-unstyled author-contact-info mb-2">
                                                <li>
                                                    <span className="mr-3"><i className="zmdi zmdi-phone-msg"></i></span>
                                                    <a href="tel:123456" className="font-xs text-muted">{author.phone}</a>
                                                </li>
                                                <li>
                                                    <span className="mr-3"><i className="zmdi zmdi-email"></i></span>
                                                    <a href="mailto:joan_parisian@gmail.com" className="font-xs text-muted">{author.email}</a>
                                                </li>
                                                <li>
                                                    <span className="mr-3"><i className="zmdi zmdi-pin"></i></span>
                                                    <span className="font-xs text-muted">{author.address}</span>
                                                </li>
                                            </ul>
                                            <ul className="d-flex social-info list-unstyled">
                                                <li><a className="facebook" href="#" onClick={e => e.preventDefault()}><i className="zmdi zmdi-facebook-box"></i></a></li>
                                                <li><a className="twitter" href="#" onClick={e => e.preventDefault()}><i className="zmdi zmdi-twitter-box"></i></a></li>
                                                <li><a className="linkedin" href="#" onClick={e => e.preventDefault()}><i className="zmdi zmdi-linkedin-box"></i></a></li>
                                                <li><a className="instagram" href="#" onClick={e => e.preventDefault()}><i className="zmdi zmdi-instagram"></i></a></li>
                                            </ul>
                                        </div>
                                        <ul className="d-flex list-unstyled footer-content text-center w-100 border-top mb-0">
                                            <li>
                                                <h5 className="mb-0">{author.articles}</h5>
                                                <span className="font-xs text-muted">Articles</span>
                                            </li>
                                            <li>
                                                <h5 className="mb-0">{author.followers}</h5>
                                                <span className="font-xs text-muted">Followers</span>
                                            </li>
                                            <li>
                                                <h5 className="mb-0">{author.likes}</h5>
                                                <span className="font-xs text-muted">Likes</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </USCollapsibleCard>
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} md={12} xl={4} style={{ display:'block' }}>
                    <Row>
                        <Col sm={12} md={12} xl={12}>
                            <USCollapsibleCard heading={<IntlMessages id="employee.personal-information"/>} collapse={true}>
                                <TextField id="firstName" label={<IntlMessages id="employee.first-name" />} onChange={(e) => setCountry(e.target.value)} value={country} fullWidth />
                                <TextField id="lastName" label={<IntlMessages id="employee.last-name" />} onChange={(e) => setCountry(e.target.value)} value={country} fullWidth className="mt-10"/>
                                <TextField id="middleName" label={<IntlMessages id="employee.middle-name" />} onChange={(e) => setCountry(e.target.value)} value={country} fullWidth className="mt-10"/>
                                <TextField id="workPhone" label={<IntlMessages id="employee.work-phone" />} onChange={(e) => setCountry(e.target.value)} value={country} fullWidth className="mt-10"/>
                                <TextField id="workEmail" label={<IntlMessages id="employee.work-email" />} onChange={(e) => setCountry(e.target.value)} value={country} fullWidth className="mt-10"/>
                                <TextField id="workTelegram" label={<IntlMessages id="employee.work-telegram" />} onChange={(e) => setCountry(e.target.value)} value={country} fullWidth className="mt-10"/>
                                <TextField id="birthdate" label={<IntlMessages id="employee.birthdate" />} onChange={(e) => setCountry(e.target.value)} value={country} fullWidth className="mt-10"/>
                            </USCollapsibleCard>
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} md={12} xl={4} style={{ display:'block' }}>
                    <Row>
                        <Col sm={12} md={12} xl={12}>
                            <USCollapsibleCard heading={<IntlMessages id="address.billing-address"/>} collapse={true}>
                                <TextField id="formCountry" label="Country" onChange={(e) => setCountry(e.target.value)} value={country} fullWidth className="mt-10" />
                                <TextField id="formCity" label="City" onChange={(e) => setCity(e.target.value)} value={city} fullWidth className="mt-10"/>
                                <TextField id="formStreet" label="Street" onChange={(e) => setStreet(e.target.value)} value={street} fullWidth className="mt-10"/>
                                <TextField id="formState" label="State" onChange={(e) => setState(e.target.value)} value={state} fullWidth className="mt-10"/>
                                <TextField id="formZipcode" label="Zip Code" onChange={(e) => setZipCode(e.target.value)} value={zipCode} fullWidth className="mt-10"/>
                            </USCollapsibleCard>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={12} xl={12}>
                    <USCollapsibleCard heading={<IntlMessages id="Current Orders"/>} collapsible={true} collapse={true}>
                        <ClientProductHistory employeeID={employeeId}/>
                    </USCollapsibleCard>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={12} xl={12}>
                    <USCollapsibleCard heading={<IntlMessages id="Product History"/>} collapsible={true} collapse={true}>
                        <ClientProductHistory employeeID={employeeId}/>
                    </USCollapsibleCard>
                </Col>
            </Row>
        </div>
    );
}
export default USClientDetails;
