import { useParams } from "react-router-dom";
import USTable from "../../../../USCommonComponents/USTable";
import { Button } from "@mui/material";
import { USCard, USCardContent } from "../../../../USCommonComponents/USCardComponent";

export default function USStoreCatalogs() {
    const {id} = useParams();

    return (
        <>
            <USCard>
                <USCardContent>
                    <Button variant="outlined">New</Button>
                </USCardContent>
            </USCard>
            <USTable tableTitle="Store Catalogs" parentObjectId={id} parentFieldName="StoreId" objectName={'productcatalog'} requestUrl={'company/child/'} expandableRows={false} />
        </>
    )
}
