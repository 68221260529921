import axios from 'axios';

const axiosClient = axios.create();
axiosClient.defaults.baseURL = 'http://127.0.0.1:8000/api/site-builder/';
axiosClient.defaults.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token')
};

axiosClient.defaults.timeout = 2000;

export function getRequest(URL) {
    return axiosClient.get(`${URL}`).then(response => response);
}

export function postRequest(URL, payload) {
    return axiosClient.post(`${URL}`, payload).then(response => response);
}

export function patchRequest(URL, payload) {
    return axiosClient.patch(`${URL}`, payload).then(response => response);
}

export function deleteRequest(URL) {
    return axiosClient.delete(`${URL}`).then(response => response);
}

export function loadPage(pageId) {
    return axiosClient.get(`${pageId}`);
}

export function  storePage(pageId,data) {
    return axiosClient.patch(`${pageId}`, { data });
}


