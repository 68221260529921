import { useParams } from "react-router-dom";
import USDetailsPage from "../../../../USCommonComponents/USDetailsPage";

export default function USCatalogDetails() {
    const {id} = useParams();

    return (
        <USDetailsPage objectName="productcatalog" id={id}/>
    )
}
