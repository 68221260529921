import React from 'react';
import {LoggedForCompany} from '../../../helpers/RouteHelper';
import USContainerCompanyLayout from './USContainerCompanyLayout';
import Pages from '../Pages';
import { useHistory } from 'react-router-dom';

function USCompanyLayout(props) {
    const navigate = useHistory();
    return (
        <USContainerCompanyLayout >
            <LoggedForCompany path="/company" component={Pages} token={localStorage.getItem('token')} location={navigate.location}/>
        </USContainerCompanyLayout >
    );
}

export default USCompanyLayout;
