import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedBrand: localStorage.getItem('carBrand') ? localStorage.getItem('carBrand') : "none",
    selectedMarket:"none",
    selectedModel: localStorage.getItem('carModel') ? localStorage.getItem('carModel') : "none",
    producedYear: "none",
    restrictions: "none",
    marker: "none",
    selectedEngine: localStorage.getItem('carEngine') ? localStorage.getItem('carEngine') : "none",
    gearbox: "none"
}

export const carPartsSlice = createSlice({
    name: 'carSearchDetails',
    initialState,
    reducers: {
        setSelectedBrand: (state, action) => {
            state.selectedBrand = action.payload;
            localStorage.setItem('carBrand',action.payload);
        },
        setSelectedMarket: (state, action) => {
            state.selectedMarket = action.payload;
        },
        setSelectedModel: (state, action) => {
            state.selectedModel = action.payload;
            localStorage.setItem('carModel',action.payload);
        },
        setProducedYear: (state, action) => {
            state.producedYear = action.payload;
        },
        setRestrictions: (state, action) => {
            state.restrictions = action.payload;
        },
        setMarker: (state, action) => {
            state.marker = action.payload;
        },
        setSelectedEngine: (state, action) => {
            state.selectedEngine = action.payload;
            localStorage.setItem('carEngine',action.payload);
        },
        setGearbox: (state, action) => {
            state.gearbox = action.payload;
        },
        clearAll: (state, action) => {
            state.selectedBrand = "none";
            state.selectedMarket = "none";
            state.selectedModel = "none";
            state.selectedEngine = "none";
            state.producedYear = "none";
            state.restrictions = "none";
            state.marker = "none";
            state.engine = "none";
            state.gearbox = "none";
            localStorage.setItem('carBrand',"none");
            localStorage.setItem('carModel',"none");
            localStorage.setItem('carEngine',"none");
            console.log('clearAll');
        },
    }
})

export const { setSelectedBrand, setSelectedMarket, setSelectedModel, setProducedYear, setRestrictions, setMarker, setSelectedEngine, setGearbox, clearAll } = carPartsSlice.actions;
export default carPartsSlice.reducer;
