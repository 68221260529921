import React, { useEffect, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import IntlMessages from 'Util/IntlMessages';
import { Element } from '../../../helpers/RouteHelper';
import { getRequest, postRequest } from '../../../Api/ApiService/Service';
import { USCard, USCardContent } from '../USCardComponent';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../reduxSettings/reduxSlices/NotificationSlice';

function USRecordDetails({objectName, recordId, onSaveAction, actions, children}) {

    const dispatch = useDispatch();
    const [sections,setSections] = useState(null);
    const [objectFields, setObjectFields] = useState(null);

    const handleFieldValue = e => {
        const fieldName = e.target.name;
        //const value = e.target.value != null && e.target.value.hasOwnProperty('value') ? e.target.value.value : e.target.value ;
        setObjectFields(existingValues => ({
            // Retain the existing values
            ...existingValues,
            // update the current field
            [fieldName]: e.target.value,
        }))
    }

    const [isEdit, setIsEdit] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const saveRecord = async () => {
        try {
            console.log('objectFields', objectFields);
            const response = await postRequest('company/edit/' + objectName + '/' + (recordId ? recordId : ''), objectFields);
            console.log('response', response.data);
            dispatch(addNotification({type: "info", message: "Record Updated"}));
            setObjectFields(response.data.data?.recordData);
            setSections(response.data.data?.layout);
            if(onSaveAction) {
                onSaveAction();
            }
        } catch (error) {
            dispatch(addNotification({type: "info", message: "Error Init details page"}));
        }
    }

    useEffect(() => {
        initData(recordId, objectName);
    }, [objectName, recordId]);

    const initData = async (recId, objName) => {
        try {
            let responseCompanyLayout = null;
            if(recId) {
                responseCompanyLayout = await getRequest('company/view/' + objName + '/'+recId);
            } else {
                setIsEdit(true);
                responseCompanyLayout = await getRequest('company/layout/' + objName + '/' + 'editlayout');
            }

            console.log('record details page' , responseCompanyLayout);
            setObjectFields(responseCompanyLayout.data.data.recordData);
            setSections(responseCompanyLayout.data.data.layout);
        } catch (error) {
            dispatch(addNotification({type: "info", message: "Error Init details page"}));
            console.error(error)
        }
    }

    const chekIfDependedLookup = (currentField) => {
        const propExist = currentField.hasOwnProperty('depended_picklist');
        if(propExist) {
            const hasField = objectFields.hasOwnProperty(currentField.depended_picklist);
            if(hasField) {
                return objectFields[currentField.depended_picklist];
            }
        }
        return null;
    }

    return (
        <>
            {sections && <USCard>
                <USCardContent>
                    <div className='row'>
                        <div className='col-sm-12 col-md-2 col-lg-2'>
                            <Typography variant='h5'>{sections.page_label}</Typography>
                        </div>
                        <div className='col-sm-12 col-md-10 col-lg-10'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className="btn-group d-flex flex-row-reverse" role="group">
                                        {!isEdit && <Button onClick={e => setIsEdit(!isEdit)}  variant='outlined' size="small">
                                            <IntlMessages id="common.edit" />
                                        </Button>}
                                        {isEdit && <Button onClick={e => saveRecord()}  variant='outlined' size="small">
                                            <IntlMessages id="common.save" />
                                        </Button>}
                                        {isEdit && <Button onClick={e => setIsEdit(!isEdit)}  variant='outlined' size="small">
                                            <IntlMessages id="common.cancel" />
                                        </Button>}
                                        {actions}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </USCardContent>
            </USCard>}

            {sections && objectFields ? sections.sections.map((section, i) => (
                <div className='row' key={i}>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                        <USCard heading={<IntlMessages id={section.sectionName}/>} >
                            <USCardContent>
                                {(section.style && section.style === 'columns') ?
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-6 col-lg-6' style={{ display:'block' }}>
                                            {section.leftSideFields && section.leftSideFields.map((lField, j) => {
                                                if(lField.show) {
                                                    let depLookUp = chekIfDependedLookup(lField);
                                                    return <Element key={j} fieldDetails={lField} handleEdit={handleFieldValue} value={objectFields[lField.field_api_name]} dependedObjectValue={depLookUp} variant="standard" readOnly={!isEdit}/>
                                                }
                                                return null;
                                            })}
                                        </div>
                                        <div className='col-sm-12 col-md-6 col-lg-6' style={{ display:'block' }}>
                                            {section.rightSideFields && section.rightSideFields.map((rField, k) => {
                                                if(rField.show) {
                                                    console.log('rField', rField);
                                                    let depLookUp = chekIfDependedLookup(rField);
                                                    return <Element key={k} fieldDetails={rField} handleEdit={handleFieldValue} value={objectFields[rField.field_api_name]} dependedObjectValue={depLookUp}  variant="standard" readOnly={!isEdit}/>
                                                }
                                                return null;
                                            })}
                                        </div>
                                    </div>
                                        :
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-12 col-lg-12' style={{ display:'block' }}>
                                            {section.leftSideFields && section.leftSideFields.map((lField, j) => {
                                                if(lField.show == 'true') {
                                                    let depLookUp = chekIfDependedLookup(lField)
                                                    return <Element key={j} fieldDetails={lField} handleEdit={handleFieldValue} value={objectFields[lField.field_api_name]} dependedObjectValue={depLookUp} variant="standard" readOnly={!isEdit}/>
                                                }
                                                return null;
                                            })}
                                        </div>
                                    </div>
                                }
                            </USCardContent>
                        </USCard>
                    </div>
                </div>
                )) : null
            }

            {children}

            {sections && <USCard>
                <USCardContent>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="btn-group d-flex justify-content-center" role="group">
                                {!isEdit && <Button onClick={e => setIsEdit(!isEdit)}  variant='outlined' size="small">
                                    <IntlMessages id="common.edit" />
                                </Button>}
                                {isEdit && <Button onClick={e => saveRecord()}  variant='outlined' size="small">
                                    <IntlMessages id="common.save" />
                                </Button>}
                                {isEdit && <Button onClick={e => setIsEdit(!isEdit)}  variant='outlined' size="small">
                                    <IntlMessages id="common.cancel" />
                                </Button>}
                                {actions}
                            </div>
                        </div>
                    </div>
                </USCardContent>
            </USCard>}
        </>
    );
}

export default USRecordDetails;
