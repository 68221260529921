// sidebar nav links
export default {
    compnayBlock: {
        messageId : 'sidebar.general',
         links : [
            {
                "id": 0,
                "menu_title": "sidebar.userdetails",
                "menu_icon": "zmdi zmdi-view-dashboard",
                "type_multi": null,
                "new_item": false,
                "open": false,
                "child_routes": [
                    {
                        "menu_title": "common.profile",
                        "new_item": false,
                        "path": "/user"
                    },
                    {
                        "menu_title": "common.profileSettings",
                        "new_item": false,
                        "path": "/user/settings"
                    }
                ]
            },
            {
                "id": 10,
                "menu_title": "sidebar.mypurchases",
                "menu_icon": "zmdi zmdi-view-dashboard",
                "type_multi": null,
                "new_item": false,
                "open": false,
                "child_routes": [
                    {
                        "menu_title": "sidebar.myfavoriteshops",
                        "new_item": false,
                        "path": "/user/shops"
                    },
                    {
                        "menu_title": "sidebar.shopshares",
                        "new_item": false,
                        "path": "/user/shops-shares"
                    },
                    {
                        "menu_title": "sidebar.myorders",
                        "new_item": false,
                        "path": "/user/myorders"
                    },
                    {
                        "menu_title": "sidebar.producttrack",
                        "new_item": false,
                        "path": "/user/producttrack"
                    }
                ]
            },
            {
                "id": 1,
                "menu_title": "sidebar.mycareer",
                "menu_icon": "zmdi zmdi-view-dashboard",
                "type_multi": null,
                "new_item": false,
                "open": false,
                "child_routes": [
                    {
                        "menu_title": "sidebar.mycompany",
                        "new_item": false,
                        "path": "/user/mycompany"
                    },
                    {
                        "menu_title": "sidebar.myjobs",
                        "new_item": false,
                        "path": "/user/myjobs"
                    },
                    {
                        "menu_title": "sidebar.mycources",
                        "new_item": false,
                        "path": "/user/mycources"
                    },
                    {
                        "menu_title": "sidebar.mycv",
                        "new_item": false,
                        "path": "/user/mycv"
                    },
                    {
                        "menu_title": "sidebar.trackvacancy",
                        "new_item": false,
                        "path": "/user/trackvacancy"
                    },
                    {
                        "menu_title": "sidebar.myfinance",
                        "new_item": false,
                        "path": "/user/myfinance"
                    },
                    {
                        "menu_title": "sidebar.us_company-requests",
                        "new_item": false,
                        "path": "/user/requests"
                    }
                ]
            },
            {
                "id": 2,
                "menu_title": "sidebar.realestate",
                "menu_icon": "zmdi zmdi-view-dashboard",
                "type_multi": null,
                "new_item": false,
                "open": false,
                "child_routes": [
                    {
                        "menu_title": "sidebar.myhome",
                        "new_item": false,
                        "path": "/user/home"
                    },
                    {
                        "menu_title": "sidebar.wishlist",
                        "new_item": false,
                        "path": "/user/wishlist"
                    },
                    {
                        "menu_title": "sidebar.myhometasks",
                        "new_item": false,
                        "path": "/user/hometasks"
                    }
                ]
            }
        ]
    },
    category2: {
        messageId : 'sidebar.general',
        links : [
      {
         "menu_title": "sidebar.inbox",
         "menu_icon": "zmdi zmdi-email",
         "path": "/user/mail",
         "new_item": false,
         "child_routes": null
      },
      {
         "menu_title": "sidebar.chat",
         "menu_icon": "zmdi zmdi-comments",
         "path": "/user/chat",
         "new_item": false,
         "child_routes": null
      },
      {
        "menu_title": "sidebar.calendar",
        "menu_icon": "zmdi zmdi-calendar-note",
        "path": "/user/calendar",
        "new_item": false,
        "child_routes": null
       }
   ]},
   category3: {
    messageId : 'sidebar.general',
    links : [
        {
            "menu_title": "sidebar.aboutUs",
            "menu_icon": "zmdi zmdi-info",
            "path": "/about-us",
            "new_item": false,

        }
    ]}
 }
