import React, {useEffect, useState} from 'react';
import { USCard, USCardContent } from '../../../USCommonComponents/USCardComponent';
import USTable from '../../../USCommonComponents/USTable';
import { FormControl, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, CardActions } from '@mui/material';
import IntlMessages from 'Util/IntlMessages';
import Paper from '@mui/material/Paper';
import { getRequest, postFileRequest } from '../../../../Api/ApiService/Service';
import USUploadFiles from '../../../USCommonComponents/USUploadFiles/USUploadFiles';

const USCompanyProductLoad = () => {
    // state to store the selected file.

    const [selectedFile, setSelectedFile] = useState(null);
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await postFileRequest("/company/uploadedfile/previewFile", selectedFile);
            console.log(response);
            setPreviewUploadedFile(response.data.data);
            setIsModalOpen(true);
        } catch (error) {
            console.log(error)
        }
    }

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0])
    }

    const [importType, setImportType] = useState("null");
    const handleChangeImportType = (e) => {
        console.log('handleChangeImportType', e.target.value);
        setImportType(e.target.value);
    }

    const [importSupplier, setImportSupplier] = useState("null");
    const [importMappingList, setImportMappingList] = useState(null);
    const handleChangeSupplier = (e) => {
        console.log('handleChangeSupplier', e.target.value);
        setImportSupplier(e.target.value);
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [previewUploadedFile, setPreviewUploadedFile] = useState(null);

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setPreviewUploadedFile(null);
    }

    const handleSaveModal = async () => {
        try {
            console.log(selectedFile);
            const response = await postFileRequest("/company/uploadedfile/uploadFile/"+ importType +"/"+ importSupplier, selectedFile);
            console.log(response);
            setIsModalOpen(false);
            setPreviewUploadedFile(null);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await getRequest("/company/uploadedfile/settings");
            console.log(response);
            setImportMappingList(response.data.data);
        }
        fetchData();
    },[]);

    const [isUploadModalOpen, setIsUploadModalOpen] = useState();
    const handleUploadModal = () => {
        setIsUploadModalOpen(true);
    }

    return (
        <div className="data-table-wrapper">
            <USCard>
                <USCardContent>
                    <div className='row'>
                        <div className='col-sm-12 col-md-4 col-lg-4'>
                        {importMappingList && <FormControl size="small" fullWidth style={{ marginTop:'8px' }}>
                                <InputLabel id="importTypeId">{<IntlMessages id="common.import-type" />}</InputLabel>
                                <Select
                                    id="importTypeId"
                                    name="importType"
                                    value={importType}
                                    onChange={(e) => handleChangeImportType(e)}
                                    label={<IntlMessages id="common.import-type" />}
                                    size="small"
                                >
                                    <MenuItem value="null">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="unite">{<IntlMessages id="common.import-type-unite" />}</MenuItem>
                                    <MenuItem value="replace">{<IntlMessages id="common.import-type-replace" />}</MenuItem>
                                </Select>
                            </FormControl>}
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-4'>
                            {importMappingList && <FormControl size="small" fullWidth style={{ marginTop:'8px' }}>
                                <InputLabel id="importSupplierId">{<IntlMessages id="sidebar.us_suppliers" />}</InputLabel>
                                <Select
                                    id="importSupplierId"
                                    name="importSupplier"
                                    value={importSupplier}
                                    onChange={(e) => handleChangeSupplier(e)}
                                    label={<IntlMessages id="sidebar.us_suppliers" />}
                                    size="small"
                                >
                                    <MenuItem value="null">
                                        <em>None</em>
                                    </MenuItem>
                                    {importMappingList.map((item, key) => {
                                        return <MenuItem key={key} value={item.id}>{item.Name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>}
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-4 mt-10'>
                        <input type="button" onClick={handleUploadModal} value="btn"/>
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-4 mt-10'>
                            <form onSubmit={handleSubmit}>
                                <input type="file" onChange={handleFileSelect}/>
                                <input type="submit" value="Upload File"/>
                            </form>
                        </div>
                    </div>
                </USCardContent>
            </USCard>

            <USTable tableTitle="Uploaded files" objectName={'uploadedfile'} requestUrl={'company/getTableList'} expandableRows={false} />

            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="m-10"
                >
                <USCard>
                    <USCardContent>
                        {previewUploadedFile && <TableContainer component={Paper} sx={{ maxHeight: '500px' }}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        {previewUploadedFile[0].map((headerCell, indexHeader) => {
                                            return <TableCell key={indexHeader}>{headerCell}</TableCell>
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {previewUploadedFile.map((row, indexRow) => {
                                    if(indexRow != 0) {
                                        return <TableRow key={indexRow}>
                                            {row.map((cell, index) => (
                                                <TableCell component="th" scope="row">
                                                    {cell}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    }
                                })}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                    </USCardContent>
                    <CardActions className='pb-20 ml-10 justify-content-center'>
                        <Button variant='outlined' size="small" color="error" onClick={handleCloseModal}>Cancel</Button>
                        <Button variant='outlined' size="small" color="success" onClick={handleSaveModal}>Save</Button>
                    </CardActions>
                </USCard>
            </Modal>



            <Modal
                open={isUploadModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="m-10"
                >
                <USUploadFiles />
            </Modal>
        </div>
    )
};

export default USCompanyProductLoad;
