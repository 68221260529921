import React, { useState } from 'react';
import Switch from 'react-toggle-switch';
import { Helmet } from "react-helmet";
// intl messages
import IntlMessages from 'Util/IntlMessages'
import PricingBlockV1 from './PricingBlockV1';
import { postRequest } from '../../../../../Api/ApiService/Service';
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';

const USUserAccountPrice = () => {

    const [monthlyPlan, setMonthlyPlan] = useState(true);
    const [businessPlan, setBusinessPlan] = useState(30);
    const [enterprisePlan, setEnterprisePlan] = useState(59);

	// on plan change
	const onPlanChange = (isMonthly) => {
		setMonthlyPlan(!isMonthly);
		if (!isMonthly) {
            setBusinessPlan(30);
            setEnterprisePlan(59);
		} else {
            setBusinessPlan(35);
            setEnterprisePlan(70);
		}
	}
    const [isOpenNewDialog, setIsOpenNewDialog] = useState(false);
    const [selectedTariff, setSelectedTariff] = useState(null);
    const handleSelectPrice = (selectedPrice) => {
        setSelectedTariff(selectedPrice);
        setIsOpenNewDialog(true);
    }

    const [newOrgName, setNewOrgName] = useState(null);
    const [loading, setLoading] = useState(false);
    const runCreateProcess = async() => {
        setLoading(true);
        const postData = {
            name : newOrgName,
            selectedTarrif : selectedTariff
        }
        const response = await postRequest('user/createOrganization', postData);
        console.log(response);
        setLoading(false);
        window.location.href='/company';
    }

    return (
        <div className="pricing-wrapper">
            <Helmet>
                <title>Pricing</title>
                <meta name="description" content="Reactify Pricing" />
            </Helmet>

            <div className="pricing-top mb-20">
                <div className="row">
                    <div className="col-sm-12 col-md-9 col-lg-7 mx-auto text-center">
                        <h2 className="mb-10">Choose the plan that works for you.</h2>
                        <div>
                            <span>Monthly</span>
                            <Switch onClick={() => onPlanChange(monthlyPlan)} on={monthlyPlan} />
                            <span>Yearly ( get 2 month extra)</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="price-list">
                <div className="row row-eq-height">
                    <PricingBlockV1
                        planType="free"
                        type="widgets.basic"
                        color="primary"
                        description="Secure file sharing and collaboration. Ideal for small teams."
                        buttonText="widgets.startToBasic"
                        price="widgets.free"
                        users={1}
                        handleOnSelect={() => handleSelectPrice("free")}
                        features={[
                            '100 GB secure storage',
                            '2 GB file upload',
                            'Minimum 3 users, max 10 users',
                            'Granular access and controls',
                            'Desktop sync',
                            'Mobile access',
                            'Version history',
                            'SSL and at-rest encryption',
                            'Two-factor authentication',
                            'Standard business support',
                            'User management',
                            '25,000 API calls per month'
                        ]}
                    />
                    <PricingBlockV1
                        planType="premium"
                        type="widgets.pro"
                        color="warning"
                        description="More power & personalization"
                        buttonText="widgets.upgradeToPro"
                        price={businessPlan}
                        users={1}
                        handleOnSelect={() => handleSelectPrice("free")}
                        features={[
                            'Unlimited storage',
                            '5 GB file upload',
                            'Minimum 3 users, max 10 users',
                            'Includes all Starter features plus',
                            'Advanced user and security reporting',
                            'Custom branding',
                            'Version history',
                            'SSL and at-rest encryption',
                            'Two-factor authentication',
                            'Standard business support',
                            'User management',
                            '25,000 API calls per month'
                        ]}
                    />
                    <PricingBlockV1
                        planType="premium"
                        type="widgets.advanced"
                        color="info"
                        description="More power & personalization"
                        buttonText="widgets.upgradeToAdvance"
                        price={enterprisePlan}
                        users={1}
                        handleOnSelect={() => handleSelectPrice("free")}
                        features={[
                            'Unlimited storage',
                            '20 GB file upload',
                            'Minimum 13 users, max 20 users',
                            'Granular access and controls',
                            'Desktop sync',
                            'Mobile access',
                            'Version history',
                            'SSL and at-rest encryption',
                            'Two-factor authentication',
                            'Standard business support',
                            'User management',
                            '25,000 API calls per month'
                        ]}
                    />
                </div>
            </div>
            <Dialog open={isOpenNewDialog} onClose={() => setIsOpenNewDialog(false)}>
                <DialogTitle>Create New Organization</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter your new Organization name
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="orgName"
                        label="Organization Name"
                        type="email"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setNewOrgName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={() => setIsOpenNewDialog(false)}>Cancel</Button>
                    <Button variant="contained" color="success"onClick={runCreateProcess}>Create</Button>
                </DialogActions>
            </Dialog>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 99999 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default USUserAccountPrice;
