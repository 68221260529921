/**
 * Horizontal App
 */
 import React from 'react';
 import {LoggedForCompany} from '../../../helpers/RouteHelper';
 // app default layout
import USContainerCompanyLayout from './USContainerSalesConsoleLayout';
import Pages from '../Pages';
import { useHistory } from 'react-router-dom';

function USSalesConsoleLayout(props) {
    const navigate = useHistory();
    console.log(navigate);
    return (
        <USContainerCompanyLayout >
            <LoggedForCompany path="/sales" component={Pages} token={localStorage.getItem('token')} location={navigate.location}/>
        </USContainerCompanyLayout >
    );
}

export default USSalesConsoleLayout;
