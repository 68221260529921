/**
 * Data Table
 */
import { Button, CardHeader } from '@mui/material';
import React, { useState } from 'react';
 // intl messages
import IntlMessages from 'Util/IntlMessages';
import { USCard, USCardContent } from '../../../USCommonComponents/USCardComponent';
import USTable from '../../../USCommonComponents/USTable';
import USUserAccountPrice from './USUserCompanyComponents/USUserAccountPrice';

 function USUserCompany() {
    const [selectPrice, setSelectPrice] = useState(false);
    const [refreshItems, setRefreshItems] = useState(null);
    const handleNewAction = () => {

    }

    return (
        <USCard>
            <CardHeader
                action={
                    <div>
                        {!selectPrice && <Button variant="outlined" onClick={(e) => {e.preventDefault(); setSelectPrice(true)}}>New</Button>}
                        {selectPrice && <Button variant="outlined" onClick={(e) => {e.preventDefault(); setSelectPrice(false)}}>Cancel</Button>}
                    </div>
                }
                title={<IntlMessages id="account.table-name" />}
                className="ml-10 mr-10"
            />
            <USCardContent>
                {selectPrice && <USUserAccountPrice />}
                {!selectPrice && <USTable refres={refreshItems} objectName={'account'} requestUrl={'user/list/'} expandableRows={false} hideToolbar={false} hidePagination={false}/>}
            </USCardContent>
        </USCard>
    );
 }

 export default USUserCompany;
