import USTable from "../../../../USCommonComponents/USTable";

function Certificates({employeeID}) {
    return (
        <USTable tableTitle="Certificates" parentObjectId={employeeID} parentFieldName="EmployeeId" objectName="certificate" requestUrl={'company/child/'} expandableRows={false} handleNew={true}/>
    );
}

export default Certificates;

