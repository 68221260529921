import { useState } from "react";
import USFullScreenDialog from "../../../USCommonComponents/USFullScreenDialog";
import USClientsManagement from "../../../USCompanyComponents/Pages/USClientsManagement";

export default function SalesConsole() {

    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenOrderDetails = () => {
        setOpenDialog(true);
    };

    return (
        <>
            <USClientsManagement handleOpenOrderDetails={handleOpenOrderDetails}/>
            <USFullScreenDialog isOpen={openDialog}/>
        </>
    )
}
