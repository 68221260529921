import { Button, CardHeader, Stack, Table, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { USCard, USCardContent } from "../../../USCommonComponents/USCardComponent";
import { useParams } from 'react-router-dom';
import USObjectManagerLayout from "./USObjectManagerLayout";
import USIOSSwitch from "../../../USCommonComponents/USIOSSwitch";
import { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../../../Api/ApiService/Service";
import { addNotification } from "../../../../reduxSettings/reduxSlices/NotificationSlice";
import { useDispatch } from "react-redux";
import USObjectLayoutEdit from "./USObjectLayoutEdit";

export default function USObjectLayoutDetails() {
    const dispatch = useDispatch();
    const { layoutName, objectName } = useParams();
    const [selectedLayout, setSelecetdLayout] = useState(null);
    const [isHeaderLayout, setIsHeaderLayout] = useState(null);

    useEffect(() => {
        fetchLayoutDetails();
    },[layoutName, objectName])

    const fetchLayoutDetails = async() => {
        try {
            const response = await getRequest('company/layout/' + objectName +'/' + layoutName);
            console.log(response.data.data.layout);
            const responseData = response.data.data.layout;
            if(responseData.hasOwnProperty("sections")) {
                setIsHeaderLayout(false);
            } else {
                setIsHeaderLayout(true);
            }
            setSelecetdLayout(response.data.data.layout);
        } catch (error) {
            console.log('fetch layout details error' ,error)
        }
    }

    const handleUpdateLayout = (sectionIndex, layoutSide, fieldIndex, fieldName, value) => {
        const updatedLayoutValues = JSON.parse(JSON.stringify(selectedLayout));
        updatedLayoutValues.sections[sectionIndex][layoutSide][fieldIndex][fieldName] = value;
        setSelecetdLayout(updatedLayoutValues);
    }

    const handleUpdateTableHeaderFields = (index, valueName, value) => {
        const updatedLayoutValues = JSON.parse(JSON.stringify(selectedLayout));
        updatedLayoutValues[index][valueName] = value;
        setSelecetdLayout(updatedLayoutValues);
    }

    const updateLayoutDetails = (valueName, value) => {
        setSelecetdLayout(existingValue => ({
            ...existingValue,
            [valueName] : value
        }));
    }

    const saveLayout = async () => {
        try {
            const response = await postRequest('company/layout/' + objectName +'/' + layoutName , selectedLayout);
            console.log('saveLayout response', response);
            dispatch(addNotification({type: "info", message: "Layout Updated"}));
        } catch(error) {
            console.log('saveLayout', error);
        }
    }

    const [isNewSection, setIsNewSection] = useState(false);
    const [newSectioName, setNewSectionName] = useState('');
    const newSection = (sectionName) => {
        console.log('addSection', sectionName);
        setIsNewSection(true);
    }

    const saveSection = () => {
        const updatedLayoutValues = JSON.parse(JSON.stringify(selectedLayout));
        const section = {"sectionName": newSectioName,"style": "columns","leftSideFields": [],"rightSideFields": []}
        updatedLayoutValues.sections.push(section);
        //console.log('updatedLayoutValues', updatedLayoutValues);
        setSelecetdLayout(updatedLayoutValues);
        setIsNewSection(false);
    }

    const cancelSection = () => {
        setIsNewSection(false);
    }

    return (
        <USObjectManagerLayout objectName={objectName}>
            {!isHeaderLayout && <USObjectLayoutEdit />}
            {isHeaderLayout && <USCard>
                    <CardHeader
                        action={
                            <div style={{ justifyContent:"space-between" }}>
                                <Button variant="outlined" size="small" onClick={saveLayout}>Save</Button>
                            </div>
                        }
                        title="Layout"
                        subheader="Field Layout"
                    />
                    <USCardContent>
                                <div className="bg-primary text-white">
                                    Table Columns Layout
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="table-responsive">
                                            <div className="unseen">
                                                <Table size="small">
                                                    <thead>
                                                        <tr className="bg-primary text-white">
                                                            <th>Field Label</th>
                                                            <th>Field Name</th>
                                                            <th>Field Type</th>
                                                            <th>Reference Object</th>
                                                            <th>Is Active</th>
                                                            <th>Order</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {selectedLayout && selectedLayout.map((headerLayoutItem, headerItemKey) => (
                                                        <TableRow key={headerItemKey}>
                                                            <TableCell>
                                                                <Typography>{headerLayoutItem.fieldLabel}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography>{headerLayoutItem.fieldName}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography>{headerLayoutItem.fieldType}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography>{headerLayoutItem.referenceObject}</Typography>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                <USIOSSwitch name="isActive" onChange={(e) => handleUpdateTableHeaderFields(headerItemKey, "isAvailable", !headerLayoutItem.isAvailable) } checked={headerLayoutItem.isAvailable} />
                                                            </TableCell>    
                                                            <TableCell>
                                                                <TextField
                                                                    id="standard-number"
                                                                    label="Order"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    variant="standard"
                                                                    value={headerItemKey}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </USCardContent>
                </USCard>}
            {/* {selectedLayout && <div>
                <USCard>
                    <CardHeader
                        action={
                            <div style={{display: 'flex', justifyContent:"space-between" }}>
                                <Button variant="outlined" size="small">Security Settings</Button>
                                <Button variant="outlined" size="small">Access</Button>
                                <Button variant="outlined" size="small">New</Button>
                            </div>
                        }
                        title="Details"
                        subheader=""
                    />
                    <USCardContent>
                        <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <TextField
                                    id="standard-number"
                                    label="API Name"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="standard"
                                />
                                <TextField
                                    id="standard-number"
                                    label="Label"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="standard"
                                    value={selectedLayout?.page_label}
                                />
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6">
                                <TextField
                                    id="standard-number"
                                    label="Description"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="standard"
                                />
                                <Stack direction="row" spacing={2} alignItems="left" >
                                    <Typography>Active</Typography>
                                    <USIOSSwitch name="isActive" onChange={(e) => updateLayoutDetails('isActive', !selectedLayout?.isActive) } checked={selectedLayout?.isActive} />
                                </Stack>
                            </div>
                        </div>
                    </USCardContent>
                </USCard>

                {isHeaderLayout && <USCard>
                    <CardHeader
                        action={
                            <div style={{ justifyContent:"space-between" }}>
                                <Button variant="outlined" size="small" onClick={saveLayout}>Save</Button>
                            </div>
                        }
                        title="Layout"
                        subheader="Field Layout"
                    />
                    <USCardContent>
                                <div className="bg-primary text-white">
                                    Table Columns Layout
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="table-responsive">
                                            <div className="unseen">
                                                <Table size="small">
                                                    <thead>
                                                        <tr className="bg-primary text-white">
                                                            <th>Field Label</th>
                                                            <th>Field Name</th>
                                                            <th>Field Type</th>
                                                            <th>Reference Object</th>
                                                            <th>Is Active</th>
                                                            <th>Order</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {selectedLayout && selectedLayout.map((headerLayoutItem, headerItemKey) => (
                                                        <TableRow key={headerItemKey}>
                                                            <TableCell>
                                                                <Typography>{headerLayoutItem.fieldLabel}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography>{headerLayoutItem.fieldName}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography>{headerLayoutItem.fieldType}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography>{headerLayoutItem.referenceObject}</Typography>
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                <USIOSSwitch name="isActive" onChange={(e) => handleUpdateTableHeaderFields(headerItemKey, "isAvailable", !headerLayoutItem.isAvailable) } checked={headerLayoutItem.isAvailable} />
                                                            </TableCell>    
                                                            <TableCell>
                                                                <TextField
                                                                    id="standard-number"
                                                                    label="Order"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    variant="standard"
                                                                    value={headerItemKey}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </USCardContent>
                </USCard>}

                {!isHeaderLayout && <USCard>
                    <CardHeader
                        action={
                            <div style={{ justifyContent:"space-between" }}>
                                {!isNewSection && <Button variant="outlined" size="small" onClick={newSection}>New Section</Button>}
                                {isNewSection && <Button variant="outlined" size="small" onClick={saveSection}>Save Section</Button>}
                                {isNewSection && <Button variant="outlined" size="small" onClick={cancelSection}>Cancel</Button>}
                                <Button variant="outlined" size="small" onClick={saveLayout}>Save</Button>
                            </div>
                        }
                        title="Layout"
                        subheader="Field Layout"
                    />
                    <USCardContent>
                        {isNewSection && <div style={{ marginBottom: '5px' }}>
                            <TextField
                                id="standard-number"
                                label="New Section Name"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                                value={newSectioName}
                                onChange={(e) => setNewSectionName(e.target.value)}
                            />
                        </div>}
                        {selectedLayout?.sections && selectedLayout?.sections.map((layoutSection, sectionKey) => (
                            <div key={sectionKey}>
                                <div key={sectionKey} className="bg-primary text-white">
                                    {layoutSection.sectionName}
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="table-responsive">
                                            <div className="unseen">
                                                <Table size="small">
                                                    <tbody>
                                                        {layoutSection?.leftSideFields && layoutSection?.leftSideFields.map((leftSectionItems, leftSectionItemsKey) => (
                                                            <TableRow key={leftSectionItemsKey}>
                                                                <TableCell>
                                                                    <Typography>{leftSectionItems.field_api_name}</Typography>
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    <USIOSSwitch name="isActive" onChange={(e) => handleUpdateLayout(sectionKey, 'leftSideFields', leftSectionItemsKey, 'show',!leftSectionItems.show) } checked={leftSectionItems.show} />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        id="standard-number"
                                                                        label="Order"
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        variant="standard"
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="table-responsive">
                                            <div className="unseen">
                                                <Table size="small">
                                                    <tbody>
                                                        {layoutSection?.rightSideFields && layoutSection?.rightSideFields.map((rightSideItems, rightSideItemsItemsKey) => (
                                                            <TableRow key={rightSideItemsItemsKey}>
                                                                <TableCell>
                                                                    <Typography>{rightSideItems.field_api_name}</Typography>
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    <USIOSSwitch name="isActive" onChange={(e) => handleUpdateLayout(sectionKey, 'rightSideFields', rightSideItemsItemsKey, 'show',!rightSideItems.show) } checked={rightSideItems.show} />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField
                                                                        id="standard-number"
                                                                        label="Order"
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        variant="standard"
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </USCardContent>
                </USCard>}

                {(!isHeaderLayout && selectedLayout?.relatedList) && selectedLayout?.relatedList.map((relatedListItem, relatedListKey) => (
                    <USCard key={relatedListKey}>
                        <CardHeader
                            title={relatedListItem.ObjectName}
                            subheader="Related List"
                        />
                        <USCardContent>
                            <div>
                                {relatedListItem.Title}
                                {relatedListItem.ParentFieldName}
                            </div>
                        </USCardContent>
                    </USCard>
                ))}

            </div>} */}
        </USObjectManagerLayout>
    )
}
