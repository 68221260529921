/**
 * Company Routes
 */
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from "react-helmet";
// async components
import {
    AsyncUSCompanyInformation, AsyncUSCompanyEmployees, AsyncUSCompanyDashboard,AsyncUSCompanyAssets, AsyncUSCompanyClients,
    AsyncUSCompanyConsumers, AsyncUSCompanySuppliers, AsyncUSCompanyProducts, AsyncUSCompanyVacancies, AsyncUSCompanyPositions, AsyncUSCompanyTasks,
    AsyncUSCompanyVacation, AsyncUSCompanyCalculations, AsyncUSCompanyCalculationSalary, AsyncUSCompanyAccountingReport, AsyncUSCompanyLearning,
    AsyncUSCompanyAchievements, AsyncUSCompanyProgress, AsyncUSCompanyCalendar, AsyncUSCompanyMainPage, AsyncUSCompanyHrReport, AsyncUSCompanyRequests,
    AsyncUSCompanyEvents, AsyncUSCompanyEmployeesDetails, AsyncUSClientsManagement
} from '../USCompanyAsyncPageLoader/USCompnayPageLoader';


import USCompanyDashboard from '../Pages/USCompanyDashboard';
import CreateProduct from './USCompanyProducts/CreateProduct';
import USClientDetails from './USCompanyClients/USClientDetails';
import USSiteBuilder from '../../USSiteBuilder';
import TestComponent from './TestFolder/TestComponent';
import USCarsList from './USCarsList';
import USCompanyStores from './USCompanyStrores';
import USCompanyCities from './USCompanyCities';
import USCompanyCurrencies from './USCompanyCurrencies';
import USCarsPartsBrands from './USCarsPartsBrands';
import USCompanyMarkup from './USCompanyMarkup';
import USCompanyProductLoad from './USCompanyProductLoad';
import USCompanySettings from './USCompanySettings';
import USEditEmailConnectSetting from './USCompanySettings/USPriceSettings/USSettingsComponents/USEditEmailConnectSetting';
import USEditEmailFilterSettings from './USCompanySettings/USPriceSettings/USSettingsComponents/USEditEmailFilterSettings';
import USEditFileMapping from './USCompanySettings/USPriceSettings/USSettingsComponents/USEditFileMapping';
import USEditFileMappingItem from './USCompanySettings/USPriceSettings/USSettingsComponents/USEditFileMappingItem';
import USRoles from './USRoles';
import USProfiles from './USProfiles';
import USCompanyEditProfile from './USProfiles/USCompanyEditProfile';
import USCompanyEditRole from './USRoles/USCompanyEditRole';
import StoreDetails from './USCompanyStrores/view/StoreDetails';
import NewSite from './USCompanyStrores/new/NewSite';
import USEditIntegration from './USCompanySettings/USPriceSettings/USSettingsComponents/USEditIntegration';
import USStoreCatalogs from './USCompanyStrores/USStoreCatalogs';
import USStoreMenu from './USCompanyStrores/USStoreMenu';
import USStoreCategories from './USCompanyStrores/USStoreCategories';
import USCatalogDetails from './USCompanyStrores/USStoreCatalogs/USCatalogDetails';
import USStoreMenuDetails from './USCompanyStrores/USStoreMenu/USStoreMenuDetails';
import USCategoryDetails from './USCompanyStrores/USStoreCategories/USCategoryDetails';
import USObjectManager from './USObjectManager';
import USObjectDetails from './USObjectManager/USObjectDetails';
import USObjectFields from './USObjectManager/USObjectFields';
import USObjectLayout from './USObjectManager/USObjectLayout';
import USObjectActions from './USObjectManager/USObjectActions';
import USObjectLimits from './USObjectManager/USObjectLimits';
import USObjectRecordType from './USObjectManager/USObjectRecordType';
import USObjectRestrictionRules from './USObjectManager/USObjectRestrictionRules';
import USObjectTrigger from './USObjectManager/USObjectTrigger';
import USObjectValidationRules from './USObjectManager/USObjectValidationRules';
import USObjectFieldDetails from './USObjectManager/USObjectFieldDetails';
import USObjectLayoutDetails from './USObjectManager/USObjectLayoutDetails';
import USCompanyAddress from './USCompanyAddress';
import EmployeeDetails from './USCompanyEmployees/EmployeeDetails';
import USViewRecord from '../../USCommonComponents/USRecordDetails/USViewRecord';
import USEditRecord from '../../USCommonComponents/USRecordDetails/USEditRecord';
import USDiscounts from './USDiscounts';
import USVauchers from './USVauchers';
import USGifts from './USGifts';
import USCompanyPost from './USCompanyPost';
import USBulkAddRelatedRecords from '../../USCommonComponents/USDetailsPage/USBulkAddRelatedRecords';
import USRecordList from '../../USCommonComponents/USRecordDetails/USRecordList';
import USManageProfile from './USProfiles/USManageProfile';
import USManageSidebar from './USProfiles/USManageSidebar';

const Pages = (props) => {
    console.log('company pages' , props);
    return  (
        <div className="content-wrapper">
            <Helmet>
                <title>Networks</title>
                <meta name="description" content="Reactify Ecommerce Shop" />
            </Helmet>
            <Switch>
                <Route exact from="/company" component={USCompanyDashboard} />
                <Route exact from="/company/account" component={USCompanyDashboard} />
                <Route exact from="/company/hr" component={AsyncUSCompanyHrReport} />
                <Route path='/company/address/:id' component={USCompanyAddress} />
                <Route path={`/company/site-builder`} component={USSiteBuilder} />
                <Route path={`/company/company-information`} component={AsyncUSCompanyInformation} />
                <Route path={`/company/employee/:id`} component={EmployeeDetails} />
                <Route path={`/company/employees`} component={AsyncUSCompanyEmployees} />
                <Route path="/company/dashboard" component={USCompanyDashboard} />
                <Route path={`/company/settings`} component={USCompanySettings} />

                <Route path="/company/ObjectManager/:objectName/ValidationRules" component={USObjectValidationRules} />
                <Route path="/company/ObjectManager/:objectName/Triggers" component={USObjectTrigger} />
                <Route path="/company/ObjectManager/:objectName/RestrictionRules" component={USObjectRestrictionRules} />
                <Route path="/company/ObjectManager/:objectName/RecordTypes" component={USObjectRecordType} />
                <Route path="/company/ObjectManager/:objectName/Limits" component={USObjectLimits} />
                <Route path="/company/ObjectManager/:objectName/Actions" component={USObjectActions} />
                <Route path="/company/ObjectManager/:objectName/Layouts/:layoutName" component={USObjectLayoutDetails} />
                <Route path="/company/ObjectManager/:objectName/Layouts" component={USObjectLayout} />
                <Route path="/company/ObjectManager/:objectName/Fields/:fieldName" component={USObjectFieldDetails} />
                <Route path="/company/ObjectManager/:objectName/Fields" component={USObjectFields} />
                <Route path="/company/ObjectManager/:objectName" component={USObjectDetails} />
                <Route path="/company/ObjectManager" component={USObjectManager} />

                <Route path={`/company/emailconnectsetting/:id`} component={USEditEmailConnectSetting} />
                <Route path={`/company/emailfiltersetting/:id`} component={USEditEmailFilterSettings} />
                <Route path={`/company/filemapping/:id`} component={USEditFileMapping} />
                <Route path={`/company/filemappingitem/:id`} component={USEditFileMappingItem} />
                <Route path={`/company/integration/:id?`} component={USEditIntegration} />
                <Route path={`/company/clients-management`} component={AsyncUSClientsManagement} />

                <Route path={`/company/uploadedfile/:id`} component={USCompanyProductLoad} />
                <Route path={`/company/loadproduct`} component={USCompanyProductLoad} />
                <Route path={`/company/store/new`} component={NewSite} />
                <Route path={`/company/productcatalog/:id`} component={USCatalogDetails} />
                <Route path={`/company/menu/:id`} component={USStoreMenuDetails} />
                <Route path={`/company/productcategory/:id`} component={USCategoryDetails} />
                <Route path={`/company/store/:id/catalogs`} component={USStoreCatalogs} />
                <Route path={`/company/store/:id/menus`} component={USStoreMenu} />
                <Route path={`/company/store/:id/categories`} component={USStoreCategories} />
                <Route path={`/company/store/:id`} component={StoreDetails} />

                <Route path={`/company/handbook/carslist`} component={USCarsList} />
                <Route path={`/company/handbook/cities`} component={USCompanyCities} />
                <Route path={`/company/handbook/currencies`} component={USCompanyCurrencies} />
                <Route path={`/company/handbook/parts-brands`} component={USCarsPartsBrands} />

                <Route path={`/company/tasks`} component={AsyncUSCompanyTasks} />
                <Route path={`/company/requests`} component={AsyncUSCompanyRequests} />
                <Route path={`/company/events`} component={AsyncUSCompanyEvents} />
                <Route path={`/company/vacation`} component={AsyncUSCompanyVacation} />
                <Route path={`/company/accounting/calculation`} component={AsyncUSCompanyCalculations} />
                <Route path={`/company/accounting/salaries`} component={AsyncUSCompanyCalculationSalary} />
                <Route path={`/company/accounting/reports`} component={AsyncUSCompanyAccountingReport} />
                <Route path={`/company/learning`} component={AsyncUSCompanyLearning} />
                <Route path={`/company/achievements`} component={AsyncUSCompanyAchievements} />
                <Route path={`/company/progress`} component={AsyncUSCompanyProgress} />
                <Route path={`/company/calendar`} component={AsyncUSCompanyCalendar} />
                <Route path="/company/general" component={AsyncUSCompanyMainPage}/>
                <Route path={`/company/hr/report`} component={AsyncUSCompanyHrReport} />
                <Route path={`/company/hr/vacancies`} component={AsyncUSCompanyVacancies} />
                <Route path={`/company/hr/positions`} component={AsyncUSCompanyPositions} />
                <Route path={`/company/profile/manage/:id`} component={USManageProfile} />
                <Route path={`/company/profile/managesidebar/:id`} component={USManageSidebar} />
                <Route path={`/company/view/profile/:id`} component={USCompanyEditProfile} />
                <Route path={`/company/roles/:id`} component={USCompanyEditRole} />
                <Route path={`/company/test`} component={USProfiles} />

                <Route path={`/company/contact/:id`} component={USClientDetails} />

                <Route path={`/company/consumer/:id`} component={AsyncUSCompanyConsumers} />
                <Route path={`/company/consumers`} component={AsyncUSCompanyConsumers} />
                <Route path={`/company/markups`} component={USCompanyMarkup} />
                <Route path={`/company/supplier/:id`} component={AsyncUSCompanySuppliers} />
                <Route path={`/company/suppliers`} component={AsyncUSCompanySuppliers} />
                <Route path={`/company/gifts`} component={USGifts} />
                <Route path={`/company/discounts`} component={USDiscounts} />
                <Route path={`/company/vouchers`} component={USVauchers} />
                <Route path={`/company/clients`} component={AsyncUSCompanyClients} />

                <Route path="/company/view/:parentObject/:relatedObject/:id" component={USBulkAddRelatedRecords}/>
                <Route path="/company/view/:objectName?/:id" component={USViewRecord}/>
                <Route path="/company/edit/:objectName?/:id?" component={USEditRecord}/>
                <Route path="/company/:objectName" component={USRecordList}/>
            </Switch>
        </div>
    );
}
export default Pages;
