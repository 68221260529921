import { Button, CardActions, CardHeader, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom"
import { getRequest, postRequest } from "../../../../../../Api/ApiService/Service";
import { USCard, USCardContent } from "../../../../../USCommonComponents/USCardComponent";
import USIOSSwitch from "../../../../../USCommonComponents/USIOSSwitch";

export default function USEditIntegration() {

    const [cardTitle, setCardTitle] = useState('');
    const location = useLocation();
    const {id} = useParams();

    const [objectFields, setObjectFields] = useState({
        'Name' : '',
        'isActive' : true,
        'isApiKey' : false,
        'HeaderKey ' : '',
        'HeaderValue ' : '',
        'Token  ' : '',
        'Login' : '',
        'Password' : ''
    });

    const handleFieldValue = e => {
        const fieldName = e.target.name;
        let fieldValue = e.target.value;
        console.log(e);
        console.log(e.target.name, e.target.checked);

        if(fieldName === "isActive" || fieldName === "isApiKey") {
            if(objectFields[fieldName]) {
                fieldValue = false;
            } else {
                fieldValue = true;
            }
        }

        //const value = e.target.value != null && e.target.value.hasOwnProperty('value') ? e.target.value.value : e.target.value ;
        setObjectFields(existingValues => ({
            // Retain the existing values
            ...existingValues,
            // update the current field
            [fieldName]: fieldValue,
        }))
    }

    useEffect(() => {
        console.log('useEffectuseEffectuseEffectuseEffectuseEffect');
        if(id) {
            setCardTitle("Edit Integration")
            fetchData(id);
        } else {
            setCardTitle("New Integration")
            console.log('location', location.state.selectedValue);
        }
    },[id])

    const fetchData = async(recId) => {
        const response = await getRequest('company/view/integration/'+recId);
        const responseData = response.data.data;
        console.log(responseData);
        setObjectFields(responseData.recordData);
    }

    const handleSave = async() => {
        const response = await postRequest('/company/edit/integration', objectFields);
        console.log(response);
    }

    const getToken = async() => {
        if(id) {
            const response = await getRequest('/comapany/getToken/'+id);
            console.log('getToken', response);
            setObjectFields(existingValues => ({
                // Retain the existing values
                ...existingValues,
                // update the current field
                ["Token"]: response,
            }))
            handleSave();
        } else {
            alert("Record doesn't exist")
        }
    }

    return (
        <USCard>
            <CardHeader
                title={cardTitle}
                className="ml-10 mr-10"
            />
            <USCardContent>
                {objectFields && <div className="row" >
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="row" >
                        <div className="col-sm-12 col-md-12 col-lg-12" >
                                <TextField id="standard-basic" label="Name" name="Name" onChange={(e) => handleFieldValue(e)} value={objectFields['Name']} variant="standard"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10" >
                                <Stack direction="row" spacing={2} alignItems="left" >
                                    <Typography>Active</Typography>
                                    <USIOSSwitch name="isActive" onChange={(e) => handleFieldValue(e) } checked={objectFields['isActive']} />
                                </Stack>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10" >
                                <Stack direction="row" spacing={2} alignItems="left" >
                                    <Typography>Use Api Key</Typography>
                                    <USIOSSwitch name="isApiKey" onChange={(e) => handleFieldValue(e) } checked={objectFields['isApiKey']} />
                                </Stack>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10" >
                                <TextField id="standard-basic" disabled={!objectFields['isApiKey']} label="Api Key" name="ApiKey" onChange={(e) => handleFieldValue(e)} value={objectFields['ApiKey']} variant="standard"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="row" style={{ display:'block' }}>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10" >
                                <TextField id="standard-basic" label="Header Key" name="HeaderKey" onChange={(e) => handleFieldValue(e)} value={objectFields['HeaderKey']} variant="standard"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10" >
                                <TextField id="standard-basic" label="Header Value" name="HeaderValue" onChange={(e) => handleFieldValue(e)} value={objectFields['HeaderValue']} variant="standard"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12  mt-10" >
                                <TextField id="standard-basic" label="Token" name="Token" onChange={(e) => handleFieldValue(e)} value={objectFields['Token']} variant="standard"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12  mt-10" >
                                <TextField id="standard-basic" disabled={objectFields['isApiKey']} label="Login" name="Login" onChange={(e) => handleFieldValue(e)} value={objectFields['Login']} variant="standard"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12  mt-10" >
                                <TextField id="standard-basic" disabled={objectFields['isApiKey']} label="Password" name="Password" onChange={(e) => handleFieldValue(e)} value={objectFields['Password']} variant="standard"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>}
            </USCardContent>
            <CardActions className='pb-20 ml-10 justify-content-center'>
                {id && <Button variant='outlined' size="small" onClick={getToken}>Get Token</Button>}
                <Button variant='outlined' size="small" color="success" onClick={handleSave}>Cancel</Button>
                <Button variant='outlined' size="small" color="success" onClick={handleSave}>Save</Button>
            </CardActions>
        </USCard>
    )

}
