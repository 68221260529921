import React from 'react';
import { styled } from '@mui/material';

const getBackgroundColor = (isDragging, isGroupedOver, authorColors) => {
  if (isDragging) {
    return authorColors.soft;
  }

  if (isGroupedOver) {
    return '#EBECF0';
  }

  return '#FFFFFF';
};

const getBorderColor = (isDragging, authorColors) =>
  isDragging ? authorColors.hard : 'transparent';

const imageSize = 40;
const borderRadius = 2;
const grid = 2;
const Container = styled('div')({
    borderRadius: `${borderRadius}px`,
    border: '2px solid transparent',
    borderColor: `${(props) => getBorderColor(props.isDragging, props.colors)}`,
    backgroundColor: `${(props) =>  getBackgroundColor(props.isDragging, props.isGroupedOver, props.colors)}`,
    boxShadow: `${({ isDragging }) => (isDragging ? `2px 2px 1px #A5ADBA` : 'none')}`,
    boxSizing: 'border-box',
    padding: `${grid}px`,
    minHeight: `${imageSize}px`,
    marginBottom: `${grid}px`,
  
    /* anchor overrides */
    color: '#091e42',

    /* flexbox */
    display: 'flex'
});
const Content = styled('div')({
  /* flex child */
  flexGrow: 1,
  /*
    Needed to wrap text in ie11
    https://stackoverflow.com/questions/35111090/why-ie11-doesnt-wrap-the-text-in-flexbox
  */
  flexBasis: '100%',
  /* flex parent */
  display: 'flex',
  flexDirection: 'column',
});

function getStyle(provided, style) {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

function USDndFieldItem(props) {
  const { quote, isDragging, isGroupedOver, provided, style, isClone, index } = props;

  return (
    <Container
      isDragging={isDragging}
      isGroupedOver={isGroupedOver}
      isClone={isClone}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getStyle(provided, style)}
      data-is-dragging={isDragging}
      data-testid={quote.field_api_name}
      data-index={index}
      aria-label={`${quote.field_label} quote ${quote.field_label}`}
    >
      <Content>
        {quote.field_label}
      </Content>
    </Container>
  );
}

export default React.memo(USDndFieldItem);
