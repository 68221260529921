import { configureStore } from '@reduxjs/toolkit';
import cartSlice from '../reduxSlices/cartSlice';
import basicSlice from '../reduxSlices/basicSlice';
import feeedbackSlice from '../reduxSlices/feedbackSlice';
import sidebarSlice from '../reduxSlices/sidebarSlice';
import carPartsSlice from '../reduxSlices/carPartsSlice';
import NotificationSlice from '../reduxSlices/NotificationSlice';
import userAuthSlice from '../reduxSlices/userAuthSlice';
import systemSlice from '../reduxSlices/systemSlice';

export const store = configureStore({
    reducer: {
        basic : basicSlice,
        cart: cartSlice,
        feedback: feeedbackSlice,
        sidebar: sidebarSlice,
        carParts: carPartsSlice,
        notification: NotificationSlice,
        auth: userAuthSlice,
        system: systemSlice
    }
})

