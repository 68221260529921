import React, {useEffect, useState} from 'react';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { saveRecord, selectObjectRecords } from '../../../Api/OrgRequests/OrgRequestHandler';
import { addNotification } from '../../../reduxSettings/reduxSlices/NotificationSlice';
import { useDispatch } from 'react-redux';
import IntlMessages from '../../../util/IntlMessages';
import { NativeSelect, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';

function USTableNew({objectName, currentAppUrl='company'}) {

    const nextRow = ">";
    const prevRow = "<";
    const dispatch = useDispatch();
    const navigate = useHistory();
    /*
        Start Table Init
    */
    const [rows, setRows] = useState(null);
    const [columns, setColumns] = useState(null);
    const [recordsCount, setRecordsCount] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [sortOrderField, setSortOrderField] = useState(null);
    const [sortOrderDirection, setSortOrderDirection] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [parentFieldName, setParentFieldName] = useState(null);
    const [parentObjectId, setParentObjectId] = useState(null);
    const [searchCondition, setSearchCondition] = useState(null);

    const [listView, setListView] = useState();
    const [selectedListView, setSelectedListView] = useState();


    const fetchData = async(selectedObjectName, selectedCurrentPage, selectedRowPerPage) => {
        console.log('selectedObjectName', selectedObjectName);
        const response = await selectObjectRecords(`/company/selectObjectRecords`,
                                                selectedObjectName,
                                                selectedCurrentPage,
                                                sortOrderField,
                                                sortOrderDirection,
                                                selectedRowPerPage,
                                                parentFieldName,
                                                parentObjectId,
                                                searchCondition,
                                                selectedListView);
        console.log('response', response);
        setListView(response?.listView);
        if(!selectedListView) {
            response?.listView?.map((listViewItem) => {
                if(listViewItem.selected) {
                    setSelectedListView(listViewItem);
                }
            });
        }
        setRecordsCount(response.count);
        setRows(response.tableData);
        setColumns(response.tableHeader);
    }

    const setPageNumber = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
    }

    const setRowPerPage = (recPerPage) => {
        setRowsPerPage(recPerPage);
    }

    const handleSearch = (searchString) => {
        if(searchString && searchString.length > 0) {
            setSearchCondition({Name:searchString});
        } else {
            setSearchCondition(null);
        }
    }

    useEffect(() => {
        if(rows && columns) {
            fetchData(objectName, currentPage, rowsPerPage);
        }
    },[rowsPerPage, currentPage,selectedListView, searchCondition]);

    useEffect(() => {
        console.log('selectedObjectName', objectName);
        fetchData(objectName, currentPage, rowsPerPage);
    },[objectName]);
    /* end init table */

    /* Update table cell */
    const [isEditCell, setIsEditCell] = useState({row: null, cell: null });
    const editTableCell = (e, fieldName, rowIndex, cellIndex) => {
        console.log('e',e);
        console.log('fieldName', rows[rowIndex][fieldName]);
        console.log('rowIndex',rowIndex);
        console.log('cellIndex',cellIndex);
        setIsEditCell({row: rowIndex, cell: cellIndex });
    }

    const changeCellValue = (newValue, rowIndex, fieldName) => {
        let changedData = [...rows];
        changedData[rowIndex][fieldName] = newValue;
        setRows(changedData);
    }

    const handleBlur = () => {
        updateRecord(rows[isEditCell.row]);
        setIsEditCell({row: null, cell: null });
    }

    const updateRecord = async (recordToUpdate) => {
        const response = await saveRecord(objectName,recordToUpdate.id,recordToUpdate);
        console.log('updateRecord', response);
        dispatch(addNotification({"type" : "success","title":"Record Updated", "message":  "Selected record updated successfully"}));
    }


    return (
        <div className="row">
            {(rows && columns) && <div className="col-md-offset-1 col-md-12">
                <div className="panel">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col col-sm-3 col-xs-12">
                                <Stack direction="row">
                                    <h4 className="title">Data <span>List</span></h4>
                                    <NativeSelect
                                        defaultValue={selectedListView}
                                        onChange={(e) => setSelectedListView(e.target.value)}
                                    >
                                        {listView && listView.map((listViewItem, indexKey) => (
                                            <option key={indexKey} value={listViewItem.ApiName} >{listViewItem.Name}</option>
                                        ))}
                                    </NativeSelect>
                                </Stack>
                            </div>
                            <div className="col-sm-9 col-xs-12 text-right">
                                <div className="btn_group">
                                    <input type="text" className="form-control" placeholder="Search" onChange={(e) => handleSearch(e.target.value)}></input>
                                    <button className="btn btn-default" title="Reload"><i className="fa fa-sync-alt"></i></button>
                                    <button className="btn btn-default" title="Pdf"><i className="fa fa-file-pdf"></i></button>
                                    <button className="btn btn-default" title="Excel"><i className="fas fa-file-excel"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    {columns.map((headreItem, headerItemIndex) => (
                                        <th key={headerItemIndex}><IntlMessages id={headreItem.fieldLabel}/></th>
                                    ))}
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((rowItem, rowItemIndex) => (
                                    <tr key={rowItemIndex}>
                                        {columns.map((cellItem, cellItemIndex) => (
                                            <td key={cellItemIndex} onDoubleClick={(e) => editTableCell(e, cellItem.fieldName, rowItemIndex, cellItemIndex)}>
                                                {(isEditCell.row == rowItemIndex && isEditCell.cell == cellItemIndex) ?
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" value={rowItem[cellItem.fieldName]} onChange={(e) => changeCellValue(e.target.value, rowItemIndex, cellItem.fieldName)} onBlur={handleBlur}></input>
                                                    </div>:
                                                    rowItem[cellItem.fieldName]
                                                }
                                            </td>
                                        ))}
                                        <td>
                                            <ul className="action-list">
                                                <li><ModeEditOutlineOutlinedIcon onClick={() => navigate.push(`/${currentAppUrl}/view/${objectName}/${rowItem['id']}`)}/></li>
                                                <li><DeleteForeverOutlinedIcon /></li>
                                            </ul>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="panel-footer">
                        <div className="row">
                            <div className="col col-sm-6 col-xs-6">showing {rowsPerPage} out of {recordsCount} entries</div>
                            <div className="col-sm-6 col-xs-6">
                                <ul className="pagination hidden-xs pull-right">
                                    <li><a href="#" onClick={(e) => setPageNumber(e, currentPage-1)}>{prevRow}</a></li>
                                    <li className="active"><a href="#" onClick={(e) => setPageNumber(e, 0)}>1</a></li>
                                    <li><a href="#" onClick={(e) => setPageNumber(e, 1)}>2</a></li>
                                    <li><a href="#" onClick={(e) => setPageNumber(e, 2)}>3</a></li>
                                    <li><a href="#" onClick={(e) => setPageNumber(e, 3)}>4</a></li>
                                    <li><a href="#" onClick={(e) => setPageNumber(e, 4)}>5</a></li>
                                    <li><a href="#" onClick={(e) => setPageNumber(e, currentPage+1)}>{nextRow}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );

}

export default USTableNew;
