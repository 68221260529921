import { CardActionArea } from "@mui/material";
import { useState } from "react";

import LaptopChromebookOutlinedIcon from '@mui/icons-material/LaptopChromebookOutlined';
import SettingsSystemDaydreamOutlinedIcon from '@mui/icons-material/SettingsSystemDaydreamOutlined';
import MissedVideoCallOutlinedIcon from '@mui/icons-material/MissedVideoCallOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import WifiCalling3OutlinedIcon from '@mui/icons-material/WifiCalling3Outlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import { USCard, USCardContent } from "../../../USCommonComponents/USCardComponent";

export default function USPablicModulesCards() {

    const [productList, setProductList] = useState([
        {name : 'Оффлайн офис', icon: <LaptopChromebookOutlinedIcon />},
        {name : 'CRM', icon: <SettingsSystemDaydreamOutlinedIcon />},
        { name :'Video calls', icon: <MissedVideoCallOutlinedIcon/>},
        { name :'Tasks', icon: <AssignmentOutlinedIcon />},
        { name :'Call-center', icon: <WifiCalling3OutlinedIcon/>},
        { name :'Sites', icon: <LanguageOutlinedIcon/>},
        { name :'E-shop', icon: <AddShoppingCartOutlinedIcon />}
    ]);

    return (
        <div className="container">
            {productList && <div className="row">
                {productList.map((item) =>(
                    <div key={item.name} className="col-sm-12 col-md-2 col-lg-2" >
                        <USCard >
                            <CardActionArea >
                                <USCardContent >
                                    <div>{item.icon}</div>
                                    <div>{item.name}</div>
                                </USCardContent>
                            </CardActionArea>
                        </USCard>
                    </div>
                ))}
            </div>}
        </div>
    )
}
