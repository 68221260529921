import { Button, Table, TableCell, TableRow, CardHeader, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions } from "@mui/material";
import { USCard, USCardContent } from "../../../USCommonComponents/USCardComponent";
import IntlMessages from 'Util/IntlMessages';
import { useParams, useHistory } from 'react-router-dom';
import USObjectManagerLayout from "./USObjectManagerLayout";
import { useEffect, useState } from "react";
import { getRequest } from "../../../../Api/ApiService/Service";

export default function USObjectLayout() {
    const { objectName } = useParams();
    const navigate = useHistory();

    useEffect(() => {
        fetchLayouts(objectName);
    },[objectName])

    const fetchLayouts = async(newObjectName) => {
        try {
            const response = await getRequest('company/list/layout/' + newObjectName);
            console.log(response);
            setLayoutData(response.data.data);
        } catch(error) {
            console.log('fetch layout error' , error);
        }
    }

    const [layoutData, setLayoutData] = useState([]);

    const handleGenerateLayouts = async () => {
        const response = await getRequest('company/generatelayout/' + objectName);
        console.log(response);
        fetchLayouts(objectName);
    }


    const [newLayoutDialog, setNewLayoutDialog] = useState(false);
    const [newLayoutName, setNewLayoutName] = useState('');
    const createLayout = async() => {
        const response = await getRequest(`company/createLayout/${objectName}/${newLayoutName}`);
        console.log(response);
        setNewLayoutDialog(false);
        fetchLayouts(objectName);
    }

    return (
        <>
            <USObjectManagerLayout objectName={objectName}>
                <USCard>
                    <CardHeader
                        action={
                            <div>
                                <Button variant="outlined" size="small" onClick={handleGenerateLayouts}>Generate Layouts</Button>
                                <Button variant="outlined" size="small" onClick={() => setNewLayoutDialog(true)}>Create Layouts</Button>
                            </div>
                        }
                        title="Layouts"
                        subheader=""
                    />
                    <USCardContent>
                        <div className="table-responsive">
                            <div className="unseen">
                                <Table size="small">
                                    <thead>
                                        <tr className="bg-primary text-white">
                                            <th ><IntlMessages id="common.label" /></th>
                                            <th><IntlMessages id="common.apiname" /></th>
                                            <th><IntlMessages id="common.type" /></th>
                                            <th><IntlMessages id="common.description" /></th>
                                            <th><IntlMessages id="common.indexed" /></th>
                                            <th><IntlMessages id="widgets.action" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {layoutData && layoutData.map((dataItem, key) => {
                                            return <TableRow key={key}>
                                                        <TableCell>
                                                            <Button onClick={() => navigate.push('/company/ObjectManager/' + objectName + '/Layouts/' + dataItem.apiname)} variant="text">{dataItem.label}</Button>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {dataItem.apiname}
                                                        </TableCell>
                                                        <TableCell>
                                                            {dataItem.type}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {dataItem.description}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {dataItem.lastmodified}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {dataItem.deployed}
                                                        </TableCell>
                                                    </TableRow>
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </USCardContent>
                </USCard>
            </USObjectManagerLayout>
            <Dialog
                open={newLayoutDialog}
                onClose={() => setNewLayoutDialog(false)}
                style={{ minWidth:'350px' }}
            >
                <DialogTitle>New Layout</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please input new layout name
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="newLayoutName"
                        name="newLayoutName"
                        label="New Layout Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setNewLayoutName(e.target.value)}
                        value={newLayoutName}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setNewLayoutDialog(false)}>Cancel</Button>
                    <Button onClick={() => createLayout()}>Create</Button>
                </DialogActions>
            </Dialog>
        </>

    )
}
