import React from "react";
import { IconButton, InputAdornment, Tooltip, Popover, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function USInputHelperIcon(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <InputAdornment position="end">
            <Tooltip title="Help">
                <IconButton onClick={(e) => handleClick(e)}><HelpOutlineIcon /></IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2 }}>{props.text}</Typography>
            </Popover>
        </InputAdornment>
    );

}

export default USInputHelperIcon;
