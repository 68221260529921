import { getRequest, postGetFileRequest, postRequest } from "../ApiService/Service";

export async function getContactOrLeadByPhone(phoneNumber) {
    try{
        const response = await getRequest('company/client/getClientByPhone/'+phoneNumber);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function getContactOrLeadById(contactId) {
    try {
        const response = await getRequest('company/client/'+contactId);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function getContactOrLeadByContract(contractNumber) {
    try{
        const response = await getRequest('company/client/getClientByOrderNumber/'+contractNumber);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function getProductByFieldNameAndValue(fieldName, fieldValue) {
    try{
        const response = await getRequest(`/company/product/${fieldName}/${fieldValue}`);
        if(response.data.success) {
            return response.data.data;
        }
        return [];
    } catch(error) {
        return error;
    }
}

export async function getCompanyProductById(recordId) {
    try{
        const response = await getRequest(`/company/sales/companyProducts/${recordId}`);
        console.log('getCompanyProductById',response);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function getOpportunityProductById(recordId) {
    try{
        const response = await getRequest(`/company/sales/opportunityProduct/${recordId}`);
        console.log('opportunityproduct',response);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function saveOppProduct(params) {
    try{
        const response = await postRequest(`/company/sales/saveOpportunityProduct`, params);
        console.log('opportunityproduct',response);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function getCompanyProductsByFieldNameAndValue(fieldName, fieldValue) {
    try{
        const response = await getRequest(`/company/companyproduct/${fieldName}/${fieldValue}`);
        if(response.data.success) {
            return response.data.data;
        }
        return [];
    } catch(error) {
        return error;
    }
}

export async function getRecordById(objectName, recordId) {
    try{
        const response = await getRequest(`/company/view/${objectName}/${recordId}`);
        console.log('getProductByFieldNameAndValue',response);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function getAllRelatedRecords(objectName, parentRecordId, childObjectName) {
    try{
        const response = await postRequest(`/company/allChild`, {"objectName" : objectName , "parentObjectId":parentRecordId, "childObjectName": childObjectName });
        console.log('getAllRelatedRecords',response);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function getFilteredRecords(relatedObject, selectedField, filterParams) {
    try{
        const response = await postRequest(`/company/getFilteredRecords/${relatedObject}`,  filterParams != 'all' ? [{[selectedField] : filterParams}] : []);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function getRecordsForBulkCreate(params) {
    try{
        const response = await postRequest(`/company/getRecordsBulkCreate`, params);
        console.log('getRecordsForBulkCreate',response);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function getContractDocument(params) {
    try{
        const response = await postGetFileRequest(`/company/sales/getContractTemplate`,params);
        console.log('updateRecord',response);
        return response.data.data;
    } catch(error) {
        return error;
    }
}


export async function createContract(params) {
    try{
        const response = await postRequest(`/company/sales/createContract`,params);
        console.log('updateRecord',response);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function updateSalesContact(params) {
    try{
        const response = await postRequest(`/company/sales/saveContactInformation`, params);
        console.log('updateRecord',response);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function updateRecord(objectName, recordId, objectToSave) {
    try{
        const response = await postRequest(`/company/edit/${objectName}/${recordId}`,objectToSave);
        console.log('updateRecord',response);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function saveRecord(objectName, id, objectFields) {
    try{
        const response = await postRequest('/company/edit/'+objectName +  '/' + id, objectFields);
        return response;
    } catch(error) {
        return error;
    }
}

export async function deleteRecord(objectName, id) {
    try{
        const response = await getRequest('/company/delete/'+objectName +  '/' + id);
        return response;
    } catch(error) {
        return error;
    }
}

export async function saveNewCall(data) {
    try{
        const response = await postRequest(`/company/sales/saveNewCall`,data);
        console.log('saveNewRequestInfo',response);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function saveNewLead(data) {
    try{
        const response = await postRequest(`/company/sales/saveNewLead`,data);
        console.log('saveNewRequestInfo',response);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function getPartCategory() {
    try{
        const response = await getRequest(`/company/sales/getPartCategories`);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function getPartSubCategory(id) {
    try{
        const response = await getRequest(`/company/sales/getPartSubCategories/${id}`);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function saveBulkRecords(selectedRecords, relatedObject) {
    try{
        const response = await postRequest(`/company/bulkCreateUpdate/${relatedObject}`, selectedRecords);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function getAdditionalProductParts(companyProductId, selectedType) {
    try{
        const response = await getRequest(`/company/sales/getAdditionalProductParts/${companyProductId}/${selectedType}`);
        console.log('getAdditionalProductParts',response);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function getObjectFieldList(objectName) {
    try{
        const response = await getRequest('/company/objectFields/' + objectName);
        console.log('getAdditionalProductParts',response);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function getReferenceObjectFieldList(objectName, parentObjectName) {
    try{
        const response = await getRequest(`/company/referenceObjects/${parentObjectName}/${objectName}`);
        console.log('getReferenceObjectFieldList',response);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function getObjectFieldValues(selectedReference, selectedReferenceField) {
    try {
        console.log(`/company/referenceFieldValues/${selectedReference}/${selectedReferenceField}`);
        const response = await getRequest(`/company/referenceFieldValues/${selectedReference}/${selectedReferenceField}`);
        console.log('getObjectFieldValues',response);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function getSortFieldValues(objectName, fieldName) {
    try{
        const response = await getRequest(`/getDropdownItems/${objectName}/${fieldName}`);
        console.log('getSortFieldValues',response);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function getLayout(objectName) {
    try{
        const response = await getRequest(`/company/layout/${objectName}`);
        console.log('getLayout',response);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function getLayoutByName(layoutName, objectName) {
    try{
        const response = await getRequest(`/company/layout/${objectName}/${layoutName}`);
        console.log('getLayout',response);
        return response.data.data;
    } catch(error) {
        return error;
    }
}

export async function getTableData(requestUrl, objectName, currentPage, sortOrderField, sortOrderDirection, rowsPerPage, parentFieldName, parentObjectId, searchCondition, listViewApiName) {
    try{
        let searchParams = {};
        objectName = objectName ? objectName : null;
        parentFieldName = parentFieldName ? parentFieldName : null;
        parentObjectId = parentObjectId ? parentObjectId : null;
        searchParams['objectName'] =  objectName;
        searchParams['currentPage'] =  currentPage;
        searchParams['sortOrderField'] =  sortOrderField;
        searchParams['sortOrderDirection'] =  sortOrderDirection;
        searchParams['rowsPerPage'] =  rowsPerPage;
        searchParams['parentFieldName'] =  parentFieldName;
        searchParams['parentObjectId'] =  parentObjectId;
        searchParams['searchCondition'] = searchCondition;
        searchParams['listViewApiName'] = listViewApiName;
        const response = await postRequest(requestUrl, searchParams);
        console.log('response', response);
        return response?.data?.data;
    } catch(error) {
        return error;
    }
}
