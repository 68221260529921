/**
 * AsyncComponent
 * Code Splitting Component / Server Side Rendering
 */
 import React from 'react';
 import Loadable from 'react-loadable';

 // rct page loader
 import RctPageLoader from 'Components/USCommonComponents/USPageLoader/USPageLoader';

 // ecommerce dashboard
 const AsyncUSCompanyMainPage = Loadable({
    loader: () => import("../Pages/USCompanyMainPage"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyHrReport = Loadable({
    loader: () => import("../Pages/USCompanyHrReport"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyInformation = Loadable({
    loader: () => import("../Pages/USCompanyInformation"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyEmployees = Loadable({
    loader: () => import("../Pages/USCompanyEmployees"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyEmployeesDetails = Loadable({
    loader: () => import("../Pages/USCompanyEmployees/EmployeeDetails"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyDashboard = Loadable({
    loader: () => import("../Pages/USCompanyDashboard"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyAssets = Loadable({
    loader: () => import("../Pages/USCompanyAssets"),
    loading: () => <RctPageLoader />,
 });
 const AsyncUSCompanyAssetDetails = Loadable({
    loader: () => import("../Pages/USCompanyAssets/AssetView"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSClientsManagement = Loadable({
    loader: () => import("../Pages/USClientsManagement"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyClients = Loadable({
    loader: () => import("../Pages/USCompanyClients"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyConsumers = Loadable({
    loader: () => import("../Pages/USCompanyConsumers"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanySuppliers = Loadable({
    loader: () => import("../Pages/USCompanySuppliers"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyProducts = Loadable({
    loader: () => import("../Pages/USCompanyProducts"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyVacancies = Loadable({
    loader: () => import("../Pages/USCompanyVacancies"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyPositions = Loadable({
    loader: () => import("../Pages/USCompanyPositions"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyTasks = Loadable({
    loader: () => import("../Pages/USCompanyTasks"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyVacation = Loadable({
    loader: () => import("../Pages/USCompanyVacation"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyCalculations = Loadable({
    loader: () => import("../Pages/USCompanyCalculations"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyCalculationSalary = Loadable({
    loader: () => import("../Pages/USCompanyCalculationSalary"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyAccountingReport = Loadable({
    loader: () => import("../Pages/USCompanyAccountingReport"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyLearning = Loadable({
    loader: () => import("../Pages/USCompanyLearning"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyAchievements = Loadable({
    loader: () => import("../Pages/USCompanyAchievements"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyProgress = Loadable({
    loader: () => import("../Pages/USCompanyProgress"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyCalendar = Loadable({
    loader: () => import("../Pages/USCompanyCalendar"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyRequests = Loadable({
    loader: () => import("../Pages/USCompanyRequests"),
    loading: () => <RctPageLoader />,
 });

 const AsyncUSCompanyEvents = Loadable({
    loader: () => import("../Pages/USCompanyEvents"),
    loading: () => <RctPageLoader />,
 });


 export {
    AsyncUSCompanyInformation, AsyncUSCompanyEmployees, AsyncUSCompanyEmployeesDetails, AsyncUSCompanyDashboard,AsyncUSCompanyAssets, AsyncUSCompanyAssetDetails, AsyncUSCompanyClients,
    AsyncUSCompanyConsumers, AsyncUSCompanySuppliers, AsyncUSCompanyProducts, AsyncUSCompanyVacancies, AsyncUSCompanyPositions, AsyncUSCompanyTasks,
    AsyncUSCompanyVacation, AsyncUSCompanyCalculations, AsyncUSCompanyCalculationSalary, AsyncUSCompanyAccountingReport, AsyncUSCompanyLearning,
    AsyncUSCompanyAchievements, AsyncUSCompanyProgress, AsyncUSCompanyCalendar, AsyncUSCompanyMainPage, AsyncUSCompanyHrReport,AsyncUSCompanyRequests,
    AsyncUSCompanyEvents, AsyncUSClientsManagement
 };
