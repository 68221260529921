import { Button, CardActions, CardHeader, FormControl, InputLabel, MenuItem, Modal, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getRequest, postFileRequest } from "../../../../../../Api/ApiService/Service";
import { addNotification } from "../../../../../../reduxSettings/reduxSlices/NotificationSlice";
import { USCard, USCardContent } from "../../../../../USCommonComponents/USCardComponent";


function USUseFileForMapping({isModalOpen, saveModal, closeModal}) {

    const dispatch = useDispatch();
    const [selectMapObject, setSelectMapObject] = useState('');
    const [objectFields, setObjectFields] = useState([]);
    const handleSelectObject = async (e) => {
        console.log(e.target.value);
        setSelectMapObject(e.target.value);
        const response = await getRequest('/company/getMetadata/' + e.target.value);
        console.log(response);
        setObjectFields(response.data.data);
    }

    const [previewUploadedFile, setPreviewUploadedFile] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0])
    }

    const [headerList, setHeaderList] = useState([]);
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if(selectedFile) {
                const response = await postFileRequest("/company/uploadedfile/previewFile", selectedFile);
                console.log(response);
                const fileHeaderRow = response?.data?.data ? response?.data?.data : [];
                console.log(':::', fileHeaderRow);

                if(fileHeaderRow.length > 0) {
                    fileHeaderRow[0].map((elem, item) => {
                        headerList.push({[`${item}`] : elem});
                        handleChangeMappingField(item , {"target" : {"value" : 'none'}});
                    });
                }
                setPreviewUploadedFile(fileHeaderRow);
            } else {
                console.log('file not selected');
                dispatch(addNotification({"type" : "error","title":"Upload Error", "message":  "File Not Selected"}));
            }
        } catch (error) {
            console.log(error)
        }
    }

    const [mergedFields, setMergedFields] = useState({
    });

    const handleChangeMappingField = (indexRow, event) => {
        setMergedFields(existingValues => ({
            // Retain the existing values
            ...existingValues,
            // update the current field
            [`${indexRow}`]: event?.target?.value,
        }))
    }

    const handleSaveMapping = () => {
        let resultArray = [];
        for (const fieldItem in mergedFields) {
            if (mergedFields.hasOwnProperty(fieldItem)) {
                resultArray.push({
                    "columnFromIndex" : fieldItem,
                    "fieldNameFrom" : previewUploadedFile[0][fieldItem],
                    "fieldNameTo" : mergedFields[fieldItem]

                });
            }
        }
        saveModal({ 'mappingData' : resultArray})
    }

    return (
        <Modal
            open={isModalOpen}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="m-10 w-80"
            style={{ left: '10%', overflow:'scroll'}}
            >
            <USCard>
                <CardHeader
                    title="Mew Mapping"
                    className="ml-10 mr-10"
                />
                <USCardContent>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12" >
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6" >
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel id="demo-simple-select-standard-label">Select Object</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={selectMapObject}
                                            onChange={handleSelectObject}
                                            label="Select Object"
                                        >
                                            <MenuItem value="none">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="product">Product</MenuItem>
                                            <MenuItem value="ProductPrice">Product Price</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6" >
                                    <Stack direction="row" spacing={1}>
                                        <input type="file" onChange={handleFileSelect}/>
                                        <Button variant='outlined' size="small" onClick={handleSubmit} >Upload File</Button>
                                    </Stack>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 mt-10" >
                            {headerList?.length > 0 && <TableContainer component={Paper} >
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Importrd file column name</TableCell>
                                            <TableCell>Object field name</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {headerList.map((headerValue, keyItem) => {
                                                return <TableRow key={keyItem}>
                                                        <TableCell component="th" scope="row">
                                                            <div className="col-sm-12 col-md-2 col-lg-2" >
                                                                <Typography variant="button" id="standard-basic" key={keyItem} label="Standard">{headerValue[keyItem]}</Typography>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} size="small">
                                                            <Select
                                                                labelId="demo-simple-select-standard-label"
                                                                id="demo-simple-select-standard"
                                                                value={setMergedFields[keyItem]}
                                                                onChange={(e) => handleChangeMappingField(keyItem, e)}
                                                            >
                                                                <MenuItem value="none">
                                                                    <em>None</em>
                                                                </MenuItem>
                                                                {objectFields && objectFields.map((filedName, keyItem) => {
                                                                    return <MenuItem key={keyItem} name={keyItem} value={objectFields[keyItem]} >{objectFields[keyItem]}</MenuItem>
                                                                })}
                                                            </Select>
                                                            </FormControl>
                                                        </TableCell>
                                                </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>}
                        </div>
                    </div>
                    <div className="row mt-20">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            {previewUploadedFile &&
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="caption table">
                                        <TableHead>
                                            <TableRow>
                                                {previewUploadedFile[0].map((headerCell, indexHeader) => {
                                                    return <TableCell key={indexHeader}>{headerCell}</TableCell>
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {previewUploadedFile.map((row, indexRow) => {
                                            if(indexRow != 0) {
                                                return <TableRow key={indexRow}>
                                                    {row.map((cell, index) => (
                                                        <TableCell component="th" scope="row">
                                                            {cell}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            }
                                        })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </div>
                    </div>
                </USCardContent>
                <CardActions className='pb-20 ml-10' sx={{ justifyContent: "center" }}>
                    <Button variant='outlined' size="small" color="error" onClick={closeModal}>Cancel</Button>
                    <Button variant='outlined' size="small" color="success" onClick={() => handleSaveMapping()}>Save</Button>
                </CardActions>
            </USCard>
        </Modal>
    )
}
export default USUseFileForMapping;
