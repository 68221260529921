/**
 * Rct Collapsible Card
 */
import React, { useEffect, useState } from 'react';
import { Collapse, Badge, Button } from 'reactstrap';
import classnames from 'classnames';
// rct section loader
import RctSectionLoader from '../USSectionLoader/USSectionLoader';

function USCollapsibleCard(props) {

    const { children,  badge, handleNewRecord } = props;

    const [reload, setReload] = useState(false);
    const [collapse, setCollapse] = useState(false);
    const [close, setClose] = useState(false);
    const [collapsible, setCollapsible] = useState(false);

    const [closeable, setCloseable] = useState(false);
    const [reloadable, setReloadable] = useState(false);
    const [heading, setHeading] = useState(false);
    const [contentCustomClasses, setContentCustomClasses] = useState('');
    const [colClasses, setColClasses] = useState('');
    const [customClasses, setCustomClasses] = useState('');
    const [headingCustomClasses, setHeadingCustomClasses] = useState('');
    const [fullBlock, setFullBlock] = useState(false);

    useEffect(() => {
        setReload(props.reload != undefined ? props.reload : false );
        setCollapse(props.collapse != undefined ? props.collapse : true);
        setClose(props.close != undefined ? props.close : false);
        setCollapsible(props.collapsible != undefined ? props.collapsible : false);
        setCloseable(props.closeable != undefined ? props.closeable : false);
        setReloadable(props.reloadable != undefined ? props.reloadable : false);
        setHeading(props.heading != undefined ? props.heading : '');
        setFullBlock(props.fullBlock != undefined ? props.fullBlock : false);
        setColClasses(props.colClasses != undefined ? props.colClasses : '');
        setCustomClasses(props.customClasses != undefined ? props.customClasses : '');
        setContentCustomClasses(props.contentCustomClasses != undefined ? props.contentCustomClasses : '');
        setHeadingCustomClasses(props.headingCustomClasses != undefined ? props.headingCustomClasses : '');
    },[props]);

	function onCollapse(e) {
		e.preventDefault();
        if(collapsible)
    		setCollapse(!collapse);
	}

	function onReload(e) {
		setReload( true );
		setTimeout(() => {
			setReload( true );
		}, 1500);
		e.preventDefault();
	}

    return (
        <div className={classnames(colClasses ? colClasses : '', { 'd-block': !collapse })}>
            <div className={classnames(`rct-block ${customClasses ? customClasses : ''}`, { 'd-none': close })}>
                {heading &&
                    <div className={`rct-block-title ${headingCustomClasses ? headingCustomClasses : ''}`} onClick={(e) => onCollapse(e)}>
                        <h4>{heading} {badge && <Badge className="p-1 ml-10" color={badge.class}>{badge.name}</Badge>}</h4>
                        {(collapsible || reloadable || closeable) &&
                            <div className="contextual-link">
                                {collapsible && <a href="#" onClick={(e) => onCollapse(e)}><i className="ti-minus"></i></a>}
                                {reloadable && <a href="#" onClick={(e) => onReload(e)}><i className="ti-reload"></i></a>}
                                {closeable && <a href="#" onClick={() => setClose(true)}><i className="ti-close"></i></a>}
                            </div>
                        }
                        {handleNewRecord &&
                            <div className="contextual-link">
                                <Button outline color="primary" onClick={() => handleNewRecord()}>New</Button>
                            </div>
                        }
                    </div>
                }
                <Collapse id="collapse" isOpen={collapse} >
                    {collapse && <div className={classnames(contentCustomClasses ? contentCustomClasses : '', { "rct-block-content": !fullBlock, 'rct-full-block': fullBlock })}>
                        {children}
                    </div>}
                </Collapse>
                {reload && <RctSectionLoader />}
            </div>
        </div>
    );
}

export default USCollapsibleCard;
