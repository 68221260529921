/**
 * User List
 */
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { Helmet } from "react-helmet";
// intl messages
import IntlMessages from 'Util/IntlMessages';
import { USCard } from '../../../USCommonComponents/USCardComponent';

export default function USUserShops() {

   const [shops, setShops] = useState([{
        Name : 'Carparts',
        CoverPhoto : null,
        Logo : '',
        Address: 'Some Addres, 123 Lem=nina',
        socialLinks: []

   }]);

   useEffect(() => {
        //setShops([]);
   },[]);

    return (
        <div className="user-list-wrapper">
        <Helmet>
            <title>Reactify | Shop List</title>
            <meta name="description" content="Reactify Widgets" />
        </Helmet>

        <div className="row">
            {shops && shops.map((shop, key) => (
                <USCard customClasses="p-10" colClasses="col-sm-6 col-lg-4 col-xl-3" key={key}>
                    <img src={shop.CoverPhoto} className="img-fluid" alt="user listing" width="100%" height="40" />
                    <div className="card-block-content">
                        <div className="d-flex justify-content-between mb-20">
                        <div className="d-flex align-items-start">
                            <div className="media">
                                <div className="media-left mx-10">
                                    <img src={shop.Logo} alt="user profile" className="rounded-circle img-fluid" width="90" height="90" />
                                </div>
                                <div className="media-body py-10">
                                    <p className="mb-0">{shop.Name}</p>
                                    <span className="text-muted fs-12"><i className="ti-world mr-5"></i>{shop.Address}</span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-end card-action pt-15">
                            {(shop.socialLinks.length > 0 && shop.socialLinks !== null) && shop.socialLinks.map((link, subkey) => (
                                <a key={subkey} href={link.url} className="mr-0"><i className={`ti-${link.icon}`}></i></a>
                            ))}
                        </div>
                        </div>

                        <div className="d-flex justify-content-between">
                            <Button variant="contained" color="primary" className="text-white btn-xs">
                                <i className="zmdi zmdi-comment-outline mr-10"></i>Visit Site
                            </Button>
                            <Button className="text-success btn-xs"><i className="zmdi zmdi-check-circle mr-10"></i> Available for visit</Button>
                        </div>

                    </div>
                </USCard>
            ))}
        </div>
        </div>
    );

}


