

export default function USStoreMenuDetails() {

    return (
        <>
            store menu details
        </>
    )
}
