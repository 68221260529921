import { Button, CardHeader } from "@mui/material";
import { CardActions, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRequest, postRequest } from "../../../../../../Api/ApiService/Service";
import { USCard, USCardContent } from "../../../../../USCommonComponents/USCardComponent";
import USIOSSwitch from "../../../../../USCommonComponents/USIOSSwitch";
import USTable from "../../../../../USCommonComponents/USTable";
import USNewFilterSettingModal from "../USPriceSettingsHelper/USNewFilterSettingModal";

function USEditEmailConnectSetting() {
    const {id} = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [objectFields, setObjectFields] = useState({
        'Name' : '',
        'isActive' : true,
        'Host' : 'other.gmail.com',
        'Port' : '993',
        'Encryption' : 'ssl',
        'ValidateCerteficate' : true,
        'Username' : '',
        'Password' : '',
        'Protocol' : 'imap',
        'AccountId' : localStorage.getItem('currentAccount')
    });

    const handleFieldValue = e => {
        const fieldName = e.target.name;
        let fieldValue = e.target.value;
        console.log(e);
        console.log(e.target.name, e.target.checked);

        if(fieldName === "isActive" || fieldName === "ValidateCerteficate") {
            if(objectFields[fieldName]) {
                fieldValue = false;
            } else {
                fieldValue = true;
            }
        }

        //const value = e.target.value != null && e.target.value.hasOwnProperty('value') ? e.target.value.value : e.target.value ;
        setObjectFields(existingValues => ({
            // Retain the existing values
            ...existingValues,
            // update the current field
            [fieldName]: fieldValue,
        }))
    }

    const handleSave = async () => {
        const response = await postRequest('company/edit/emailconnectsetting/'+id, objectFields);
        console.log(response);
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await getRequest('company/view/emailconnectsetting/'+id);
            const responseData = response.data.data;
            console.log(responseData);
            setObjectFields(responseData.recordData);
        }
        fetchData();
    },[id])

    const [refresh, setRefresh] = useState();
    const handleCloseModal = () => {
        setIsModalOpen(false);
    }

    const handleSaveModal = async (newData) => {
        try {
            console.log(newData);
            newData.EmailConnectSettingsId = id;
            const response = await postRequest('/company/edit/emailfiltersetting',newData);
            console.log(response);
            var currentdate = new Date();
            setRefresh(currentdate.getSeconds());
            setIsModalOpen(false);
        } catch(e) {
            console.log(e);
        }
    }

    const handleRunIddle = async () => {
        try {
            const response = await getRequest('/company/emailconnectsettings/runiddle/' + id + '/'+localStorage.getItem('currentAccount'));
            console.log(response);
        } catch(e) {
            console.log(e);
        }
    }

    return (
        <>
            <USCard>
            <CardHeader
                    title="Email Connection Settings"
                    className="ml-10 mr-10"
                />
                <USCardContent>
                    <div className="row" >
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="row" >
                                <div className="col-sm-12 col-md-12 col-lg-12 " >
                                    <Stack direction="row" spacing={2} alignItems="left" >
                                        <Typography>Active</Typography>
                                        <USIOSSwitch name="isActive" onChange={(e) => handleFieldValue(e) } checked={objectFields['isActive']} />
                                    </Stack>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12" >
                                    <TextField id="standard-basic" label="Host" name="Host" onChange={(e) => handleFieldValue(e)} value={objectFields['Host']} variant="standard" />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12" >
                                    <TextField id="standard-basic" label="Port" name="Port" onChange={(e) => handleFieldValue(e)} value={objectFields['Port']} variant="standard" />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12" >
                                    <TextField id="standard-basic" label="Encryption" name="Encryption" onChange={(e) => handleFieldValue(e)} value={objectFields['Encryption']} variant="standard" />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="row" style={{ display:'block' }}>
                                <div className="col-sm-12 col-md-12 col-lg-12" >
                                    <Stack direction="row" spacing={2} alignItems="left">
                                        <Typography>Validate Certeficate</Typography>
                                        <USIOSSwitch name="ValidateCerteficate" onChange={(e) => handleFieldValue(e) } checked={objectFields['ValidateCerteficate']} />
                                    </Stack>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12" >
                                    <TextField id="standard-basic" label="Username" name="Username" onChange={(e) => handleFieldValue(e)} value={objectFields['Username']} variant="standard" />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12" >
                                    <TextField id="standard-basic" label="Password" name="Password" onChange={(e) => handleFieldValue(e)} value={objectFields['Password']} variant="standard" />
                                </div>
                            </div>
                        </div>
                    </div>
                </USCardContent>
                <CardActions className='pb-20 ml-10 justify-content-center'>
                    <Button variant='outlined' size="small" color="success" onClick={handleSave}>Save</Button>
                    <Button variant='outlined' size="small" color="success" onClick={handleRunIddle}>Run Iddle</Button>
                </CardActions>
            </USCard>

            <USCard>
                <CardHeader
                    action={
                        <Button variant="outlined" onClick={() => setIsModalOpen(true)}>New</Button>
                    }
                    title="Email Filter Settings"
                    className="ml-10 mr-10"
                />
                <USCardContent>
                    <USTable tableTitle="Email Filter Settings" refres={refresh} objectName={'emailfiltersetting'} requestUrl={'company/child/EmailConnectSettingsId/'+ id +'/'} expandableRows={false} hideToolbar={true} hidePagination={true}/>
                </USCardContent>
            </USCard>
            {isModalOpen && <USNewFilterSettingModal isModalOpen={isModalOpen} saveModal={handleSaveModal} closeModal={handleCloseModal} />}
        </>
    )

}
export default USEditEmailConnectSetting;
