import { Button, Stack } from "@mui/material";
import { USCard, USCardContent } from "../../../USCommonComponents/USCardComponent";
import USTable from "../../../USCommonComponents/USTable";


const USCompanyCities = () => {

    return (
        <>
            <USCard>
                <Stack direction="row" spacing={2}>
                    <Button variant="outlined">New</Button>
                </Stack>
            </USCard>

            <USTable tableTitle="Compay's Cities" objectName={'city'} requestUrl={'company/list/'} expandableRows={false} />
        </>

    )
}

export default USCompanyCities;
