import React, { useEffect, useState } from 'react'
import USDetailsContent from '../../../USCommonComponents/USDetailsPage/USDetailsContent';
import { useDispatch } from 'react-redux';
import { getRequest, postRequest } from '../../../../Api/ApiService/Service';
import { addNotification } from '../../../../reduxSettings/reduxSlices/NotificationSlice';
import { Box, TextField } from '@mui/material';
import IntlMessages from 'Util/IntlMessages';
import USDate from '../../../USCommonComponents/USService/Inputs/USDate';

export default function USContactStep({id, handleUpdate}) {

    const dispatch = useDispatch();
    const [readRecord, setReadRecord] = useState(false);

    useEffect(() => {
        if(id) {
            initData();
        }
    }, [id]);

    const [oppContact, setOppContact] = useState(null);
    const [contactFields, setContactFields] = useState(null);

    const [oppContactAddress, setOppContactAddress] = useState(null);
    const [addressFields, setAddressFields] = useState();
    const [deliveryAddressFields, setDeliveryAddressFields] = useState();

    const [oppContract, setOppContract] = useState(null);
    const [contractFields, setContractFields] = useState(null);

    const initData = async () => {
        try {
            const currentRecord = await getRequest('/company/sales/getContractDetails/' + id);
            console.log('currentRecord', currentRecord);
            const selectedData = currentRecord.data.data;
            if(selectedData?.contact) {
                if(selectedData?.contact?.recordData) {
                    setContactFields(selectedData?.contact?.recordData);
                    setOppContact(selectedData?.contact?.layout?.sections);
                }
            }
            if(selectedData?.address) {
                if(selectedData?.address?.recordData) {
                    setAddressFields(selectedData?.address?.recordData);
                    setOppContactAddress(selectedData?.address?.layout?.sections);
                }
            }
            if(selectedData?.deliveryAddress) {
                if(selectedData?.deliveryAddress?.recordData) {
                    setDeliveryAddressFields(selectedData?.deliveryAddress?.recordData);
                    setOppContactAddress(selectedData?.deliveryAddress?.layout?.sections);
                }
            }
            if(selectedData?.contract) {
                if(selectedData?.contract?.recordData) {
                    setContractFields(selectedData?.contract?.recordData);
                    setOppContract(selectedData?.contract?.layout?.sections);
                }
            }
        } catch (error) {
            console.error(error);
            dispatch(addNotification({"type" : "info", "message" : error}));
        }
    }

    const updateContact = async(recToSave) => {
        setContactFields(recToSave);
        handleUpdate({
            'contact': recToSave,
            'deliveryAddress': deliveryAddressFields,
            'address': addressFields,
            'contract' : contractFields,
            'opportunityId': id,
            'contactPasport' : pasportNumber,
            'issuedDate':issuedPasport
        });
    }

    const updateDefaultAddress = (rec) => {
        setAddressFields(rec);
        handleUpdate({
            'contact': contactFields,
            'deliveryAddress': deliveryAddressFields,
            'address': rec,
            'contract' : contractFields,
            'opportunityId': id,
            'contactPasport' : pasportNumber,
            'issuedDate':issuedPasport
        });
    }

    const updateDeliveryAddress = (rec) => {
        setDeliveryAddressFields(rec);
        handleUpdate({
            'contact': contactFields,
            'deliveryAddress': rec,
            'address': addressFields,
            'contract' : contractFields,
            'opportunityId': id,
            'contactPasport' : pasportNumber,
            'issuedDate':issuedPasport
        });
    }

    const updateContract = (rec) => {
        setContractFields(rec);
        handleUpdate({
            'contact': contactFields,
            'deliveryAddress': deliveryAddressFields,
            'address': addressFields,
            'contract' : rec,
            'opportunityId': id,
            'contactPasport' : pasportNumber,
            'issuedDate':issuedPasport
        });
    }

    const [pasportNumber, setPasportNumber] = useState();
    const handleChangePassport = (val) => {
        setPasportNumber(val);
        handleUpdate({
            'contact': contactFields,
            'deliveryAddress': deliveryAddressFields,
            'address': addressFields,
            'contract' : contractFields,
            'opportunityId': id,
            'contactPasport' : val,
            'issuedDate':issuedPasport
        });
    }
    const [issuedPasport, setIssuedPasport] = useState(null);
    const handleChangeIssued = (val) => {
        setIssuedPasport(val.target.value);
        handleUpdate({
            'contact': contactFields,
            'deliveryAddress': deliveryAddressFields,
            'address': addressFields,
            'contract' : contractFields,
            'opportunityId': id,
            'contactPasport' : pasportNumber,
            'issuedDate':val.target.value
        });
    }

    return (
        <>
            {contactFields && <USDetailsContent sections={oppContact} objectName={'contact'} objectFields={contactFields} handleUpdateRecord={updateContact} readRecord={readRecord}/>}
            <div className="row p-10">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <Box style={{width: '100%', height:'25px', backgroundColor: '#e2eaf2'}} >
                        Passport Information
                    </Box>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6" style={{ display:'block' }}>
                            <TextField
                                id="pasport"
                                name="pasport"
                                label={<IntlMessages id={'passport'} />}
                                onChange={(e) => handleChangePassport(e.target.value)}
                                value={pasportNumber}
                                variant="standard"
                                margin="dense" fullWidth
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6" style={{ display:'block' }}>
                            <USDate fieldDetails={{ 'field_api_name': 'Issued_Date', 'field_access': 'edit', field_label : 'IssuedDate'}} handleChange={handleChangeIssued} value={issuedPasport} variant="standard" readOnly={false} />
                        </div>
                    </div>
                </div>
            </div>
            {contactFields && <USDetailsContent sections={oppContactAddress} objectName={'address'} objectFields={addressFields} handleUpdateRecord={updateDefaultAddress} readRecord={readRecord}/>}
            {contactFields && <USDetailsContent sections={oppContactAddress} objectName={'address'} objectFields={deliveryAddressFields} handleUpdateRecord={updateDeliveryAddress} readRecord={readRecord}/>}
            {contractFields && <USDetailsContent sections={oppContract} objectName={'contract'} objectFields={contractFields} handleUpdateRecord={updateContract} readRecord={readRecord}/>}
        </>
    )
}
