import { Button } from "@mui/material";
import { USCard, USCardContent } from "../../../USCommonComponents/USCardComponent";
import USTable from "../../../USCommonComponents/USTable";
import { useState } from "react";
import USNewRecordDialog from "../../../USCommonComponents/USNewRecordDialog";

export default function USTasks() {

    const [isNewOpen, setIsNewOpen] = useState(false);
    return (
        <>
            <USCard>
                <USCardContent>
                    <Button variant="outlined" size="small" onClick={() => setIsNewOpen(true)}>New</Button>
                </USCardContent>
            </USCard>
            <USTable tableTitle="Tasks" objectName={'task'} requestUrl={'company/list/'} expandableRows={false} appUrl="/service"/>
            {isNewOpen && <USNewRecordDialog isOpen={isNewOpen} objectName="task" handleClose={() => setIsNewOpen(false)} dialogTitle={"New Task"} />}
        </>
    )
}
