import { USCard, USCardContent } from "../../../USCommonComponents/USCardComponent";
import EmailStatics from "../../../USCommonComponents/USDashboard/EmailStatics";
import IntlMessages from 'Util/IntlMessages';

// chart config
import ChartConfig from 'Constants/chart-config';

// helpers
import { hexToRgbA } from 'Helpers/helpers';
import TinyAreaChart from "../../../USCommonComponents/Charts/TinyAreaChart";
import TinyLineChart from "../../../USCommonComponents/Charts/TinyLineChart";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";

export default function USReports() {

    const [type, setType] = useState(null);
    const [reportData, setReportData] = useState(null);

    useEffect(() => {
        fetchData();
    },[]);

    const fetchData = () => {
        setReportData([
            {   "type" : "charts1",
                "title" : "Задолженность жильцев по платежкам",
                "reports" : [{"title" : "Холодная вода", "data" : {
                                data: [80, 180, 165, 220, 130, 255, 245, 325],
                                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug'],
                            }
                        },
                        {"title" : "Подогрев воды", "data" : {
                                data: [80, 180, 165, 220, 130, 255, 245, 325],
                                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug'],
                            }
                        },
                        {"title" : "Электричество", "data" : {
                                data: [80, 180, 165, 220, 130, 255, 245, 325],
                                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug'],
                            }
                        },
                        {"title" : "Газ", "data" : {
                                data: [80, 180, 165, 220, 130, 255, 245, 325],
                                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug'],
                            }
                        }]},
            {   "type" : "charts2",
                "title" : "Задолженность ТС перед поставщиками",
                "reports" : [{"title" : "Общая задолженность", "data" : {
                                data: [80, 180, 165, 220, 130, 255, 245, 325],
                                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug'],
                            }
                        },
                        {"title" : "Расходы ТС", "data" : {
                                data: [80, 180, 165, 220, 130, 255, 245, 325],
                                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug'],
                            }
                        },
                        {"title" : "Доходы ТС", "data" : {
                                data: [80, 180, 165, 220, 130, 255, 245, 325],
                                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug'],
                            }
                        },
                        {"title" : "Прочие расходы", "data" : {
                                data: [80, 180, 165, 220, 130, 255, 245, 325],
                                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug'],
                            }
                        }]}
        ]);
    }

    return (
        <>
            <USCard>
                <USCardContent>
                    <div className="row">
                        <div className="col-sm-12 col-md-9 col-lg-9">
                            Reports
                        </div>
                        <div className="col-sm-12 col-md-1 col-lg-1">
                            <Button variant="outlined" size="small">Finance</Button>
                        </div>
                        <div className="col-sm-12 col-md-1 col-lg-1">
                            <Button variant="outlined" size="small">Consumption</Button>
                        </div>
                        <div className="col-sm-12 col-md-1 col-lg-1">
                            <Button variant="outlined" size="small">Maintance</Button>
                        </div>
                    </div>

                </USCardContent>
            </USCard>

            {reportData && reportData.map((dataItem, key2) => {
                if(dataItem.type == 'charts1') {
                    return <USCard key={key2} heading={dataItem.title}>
                                <USCardContent>
                                    <div className="row">
                                        {dataItem?.reports && dataItem.reports.map((reportItem, key) => (
                                            <div key={key} className="col-sm-12 col-md-3 col-lg-3">
                                                <USCard
                                                    customClasses="gradient-primary"
                                                    colClasses="w-xs-half-block w-8-full p-0"
                                                    heading={reportItem.title}
                                                    reloadable
                                                    fullBlock
                                                >
                                                    <TinyLineChart
                                                        label={reportItem.title}
                                                        chartdata={reportItem.data.data}
                                                        labels={reportItem.data.labels}
                                                        borderColor={ChartConfig.color.white}
                                                        pointBackgroundColor={ChartConfig.color.dark}
                                                        pointBorderColor={ChartConfig.color.white}
                                                        height={100}
                                                        borderWidth={3}
                                                        xAxes={true}
                                                    />
                                                </USCard>
                                            </div>
                                        ))}
                                    </div>
                                </USCardContent>
                            </USCard>
                } else {
                    return <USCard key={key2} heading={dataItem.title}>
                        <USCardContent>
                            <div className="row">
                                {dataItem?.reports && dataItem.reports.map((reportItem, key) => (
                                    <div key={key} className="col-sm-12 col-md-3 col-lg-3">
                                        <USCard
                                            customClasses="gradient-primary"
                                            colClasses="w-xs-half-block w-8-full p-0"
                                            heading={reportItem.title}
                                            reloadable
                                            fullBlock
                                        >
                                            <TinyAreaChart
                                                label="Bounced"
                                                chartdata={reportItem.data.data}
                                                labels={reportItem.data.labels}
                                                backgroundColor={hexToRgbA(ChartConfig.color.white, 0.5)}
                                                borderColor={ChartConfig.color.white}
                                                lineTension="0.4"
                                                height={100}
                                                gradient
                                            />
                                        </USCard>
                                    </div>
                                ))}
                            </div>
                        </USCardContent>
                    </USCard>
                }
            })}

            {type && <div className="row">
                <div className="col-sm-12 col-md-3 col-lg-3">
                    <USCard
                        customClasses="gradient-primary"
                        colClasses="w-xs-half-block w-8-full p-0"
                        heading={<IntlMessages id="widgets.emailsStatistics" />}
                        reloadable
                        fullBlock
                    >


                    </USCard>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3">
                    <USCard
                        customClasses="gradient-primary"
                        colClasses="w-xs-half-block w-8-full p-0"
                        heading={<IntlMessages id="widgets.emailsStatistics" />}
                        reloadable
                        fullBlock
                    >

                        <TinyAreaChart
                            label="Bounced"
                            chartdata={emailStatisticsV2Data.chartData.bounce.data}
                            labels={emailStatisticsV2Data.chartData.bounce.labels}
                            backgroundColor={hexToRgbA(ChartConfig.color.white, 0.5)}
                            borderColor={ChartConfig.color.white}
                            lineTension="0.4"
                            height={100}
                            gradient
                        />
                    </USCard>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3">
                    <USCard
                        customClasses="gradient-primary"
                        colClasses="w-xs-half-block w-8-full p-0"
                        heading={<IntlMessages id="widgets.emailsStatistics" />}
                        reloadable
                        fullBlock
                    >

                        <TinyAreaChart
                            label="Bounced"
                            chartdata={emailStatisticsV2Data.chartData.bounce.data}
                            labels={emailStatisticsV2Data.chartData.bounce.labels}
                            backgroundColor={hexToRgbA(ChartConfig.color.white, 0.5)}
                            borderColor={ChartConfig.color.white}
                            lineTension="0.4"
                            height={100}
                            gradient
                        />
                    </USCard>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3">
                    <USCard
                        customClasses="gradient-primary"
                        colClasses="w-xs-half-block w-8-full p-0"
                        heading={<IntlMessages id="widgets.emailsStatistics" />}
                        reloadable
                        fullBlock
                    >

                        <TinyAreaChart
                            label="Bounced"
                            chartdata={emailStatisticsV2Data.chartData.bounce.data}
                            labels={emailStatisticsV2Data.chartData.bounce.labels}
                            backgroundColor={hexToRgbA(ChartConfig.color.white, 0.5)}
                            borderColor={ChartConfig.color.white}
                            lineTension="0.4"
                            height={100}
                            gradient
                        />
                    </USCard>
                </div>
            </div>}
        </>
    )
}
