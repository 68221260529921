import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from "react-helmet";
import USHome from './USHome/USHome';
import USNewContractDialog from '../../USSalesConsole/Pages/USNewContractDialog/USNewContractDialog';

const Pages = (props) => {
    return  (
        <div className="content-wrapper">
            <Helmet>
                <title>Home Page</title>
                <meta name="description" content="Home Page" />
            </Helmet>
            <Switch>
                <Route path={`/home`} component={USHome} />
            </Switch>
            <USNewContractDialog />
        </div>
    );
}
export default Pages;
