/**
 * Overall Traffic Stats
 * Stacked Bar CHart Component
 */
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

// chart config
import ChartConfig from 'Constants/chart-config';

const BarChart = ({labels, datasets}) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        setData({
            labels, datasets
        })
    },[labels, datasets])

    const options = {
        legend: {
            display: true
        },
        layout: {
            padding: {
                left: 20,
                right: 20,
                top: 20,
                bottom: 20
            }
        },
        scales: {
            xAxes: [{
                gridLines: {
                    color: ChartConfig.chartGridColor
                },
                ticks: {
                    fontColor: ChartConfig.axesColor
                },
                display: true
            }],
            yAxes: [{
                gridLines: {
                    color: ChartConfig.chartGridColor
                },
                ticks: {
                    fontColor: ChartConfig.axesColor
                }
            }]
        }
    };
    return (
        <>
            {(data != null) ? <Bar data={data} options={options} height={150} /> : null }
        </>

    );
}

export default BarChart;
