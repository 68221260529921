import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    openNewDialog: false,
    options: {objectName: null, dialogTitle: null, parentFieldName: null, parentRecordId: null, detailsParams:{},showStepper:null, stepperFieldName:null },
    newContractDialog : false,
    contractOptions: {contactId: null, opportunityId: null, contractId : null}
};

export const systemSlice = createSlice({
    name: "system",
    initialState,
    reducers: {
        openDialog: (state, action) => {
            state.options = action.payload;
            state.openNewDialog = true;

        },

        closeDialog: (state, action) => {
            state.options = {objectName: null, dialogTitle: null, parentFieldName: null, parentRecordId: null, detailsParams:{}, showStepper:null, stepperFieldName:null};
            state.openNewDialog = false;
        },

        openContractDialog: (state, action) => {
            state.contractOptions = action.payload;
            state.newContractDialog = true;
        },

        closeContractDialog: (state, action) => {
            state.options = {contactId: null, opportunityId: null, contractId : null};
            state.newContractDialog = false;
        },
        updateContractOptions: (state, action) => {
            state.options =  action.payload;
        }
    }
});

export const {openDialog, closeDialog, openContractDialog, closeContractDialog, updateContractOptions} = systemSlice.actions;
export default systemSlice.reducer;

