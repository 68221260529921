import React from 'react';
import { Route } from 'react-router-dom';
import Builder from './Builder';
import PagesList from './PagesList';

// app default layout
import USHorizontalLayout from '../USCommonComponents/USUtils/USHorizontalLayout';


function USSiteBuilder() {

    return (
        <USHorizontalLayout >
            <Route exact path="/site-builder/pages" component={PagesList}/>
            <Route exact path="/site-builder/builder/:id" component={Builder}/>
            <Route exact path="/site-builder" component={PagesList}/>
        </USHorizontalLayout>
    );
}

export default USSiteBuilder;
        /*<RctHorizontalLayout >
            <Route exact path="/site-builder/pages" component={PagesList}/>
            <Route exact path="/site-builder/builder/:id" component={Builder}/>
            <Route exact path="/site-builder" component={PagesList}/>
        </RctHorizontalLayout>*/
