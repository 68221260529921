import { Button, CardHeader } from "@mui/material";
import { CardActions, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRequest, postFileRequest, postRequest } from "../../../../../../Api/ApiService/Service";
import USIOSSwitch from "../../../../../USCommonComponents/USIOSSwitch";
import { USCard, USCardContent } from "../../../../../USCommonComponents/USCardComponent";
import USTable from "../../../../../USCommonComponents/USTable";
import USNewMappingItemModal from "../USPriceSettingsHelper/USNewMappingItemModal";
import USUseFileForMapping from "../USPriceSettingsHelper/USUseFileForMapping";

function USEditFileMapping() {
    const {id} = useParams();
    const [objectFields, setObjectFields] = useState({
        'Name' : '',
        'isActive' : true,
        'EmailConnectSettingsId' : '',
        'EmailFilterSettingsId ' : '',
        'AccountId' : localStorage.getItem('currentAccount')
    });

    const handleFieldValue = e => {
        const fieldName = e.target.name;
        let fieldValue = e.target.value;
        console.log(e);
        console.log(e.target.name, e.target.checked);

        if(fieldName === "isActive" || fieldName === "ValidateCerteficate") {
            if(objectFields[fieldName]) {
                fieldValue = false;
            } else {
                fieldValue = true;
            }
        }

        //const value = e.target.value != null && e.target.value.hasOwnProperty('value') ? e.target.value.value : e.target.value ;
        setObjectFields(existingValues => ({
            // Retain the existing values
            ...existingValues,
            // update the current field
            [fieldName]: fieldValue,
        }))
    }

    const handleSave = async () => {
        const response = await postRequest('/company/edit/filemapping/', objectFields);
        console.log(response);
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await getRequest('company/view/filemapping/'+id);
            const responseData = response.data.data;
            console.log(responseData);
            setObjectFields(responseData.recordData);
        }
        fetchData();
    },[id])

    const [isMappingItemModalOpen, setIsMappingItemModalOpen] = useState(false);
    const [refreshItems, setRefreshItems] = useState(null);
    const handleItemSave = async (newData) => {
        console.log(newData);
        newData.FileMappingId = id;
        const response = await postRequest('/company/edit/filemappingitem/', newData);
        console.log(response);
        var currentdate = new Date();
        setRefreshItems(currentdate.getSeconds());
        setIsMappingItemModalOpen(false);
    }

    const handleItemModalClose = () => {
        setIsMappingItemModalOpen(false);
    }

    /*modal to make mapping from file */
    const [isFromFileMapping, setIsFromFileMapping] = useState(false);

    const handleSaveFromFileMapping = async (newData) => {
        console.log(newData);
        const response = await postRequest('/company/filemappingitem/new-items/' + id,newData);
        console.log(response);

        setIsFromFileMapping(false);
        var currentdate = new Date();
        setRefreshItems(currentdate.getSeconds());
    }

    const handleIsFromFileMappingClose = () => {
        setIsFromFileMapping(false);
    }
    return (
        <>
            <USCard>
                <CardHeader
                    title="File Mapping"
                    className="ml-10 mr-10"
                />
                <USCardContent>
                    {id && <div className="row" >
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="row" >
                            <div className="col-sm-12 col-md-12 col-lg-12" >
                                    <TextField id="standard-basic" label="Name" name="Name" onChange={(e) => handleFieldValue(e)} value={objectFields['Name']} variant="standard"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12 mt-10" >
                                    <TextField id="standard-basic" label="Email Connect Settings" name="EmailConnectSettingsId" onChange={(e) => handleFieldValue(e)} value={objectFields['EmailConnectSettingsId']} variant="standard"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="row" style={{ display:'block' }}>
                                <div className="col-sm-12 col-md-12 col-lg-12" >
                                    <Stack direction="row" spacing={2} alignItems="left" >
                                        <Typography>Active</Typography>
                                        <USIOSSwitch name="isActive" onChange={(e) => handleFieldValue(e) } checked={objectFields['isActive']} />
                                    </Stack>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12  mt-10" >
                                    <TextField id="standard-basic" label="Email Filter Settings" name="EmailFilterSettingsId" onChange={(e) => handleFieldValue(e)} value={objectFields['EmailFilterSettingsId']} variant="standard"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                </USCardContent>
                <CardActions className='pb-20 ml-10 justify-content-center'>
                    <Button variant='outlined' size="small" color="success" onClick={handleSave}>Save</Button>
                </CardActions>
            </USCard>

            <USCard>
                <CardHeader
                    action={
                        <div>
                            <Button className="mr-10" variant="outlined" onClick={() => setIsFromFileMapping(true)}>Use File to Create Mapping</Button>
                            <Button variant="outlined" onClick={() => setIsMappingItemModalOpen(true)}>New</Button>
                        </div>

                    }
                    title="File Mapping Items"
                    className="ml-10 mr-10"
                />
                <USCardContent>
                    <USTable tableTitle="Mapping Items" refres={refreshItems} objectName={'filemappingitem'} requestUrl={'company/child/FileMappingId/'+ id +'/'} expandableRows={false} hideToolbar={true} hidePagination={false}/>
                </USCardContent>
            </USCard>
            {isMappingItemModalOpen && <USNewMappingItemModal isModalOpen={isMappingItemModalOpen} saveModal={handleItemSave} closeModal={handleItemModalClose} />}
            {isFromFileMapping && <USUseFileForMapping isModalOpen={isFromFileMapping} saveModal={handleSaveFromFileMapping} closeModal={handleIsFromFileMappingClose} />}
        </>
    )

}
export default USEditFileMapping;
