import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { getObjectFieldList, getObjectFieldValues, getRecordById, getRecordsForBulkCreate, getReferenceObjectFieldList, saveBulkRecords } from '../../../Api/SalesConsoleRequests/USSalesRequestHandler';
import { USCard, USCardContent, USCardHeading } from '../USCardComponent';
import IntlMessages from 'Util/IntlMessages';
import USIOSSwitch from '../USIOSSwitch';
import { Element } from '../../../helpers/RouteHelper';
export default function USBulkAddRelatedRecords() {
    const { id, parentObject, relatedObject } = useParams();
    const [newObjectLayout, setNewObjectLayout] = useState();
    const [selctedParentRecord, setSelectedParentRecrod] = useState();

    const [referencePicklist, setReferencePicklist] = useState([]);
    const [selectedReference, setSelectedReference] = useState('all');

    const [fieldPickList, setFieldPickList] = useState([]);
    const [selectedField, setSelectedField] = useState('all');

    const [filterValuesPickList, setFilterValuesPickList] = useState([]);
    const [selectedFieldValue, setSelectedFieldValue] = useState('all');

    const [selectedRecords, setSelectedRecords] = useState();

    const fetchInit = async () => {
        try {
            const response = await getRecordById(parentObject, id);
            setSelectedParentRecrod(response.recordData);
            const responseObjectFields = await getReferenceObjectFieldList(relatedObject, parentObject, relatedObject);
            setReferencePicklist(responseObjectFields);
        } catch(e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchInit();
    },[id, parentObject,relatedObject]);

    const fetchFieldList = async(referenceObject) => {
        try {
            const responseObjectFields = await getObjectFieldList(referenceObject);
            setFieldPickList(responseObjectFields);
        } catch(e) {
            console.log(e);
        }
    }

    const handleSelectReference = (e) => {
        const reference = e.target.value;
        setSelectedReference(reference);
        fetchFieldList(reference["objectName"]);
    }

    const fetchFieldValues = async(selectedReferenceField) => {
        try {
            const responseObjectFields = await getObjectFieldValues(selectedReference["objectName"], selectedReferenceField);
            setFilterValuesPickList(responseObjectFields);
        } catch(e) {
            console.log(e);
        }
    }

    const handleSelectField = (e) => {
        setSelectedField(e.target.value);
        fetchFieldValues(e.target.value);
        fetchRecords('all');
    }

    const handleSelectFilterField = (e) => {
        setSelectedFieldValue(e.target.value);
        fetchRecords(e.target.value);
    }

    const fetchRecords = async(filterParams) => {
        try {
            const params = {
                "parentRecordId" : id,
                "childObjectApi" : selectedReference["objectName"],
                "childReferenceFieldApi" : selectedReference["apiName"],
                "parentObjectApi" : parentObject,
                "currentObjectApi" : relatedObject,
                "searchParams" : [
                    {"fieldApiName" : selectedField, "fieldValue": filterParams}
                ]
            }
            console.log('params', params);
            const response = await getRecordsForBulkCreate(params);
            setNewObjectLayout(response?.visibleFields);
            setSelectedRecords(response?.records);
        }catch(e) {
            console.log(e);
        }
    }

    const updateFieldValues = (e,partIndex) => {
        const modifiedEmployees = selectedRecords.map((obj, index) => {
            if (index == partIndex) {
                return { ...obj, [e.target.name]: e.target.value };
            }
            return obj;
        });
        setSelectedRecords(modifiedEmployees);
    }

    const handleSelectPart = (partIndex) => {
        const modifiedEmployees = selectedRecords.map((obj, index) => {
            if (index == partIndex) {
                return { ...obj, ['selected']: !obj.selected };
            }
            return obj;
        });
        setSelectedRecords(modifiedEmployees);
    }

    const navigate = useHistory();
    const handleSave = async () => {
        const response = await saveBulkRecords(selectedRecords, relatedObject);
        navigate.push(`/sales/view/${parentObject}/${id}`);
    }

    const handleCancel = () => {
        navigate.push(`/sales/view/${parentObject}/${id}`);
    }
    return (
        <div>
            <USCard>
                <USCardContent>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                        <div>
                            {selctedParentRecord && <Typography>{selctedParentRecord.Name}</Typography>}
                        </div>
                        <div>
                            <Button size="small" variant="outlined" className="ml-5" onClick={handleSave}>Save</Button>
                            <Button size="small" variant="outlined" className="ml-5" onClick={handleCancel}>Cancel</Button>
                        </div>
                    </Stack>
                </USCardContent>
            </USCard>

            <USCard>
                <USCardContent>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                        <div>
                            <FormControl fullWidth>
                                <InputLabel id="PartCategory">Select Reference</InputLabel>
                                    <Select
                                        id="selectedReference"
                                        value={selectedReference}
                                        label="Select Filter Field"
                                        onChange={handleSelectReference}
                                    >
                                    <MenuItem value='all'>All References</MenuItem>
                                    {referencePicklist?.map((item, key) => (
                                        <MenuItem key={item["apiName"]} value={item}>{<IntlMessages id={item["objectName"]}/>}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl fullWidth>
                                <InputLabel id="PartCategory">Select Filter Field</InputLabel>
                                    <Select
                                        id="selectedField"
                                        value={selectedField}
                                        label="Select Filter Field"
                                        onChange={handleSelectField}
                                    >
                                    <MenuItem value='all'>All Categories</MenuItem>
                                    {fieldPickList?.map((item, key) => (
                                        <MenuItem key={item.fieldName} name={item.fieldName} value={item.fieldName}>{<IntlMessages id={item.fieldLabel}/>}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            {filterValuesPickList && <FormControl fullWidth>
                                <InputLabel id="PartCategory">Selected Field Values</InputLabel>
                                    <Select
                                        id="selectedFieldValue"
                                        value={selectedFieldValue}
                                        label="Selected Field Values"
                                        onChange={handleSelectFilterField}
                                    >
                                    <MenuItem value='all'>Picklist</MenuItem>
                                    {filterValuesPickList?.map((item, key) => (
                                        <MenuItem key={item.Value} name={item.Value} value={item.Value}>{item.Value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>}
                            {!filterValuesPickList && <TextField value={selectedFieldValue} onChange={handleSelectFilterField} label={selectedField}></TextField>}
                        </div>
                    </Stack>
                </USCardContent>
            </USCard>

            <USCard>
                <USCardHeading
                    title="Related List manager"
                    customClasses="pb-0"
                />
                <USCardContent>
                    {selectedRecords && <div>
                        <Box >
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Name</TableCell>
                                        {newObjectLayout && newObjectLayout?.map((fieldItem) => (
                                            <TableCell key={fieldItem.field_label} align="center"><IntlMessages id={fieldItem.field_label}/></TableCell>
                                        ))}
                                        <TableCell align="center">Select</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {selectedRecords?.map((recItem, itemKey) => (
                                    <TableRow  key={recItem.id}>
                                        <TableCell><Typography className="ml-10">{recItem.Name}</Typography></TableCell>
                                        {recItem?.fields?.map((fieldItem) => {
                                            return <TableCell key={fieldItem.field_api_name} align="center" ><Element keyItem={itemKey} fieldDetails={fieldItem} handleEdit={updateFieldValues} value={recItem[fieldItem.field_api_name]} dependedObjectValue={null} variant="standard" readOnly={false}/></TableCell>
                                        })}
                                        <TableCell align="center"><USIOSSwitch name={recItem.id} onChange={(e) => handleSelectPart(itemKey) } checked={recItem['selected']} /></TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </div>}
                </USCardContent>
            </USCard>
        </div>
    )
}
