import { Button, Table, TableCell, TableRow, Typography } from "@mui/material";
import { USCard, USCardContent } from "../../../USCommonComponents/USCardComponent";
import IntlMessages from 'Util/IntlMessages';
import { useHistory } from "react-router-dom";
import { getRequest } from "../../../../Api/ApiService/Service";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../../reduxSettings/reduxSlices/NotificationSlice";
import { useEffect, useState } from "react";

export default function USObjectManager() {

    const navigate = useHistory();
    const dispatch = useDispatch();
    const [objectList, setObjectList] = useState(null);

    useEffect(() => {
        fetchData();
    },[])

    const fetchData = async () => {
        try {
            const response = await getRequest('/company/objectList');
            console.log("response.data.data", response.data.data);
            setObjectList(response.data.data);
        } catch (error) {
            dispatch(addNotification({"type" : "error", "message" : error, "title" : "load error"}));
        }
    }

    const data = [
        {"label" : "Account" , "apiname": "account", "type" : "Standard Object", "description" : "some object description", "lastmodified" : "21.04.2023", "deployed" : "false" },
        {"label" : "Account" , "apiname": "account", "type" : "Standard Object", "description" : "some object description", "lastmodified" : "21.04.2023", "deployed" : "false" }
    ];

    return (
        <>
            <USCard>
                <USCardContent>
                    <Typography variant="body1" >Object Manager</Typography>
                </USCardContent>
            </USCard>

            <USCard>
                <USCardContent>
                    <div className="table-responsive">
                        <div className="unseen">
                            <Table bordered striped size="small">
                                <thead>
                                    <tr className="bg-primary text-white">
                                        <th ><IntlMessages id="common.label" /></th>
                                        <th><IntlMessages id="common.apiname" /></th>
                                        <th><IntlMessages id="common.type" /></th>
                                        <th><IntlMessages id="common.description" /></th>
                                        <th><IntlMessages id="common.last-modified" /></th>
                                        <th><IntlMessages id="common.deployed" /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {objectList && objectList.map((dataItem, key) => {
                                        return <TableRow key={dataItem}>
                                                    <TableCell>
                                                        <Button onClick={() => navigate.push('/company/ObjectManager/' + dataItem)} variant="text">{dataItem}</Button>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {dataItem}
                                                    </TableCell>
                                                    <TableCell>
                                                        {dataItem.type}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {dataItem.description}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {dataItem.lastmodified}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {dataItem.deployed}
                                                    </TableCell>
                                                </TableRow>
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </USCardContent>
            </USCard>
        </>
    )

}
