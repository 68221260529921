/**
 * Company Routes
 */
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from "react-helmet";
import USReports from './USReprots';
import USRequests from './USRequests';
import USAnnouncements from './USEvents/USAnnouncements/USAnnouncements';
import USPublicEvents from './USEvents/USPublicEvents';
import USSurvey from './USEvents/USSurvey/USSurvey';
import USHousemates from './USHousemates/inedx';
import USEmployees from './USEmployees';
import USAssets from './USAssets';
import USTasks from './USTasks';
import USRequestDetails from './USRequests/USRequestDetails';
import USSurveyDetails from './USEvents/USSurvey/USSurveyDetails';
import USSurveyPageDetails from './USEvents/USSurvey/USSurveyPageDetails';
import USSurveyElementValueDetails from './USEvents/USSurvey/USSurveyElementValueDetails';
import USSurveyElementDetails from './USEvents/USSurvey/USSurveyElementDetails';
import USEvents from './USEvents/USEvent/USEvents';
import USEventDetails from './USEvents/USEvent/USEventDetails';
import USAnnouncementDetails from './USEvents/USAnnouncements/USAnnouncementDetails';
import USAssetDetails from './USAssets/USAssetDetails';
import USDocuments from './USDocuments/USDocuments';
import USDocumentDetails from './USDocuments/USDocumentDetails';


const Pages = (props) => {
    console.log('company pages' , props);
    return  (
        <div className="content-wrapper">
            <Helmet>
                <title>Setvice console</title>
                <meta name="description" content="service console" />
            </Helmet>
            <Switch>
                <Route path={`/service/reports`} component={USReports} />
                <Route path={`/service/contactrequest/:id`} component={USRequestDetails} />
                <Route path={`/service/requests`} component={USRequests} />
                <Route path={`/service/event/:id`} component={USEventDetails} />
                <Route path={`/service/events`} component={USEvents} />
                <Route path={`/service/contact/:id`} component={USAnnouncementDetails} />
                <Route path={`/service/announcement/:id`} component={USAnnouncementDetails} />
                <Route path={`/service/announcements`} component={USAnnouncements} />
                <Route path={`/service/publicevents`} component={USPublicEvents} />
                <Route path={`/service/surveyelementvalue/:id`} component={USSurveyElementValueDetails} />
                <Route path={`/service/surveyelement/:id`} component={USSurveyElementDetails} />
                <Route path={`/service/surveypage/:id`} component={USSurveyPageDetails} />
                <Route path={`/service/survey/:id`} component={USSurveyDetails} />
                <Route path={`/service/surveys`} component={USSurvey} />
                <Route path={`/service/housemates`} component={USHousemates} />
                <Route path={`/service/employees`} component={USEmployees} />
                <Route path={`/service/asset/:id`} component={USAssetDetails} />
                <Route path={`/service/assets`} component={USAssets} />
                <Route path={`/service/document/:id`} component={USDocumentDetails} />
                <Route path={`/service/documents`} component={USDocuments} />
                <Route path={`/service/tasks`} component={USTasks} />
                <Route exact from="/service" component={USReports} />
            </Switch>
        </div>
    );
}
export default Pages;
