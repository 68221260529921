import React from 'react';
import { USCardHeading } from './USCardHeading'

const USCard = ({ children, customClasses, heading, headingCustomClasses, colClasses, styleElem }) => (
    <div className={`${colClasses ? colClasses : ''}`}>
        <div className={`rct-block ${customClasses ? customClasses : ''}`} style={{...styleElem}}>
            {heading &&
                <USCardHeading
                    title={heading}
                    customClasses={headingCustomClasses}
                />
            }
            {children}
        </div>
    </div>
);

export { USCard };
