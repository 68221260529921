/**
 * Cart Component
 */
import React, { Fragment, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Button, Tooltip, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { deleteItemFromCart, fetchCartData } from '../../../../reduxSettings/reduxSlices/cartSlice';
//Helper
import { textTruncate, getAppLayout } from "Helpers/helpers";

//intl Messages
import IntlMessages from 'Util/IntlMessages';

function Carts({cartReference}) {
    const dispatch = useDispatch();
    const navigate = useHistory();
    const isLogged = useSelector((state) => state.auth.isLogged);
    const productCarts = useSelector((state) => state.cart.carts);

	//Get Total Price
	function getTotalPrice() {
		let totalPrice = 0;
        if(productCarts) {
            for (const item of productCarts) {
                totalPrice += item.totalPrice
            }
        }
		return totalPrice.toFixed(2);
	}

    const navigateToCart = () => {
        navigate.push(cartReference + '/cart');
    }

    useEffect(() => {
        if(isLogged)
            dispatch(fetchCartData());
    },[isLogged]);

    return (
        <UncontrolledDropdown nav className="list-inline-item cart-dropdown">
            <DropdownToggle nav className="p-0">
                <Tooltip title="Shopping Cart" placement="bottom">
                    <IconButton aria-label="bag">
                        <i className="zmdi zmdi-shopping-cart"></i>
                        <Badge
                            color="success"
                            className="badge-xs badge-top-right"
                        >
                            {productCarts?.length}
                        </Badge>
                    </IconButton>
                </Tooltip>
            </DropdownToggle>
            <DropdownMenu>
                <div className="dropdown-content">
                    <div className="dropdown-top d-flex justify-content-between rounded-top">
                        <span className="font-weight-bold"><IntlMessages id="components.cart" /></span>
                        <Badge color="warning">{productCarts?.length} NEW</Badge>
                    </div>
                    {(productCarts && productCarts?.length == 0) ? (
                        <div className="text-center p-4">
                            <span className="d-block font-3x mb-15 text-danger"><i className="zmdi zmdi-shopping-cart"></i></span>
                            <h3><IntlMessages id="components.CartEmptyText" /></h3>
                        </div>
                    ) : (
                            <Fragment>
                                <Scrollbars className="rct-scroll" autoHeight autoHeightMin={100} autoHeightMax={280} autoHide>
                                    <ul className="list-unstyled dropdown-list">
                                        {Array.isArray(productCarts) ? productCarts.map((cart, key) => (
                                            <li className="d-flex justify-content-between" key={key}>
                                                <div className="media overflow-hidden w-75">
                                                    <div className="mr-15">
                                                        <img src={require(`Assets/img/${cart.Image}`).default} alt="products" className="media-object" width="63" height="63" />
                                                    </div>
                                                    <div className="media-body">
                                                        <span className="fs-14 d-block">{textTruncate(cart.Name, 25)}</span>
                                                        <span className="fs-12 d-block text-muted">{textTruncate(cart.Description, 50)}</span>
                                                        <span className="fs-12 d-block text-muted">{cart.Brand}</span>
                                                    </div>
                                                </div>
                                                <div className="text-center">
                                                    <span className="text-muted fs-12 d-block mb-10">$ {cart.Price} X {cart.Quantity}</span>
                                                    <IconButton onClick={() => dispatch(deleteItemFromCart(cart))}>
                                                        <CloseOutlinedIcon />
                                                    </IconButton>
                                                </div>
                                            </li>
                                        )) : null}
                                    </ul>
                                </Scrollbars>
                                <div className="dropdown-foot d-flex justify-content-between align-items-center p-2 bg-white rounded-bottom">
                                    <div>
                                        <Button
                                            variant="contained"
                                            onClick={navigateToCart}
                                            color="primary"
                                            className="mr-10 btn-xs bg-primary text-white"
                                        >
                                            <IntlMessages id="components.viewCart" />
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={() => navigate.push({pathname: '/checkout', state : {selectedProduct: null}})}
                                            color="secondary"
                                            className="btn-xs text-white"
                                        >
                                            <IntlMessages id="components.checkout" />
                                        </Button>
                                    </div>
                                    <span className="fw-normal text-dark font-weight-bold font-xs">
                                        <IntlMessages id="widgets.total" /> $ {getTotalPrice()}
                                    </span>
                                </div>
                            </Fragment>
                        )
                    }
                </div>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

export default Carts;
