import { useState } from "react";
import USLogin from "../../USCommonComponents/USAuth/USLogin";
import USRegister from "../../USCommonComponents/USAuth/USRegister";

export default function USPublicLogin() {
    const [isLoginPage, setIsLoginPage] = useState(true);

    const changeForm = () => {
        setIsLoginPage(!isLoginPage);
    }
    return (
        <div className="container mt-40">
            <div className='row'>
                <div className='col-sm-12 col-md-6 col-lg-6'>
                    {isLoginPage && <USLogin onChangeToRegister={changeForm} />}
                    {!isLoginPage && <USRegister onChangeToRegister={changeForm} />}
                </div>
                <div className='col-sm-12 col-md-6 col-lg-6'>

                </div>
            </div>
        </div>
    )
}
