import { useParams } from "react-router-dom";
import USTable from "../../../../USCommonComponents/USTable";

export default function USStoreCategories() {
    const {id} = useParams();
    return (
        <>
            <USTable tableTitle="Store Catalogs" parentObjectId={id} parentFieldName="ProductCatalogId" objectName={'productcategory'} requestUrl={'company/child/'} expandableRows={false} />
        </>
    )
}
