import { Button } from "@mui/material";
import { USCard, USCardContent } from "../../../../USCommonComponents/USCardComponent";
import AgencyWelcomeBarChart from "../../../../USCommonComponents/Charts/AgencyWelcomeBarChart";
import CampaignBarChart from "../../../../USCommonComponents/Charts/CampaignBarChart";
import DoughnutChart from "../../../../USCommonComponents/Charts/DoughnutChart";
import EmailStatistic from "../../../../USCommonComponents/Charts/EmailStatistic";
import HorizontalBarChart from "../../../../USCommonComponents/Charts/HorizontalBarChart";
import NewsVisitorsChart from "../../../../USCommonComponents/Charts/NewsVisitorsChart";
import ProductStatsChart from "../../../../USCommonComponents/Charts/ProductStatsChart";
import SalesChart from "../../../../USCommonComponents/Charts/SalesChart";
import SimpleBarChart from "../../../../USCommonComponents/Charts/SimpleBarChart";
import SpacePieChart from "../../../../USCommonComponents/Charts/SpacePieChart";
import StackedAreaChart from "../../../../USCommonComponents/Charts/StackedAreaChart";
import StackedBarChart from "../../../../USCommonComponents/Charts/StackedBarChart";
import StackedLineChart from "../../../../USCommonComponents/Charts/StackedLineChart";
import SubscriberDoughnutChart from "../../../../USCommonComponents/Charts/SubscriberDoughnutChart";
import TinyAreaChart from "../../../../USCommonComponents/Charts/TinyAreaChart";
import TinyLineChart from "../../../../USCommonComponents/Charts/TinyLineChart";
import TinyPieChart from "../../../../USCommonComponents/Charts/TinyPieChart";

import ChartConfig from 'Constants/chart-config';
import { hexToRgbA } from 'Helpers/helpers';

import {SupportRequest, EmailStatisticsVersion2Widget, TrafficChannel, Visitors, ProductStatsWidget, DailySales, Space,
    TotalEarnsWithAreaChartWidget, OverallTrafficStatusWidget, RevenueWidget, OnlineVisitorsWidget, TrafficSourcesWidget, BandwidthUsageWidget, Subscribers
} from "Components/USCommonComponents/USDashboard";
import IntlMessages from 'Util/IntlMessages';
import { useParams, useHistory } from "react-router-dom";
export const WelcomeBarChart1 = {
   labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],
   data: [75, 60, 50, 35, 90, 35, 75, 20, 10, 20, 40, 5, 30, 75, 40, 90, 35, 20, 40, 30, 50, 35, 20, 75],
   color: ChartConfig.color.primary
}

const campaigns = {
	yesterday: [
		{ name: '12:00 AM', websiteViews: 600, emailSubscription: 400 },
		{ name: '4:00 AM', websiteViews: 900, emailSubscription: 550 },
		{ name: '8:00 AM', websiteViews: 660, emailSubscription: 400 },
		{ name: '12:00 PM', websiteViews: 750, emailSubscription: 400 },
		{ name: '16:00 PM', websiteViews: 800, emailSubscription: 450 }
	],
	last5Days: [
		{ name: 'Mon', websiteViews: 600, emailSubscription: 400 },
		{ name: 'Tue', websiteViews: 900, emailSubscription: 700 },
		{ name: 'Wed', websiteViews: 725, emailSubscription: 500 },
		{ name: 'Thu', websiteViews: 900, emailSubscription: 625 },
        { name: 'Fri', websiteViews: 460, emailSubscription: 400 },
        { name: 'Sat', websiteViews: 500, emailSubscription: 625 },
        { name: 'Sun', websiteViews: 400, emailSubscription: 625 }
	],
	last1Month: [
		{ name: '1-5', websiteViews: 800, emailSubscription: 700 },
		{ name: '6-10', websiteViews: 700, emailSubscription: 600 },
		{ name: '11-15', websiteViews: 725, emailSubscription: 400 },
		{ name: '16-20', websiteViews: 600, emailSubscription: 400 },
		{ name: '21-25', websiteViews: 900, emailSubscription: 500 }
	],
	last5Months: [
		{ name: 'Jan', websiteViews: 900, emailSubscription: 800 },
		{ name: 'Feb', websiteViews: 700, emailSubscription: 450 },
		{ name: 'Mar', websiteViews: 725, emailSubscription: 550 },
		{ name: 'Apr', websiteViews: 625, emailSubscription: 500 },
		{ name: 'May', websiteViews: 600, emailSubscription: 450 }
	]
}

export const emailStatisticsData = {
    labels: ['Opened', 'Bounced', 'Spam', 'Unsent'],
    datasets: [
        {
            label: 'Series A',
            backgroundColor: ChartConfig.color.white,
            borderColor: ChartConfig.color.primary,
            borderWidth: 0,
            hoverBackgroundColor: ChartConfig.color.white,
            hoverBorderColor: ChartConfig.color.white,
            data: [53, 15, 30, 7]
        }
    ]
}

const newsVisitorsData = {
    chartLabels: ['0', '2', '3', '4', '5', '6', '7','8','9','10','11','12'],
    chartDatasets: [
       {
          label: 'Series A',
          backgroundColor: ChartConfig.color.primary,
          borderColor: ChartConfig.color.primary,
          borderWidth: 1,
          hoverBackgroundColor: ChartConfig.color.primary,
          hoverBorderColor: ChartConfig.color.primary,
          data: [5,20,40,15,8,50,30,20,35,30,30,50]
       },
       {
          label: 'Series B',
          backgroundColor: ChartConfig.color.default,
          borderColor: ChartConfig.color.default,
          borderWidth: 1,
          hoverBackgroundColor: ChartConfig.color.default,
          hoverBorderColor: ChartConfig.color.default,
          data: [20,70,60,50,10,55,65,60,65,40,67,60]
       }
    ]
 }

 // product stats
export const productStats = {
    labels: ['2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017'],
    datasets: [
        {
            label: 'Series A',
            fill: false,
            tension: 0,
            backgroundColor: ChartConfig.color.primary,
            borderColor: ChartConfig.color.primary,
            borderWidth: 4,
            hoverBackgroundColor: ChartConfig.color.primary,
            hoverBorderColor: ChartConfig.color.primary,
            data: [2500, 900, 400, 1100, 1250, 900, 2100, 3400, 1950, 2000, 700, 740]
        },
        {
            label: 'Series B',
            fill: false,
            tension: 0,
            backgroundColor: ChartConfig.color.info,
            borderColor: ChartConfig.color.info,
            borderWidth: 4,
            hoverBackgroundColor: ChartConfig.color.info,
            hoverBorderColor: ChartConfig.color.info,
            data: [3800, 3300, 2300, 3500, 2800, 3200, 3100, 4100, 3500, 3000, 2500, 2300]
        }
    ],
    customLegends: [
        {
            name: 'Admin Theme',
            class: 'badge-primary'
        },
        {
            name: 'Wordpress Theme',
            class: 'badge-info'
        }
    ]
}

export const dailySales = {
    label: 'Daily Sales',
    chartdata: [100, 200, 125, 250, 200, 150, 200],
    labels: ['9', '10', '11', '12', '13', '14', '15'],
 }

 export const dataUsed = {
    labels: ['0.00', '1.0', '2.0', '3.0', '4.0', '5.0', '6.0', '7.0', '8.0', '9.0', '10.0', '11.0', '12.0', '13.0', '14.0', '15.0'],
    datasets: [
        {
            label: 'Series A',
            backgroundColor: ChartConfig.color.white,
            borderColor: ChartConfig.color.white,
            borderWidth: 4,
            hoverBackgroundColor: ChartConfig.color.white,
            hoverBorderColor: ChartConfig.color.white,
            data: [65, 59, 80, 81, 56, 55, 40, 45, 35, 45, 65, 70, 75, 80, 30, 45]
        }
    ],
    totalUsed: '200 GB'
}

export const spaceUsed = {
    chartData: {
        labels: ['Space Used', 'Space Left'],
        datasets: [{
            data: [275, 100],
            backgroundColor: [
                ChartConfig.color.primary,
                ChartConfig.color.info
            ],
            hoverBackgroundColor: [
                ChartConfig.color.primary,
                ChartConfig.color.info
            ]
        }]
    },
}

export const totalEarns = {
    labels: ['Jan 1', 'Jan 7', 'Jan 14', 'Jan 21', 'Jan 28', 'Feb 4', 'Feb 11', 'Feb 18', 'Feb 25', 'Feb 28', 'Mar 2', 'Mar 6'],
    datasets: [
       {
          label: 'Sales',
          fill: true,
          lineTension: 0,
          fillOpacity: 0.5,
          backgroundColor: hexToRgbA(ChartConfig.color.primary, 0.8),
          borderColor: ChartConfig.color.primary,
          pointBorderColor: ChartConfig.color.white,
          pointBackgroundColor: ChartConfig.color.white,
          pointBorderWidth: 0,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: hexToRgbA(ChartConfig.color.primary, 1),
          pointHoverBorderColor: hexToRgbA(ChartConfig.color.primary, 1),
          pointHoverBorderWidth: 8,
          pointRadius: 0,
          pointHitRadius: 10,
          data: [250, 350, 270, 420, 380, 220, 400, 550, 480, 190, 390, 380]
       },
       {
          label: 'Visitors',
          fill: true,
          lineTension: 0,
          fillOpacity: 0.5,
          backgroundColor: hexToRgbA(ChartConfig.color.warning, 0.4),
          borderColor: ChartConfig.color.warning,
          pointBorderColor: ChartConfig.color.white,
          pointBackgroundColor: ChartConfig.color.white,
          pointBorderWidth: 0,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: hexToRgbA(ChartConfig.color.warning, 1),
          pointHoverBorderColor: hexToRgbA(ChartConfig.color.warning, 1),
          pointHoverBorderWidth: 8,
          pointRadius: 0,
          pointHitRadius: 10,
          data: [600, 400, 500, 350, 650, 630, 450, 480, 650, 500, 530, 550,]
       }
    ]
 }

 export const trafficStatus = {
    chartLabels: ['0.00', '1.0', '2.0', '3.0', '4.0', '5.0', '6.0'],
    chartDatasets: [
       {
          label: 'Series A',
          backgroundColor: ChartConfig.color.primary,
          borderColor: ChartConfig.color.primary,
          borderWidth: 1,
          hoverBackgroundColor: ChartConfig.color.primary,
          hoverBorderColor: ChartConfig.color.primary,
          data: [65, 59, 80, 81, 56, 55, 40]
       },
       {
          label: 'Series B',
          backgroundColor: ChartConfig.color.default,
          borderColor: ChartConfig.color.default,
          borderWidth: 1,
          hoverBackgroundColor: ChartConfig.color.default,
          hoverBorderColor: ChartConfig.color.default,
          data: [45, 39, 40, 60, 35, 25, 60]
       }
    ],
    onlineSources: '3500',
    today: '17,020',
    lastMonth: '20.30%'
 }

 export const onlineVisitorsData = {
    markers: [
        { latLng: [41.90, 12.45], name: 'Vatican City' },
        { latLng: [43.73, 7.41], name: 'Monaco' },
        { latLng: [-0.52, 166.93], name: 'Nauru' },
        { latLng: [-8.51, 179.21], name: 'Tuvalu' },
        { latLng: [43.93, 12.46], name: 'San Marino' },
        { latLng: [47.14, 9.52], name: 'Liechtenstein' },
        { latLng: [7.11, 171.06], name: 'Marshall Islands' },
        { latLng: [17.3, -62.73], name: 'Saint Kitts and Nevis' },
        { latLng: [3.2, 73.22], name: 'Maldives' },
        { latLng: [35.88, 14.5], name: 'Malta' },
        { latLng: [12.05, -61.75], name: 'Grenada' },
        { latLng: [13.16, -61.23], name: 'Saint Vincent and the Grenadines' },
        { latLng: [13.16, -59.55], name: 'Barbados' },
        { latLng: [17.11, -61.85], name: 'Antigua and Barbuda' },
        { latLng: [-4.61, 55.45], name: 'Seychelles' },
        { latLng: [7.35, 134.46], name: 'Palau' },
        { latLng: [42.5, 1.51], name: 'Andorra' },
        { latLng: [14.01, -60.98], name: 'Saint Lucia' },
        { latLng: [6.91, 158.18], name: 'Federated States of Micronesia' },
        { latLng: [1.3, 103.8], name: 'Singapore' },
        { latLng: [1.46, 173.03], name: 'Kiribati' },
        { latLng: [-21.13, -175.2], name: 'Tonga' },
        { latLng: [15.3, -61.38], name: 'Dominica' },
        { latLng: [-20.2, 57.5], name: 'Mauritius' },
        { latLng: [26.02, 50.55], name: 'Bahrain' },
        { latLng: [0.33, 6.73], name: 'São Tomé and Príncipe' }
    ],
    totalVisitors: '1655+'
}
 export const totalRevenue = {
    chartData: {
        labels: ['Last Week', 'Target'],
        datasets: [{
            data: [100, 260],
            backgroundColor: [
                ChartConfig.color.info,
                ChartConfig.color.primary
            ],
            hoverBackgroundColor: [
                ChartConfig.color.info,
                ChartConfig.color.primary
            ]
        }]
    },
    target: '$566',
    lastWeek: '$100'
}

export const trafficSources = {
    totalActiveUsers: '359,234',
    chartData: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        data: [40, 30, 85, 50, 90, 45, 55, 45, 80, 72, 78, 63]
    }
}

export default function StoreDetails() {

    const {id} = useParams();
    const navigate = useHistory();

    const redirect = (url) => {
        navigate.push('/company/store/' + id +  '/' + url);
    }

    return (
        <>
            <USCard>
                <USCardContent>
                    <Button variant="outlined" size="small" className="ml-5" onClick={() => redirect('catalogs')}>Catalogs</Button>
                    <Button variant="outlined" size="small" className="ml-5" onClick={() => redirect('menus')}>Menus</Button>
                    <Button variant="outlined" size="small" className="ml-5" onClick={() => redirect('categories')}>Sections</Button>
                </USCardContent>
            </USCard>
            <USCard>
                <USCardContent>
                    <div className="welcome-chart row">
                        {/*<div className="col-lg-3 col-sm-3 mb-30 mb-sm-0">
                            <span className="fw-semi-bold font-lg d-block mb-5">$21,349.29</span>
                            <span className="d-block fs-12 mb-3">Earned Today</span>
                            <AgencyWelcomeBarChart
                                data={WelcomeBarChart1.data}
                                labels={WelcomeBarChart1.labels}
                                color={WelcomeBarChart1.color}
                            />
                        </div>*/}
                        <div className="col-lg-4 col-sm-4 mb-30 mb-sm-0">
                            <span className="fw-semi-bold font-lg d-block mb-5">$21,349.29</span>
                            <span className="d-block fs-12 mb-3">Earned Today</span>
                            <CampaignBarChart
                                data={campaigns['last5Days']}
                            />
                        </div>
                        <div className="col-lg-8 col-sm-8 mb-30 mb-sm-0">
                            <TotalEarnsWithAreaChartWidget chartData={totalEarns} />
                        </div>

                        <div className="col-lg-3 col-sm-3 mb-30 mb-sm-0">
                            <SupportRequest />
                        </div>

                        <div className="col-lg-3 col-sm-3 mb-30 mb-sm-0">
                            <TrafficChannel
                                label='Direct User'
                                chartdata={[8.5, 6.75, 5.5, 7, 4.75]}
                                labels={['Direct User', 'Referral', 'Facebook', 'Google', 'Instagram']}
                            />
                        </div>
                        {/*<div className="col-lg-4 col-sm-4 mb-30 mb-sm-0">
                            <EmailStatisticsVersion2Widget data={emailStatisticsData}/>
                        </div>*/}
                        <div className="col-lg-3 col-sm-3 mb-30 mb-sm-0">
                            <Visitors
                                chartData={newsVisitorsData}
                            />
                        </div>
                        {/*<div className="col-lg-3 col-sm-3 mb-30 mb-sm-0">
                            <ProductStatsWidget data={productStats} />
                        </div>
                        <div className="col-lg-3 col-sm-3 mb-30 mb-sm-0">
                            <DailySales
                                label={dailySales.label}
                                chartdata={dailySales.chartdata}
                                labels={dailySales.labels}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-3 mb-30 mb-sm-0">
                            <USCard customClasses="gradient-success overflow-hidden">
                                <div className="p-20 text-white">
                                    <h2><IntlMessages id="widgets.dataUse" /></h2>
                                    <h2>{dataUsed.totalUsed}</h2>
                                </div>
                                <USCardContent>
                                    <SimpleBarChart
                                        labels={dataUsed.labels}
                                        datasets={dataUsed.datasets}
                                    />
                                </USCardContent>
                            </USCard>
                        </div>*/}
                        <div className="col-lg-3 col-sm-3 mb-30 mb-sm-0">
                            <Space data={spaceUsed} />
                        </div>

                        {/*<div className="col-lg-3 col-sm-3 mb-30 mb-sm-0">
                            <OverallTrafficStatusWidget chartData={trafficStatus} />
                        </div>
                        <div className="col-lg-3 col-sm-3 mb-30 mb-sm-0">
                            <StackedLineChart />
                        </div>
                        <div className="col-lg-3 col-sm-3 mb-30 mb-sm-0">
                            <Subscribers />
                        </div>
                        <div className="col-lg-3 col-sm-3 mb-30 mb-sm-0">
                            <TinyAreaChart
                                label="Orders"
                                chartdata={[542, 480, 430, 550, 530, 453, 380, 434, 568, 610, 700, 630]}
                                labels={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]}
                                backgroundColor={ChartConfig.color.info}
                                borderColor={ChartConfig.color.info}
                                lineTension={0}
                                height={110}
                                gradient
                                hideDots
                            />
                        </div>
                        <div className="col-lg-3 col-sm-3 mb-30 mb-sm-0">
                            <TinyLineChart
                                label="Open"
                                chartdata={trafficSources.chartData.data}
                                labels={trafficSources.chartData.labels}
                                borderColor={ChartConfig.color.warning}
                                pointBackgroundColor={ChartConfig.color.warning}
                                height={100}
                                pointBorderColor={ChartConfig.color.white}
                                borderWidth={4}
                            />
                        </div>
                        <div className="col-lg-12 col-sm-12 mb-30 mb-sm-0">
                            <div className="row">
                                <div className="col-sm-6 col-md-3 col-xl-3 w-xs-half-block">
                                    <RevenueWidget data={totalRevenue} />
                                </div>
                                <div className="col-sm-6 col-md-3 col-xl-3 w-xs-half-block">
                                    <OnlineVisitorsWidget data={onlineVisitorsData} />
                                </div>
                                <div className="col-sm-6 col-md-3 col-xl-3 w-xs-half-block">
                                    <TrafficSourcesWidget data={trafficSources} />
                                </div>
                                <div className="col-sm-6 col-md-3 col-xl-3 w-xs-half-block">
                                    <BandwidthUsageWidget />
                                </div>
                            </div>
                    </div>*/}
                    </div>
                    {/*

                    <TinyAreaChart />
                    <TinyLineChart />
                    <TinyPieChart /> */}
                </USCardContent>
            </USCard>
        </>
    )
}
