import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../../../Api/ApiService/Service";
import { USCard, USCardContent } from "../USCardComponent";
import { Avatar, Box, Button, CardHeader, Stepper } from "@mui/material";
import { Element } from "../../../helpers/RouteHelper";
import USTable from "../USTable";
import IntlMessages from 'Util/IntlMessages';
import { addNotification } from "../../../reduxSettings/reduxSlices/NotificationSlice";
import { useHistory } from 'react-router-dom';
import { red } from "@mui/material/colors";
import { closeDialog, openContractDialog } from "../../../reduxSettings/reduxSlices/systemSlice";
import USPickListStepper from "./USPickListStepper";

export default function USDetailsPage({objectName, id, parentFieldName=null, parentRecordId=null, actionButtons=true, showRelatedList=true, showNew=true, showEdit=true, showDelete=true, additionalButtons, handleAddtionalActions, viewRecord=true, detailsParams={}, moduleUrl='company', showStepper=false, stepperFieldName="" }) {

    const navigate = useHistory();
    const [readRecord, setReadRecord] = useState(false);
    const [sections,setSections] = useState(null);
    const [objectFields, setObjectFields] = useState(null);
    const [relatedList, setRelatedList] = useState(null);
    const [buttons, setButtons] = useState(null);

    const dispatch = useDispatch();
    const handleFieldValue = e => {
        const fieldName = e.target.name;
        setObjectFields(existingValues => ({
            ...existingValues,
            [fieldName]: e.target.value,
        }))
    }

    useEffect(() => {
        //if(id)
            setReadRecord(viewRecord);
            initData(id);
    }, [id, objectName]);

    const initData = async (recId) => {
        try {
            const currentRecord = await postRequest('/company/view/' + objectName + (recId ? '/' + recId : ''), detailsParams);
            console.log('currentRecord', currentRecord);
            const hasButtons = currentRecord.data.data?.layout?.buttons ? true : false;
            let hasSections = currentRecord.data.data?.layout?.sections ? true : false;
            let hasData = currentRecord.data.data?.recordData ? true : false;
            let hasRelatedLists = currentRecord.data.data?.layout?.relatedList ? true : false;
            if(hasData) {
                let currSelRec = currentRecord.data.data.recordData;
                if(parentFieldName && parentRecordId) {
                    currSelRec = {...currSelRec, [parentFieldName]: parentRecordId};
                }
                setObjectFields(currSelRec);
            }
            if(hasSections) {
                setSections(currentRecord.data.data.layout.sections);
                console.log('sections', currentRecord.data.data.layout.sections);
            }

            if(hasRelatedLists && showRelatedList) {
                setRelatedList(currentRecord.data.data.layout.relatedList);
            }
            if(hasButtons) {
                console.log('buttons', currentRecord.data.data?.layout?.buttons);
                setButtons(currentRecord.data.data?.layout?.buttons);
            }
        } catch (error) {
            console.error(error);
            dispatch(addNotification({"type" : "info", "message" : error}));
        }
    }

    const chekIfDependedLookup = (currentField) => {
        const propExist = currentField.hasOwnProperty('depended_picklist');
        if(propExist) {
            const hasField = objectFields.hasOwnProperty(currentField.depended_picklist);
            if(hasField) {
                return objectFields[currentField.depended_picklist];
            }
        }
        return null;
    }

    const handleSaveRecord = () => {
        saveCurrentRecord(objectFields);
    }

    const saveCurrentRecord = async(recToSave) => {
        try {
            const response = await postRequest('/company/edit/'+objectName + (id ? '/' + id : ''), recToSave);
            if(response.status == 200) {
                setReadRecord(true);
                dispatch(addNotification({"type" : "info", "message" : response.data.data.message}));
                dispatch(closeDialog());
            } else {
                dispatch(addNotification({"type" : "error", "message" : "Error record creation"}));
            }
        } catch(error) {
            console.error(error);
            dispatch(addNotification({"type" : "info", "message" : error}));
        }
    }
    const handleDelete = async () => {
        const response = await getRequest('/company/delete/'+objectName +  '/' + id);
        if(response.status == 200) {
            navigate.push(`/${moduleUrl}`);
        } else {
            dispatch(addNotification({"type" : "error", "message" : "New Record Crea"}));
        }
    }

    const handleAction = async (url) => {
        try {
            const response = await getRequest(url);
            console.log(response);
            navigate.push(`/${moduleUrl}/` + response.data.data);
        } catch(e) {

        }
    }

    const handleUpdateStepper = (fName, fValue) => {
        let curRec = objectFields;
        setObjectFields(existingValues => ({
            ...existingValues,
            [fName]: fValue,
        }));
        curRec[fName] = fValue;
        saveCurrentRecord(curRec);
    }

    const handleNewContract = () => {
        dispatch(openContractDialog({contactId: objectFields?.ContactId, opportunityId: id, contractId : objectFields?.ContractId}));
    }

    return (
        <>
            <USCard >
                <CardHeader
                    avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        T
                    </Avatar>
                    }
                    action={
                        <>
                            {actionButtons && <div>
                                {readRecord && showNew && <Button size="small" variant="outlined" className="ml-5" onClick={() =>  navigate.push('/company/edit/'+objectName)}>New</Button>}
                                {readRecord && showEdit && <Button size="small" variant="outlined" className="ml-5" onClick={() => setReadRecord(false)}>Edit</Button>}
                                {!readRecord && <Button size="small" variant="outlined" className="ml-5" onClick={() => handleSaveRecord(navigate)}>Save</Button>}
                                {!readRecord && <Button size="small" variant="outlined" className="ml-5" onClick={() => {setReadRecord(true); dispatch(closeDialog())}}>Cancel</Button>}
                                {readRecord && showDelete && <Button size="small" variant="outlined" className="ml-5" onClick={handleDelete}>Delete</Button>}
                                {buttons && buttons.map((item, index) => (
                                    <Button key={index} size="small" variant="outlined" className="ml-5" onClick={() => handleAction(item.value+id)}><IntlMessages id={item.Name}/></Button>
                                ))}
                                {(additionalButtons && objectFields) && additionalButtons.map((item, index) => (
                                    <Button key={index} size="small" variant="outlined" className="ml-5" onClick={() => handleAddtionalActions(item.Name, objectFields)}><IntlMessages id={item.Label}/></Button>
                                ))}
                                {(objectName == 'opportunity' && readRecord) && <Button size="small" variant="outlined" className="ml-5" onClick={handleNewContract}>Контракт</Button>}
                            </div>}
                        </>
                    }
                    title={<IntlMessages id={objectName}/>}
                    subheader="Record"
                />
                    <USCardContent>
                        {(objectName == 'opportunity' && objectFields) && <USPickListStepper objectName={objectName} selectedValue={objectFields["Stage"]} fieldName="Stage" handleUpdate={handleUpdateStepper} />}
                        {sections && objectFields ? sections.map((section, i) => (
                            <div className="row p-10" key={'section:'+i}>
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <Box style={{width: '100%', height:'25px', backgroundColor: '#e2eaf2'}} >
                                        {section.sectionName}
                                    </Box>
                                    {(section.style && section.style === 'columns') ?
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ display:'block' }}>
                                                {section.leftSideFields && section.leftSideFields.map((lField, j) => {
                                                    if(lField.show) {
                                                        let depLookUp = chekIfDependedLookup(lField);
                                                        return <Element objectName={objectName} key={j} fieldDetails={lField} handleEdit={handleFieldValue} value={objectFields[lField.field_api_name]} dependedObjectValue={depLookUp} variant="standard"  readOnly={readRecord}/>
                                                    }
                                                    return null;
                                                })}
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6" style={{ display:'block' }}>
                                                {section.rightSideFields && section.rightSideFields.map((rField, k) => {
                                                    if(rField.show) {
                                                        let depLookUp = chekIfDependedLookup(rField);
                                                        return <Element objectName={objectName} key={k} fieldDetails={rField} handleEdit={handleFieldValue} value={objectFields[rField.field_api_name]} dependedObjectValue={depLookUp}  variant="standard"  readOnly={readRecord}/>
                                                    }
                                                    return null;
                                                })}
                                            </div>
                                        </div>
                                            :
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12 col-lg-12" style={{ display:'block' }}>
                                                {section.leftSideFields && section.leftSideFields.map((lField, j) => {
                                                    if(lField.show) {
                                                        let depLookUp = chekIfDependedLookup(lField)
                                                        return <Element objectName={objectName} key={j} fieldDetails={lField} handleEdit={handleFieldValue} value={objectFields[lField.field_api_name]} dependedObjectValue={depLookUp} variant="standard" readOnly={readRecord}/>
                                                    }
                                                    return null;
                                                })}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            )) : null
                        }
                    </USCardContent>
                </USCard>
                {(objectName && relatedList && readRecord) &&
                    relatedList.map((listItem, tableIndex) => {
                        return <div key={'rl:'+tableIndex} style={{ marginBottom:'10px' }}><USTable key={listItem.Title} tableTitle={<IntlMessages id={listItem.Title}/>} parentObjectName={objectName} parentObjectId={id} parentFieldName={listItem.ParentFieldName} objectName={listItem.ObjectName} requestUrl={'company/child'} expandableRows={false} handleNew={true}/></div>
                })}
        </>
    )

}
