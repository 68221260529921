/**
 * Basic Table
 */

import USTable from '../../../../USCommonComponents/USTable';

function Assets({employeeID}) {
    return (
        <USTable tableTitle="Assets" parentObjectId={employeeID} parentFieldName="EmployeeId" objectName="asset" requestUrl={'company/child/'} expandableRows={false} handleNew={true}/>
    );
}

export default Assets;
