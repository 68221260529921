import React from 'react'
import { USCard, USCardContent } from '../../../USCommonComponents/USCardComponent'
import { Button, CardHeader } from '@mui/material'
import USTable from '../../../USCommonComponents/USTable'
import { useHistory } from 'react-router-dom';

export default function SalesCompanyProducts() {
    const navigate = useHistory();
    return (
        <USCard>
            <CardHeader
                action={
                    <>
                        <Button variant="outlined" onClick={() => navigate.push('edit/productscompany')}>New</Button>
                        <Button variant="outlined" onClick={() => navigate.push('edit/productscompany')}>Download Template</Button>
                        <Button variant="outlined" onClick={() => navigate.push('edit/productscompany')}>Upload Products</Button>
                    </>
                }
                title="Company's Products"
                className="ml-10 mr-10"
            />
            <USCardContent>
                <USTable tableTitle="Company Products" objectName={'productscompany'} requestUrl={'company/getTableList'} expandableRows={false} hideToolbar={false} hidePagination={false} packageUrl="sales"/>
            </USCardContent>
        </USCard>
    )
}
