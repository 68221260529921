import { Route } from "react-router-dom";

 export const LoggedForCompany = ({component:Component, token:Token, location, routeRedirect,...rest}) => {
    console.log('routeRedirect', routeRedirect);
    console.log('location', location);
    console.log('token', Token);
    return <Route {...rest} render={props => (
        (Token != 'null' && Token.length > 0)
        ? <Component />
        :  <Component />//<Redirect to={{pathname:'/login', state:{from: location.pathname}}} />
    )}/>
}


import USText from 'Components/USCommonComponents/USService/Inputs/USText';
import USDate from 'Components/USCommonComponents/USService/Inputs/USDate';
import USCheckBox from 'Components/USCommonComponents/USService/Inputs/USCheckBox';
import USTextArea from 'Components/USCommonComponents/USService/Inputs/USTextArea';
import USPickList from 'Components/USCommonComponents/USService/Inputs/USPickList';
import USLookup from 'Components/USCommonComponents/USService/Inputs/USLookup';
import USNumber from 'Components/USCommonComponents/USService/Inputs/USNumber';

export const Element = ({fieldDetails, handleEdit, value, variant, dependedObject, dependedObjectValue, readOnly, keyItem, objectName='Account'}) => {
    switch (fieldDetails.field_type) {
        case 'text':
            return (<USText fieldDetails={fieldDetails} handleChange={handleEdit} value={value} variant={variant} readOnly={readOnly} keyItem={keyItem}/>);
        case 'string':
            return (<USText fieldDetails={fieldDetails} handleChange={handleEdit} value={value} variant={variant} readOnly={readOnly} keyItem={keyItem}/>);
        case 'date':
            return (<USDate fieldDetails={fieldDetails} handleChange={handleEdit} value={value} variant={variant} readOnly={readOnly} keyItem={keyItem}/>);
        case 'datetime':
            return (<USDate fieldDetails={fieldDetails} handleChange={handleEdit} value={value} variant={variant} readOnly={readOnly} keyItem={keyItem}/>);
        case 'checkbox':
            return (<USCheckBox fieldDetails={fieldDetails} handleChange={handleEdit} value={value} variant={variant} readOnly={readOnly} keyItem={keyItem}/>);
        case 'boolean':
            return (<USCheckBox fieldDetails={fieldDetails} handleChange={handleEdit} value={value} variant={variant} readOnly={readOnly} keyItem={keyItem}/>);
        case 'textArea':
            return (<USTextArea fieldDetails={fieldDetails} handleChange={handleEdit} value={value} variant={variant} readOnly={readOnly} keyItem={keyItem}/>);
        case 'integer':
            return (<USNumber fieldDetails={fieldDetails} handleChange={handleEdit} value={value} variant={variant} readOnly={readOnly} keyItem={keyItem}/>);
        case 'float':
            return (<USNumber fieldDetails={fieldDetails} handleChange={handleEdit} value={value} variant={variant} readOnly={readOnly} keyItem={keyItem}/>);
        case 'picklist':
            return (<USPickList objectName={objectName} fieldDetails={fieldDetails} handleChange={handleEdit} value={value} variant={variant} readOnly={readOnly} keyItem={keyItem}/>);
        case 'lookup':
            return (<USLookup fieldDetails={fieldDetails} handleChange={handleEdit} value={value} variant={variant} dependedObjectValue={dependedObjectValue} dependedObject={dependedObject} readOnly={readOnly} keyItem={keyItem}/>);
        case 'bigint':
            return (<USLookup fieldDetails={fieldDetails} handleChange={handleEdit} value={value} variant={variant} dependedObjectValue={dependedObjectValue} dependedObject={dependedObject} readOnly={readOnly} keyItem={keyItem}/>);
        default:
            return null;
    }
}
