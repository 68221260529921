import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import { USCard } from "../../../USCommonComponents/USCardComponent";
import IntlMessages from 'Util/IntlMessages';
import USGeneralInformationSettings from "./USGeneralInformationSettings";
import USProfileAccessSettings from "./USProfileAccessSettings";
import USPriceSettings from "./USPriceSettings";


function USCompanySettings() {

    const [tabIndex, setTabIndex] = useState('1');

    const handleChangeTab = (event, newValue) => {
        setTabIndex(newValue);
    };

    return (
        <USCard>
            <TabContext value={tabIndex}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                        <Tab label={<IntlMessages id="content.company-details.main"/>} value="1" />
                        <Tab label={<IntlMessages id="common.profileSettings"/>} value="2" />
                        <Tab label={<IntlMessages id="common.price-settings"/>} value="3" />
                    </TabList>
                </Box>
                <TabPanel value="1"><USGeneralInformationSettings /></TabPanel>
                <TabPanel value="2"><USProfileAccessSettings/></TabPanel>
                <TabPanel value="3"><USPriceSettings /></TabPanel>
            </TabContext>
        </USCard>


    );
}

export default USCompanySettings;
