import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationManager } from "react-notifications";

const initialState = {
  open: false,
  type: "info",
  message: "",
  title:"",
  timeout: 5000
};

export const NotificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        addNotification: (state, action) => {
            switch (action.payload.type) {
                case 'info':
                    NotificationManager.info(action.payload.message);
                break;
                case 'success':
                    NotificationManager.success(action.payload.message, action.payload.title);
                break;
                case 'warning':
                    NotificationManager.warning(action.payload.message, action.payload.title, state.timeout);
                break;
                case 'error':
                    NotificationManager.error(action.payload.message, action.payload.title, 5000, () => {
                    alert('callback');
                });
                break;
            }
        },
        clearNotification: (state) => ({ ...state, open: false })
    }
});

export const {addNotification, clearNotification} = NotificationSlice.actions;
export default NotificationSlice.reducer;
