import { useEffect, useState } from 'react';
import { getContractDocument } from '../../../../Api/SalesConsoleRequests/USSalesRequestHandler';
export default function USCheckContract({id, contactPasport, issuedDate}) {
    const [selectedDocs, setSelectedDocs] = useState(null);

	useEffect(() => {
		initData(id);
	}, [id]);

    const initData = async (recId) => {
        const response = await getContractDocument({'contractName': 'contrakt1.docx', 'contactPasport': contactPasport, 'issuedDate': issuedDate, 'opportunityId': recId});
        setSelectedDocs(response);
    }

	return <>
        {selectedDocs && <iframe src={selectedDocs} style={{width:'100%', height:'500px'}} ></iframe>}
    </>
}
