import { Button, Stack, Switch, TextField, IconButton } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../../../Api/ApiService/Service";
import { USCard, USCardContent } from "../../../USCommonComponents/USCardComponent";
import USCollapsibleCard from "../../../USCommonComponents/USCollapsibleCard/USCollapsibleCard";

import ToysOutlinedIcon from '@mui/icons-material/ToysOutlined';

import { Table } from 'reactstrap';
import IntlMessages from 'Util/IntlMessages';

import { useParams } from "react-router-dom";
import USRecordDetails from "../../../USCommonComponents/USRecordDetails";
import USIOSSwitch from "../../../USCommonComponents/USIOSSwitch";

export default function USCompanyEditRole() {
    const {id} = useParams();
    const [permissions, setPermissions] = useState(null);
    const [objectsName, setObjectsName] = useState(null);

    useEffect(() => {
        getPermissions(getPermissions);
    },[])

    const getPermissions = async () => {
        const response = await getRequest('permissions/role/'+id);
        console.log(response.data.data);
        //const resultData = response.data.data.tableData;
        setPermissions(response.data.data.tableData);
        setObjectsName(response.data.data.objectsList);
    }

    const changeCheckBox = async (recId) => {
        const response = await getRequest('permissions/updatePermission/'+ id + '/' + recId);
        const responsedata = response.data.data;
        console.log(responsedata);
        setPermissions(responsedata.tableData);
        //searchBrand(searchStr);
    }

    return (

            <USRecordDetails objectName={'roles'} recordId={id}
            actions={<Button onClick={e => {}}  variant='outlined' >
                        <IntlMessages id="common.assignToUser" />
                    </Button>}>
                <div className="responsive-table-wrapper">
                    <USCollapsibleCard heading={<IntlMessages id="common.rolePermissions" />}>
                        <div className="table-responsive">
                            <div className="unseen">
                                <Table bordered striped>
                                    <thead>
                                        <tr className="bg-primary text-white">
                                            <th><IntlMessages id="common.objectName" /></th>
                                            <th><IntlMessages id="common.create" /></th>
                                            <th><IntlMessages id="common.read" /></th>
                                            <th><IntlMessages id="common.update" /></th>
                                            <th><IntlMessages id="common.delete" /></th>
                                            <th><IntlMessages id="common.allpermissions" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {objectsName && objectsName.map((objectsNameItem, key) => {
                                            return <tr key={objectsNameItem}>
                                                <td>{objectsNameItem} </td>
                                                    {permissions[objectsNameItem] && permissions[objectsNameItem].map((permissionAction) => {
                                                        if(permissionAction.action == 'Create') {
                                                            return <td><USIOSSwitch sx={{ m: 1 }} onClick={() => changeCheckBox(permissionAction.id, 'isActive')} checked={permissionAction.isActive}/></td>
                                                        } else if(permissionAction.action == 'Read') {
                                                            return <td><USIOSSwitch sx={{ m: 1 }} onClick={() => changeCheckBox(permissionAction.id, 'isActive')} checked={permissionAction.isActive}/></td>
                                                        } else if(permissionAction.action == 'Update') {
                                                            return <td><USIOSSwitch sx={{ m: 1 }} onClick={() => changeCheckBox(permissionAction.id, 'isActive')} checked={permissionAction.isActive}/></td>
                                                        } else if(permissionAction.action == 'Delete') {
                                                            return <td><USIOSSwitch sx={{ m: 1 }} onClick={() => changeCheckBox(permissionAction.id, 'isActive')} checked={permissionAction.isActive}/></td>
                                                        }
                                                    })}
                                                <td><USIOSSwitch sx={{ m: 1 }} onClick={() => changeCheckBox(objectsNameItem, 'isActive')} checked={true}/></td>
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </USCollapsibleCard>
                </div>
            </USRecordDetails>


    )
}
