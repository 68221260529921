import { Card } from "@mui/material";
import { toggleSidebar } from "../BuilderConfig/Utils";

function TopNav() {
  const handleClick = () => {
    toggleSidebar(false);
  };
  return (
    <Card>
        <nav className="navbar navbar-light">
            <div className="container">
                <div className="panel__devices"></div>
                <div className="panel__editor"></div>
                <div className="panel__basic-actions"></div>
            </div>
        </nav>
    </Card>
  );
}

export default TopNav;
