import { Avatar, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, FormControl, IconButton, MenuItem, NativeSelect, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { red } from '@mui/material/colors';
import { getTableData } from '../../../Api/SalesConsoleRequests/USSalesRequestHandler';

export default function USTaskSidebar({objectName, handleOpen, handleCall, refreshToken}) {

    const [relatedObjects, setRelatedObjects] = useState();
    const [count, setCount] = useState(0);
    const [tableData, setTableData] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [listView, setListView] = useState();
    const [selectedListView, setSelectedListView] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const fetchData = async () => {
        const response = await getTableData('company/getTableList', objectName, pageNumber, 'created_at', 'asc', rowsPerPage, null, null, '',selectedListView);
        setRelatedObjects(response?.relatedObjects);
        setCount(response?.count);
        setTableData(response?.tableData);
        setListView(response?.listView);
        if(!selectedListView) {
            response?.listView?.map((listViewItem) => {
                if(listViewItem.selected) {
                    setSelectedListView(listViewItem);
                }
            });
        }
    }

    useEffect(() => {
        fetchData();
    },[objectName, selectedListView, refreshToken, pageNumber])

    const handleNextPage = () => {
        setPageNumber(pageNumber + 1);
    }

    const handlePrevPage = () => {
        if(pageNumber > 0) {
            setPageNumber(pageNumber - 1);
        }
    }

    const handleChangeRecPerPage = (value) => {
        setRowsPerPage(value);
        setPageNumber(0);
    }

    const [openDetails, setOpenDetails] = useState(false);
    const [taskId, setTaskId] = useState(null);
    const handleOpenDetails = (selectedId) => {
        setTaskId(selectedId);
        setOpenDetails(true);
    }

    const handleCloseTaskDetails = () => {
        setTaskId(null);
        setOpenDetails(false);
    }

    return (
        <Card>
            <CardHeader
                avatar={
                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    T
                </Avatar>
                }
                action={
                    <div>
                        <NativeSelect
                            defaultValue={selectedListView}
                            onChange={(e) => setSelectedListView(e.target.value)}
                            >
                                {listView && listView.map((listViewItem, indexKey) => (
                                    <option key={indexKey} value={listViewItem.ApiName} >{listViewItem.Name}</option>
                                ))}
                        </NativeSelect>
                        <IconButton aria-label="settings">
                            <KeyboardDoubleArrowDownOutlinedIcon />
                        </IconButton>
                    </div>
                }
                title="Task List"
                subheader="Current list"
            />
            <CardContent>
                <input type="text" className="form-control" />
                <Table bordered striped size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Due</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData && tableData.map((tableRowItem, itemRowIndex) => (
                        <TableRow hover key={itemRowIndex}>
                            <TableCell>
                                <div style={{ display: 'flex', justifyContent:'space-between' }}>
                                    <div>{tableRowItem.Name}</div>
                                    <div>{tableRowItem.Phone}
                                        <IconButton aria-label="settings" onClick={() => handleCall(tableRowItem)}>
                                            <PhoneAndroidOutlinedIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <div style={{ display: 'flex',justifyContent:'space-between' }}>
                                    <div>{tableRowItem.Status}</div>
                                    <div>{tableRowItem.DueDate}</div>
                                    <div>
                                        <ButtonGroup size="small" aria-label="Small button group">
                                            {/* <Button key="one" onClick={() => handleOpenDetails(tableRowItem.id)}>Open</Button> */}
                                            <Button key="one" onClick={() => handleOpen(tableRowItem.id)}>Open</Button>
                                            <Button key="two">Close</Button>
                                            <Button key="three">Reject</Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>))}
                    </TableBody>
               </Table>
            </CardContent>
            <CardActions>
                <Button size="small" variant='outlined' onClick={handleNextPage}>Next</Button>
                <Button size="small" variant='outlined' onClick={handlePrevPage}>Previous</Button>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={rowsPerPage}
                        label="Age"
                        onChange={(e)=> handleChangeRecPerPage(e.target.value)}
                        size="small"
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                    </Select>
                </FormControl>
            </CardActions>
            {/* {objectName && <USModal objectName="task" id={taskId} isOpenModal={openDetails} closeModal={handleCloseTaskDetails} />} */}
        </Card>
    )
}
