import {LoggedForCompany} from '../../../helpers/RouteHelper';
import USContainerUserLayout from './USContainerUserLayout';
import Pages from '../Pages';
import { useLocation } from 'react-router-dom';

function USUserLayout() {
    const location = useLocation();
    return (
        <USContainerUserLayout >
            <LoggedForCompany path="/user" component={Pages} token={localStorage.getItem('token')} location={ location }/>
        </USContainerUserLayout >
    )
}

export default USUserLayout;
