import USDetailsPage from "../../../../USCommonComponents/USDetailsPage";
import {useParams} from "react-router-dom";

export default function USAnnouncementDetails() {
    const {id} = useParams();

    return (
        <USDetailsPage objectName="event" id={id}/>
    )
}

