/**
 * Nav Menu Item
 */
import React, { Fragment, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import Chip from '@mui/material/Chip';

// intl messages
import IntlMessages from 'Util/IntlMessages';

function  NavMenuItem({ menu, onToggleSidebarMenu }) {
    const [subMenuOpen, setSubMenuOpen] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);

     /**
    * On Toggle Collapse Menu
    */
    const onToggleCollapseMenu = (index) => {
        if (subMenuOpen === '') {
                setSubMenuOpen(index);
        }
        else if (subMenuOpen !== index) {
            setSubMenuOpen(index);
        }
        else {
            setSubMenuOpen('');
        }
    }

    const onToggleMainMenu = (ismMenuOpen) => {
        setMenuOpen(!ismMenuOpen);
    }

    return (
        <>
            {menu.child_routes != null &&
                <Fragment>
                    <ListItem button component="li" onClick={() => onToggleMainMenu(menuOpen)} className={`list-item ${classNames({ 'item-active': menuOpen })}`}>
                        <ListItemIcon className="menu-icon">
                            <i className={menu.menu_icon}></i>
                        </ListItemIcon>
                        <span className="menu text-capitalize">
                            <IntlMessages id={menu.menu_title} />
                        </span>
                        {menu.new_item && menu.new_item === true ?
                            <Chip label="new" className="new-item" color="secondary" />
                            :
                            ''
                        }
                    </ListItem>
                    <Collapse in={menuOpen} timeout="auto" className="sub-menu">
                        <Fragment>
                            {menu.type_multi == null ?
                                <List className="list-unstyled py-0">
                                    {menu.child_routes != null ? menu.child_routes.map((subMenu, index) => {
                                        if(!subMenu.child_routes && subMenu.isVisible) {
                                            return (
                                                <ListItem button component="li" key={index}>
                                                    <NavLink to={subMenu.path} activeClassName="item-active" >
                                                        <span className="menu">
                                                            <IntlMessages id={subMenu.menu_title} />
                                                        </span>
                                                        {subMenu.new_item && subMenu.new_item === true ?
                                                            <Chip label="new" className="new-item" color="secondary" />
                                                            :
                                                            ''
                                                        }
                                                    </NavLink>
                                                </ListItem>
                                            )
                                        } else {
                                            return (
                                                <Fragment key={index}>
                                                    {subMenu?.isVisible && <ListItem button component="li"
                                                        onClick={() => onToggleCollapseMenu(index)}
                                                        className={`list-item ${classNames({ 'item-active': subMenuOpen === index })}`}
                                                    >
                                                    <span className="menu">
                                                        <IntlMessages id={subMenu.menu_title} />
                                                    </span>
                                                    </ListItem>}
                                                    {subMenu?.isVisible && <Collapse in={subMenuOpen === index} timeout="auto">
                                                        <List className="list-unstyled py-0">
                                                            {subMenu?.child_routes?.map((nestedMenu, nestedKey) => (
                                                                <Fragment>
                                                                    {nestedMenu?.isVisible && <ListItem button component="li" key={nestedKey}>
                                                                        <NavLink activeClassName="item-active" to={nestedMenu.path}>
                                                                        <span className="menu pl-10 d-inline-block">
                                                                            <IntlMessages id={nestedMenu.menu_title} />
                                                                        </span>
                                                                        </NavLink>
                                                                    </ListItem>}
                                                                </Fragment>

                                                            ))}
                                                        </List>
                                                    </Collapse>}
                                                </Fragment>
                                            )
                                        }

                                    }) : null}
                                </List>
                            :
                                <List className="list-unstyled py-0">
                                    {menu.child_routes ? menu.child_routes.map((subMenu, index) => {
                                        return (
                                            <Fragment key={index}>
                                                {subMenu?.isVisible && <ListItem button component="li"
                                                    onClick={() => onToggleCollapseMenu(index)}
                                                    className={`list-item ${classNames({ 'item-active': subMenuOpen === index })}`}
                                                >
                                                    <span className="menu">
                                                        <IntlMessages id={subMenu.menu_title} />
                                                    </span>
                                                </ListItem>}
                                                {subMenu?.isVisible && <Collapse in={subMenuOpen === index} timeout="auto">
                                                    <List className="list-unstyled py-0">
                                                        {subMenu.child_routes.map((nestedMenu, nestedKey) => (
                                                            <Fragment key={nestedKey}>
                                                                {nestedMenu?.isVisible && <ListItem button component="li" >
                                                                    <NavLink activeClassName="item-active" to={nestedMenu.path}>
                                                                    <span className="menu pl-10 d-inline-block">
                                                                        <IntlMessages id={nestedMenu.menu_title} />
                                                                    </span>
                                                                    </NavLink>
                                                                </ListItem>}
                                                            </Fragment>
                                                        ))}
                                                    </List>
                                                </Collapse>}
                                            </Fragment>
                                        )
                                    }) : null}
                                </List>
                            }
                        </Fragment>
                    </Collapse>
                </Fragment>
            }
            {menu.child_routes == null &&
                <ListItem button component="li">
                    <NavLink activeClassName="item-active" to={menu.path}>
                        <ListItemIcon className="menu-icon">
                            <i className={menu.menu_icon}></i>
                        </ListItemIcon>
                        <span className="menu">
                            <IntlMessages id={menu.menu_title} />
                        </span>
                    </NavLink>
                </ListItem>
            }
        </>
    )
}

 export default NavMenuItem;
