import { Button, CardHeader, Stack, TextField, Typography } from "@mui/material";
import { USCard, USCardContent } from "../../../USCommonComponents/USCardComponent";
import { useParams } from 'react-router-dom';
import USObjectManagerLayout from "./USObjectManagerLayout";
import USIOSSwitch from "../../../USCommonComponents/USIOSSwitch";

export default function USObjectDetails() {

    const {objectName} = useParams();

    return (
        <USObjectManagerLayout objectName={objectName}>
            <USCard>
                <CardHeader
                    action={
                        <div>
                            <Button variant="outlined" size="small">New</Button>
                            <Button variant="outlined" size="small">Edit</Button>
                            <Button variant="outlined" size="small">Delete</Button>
                        </div>
                    }
                    title="Details"
                    subheader=""
                />
                <USCardContent>
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <TextField
                                id="standard-number"
                                label="API Name"
                                InputLabelProps={{
                                    shrink: true,
                                    readOnly : true
                                }}
                                variant="standard"
                                value={objectName}
                                size="small"
                            />
                            <TextField
                                id="standard-number"
                                label="Label"
                                InputLabelProps={{
                                    shrink: true,
                                    readOnly : true
                                }}
                                variant="standard"
                                size="small"
                            />
                            <TextField
                                id="standard-number"
                                label="Deployment Status"
                                InputLabelProps={{
                                    shrink: true,
                                    readOnly : true
                                }}
                                variant="standard"
                                size="small"
                            />
                            <TextField
                                id="standard-number"
                                label="Description"
                                InputLabelProps={{
                                    shrink: true,
                                    readOnly : true
                                }}
                                variant="standard"
                                size="small"
                            />
                        </div>

                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <Stack direction="row" spacing={2} alignItems="left" >
                                <Typography>Enable Reports</Typography>
                                <USIOSSwitch name="isActive" onChange={(e) => console.log(e) } checked={false} readOnly={true}/>
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="left" >
                                <Typography>Track Activities</Typography>
                                <USIOSSwitch name="isActive" onChange={(e) => console.log(e) } checked={false} readOnly={true}/>
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="left" >
                                <Typography>Track Field History</Typography>
                                <USIOSSwitch name="isActive" onChange={(e) => console.log(e) } checked={false} readOnly={true}/>
                            </Stack>
                            <Stack direction="row" spacing={2} alignItems="left" >
                                <Typography>Active</Typography>
                                <USIOSSwitch name="isActive" onChange={(e) => console.log(e) } checked={false} readOnly={true} />
                            </Stack>
                        </div>
                    </div>
                </USCardContent>
            </USCard>
        </USObjectManagerLayout>
    )
}
