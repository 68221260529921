import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import IntlMessages from 'Util/IntlMessages';

export default function USCheckBox({fieldDetails, handleChange, value, variant, readOnly, keyItem=''}) {

    return (
        <FormControlLabel
            control={
                <Switch
                    id={fieldDetails.field_api_name}
                    checked={value ? true : false}
                    onChange={(e) => !readOnly ? handleChange({target:{value: !value , name: fieldDetails.field_api_name}}, keyItem) : null}
                    name={fieldDetails.field_api_name}
                />
            }
            label={<IntlMessages id={fieldDetails.field_label} />}
        />
    );
}

