import { Button, Table, TableCell, TableRow, Typography, CardHeader } from "@mui/material";
import { USCard, USCardContent } from "../../../USCommonComponents/USCardComponent";
import { useParams, useHistory } from 'react-router-dom';
import USObjectManagerLayout from "./USObjectManagerLayout";
import IntlMessages from 'Util/IntlMessages';
import { useEffect, useState } from "react";
import { getRequest } from "../../../../Api/ApiService/Service";
import { addNotification } from "../../../../reduxSettings/reduxSlices/NotificationSlice";
import { useDispatch } from "react-redux";
import { getObjectTemplate } from "../../../../Api/AdminRequests/AdminRequestHandlers";

export default function USObjectFields() {
    const { objectName } = useParams();
    const navigate = useHistory();
    const [fieldList, setFieldList] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchData();
    },[objectName])

    const fetchData = async () => {
        try {
            const response = await getRequest('/company/objectFields/' + objectName);
            console.log("response.data.data", response);
            setFieldList(response.data.data);
        } catch (error) {
            dispatch(addNotification({"type" : "error", "message" : error, "title" : "load error"}));
        }
    }

    const handleDownloadTemplate = async(objname) => {
        try {
            const response = await getObjectTemplate(objname);
            console.log("response.data.data", response);
            window.location.href = 'http://127.0.0.1:8000' + response;
        } catch (error) {
            dispatch(addNotification({"type" : "error", "message" : error, "title" : "load error"}));
        }
    }

    return (
        <USObjectManagerLayout objectName={objectName}>
            <USCard>
                <CardHeader
                    action={
                        <div>
                            <Button variant="outlined" size="small">New</Button>
                            <Button variant="outlined" size="small" onClick={() => handleDownloadTemplate(objectName)}>Download Template</Button>
                        </div>
                    }
                    title="Fields"
                    subheader=""
                />
                <USCardContent>
                    <div className="table-responsive">
                        <div className="unseen">
                            <Table bordered striped size="small">
                                <thead>
                                    <tr className="bg-primary text-white">
                                        <th ><IntlMessages id="common.label" /></th>
                                        <th><IntlMessages id="common.apiname" /></th>
                                        <th><IntlMessages id="common.type" /></th>
                                        <th><IntlMessages id="common.description" /></th>
                                        <th><IntlMessages id="common.indexed" /></th>
                                        <th><IntlMessages id="widgets.action" /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fieldList && fieldList.map((fieldItem, key) => {
                                        return <TableRow key={fieldItem.fieldName}>
                                                    <TableCell>
                                                        <Button onClick={() => navigate.push('/company/ObjectManager/' + objectName + '/Fields/' + fieldItem.fieldName)} variant="text"><IntlMessages id={fieldItem.fieldLabel} /></Button>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {fieldItem.fieldName}
                                                    </TableCell>
                                                    <TableCell>
                                                        {fieldItem.fieldType}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {fieldItem.referenceObject ? "Reference to " +  fieldItem.referenceObject : ""}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        Is Indexed ?
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        edit | delete
                                                    </TableCell>
                                                </TableRow>
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </USCardContent>
            </USCard>
        </USObjectManagerLayout>
    )
}
