import { Button, CardActions } from "@mui/material";
import { USCard, USCardContent } from "../../../USCommonComponents/USCardComponent";


export default function USPublicHeadBanner() {
    const login = () => {
        location.href="/login"
    }
    return (
        <USCard styleElem={{minHeight: '400px', backgroundImage: `url(${"storage/public/site/headerBackground.jpg"})`}}>
            <div className="container">
                <USCardContent>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <Button variant="outliner" onClick={login}>Зарегистрироваться</Button>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            Какой то блок с маркетинговой информацией которая завлекает на регистрацию
                        </div>
                    </div>
                </USCardContent>

                <CardActions>
                    <Button variant="contained" size="small">Узнать больше</Button>
                </CardActions>
            </div>
        </USCard>
    )
}
