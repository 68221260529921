import React, { useEffect, useState } from 'react'
import { getCompanyProductById, getOpportunityProductById, saveOppProduct } from '../../../../Api/SalesConsoleRequests/USSalesRequestHandler';
import USSearchProduct from '../../../USCompanyComponents/Pages/USClientsManagement/USSalesConsoleProducts/USSearchProduct';
import USProductSearchSidebar from './USProductSearchSidebar';

export default function USProductStep({opportunityId}) {

    const [clientSelectedProduct, setClientSelectedProduct] = useState();
    const [refresAddedProducts, setRefresAddedProducts] = useState();

    const dataToSaveFunc = async(productDetails) => {
        const response = await saveOppProduct({'opportunityId': opportunityId,'parentRecordId': oppProductId, 'product': productDetails});
        setRefresAddedProducts(new Date().getTime());
        setClientSelectedProduct(null);
    }

    const updateProductDetails = async (selectedValue) => {
        setOppProductId(null);
        const response = await getCompanyProductById(selectedValue);
        setClientSelectedProduct(response);
    }

    const [oppProductId, setOppProductId] = useState();
    const updateOppProductDetails = async (selectedValue) => {
        const response = await getOpportunityProductById(selectedValue);
        console.log('updateOppProductDetails',response);
        setOppProductId(selectedValue);
        setClientSelectedProduct(response);
    }

    const handleOpenProduct = (recordId, objName) => {
        if(objName == 'productscompany') {
            updateProductDetails(recordId);
        } else {
            if(objName == 'opportunityproduct') {
                updateOppProductDetails(recordId);
            }
        }
    }

    return (
        <div className='row'>
            <div className='col-sm-3 col-md-3 col-lg-3'>
                <USProductSearchSidebar objectName={'productscompany'} sortField='created_at' handleOpen={handleOpenProduct}/>
            </div>
            <div className='col-sm-9 col-md-6 col-lg-6'>
                <USSearchProduct detail={clientSelectedProduct} handleUpdateProduct={dataToSaveFunc} />
            </div>
            <div className='col-sm-3 col-md-3 col-lg-3'>
                <USProductSearchSidebar objectName={'opportunityproduct'} handleOpen={handleOpenProduct} sortField="Name" parentFildName="OpportunityId" parentRecordId={opportunityId} refreshToken={refresAddedProducts} showDelete={true}/>
            </div>
        </div>
    )
}
