import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Element } from '../../../helpers/RouteHelper';

export default function USDetailsContent({sections, objectFields, readRecord, objectName, handleUpdateRecord}) {
    const [fields, setFields] = useState(null);
    const [layout, setLayout] = useState(null);
    const handleFieldValue = e => {
        let fieldsToUpdate = fields;
        const fieldName = e.target.name;
        setFields(existingValues => ({
            ...existingValues,
            [fieldName]: e.target.value,
        }));
        fieldsToUpdate[fieldName] = e.target.value;
        handleUpdateRecord(fieldsToUpdate);
    }

    const chekIfDependedLookup = (currentField) => {
        const propExist = currentField.hasOwnProperty('depended_picklist');
        if(propExist) {
            const hasField = objectFields.hasOwnProperty(currentField.depended_picklist);
            if(hasField) {
                return objectFields[currentField.depended_picklist];
            }
        }
        return null;
    }

    useEffect(() => {
        if(sections && objectFields) {
            setFields(objectFields);
            setLayout(sections);
        }
    },[sections, objectFields]);

    return (
        <div>
            {layout && fields ? layout.map((section, i) => (
                <div className="row p-10" key={i}>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <Box style={{width: '100%', height:'25px', backgroundColor: '#e2eaf2'}} >
                            {section.sectionName}
                        </Box>
                        {(section.style && section.style === 'columns') ?
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6" style={{ display:'block' }}>
                                    {section.leftSideFields && section.leftSideFields.map((lField, j) => {
                                        if(lField.show) {
                                            let depLookUp = chekIfDependedLookup(lField);
                                            return <Element objectName={objectName} key={j} fieldDetails={lField} handleEdit={handleFieldValue} value={fields[lField.field_api_name]} dependedObjectValue={depLookUp} variant="standard"  readOnly={readRecord}/>
                                        }
                                        return null;
                                    })}
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6" style={{ display:'block' }}>
                                    {section.rightSideFields && section.rightSideFields.map((rField, k) => {
                                        if(rField.show) {
                                            let depLookUp = chekIfDependedLookup(rField);
                                            return <Element objectName={objectName} key={k} fieldDetails={rField} handleEdit={handleFieldValue} value={fields[rField.field_api_name]} dependedObjectValue={depLookUp}  variant="standard"  readOnly={readRecord}/>
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>
                                :
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12" style={{ display:'block' }}>
                                    {section.leftSideFields && section.leftSideFields.map((lField, j) => {
                                        if(lField.show) {
                                            let depLookUp = chekIfDependedLookup(lField)
                                            return <Element objectName={objectName} key={j} fieldDetails={lField} handleEdit={handleFieldValue} value={fields[lField.field_api_name]} dependedObjectValue={depLookUp} variant="standard" readOnly={readRecord}/>
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                )) : null
            }
        </div>
    )
}
