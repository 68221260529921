import { Button, CardActions, Modal, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { USCard, USCardContent } from "../../../../../USCommonComponents/USCardComponent";
import USIOSSwitch from "../../../../../USCommonComponents/USIOSSwitch";


function USNewConnectionModal({isGoogle, isYandex,  isModalOpen, saveModal, closeModal}) {

    const [objectFields, setObjectFields] = useState({
        'Name' : isGoogle ? 'google' : isYandex ? 'yandex' : 'other',
        'isActive' : true,
        'Host' : isGoogle ? 'imap.gmail.com' : isYandex ? 'yandex.gmail.com' : 'other.gmail.com',
        'Port' : '993',
        'Encryption' : 'ssl',
        'ValidateCerteficate' : true,
        'Username' : '',
        'Password' : '',
        'Protocol' : 'imap',
        'AccountId' : localStorage.getItem('currentAccount')
    });

    const handleFieldValue = e => {
        const fieldName = e.target.name;
        let fieldValue = e.target.value;
        console.log(e);
        console.log(e.target.name, e.target.checked);

        if(fieldName === "isActive" || fieldName === "ValidateCerteficate") {
            if(objectFields[fieldName]) {
                fieldValue = false;
            } else {
                fieldValue = true;
            }
        }

        //const value = e.target.value != null && e.target.value.hasOwnProperty('value') ? e.target.value.value : e.target.value ;
        setObjectFields(existingValues => ({
            // Retain the existing values
            ...existingValues,
            // update the current field
            [fieldName]: fieldValue,
        }))
    }

    return (
        <Modal
            open={isModalOpen}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="m-10 w-30"
            style={{ left: '40%'}}
            >
            <USCard>
                <USCardContent>
                    <div className="col-sm-12 col-md-12 col-lg-12 p-20">
                        <div className="row" >
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10" >
                                <Stack direction="row" spacing={2} alignItems="left" className="mt-25">
                                    <Typography>Active</Typography>
                                    <USIOSSwitch name="isActive" onChange={(e) => handleFieldValue(e) } checked={objectFields['isActive']} />
                                </Stack>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12" >
                                <TextField id="standard-basic" label="Host" name="Host" onChange={(e) => handleFieldValue(e)} value={objectFields['Host']} variant="standard" />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10" >
                                <TextField id="standard-basic" label="Port" name="Port" onChange={(e) => handleFieldValue(e)} value={objectFields['Port']} variant="standard" />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10" >
                                <TextField id="standard-basic" label="Encryption" name="Encryption" onChange={(e) => handleFieldValue(e)} value={objectFields['Encryption']} variant="standard" />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10" >
                                <Stack direction="row" spacing={2} alignItems="left" className="mt-25">
                                    <Typography>Validate Certeficate</Typography>
                                    <USIOSSwitch name="ValidateCerteficate" onChange={(e) => handleFieldValue(e) } checked={objectFields['ValidateCerteficate']} />
                                </Stack>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10" >
                                <TextField id="standard-basic" label="Username" name="Username" onChange={(e) => handleFieldValue(e)} value={objectFields['Username']} variant="standard" />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mt-10  mb-10" >
                                <TextField id="standard-basic" label="Password" name="Password" onChange={(e) => handleFieldValue(e)} value={objectFields['Password']} variant="standard" />
                            </div>
                        </div>
                    </div>
                </USCardContent>
                <CardActions className='pb-20 ml-10 justify-content-center'>
                    <Button variant='outlined' size="small" color="error" onClick={closeModal}>Cancel</Button>
                    <Button variant='outlined' size="small" color="success" onClick={() => saveModal(objectFields)}>Save</Button>
                </CardActions>
            </USCard>
        </Modal>
    )
}
export default USNewConnectionModal;
