/**
 * Rct Horizontal Menu Layout
 */
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

// Components
import Header from 'Components/USCommonComponents/USHeader';
import Footer from 'Components/USCommonComponents/USFooter';
import HorizontalMenu from '../../USHorizontalMenu/HorizontalMenu';
import ThemeOptions from '../ThemeOptions/ThemeOptions';

function USHorizontalLayout({children}) {

    function renderPage() {
        return (
            <Scrollbars
                className="rct-scroll"
                autoHide
                autoHideDuration={100}
                style={{ height: 'calc(100vh - 100px)' }}
            >
                <div className="rct-page-content">
                    {children}
                    <Footer />
                </div>
            </Scrollbars>
        );
    }

    return (
        <div className="app-horizontal collapsed-sidebar">
            <div className="app-container">
                <div className="rct-page-wrapper">
                    <div className="rct-app-content">
                        <div className="app-header">
                            <Header horizontalMenu />
                        </div>
                        <div className="rct-page">
                            <HorizontalMenu />
                            {renderPage()}
                        </div>
                        <ThemeOptions />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default USHorizontalLayout;
