import { FormControl, InputLabel, MenuItem, Select, handleChange, Autocomplete, TextField } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import IntlMessages from 'Util/IntlMessages';
import { getRequest } from '../../../../Api/ApiService/Service';


function USPickList({fieldDetails, objectName, value, handleChange, variant, readOnly}) {

    const [inputValue, setInputValue] = useState(null);
    const [selectOptions, setSelectOptions] = useState(null);

    const checkIfNull = (val) => {
        return val;// != null ? val : '';
    }

    useEffect(() => {
        const init = async () => {
            const fieldApiName = fieldDetails.field_api_name;
            const responseCompanyLayout = await getRequest('utils/getDropdownItems/' + objectName + '/' + fieldApiName);
            console.log('value', value);
            console.log('picklist',responseCompanyLayout.data.data);
            setSelectOptions(responseCompanyLayout.data.data);
            setInputValue(checkIfNull(value));
        }
        init();
    },[fieldDetails]);

    return (
        <>
            {selectOptions &&
                <Autocomplete
                    id="free-solo-demo"
                    value={checkIfNull(inputValue)}
                    name={fieldDetails?.field_api_name}
                    onChange={(event, newValue) => {
                        console.log('event.target' , event.currentTarget);
                        setInputValue(newValue);
                        handleChange({ target: {value: newValue, name: fieldDetails?.field_api_name}});
                    }}
                    disabled={readOnly}
                    options={selectOptions.map((option) => option.Value)}
                    renderInput={
                        (params) => <TextField
                        name={fieldDetails?.field_api_name}
                        {...params}
                        label={<IntlMessages id={fieldDetails?.field_label}
                        inputProps={{
                            readOnly: (fieldDetails?.field_access == 'edit' || !readOnly ? false : true),
                            ...params.InputProps,
                          }}
                        />} variant={variant}/>
                    }
                />
            }
        </>
    )
}

export default USPickList;
