import { Avatar, Button, Card, CardActions, CardContent, CardHeader, CardMedia, IconButton, Link, Typography } from "@mui/material";
import { USCard } from "../../../../../USCommonComponents/USCardComponent";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import IntlMessages from 'Util/IntlMessages';
import { red } from "@mui/material/colors";
import { getRequest } from "../../../../../../Api/ApiService/Service";
import { useEffect, useState } from "react";
import { addNotification } from "../../../../../../reduxSettings/reduxSlices/NotificationSlice";

export default function Templates({selectTemplate}) {

    const [templateList, setTemplateList] = useState(null);

    useEffect(() => {
        fetchData();
    },[])

    const fetchData = async () => {
        try {
            const response = await getRequest('/user/recrodslist/storetemplate/1/id/asc/24');
            console.log("response.data.data", response);
            setTemplateList(response.data.data.tableData);
        } catch (error) {
            dispatch(addNotification({"type" : "error", "message" : error, "title" : "load error"}));
        }
    }

    return (
        <div className="row mt-10">
            {templateList && templateList.map((item, key) => (
                <div key={item.id} className="col-sm-12 col-md-3 col-lg-3">
                    <USCard>
                        <CardHeader
                            avatar={
                            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                R
                            </Avatar>
                            }
                            action={
                            <IconButton aria-label="settings">
                                <MoreVertIcon />
                            </IconButton>
                            }
                            title={item.Name}
                            subheader="September 14, 2016"
                        />
                        <CardMedia
                            component="img"
                            height="194"
                            image={item.Image}
                            alt="Paella dish"
                        />
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                {item.ShortDescription}
                            </Typography>
                        </CardContent>
                        <CardActions disableSpacing>
                            <IconButton aria-label="add to favorites">
                                <FavoriteIcon />
                            </IconButton>
                            <IconButton aria-label="share">
                                <ShareIcon />
                            </IconButton>

                            <Button size="small" className="ml-10" fullWidth variant="outlined" onClick={() => selectTemplate(item.id)}>Select</Button>
                            <Link
                                size="small"
                                className="btn btn-outline-primary ml-10"
                                href="http://localhost:8111/"
                                target="_blank"
                                rel="noreferrer"
                                variant="button">
                                Preview
                            </Link>
                        </CardActions>
                    </USCard>
                </div>
            ))}
        </div>
    )
}
