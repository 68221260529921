import React from 'react'
import { useParams } from 'react-router-dom'
import USDetailsPage from '../USDetailsPage';

export default function USViewRecord() {
    const { id, objectName } = useParams();
    return (
        <>
            {id && <USDetailsPage  objectName={objectName} id={id}/>}
        </>

    )
}
