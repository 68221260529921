/**
 * Ecommerce Dashboard
 */

 import React, { Component } from 'react'
 import { Helmet } from "react-helmet";
 // intl messages
 import IntlMessages from 'Util/IntlMessages';

 // page title bar
 import USPageTitleBar from 'Components/USCommonComponents/USPageTitleBar/USPageTitleBar';

 // rct collapsible card
 import USCollapsibleCard from 'Components/USCommonComponents/USCollapsibleCard/USCollapsibleCard';

 import {
     VisitorAreaChartWidget,
     SalesAreaChartWidget,
     OrdersAreaChartWidget,
     RecentOrdersWidget,
     SupportRequest,
     Notifications,
     TopSellingWidget,
     OverallTrafficStatusWidget,
     ProductReportsWidget,
     OnlineVisitorsWidget,
     TodayOrdersStatsWidget,
     BookingInfo,
     NewOrderCountdown,
     FollowersWidget,
     Notes
 } from "Components/USCommonComponents/USDashboard";

 // widgets data
 import {
     visitorsData,
     salesData,
     ordersData,
     topSellingProducts,
     trafficStatus
 } from './data';

const USCompanyDashboard = () => {


    //const [ match, setMatch ] = this.props;
    //console.log('dashboard component', match );

    return (
        <div className="ecom-dashboard-wrapper">
            <Helmet>
                <title>Ecommerce Dashboard</title>
                <meta name="description" content="Reactify Ecommerce Dashboard" />
            </Helmet>
            <USPageTitleBar title={<IntlMessages id="sidebar.ecommerce" />} />
            <div className="row">
                <div className="col-sm-6 col-md-3 w-xs-half-block">
                    <VisitorAreaChartWidget
                        data={visitorsData}
                    />
                </div>

                <div className="col-sm-6 col-md-3 w-xs-half-block">
                    <OrdersAreaChartWidget
                        data={ordersData}
                    />
                </div>
                <div className="col-sm-6 col-md-3 w-xs-full">
                    <SalesAreaChartWidget
                        data={salesData}
                    />
                </div>
                <div className="col-sm-6 col-md-3 w-xs-full">
                    <SalesAreaChartWidget
                        data={salesData}
                    />
                </div>
            </div>
            <div className="row">
                <USCollapsibleCard
                    colClasses="col-sm-12 col-md-4 col-lg-4 w-xs-full"
                    heading={<IntlMessages id="widgets.supportRequest" />}
                    collapsible
                    reloadable
                    closeable
                    fullBlock
                    customClasses="overflow-hidden"
                >
                    <SupportRequest />
                </USCollapsibleCard>
                <USCollapsibleCard
                    colClasses="col-sm-12 col-md-8 col-lg-8 w-xs-full"
                    heading={<IntlMessages id="widgets.RecentOrders" />}
                    collapsible
                    reloadable
                    closeable
                    fullBlock
                >
                    <RecentOrdersWidget />
                </USCollapsibleCard>
            </div>
            <div className="row">
                <USCollapsibleCard
                    customClasses="trafic-bar-chart"
                    colClasses="col-sm-12 col-md-12 col-lg-5 d-sm-full"
                    heading={<IntlMessages id="widgets.overallTrafficStatus" />}
                    collapsible
                    reloadable
                    closeable
                    fullBlock
                >
                    <OverallTrafficStatusWidget
                        chartData={trafficStatus}
                    />
                </USCollapsibleCard>
                <div className="col-sm-12 col-md-12 col-lg-7 d-sm-full">
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <div className="dash-cards">
                                <Notes />
                            </div>
                            <NewOrderCountdown />
                            <TodayOrdersStatsWidget />
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <div className="dash-cards-lg">
                                <OnlineVisitorsWidget />
                            </div>
                            <FollowersWidget />
                            <BookingInfo />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <USCollapsibleCard
                    colClasses="col-sm-12 col-md-4 col-lg-4 w-xs-full"
                    heading={<IntlMessages id="widgets.productReports" />}
                    collapsible
                    reloadable
                    closeable
                    fullBlock
                >
                    <ProductReportsWidget />
                </USCollapsibleCard>
                <USCollapsibleCard
                    colClasses="col-sm-12 col-md-4 col-lg-4 w-xs-full"
                    fullBlock
                    customClasses="overflow-hidden"
                >
                    <Notifications />
                </USCollapsibleCard>
                <USCollapsibleCard
                    colClasses="col-sm-12 col-md-4 col-lg-4 w-xs-full"
                    heading={<IntlMessages id="widgets.topSellings" />}
                    collapsible
                    reloadable
                    closeable
                    fullBlock
                >
                    <TopSellingWidget data={topSellingProducts} />
                </USCollapsibleCard>
            </div>
        </div>
    )
}
export default USCompanyDashboard;
