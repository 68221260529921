import React, { useEffect, useState } from 'react';
// intl messages
import IntlMessages from 'Util/IntlMessages';
import { USCard, USCardContent } from '../../../USCommonComponents/USCardComponent';
import { Button, CardHeader, Stack } from '@mui/material';
import USTable from '../../../USCommonComponents/USTable';

 function USVauchers(props) {

    const navigate = useHistory();
    return (
        <USCard>
            <CardHeader
                action={
                    <Button variant="outlined" onClick={() => navigate.push('edit/gift')}>New</Button>
                }
                title="Vouchers need to create data table"
                className="ml-10 mr-10"
            />
            <USCardContent>
                <USTable tableTitle="Vouchers" objectName={'markup'} requestUrl={'company/list/'} expandableRows={false} hideToolbar={false} hidePagination={false}/>
            </USCardContent>
        </USCard>
    );

 }

 export default USVauchers;
