import { Button, Stack, Switch, TextField, IconButton } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../../../Api/ApiService/Service";
import { USCard, USCardContent } from "../../../USCommonComponents/USCardComponent";
import USCollapsibleCard from "../../../USCommonComponents/USCollapsibleCard/USCollapsibleCard";

import ToysOutlinedIcon from '@mui/icons-material/ToysOutlined';
import TwoWheelerOutlinedIcon from '@mui/icons-material/TwoWheelerOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { Table } from 'reactstrap';
import IntlMessages from 'Util/IntlMessages';
import USCarListModal from "./USCarListModal";

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 200,
      }),
    }
  }));

const USCarsList = () => {

    const [open, setOpen] = useState(false);
    const [searchStr, setSearchStr] = useState('');
    const [brands, setBrands] = useState(null);
    const [selectedCarType, setSelectedCarType] = useState('Car');
    const [initBrands, setInitBrands] = useState([]);
    const [selectedRecord, setSelectedRecord] = useState(null);

    useEffect(() => {
        getBrands(selectedCarType);
    },[])

    const getBrands = async (filteType) => {
        const response = await getRequest('carparts/admin/getBrands/'+filteType);
        console.log(response.data.data);
        const resultData = response.data.data;
        setBrands(resultData);
        setInitBrands(resultData);
    }

    const searchBrand = (searchValue) => {
        if(searchValue != '') {
            const resultData = brands.filter(e => e.Name.toLowerCase().includes(searchValue.toLowerCase()) || e.ExternalBrandId.toLowerCase().includes(searchValue.toLowerCase()));
            setSearchStr(searchValue);
            setBrands(resultData);
        } else {
            setSearchStr('');
            setBrands(initBrands);
        }
    }

    const changeType = async (typeValue) => {
        setSelectedCarType(typeValue);
        getBrands(typeValue);
        searchBrand(searchStr);
    }

    const changeCheckBox = async (recId, checkBoxName) => {
        const response = await getRequest('carparts/admin/brandChangeCheckBox/'+ checkBoxName +'/' + recId + '/'+selectedCarType);
        const responsedata = response.data.data;
        console.log(responsedata);
        setBrands(responsedata);
        setInitBrands(responsedata);
        //searchBrand(searchStr);
    }

    const editRecord = async(recId) => {
        const response = await getRequest('carparts/admin/getBrandById/' + recId);
        setSelectedRecord(response.data.data);
        setOpen(true);
    }

    const handleOnSubmit = async (recordToSave) => {
        const response = await postRequest('carparts/admin/setBrand/' + selectedCarType, recordToSave);
        console.log(response);
        const responsedata = response.data.data;
        setBrands(responsedata);
        setInitBrands(responsedata);
        handleOnClose();
    }

    const handleOnClose = () => {
        setSelectedRecord(null);
        setOpen(false);
    }

    return (
        <div className="responsive-table-wrapper">
            <USCard>
                <USCardContent>
                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <TextField
                            id="standard-with-placeholder"
                            placeholder="Search Brand"
                            size="small"
                            name="Search"
                            label="Search"
                            fullWidth
                            value={searchStr}
                            onChange={(e) => searchBrand(e.target.value)}
                        />
                        <Button onClick={e => changeType('Car')} startIcon={<ToysOutlinedIcon />} variant={selectedCarType === 'Car' ? 'contained':'outlined'}>
                            <IntlMessages id="car.car" />
                        </Button>
                        <Button onClick={e => changeType('Motorbike')} startIcon={<TwoWheelerOutlinedIcon />} variant={selectedCarType === 'Motorbike' ? 'contained':'outlined'}>
                            <IntlMessages id="car.motobike" />
                        </Button>
                        <Button onClick={e => changeType('Truck')} startIcon={<LocalShippingOutlinedIcon />} variant={selectedCarType === 'Truck' ? 'contained':'outlined'}>
                            <IntlMessages id="car.truck" />
                        </Button>
                    </Stack>
                </USCardContent>
            </USCard>
            <USCollapsibleCard heading={<IntlMessages id="sidebar.carsbrands" />}>
                <div className="table-responsive">
                    <div className="unseen">
                        <Table bordered striped>
                            <thead>
                                <tr className="bg-primary text-white">
                                    <th><IntlMessages id="widgets.action" /></th>
                                    <th><IntlMessages id="car.brandid" /></th>
                                    <th><IntlMessages id="common.name" /></th>
                                    <th><IntlMessages id="car.ispopular" /></th>
                                    <th><IntlMessages id="car.logo" /></th>
                                    <th><IntlMessages id="car.showonmain" /></th>
                                    <th><IntlMessages id="car.isactive" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {brands && brands.map((brandItem, key) => {
                                    return <tr key={key}>
                                        <td>
                                            <IconButton onClick={() => editRecord(brandItem.id)}>
                                                <EditOutlinedIcon/>
                                            </IconButton>
                                        </td>
                                        <td>{brandItem.ExternalBrandId} </td>
                                        <td>{brandItem.Name}</td>
                                        <td><IOSSwitch sx={{ m: 1 }} onClick={() => changeCheckBox(brandItem.id, 'isPopular')} checked={brandItem.isPopular}/></td>
                                        <td>{brandItem.Logo}</td>
                                        <td><IOSSwitch sx={{ m: 1 }} onClick={() => changeCheckBox(brandItem.id, 'ShowOnMainPage')} checked={brandItem.ShowOnMainPage}/></td>
                                        <td><IOSSwitch sx={{ m: 1 }} onClick={() => changeCheckBox(brandItem.id, 'isActive')} checked={brandItem.isActive}/></td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </USCollapsibleCard>
            <USCarListModal
                isOpen={open}
                onCloseSupportPage={handleOnClose}
                onSubmit={handleOnSubmit}
                record={selectedRecord}
                header="header name"
                layout={null}
            />
        </div>
    )
}
export default USCarsList;
