import React from 'react';
import { TextField } from "@mui/material";
import IntlMessages from 'Util/IntlMessages';
import USInputHelperIcon from '../USPopups/USInputHelperIcon';

export default function USNumber({fieldDetails, handleChange, value, variant, readOnly, keyItem }) {

    const checkIfNull = (val) => {
        return val != null ? val : '';
    }

    return (
        <TextField
            id={fieldDetails.field_api_name}
            name={fieldDetails.field_api_name}
            label={<IntlMessages id={fieldDetails.field_label} />}
            onChange={(e) => handleChange(e, keyItem)}
            value={checkIfNull(value)}
            variant={variant}
            fullWidth
            margin="dense"
            helperText={fieldDetails.helperText}
            InputProps={{
                readOnly: (fieldDetails.field_access == 'edit' && !readOnly ? false : true),
                inputMode: 'numeric',
                endAdornment:
                (fieldDetails.helperText != '') ? (<USInputHelperIcon text={fieldDetails.helperText}/>)  : null
            ,
            }}
        />
    );
}

