import { Card, CardContent } from '@mui/material';
import React from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd';

export default function USDndNotSelected({notSelectedItems}) {
    return (
        <Droppable 
            droppableId="notSelected"
            direction="horisontal"
        >
            {(provided, snapshot) => {
                return <Card
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >   
                    <CardContent>
                        {notSelectedItems && notSelectedItems.map((items, index) => {
                            return (
                                <Draggable
                                key={items.field_api_name}
                                draggableId={items.field_api_name}
                                index={index}
                            >
                                {(provided, snapshot) => {
                                    return (
                                        <div className='col-sm-6 col-md-6 col-lg-6'
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <div key={index}>{items.field_api_name}</div>
                                        </div>
                                    );
                                }}
                            </Draggable>
                            )
                        })}
                        {provided.placeholder}
                    </CardContent>  
                </Card>
            }}
        </Droppable>
    )
}
