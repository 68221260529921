import { Button, CardHeader } from "@mui/material";
import { CardActions, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRequest, postRequest } from "../../../../../../Api/ApiService/Service";
import USIOSSwitch from "../../../../../USCommonComponents/USIOSSwitch";
import { USCard, USCardContent } from "../../../../../USCommonComponents/USCardComponent";
import { Element } from "../../../../../../helpers/RouteHelper";
import USNewMappingModal from "../USPriceSettingsHelper/USNewMappingModal";
import USTable from "../../../../../USCommonComponents/USTable";


function USEditEmailFilterSettings() {
    const {id} = useParams();
    const [objectFields, setObjectFields] = useState({
        'Name' : '',
        'isActive' : true,
        'EmailFrom' : '',
        'FolderName' : '',
        'SubjectHas' : '',
        'TextHas' : '',
        'DateFrom' : '',
        'EmailConnectSettingsId' : null,
        'AccountId' : localStorage.getItem('currentAccount')
    });

    const handleFieldValue = e => {
        const fieldName = e.target.name;
        let fieldValue = e.target.value;
        console.log(e);
        console.log(e.target.name, e.target.checked);

        if(fieldName === "isActive" || fieldName === "ValidateCerteficate") {
            if(objectFields[fieldName]) {
                fieldValue = false;
            } else {
                fieldValue = true;
            }
        }

        //const value = e.target.value != null && e.target.value.hasOwnProperty('value') ? e.target.value.value : e.target.value ;
        setObjectFields(existingValues => ({
            // Retain the existing values
            ...existingValues,
            // update the current field
            [fieldName]: fieldValue,
        }))
    }

    const handleSave = async () => {
        console.log(objectFields);
        const response = await postRequest('/company/edit/emailfiltersetting/',objectFields);
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await getRequest('company/view/emailfiltersetting/'+id);
            const responseData = response.data.data;
            console.log(responseData);
            setObjectFields(responseData.recordData);
        }
        fetchData();
    },[id])


    const [refreshMapping, setRefreshMapping] = useState();
    const [isMappingModalOpen, setMappingIsModalOpen] = useState(false);

    const handleMappingCloseModal = () => {
        setMappingIsModalOpen(false);
    }

    const handleMappingSaveModal = async (newData) => {
        try {
            newData.EmailConnectSettingsId = objectFields['EmailConnectSettingsId'];
            newData.EmailFilterSettingsId = id;
            console.log(newData);

            const response = await postRequest('/company/edit/filemapping/', newData);
            console.log(response);
            var currentdate = new Date();
            setRefreshMapping(currentdate.getSeconds());
            setMappingIsModalOpen(false);
        } catch(e) {
            console.log(e);
        }
    }

    return (
        <>
            <USCard>
                <CardHeader
                    title="Email Filter Settings"
                    className="ml-10 mr-10"
                />
                <USCardContent>
                    {id && <div className="row" >
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="row" >
                            <div className="col-sm-12 col-md-12 col-lg-12" >
                                    <TextField id="standard-basic" label="Name" name="Name" onChange={(e) => handleFieldValue(e)} value={objectFields['Name']} variant="standard"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12 mt-10" >
                                    <TextField id="standard-basic" label="Email From" name="EmailFrom" onChange={(e) => handleFieldValue(e)} value={objectFields['EmailFrom']} variant="standard"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}/>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12 mt-10" >
                                    <TextField id="standard-basic" label="Folder Name" name="FolderName" onChange={(e) => handleFieldValue(e)} value={objectFields['FolderName']} variant="standard"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="row" style={{ display:'block' }}>
                                <div className="col-sm-12 col-md-12 col-lg-12" >
                                    <Stack direction="row" spacing={2} alignItems="left" >
                                        <Typography>Active</Typography>
                                        <USIOSSwitch name="isActive" onChange={(e) => handleFieldValue(e) } checked={objectFields['isActive']} />
                                    </Stack>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12  mt-10" >
                                    <TextField id="standard-basic" label="Subject Has" name="SubjectHas" onChange={(e) => handleFieldValue(e)} value={objectFields['SubjectHas']} variant="standard"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12 mt-10" >
                                    <TextField id="standard-basic" label="Text Has" name="TextHas" onChange={(e) => handleFieldValue(e)} value={objectFields['TextHas']} variant="standard"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12 mt-10" >
                                    <Element fieldDetails={{
                                        "field_api_name": "DateFrom",
                                        "field_label": "request.end-date",
                                        "field_mandatory": "false",
                                        "field_placeholder": "",
                                        "field_type": "date",
                                        "helperText": "",
                                        "field_access": "edit"
                                    }} handleEdit={handleFieldValue} value={objectFields['DateFrom']} variant="standard"/>
                                </div>
                            </div>
                        </div>
                    </div>}
                </USCardContent>
                <CardActions className='pb-20 ml-10 justify-content-center'>
                    <Button variant='outlined' size="small" color="success" onClick={handleSave}>Save</Button>
                </CardActions>
            </USCard>

            <USCard>
                <CardHeader
                    action={
                        <Button variant="outlined" onClick={() => setMappingIsModalOpen(true)}>New</Button>
                    }
                    title="File Mapping"
                    className="ml-10 mr-10"
                />
                <USCardContent>
                    <USTable tableTitle="File Mappings" refres={refreshMapping} objectName={'filemapping'} requestUrl={'company/child/EmailFilterSettingsId/' + id + '/'} expandableRows={false} hideToolbar={true} hidePagination={true}/>
                </USCardContent>
            </USCard>
            {isMappingModalOpen && <USNewMappingModal isModalOpen={isMappingModalOpen} saveModal={handleMappingSaveModal} closeModal={handleMappingCloseModal} />}
        </>
    )

}
export default USEditEmailFilterSettings;
